import { Box, Container, Typography, Button, Theme } from '@mui/material';
import { Link } from 'react-router-dom';

import useDocumentTitle from 'hooks/useDocumentTitle';

type ErrorPageProps = {
    title: string;
    subTitle: string;
    description: string;
};

function ErrorPage({ title, subTitle, description }: ErrorPageProps) {
    useDocumentTitle(title);

    return (
        <Box
            display="flex"
            flexDirection="column"
            height="100vh"
            textAlign="center"
            justifyContent="center"
            sx={{ backgroundColor: (theme: Theme) => `${theme.palette.mode === 'dark' ? theme.palette.background.default : '#e4f5ff'}` }}
        >
            <Container maxWidth="md">
                <Typography
                    align="center"
                    variant="h1"
                    sx={{
                        pt: 2
                    }}
                >
                    {subTitle}
                </Typography>
                <Typography
                    align="center"
                    variant="h4"
                    sx={{
                        pt: 1,
                        pb: 3
                    }}
                >
                    {description}
                </Typography>
                <Button color="primary" variant="contained" component={Link} to="/" disableElevation>
                    Back to Home
                </Button>
            </Container>
        </Box>
    );
}

export default ErrorPage;
