import {
    Dialog,
    ListItemText,
    MenuItem,
    Checkbox,
    Box,
    TextField,
    DialogContent,
    Button,
    Typography,
    DialogActions,
    IconButton,
    DialogTitle,
    Autocomplete
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import { useState } from 'react';
import SearchTag from 'components/filter/SearchTag';
import { useAppSelector } from 'store/hooks';
import { useMutation } from '@apollo/client';
import { useNotification } from 'hooks/useNotification';
import { ADD_ALERT_PRICE_MUTATION } from 'services/settings/queries';
import WebsiteImage from '../Websites/WebsiteImage';

interface ProductWebsiteType {
    websiteId: string;
    id: string;
    websiteAlias: string;
    price: number;
    name: string;
    availability: string;
    marketplace?: boolean;
    url: string;
    websiteLastDataDate?: string;
    productLastUpdate?: string;
    brand: string;
    offers?: any[] | null;
}

interface PriceHistoryModalProps {
    open: boolean;
    handleClose: () => void;
    handleCreated: (saved: boolean, id: string) => void;
    productRef?: string;
    websites: ProductWebsiteType[];
}

function PriceAlertTrackingModal({ open, handleClose, handleCreated, productRef, websites }: PriceHistoryModalProps) {
    const { displayNotification } = useNotification();
    const [selectedWebsites, setSelectedWebsites] = useState<any[]>([]);
    const [percentage, setPercentage] = useState<number>(-5);
    const { selectedRegion, userInfo } = useAppSelector((state) => state.auth);
    const [selectAllWebsite, setSelectAllWebsite] = useState(websites.length === selectedWebsites.length ? true : false);

    const [emails, setEmails] = useState<string[]>([userInfo.email]);
    const [addAlertPrice] = useMutation(ADD_ALERT_PRICE_MUTATION);

    const showToastMessage = (msg: string, success = true) => {
        displayNotification({
            message: `${msg}`,
            type: success ? 'success' : 'error',
            horizontal: 'right'
        });
    };

    const handleCreateAlert = async () => {
        if (!selectedWebsites.length) {
            showToastMessage(`Please select at least one website !`, false);
            return;
        }
        if (!percentage) {
            showToastMessage(`Please enter a percentage !`, false);
            return;
        }
        if (!emails.length) {
            showToastMessage(`Please enter at least one email !`, false);
            return;
        }
        if (!userInfo.id) {
            showToastMessage(`Please login to create an alert !`, false);
            return;
        }
        if (!productRef) {
            showToastMessage(`Please enter a product reference !`, false);
            return;
        }
        await addAlertPrice({
            variables: {
                region: selectedRegion,
                user: userInfo?.id,
                productRef,
                selectedWebsites,
                percentage,
                emails
            }
        })
            .then((res) => {
                if (res.data.addAlertPrice.id) {
                    showToastMessage(`Cool ! your changes just been saved to our database :) `, true);
                    handleCreated(true, res.data.addAlertPrice.id);
                    handleClose();
                } else {
                    showToastMessage(`Oops ! something go wrong retry later !`, false);
                }
            })
            .catch((err) => {
                console.log('error :', err);
                showToastMessage(`Oops ! something go wrong retry later ! ${err.message}`, false);
            });
    };

    const handleChangeEmails = (tags: string[]) => {
        setEmails(tags);
    };

    const handleSelectedWebsitesChange = (event: any, newValue: any[]) => {
        if (newValue.length) {
            setSelectedWebsites(newValue);
        } else {
            setSelectedWebsites([]);
        }
    };

    const handleSelectAllWebsite = () => {
        if (!selectAllWebsite) {
            setSelectedWebsites(websites);
        } else {
            setSelectedWebsites([]);
        }
        setSelectAllWebsite((prev) => !prev);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{ sx: { borderRadius: '20px' } }}
        >
            <DialogTitle id="scroll-dialog-title">
                <Typography variant="h5" component="p">
                    💰 Create Price Alert Tracking for this GTIN : {productRef}
                </Typography>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers={true}>
                <form onSubmit={handleCreateAlert}>
                    <Box>
                        <Autocomplete
                            size="small"
                            sx={{ mt: '10px', mb: '10px' }}
                            multiple
                            clearOnEscape
                            id="selected-websites"
                            onChange={handleSelectedWebsitesChange}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            value={selectedWebsites || null}
                            renderTags={() => null}
                            options={[{ name: 'Select All', id: 'all' }, ...websites]}
                            disableCloseOnSelect
                            getOptionLabel={(option: any) => option.name}
                            disableClearable
                            renderOption={(props, option: any) => {
                                return option.name == 'Select All' ? (
                                    <MenuItem selected={false} sx={{ p: 0 }} onClick={handleSelectAllWebsite} key={option?.id}>
                                        <Checkbox size="small" sx={{ mr: 1 }} checked={selectAllWebsite} />
                                        <ListItemText>Select All</ListItemText>
                                    </MenuItem>
                                ) : (
                                    <Box
                                        // key={option?.id}
                                        component="li"
                                        sx={{
                                            '& > img': { mr: 2, flexShrink: 0 },
                                            my: 0.3
                                        }}
                                        {...props}
                                    >
                                        <WebsiteImage
                                            name={option?.websiteAlias}
                                            websiteId={option?.id}
                                            websiteUrl={option.url}
                                            height={20}
                                            width={20}
                                        />
                                        {option?.websiteAlias}
                                    </Box>
                                );
                            }}
                            renderInput={(params) => <TextField {...params} placeholder={`${selectedWebsites.length} Selected Websites`} />}
                        />
                    </Box>
                    <TextField
                        type="number"
                        variant="outlined"
                        color="secondary"
                        size="small"
                        label="Percentage (%)"
                        sx={{ mt: '10px', mb: '10px' }}
                        onChange={(e) => setPercentage(parseFloat(e.target.value))}
                        value={percentage}
                        fullWidth
                        required
                    />
                    <Box sx={{ mt: '10px', mb: '10px' }}>
                        <SearchTag
                            tagsData={emails || []}
                            tagPlaceholder="Emails"
                            onChangeTag={(tags: string[]) => handleChangeEmails(tags)}
                        />
                    </Box>
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleCreateAlert}>Ok</Button>
            </DialogActions>
        </Dialog>
    );
}

export default PriceAlertTrackingModal;
