import { gql } from '@apollo/client';

/**
 * Relevant queries
 */
export const RELEVANT_COUNT_QUERY = gql`
    query relevantCount(
        $region: String!
        $website: WebsiteArgs
        $website_amazon: WebsiteArgs
        $config: JSON
        $websites: [WebsiteArgs!]
        $from: Int
        $size: Int
        $marketplace: Int
        $availability: String
        $keywords: String
        $advancedSearch: JSON
        $hideBundles: Boolean
        $hideRefurbished: Boolean
        $hideMarketplace: Boolean
        $hidelost: Boolean
        $pgcategoryid: String
        $instock: Boolean
        $fromsuite: Boolean
        $onlyGfk: Boolean
        $access: String
        $reviewedAccount: String
        $loadReviewed: Boolean
        $firstTimeDiscoverd: String
        $brands: String
    ) {
        relevantCount(
            region: $region
            website: $website
            website_amazon: $website_amazon
            config: $config
            websites: $websites
            from: $from
            size: $size
            hidelost: $hidelost
            marketplace: $marketplace
            availability: $availability
            keywords: $keywords
            advancedSearch: $advancedSearch
            hideBundles: $hideBundles
            hideRefurbished: $hideRefurbished
            hideMarketplace: $hideMarketplace
            pgcategoryid: $pgcategoryid
            instock: $instock
            fromsuite: $fromsuite
            onlyGfk: $onlyGfk
            access: $access
            reviewedAccount: $reviewedAccount
            loadReviewed: $loadReviewed
            firstTimeDiscoverd: $firstTimeDiscoverd
            brands: $brands
        ) {
            error
            xAxisMarket
            seriesMarket
            colorsMarket
            legendMarket
            seriesBrand
            legendBrand
            seriesCategory
            legendCategory
            queryHash
        }
    }
`;
export const RELEVANT_PRODUCTS_QUERY = gql`
    query relevantProducts(
        $region: String!
        $website: WebsiteArgs
        $website_amazon: WebsiteArgs
        $config: JSON
        $websites: [WebsiteArgs!]
        $sort: [String!]
        $from: Int
        $size: Int
        $marketplace: Int
        $availability: String
        $keywords: String
        $advancedSearch: JSON
        $hidelost: Boolean
        $hideBundles: Boolean
        $hideRefurbished: Boolean
        $hideMarketplace: Boolean
        $pgcategoryid: String
        $instock: Boolean
        $fromsuite: Boolean
        $betterway: Boolean
        $loadGfk: Boolean
        $access: String
        $task: String
        $user: String
        $export: Boolean
        $fileName: String
        $reviewedAccount: String
        $loadReviewed: Boolean
        $gfkId: String
        $onlyGfk: Boolean
        $firstTimeDiscoverd: String
        $brands: String
    ) {
        relevantProducts(
            region: $region
            website: $website
            website_amazon: $website_amazon
            config: $config
            websites: $websites
            sort: $sort
            from: $from
            size: $size
            marketplace: $marketplace
            availability: $availability
            keywords: $keywords
            advancedSearch: $advancedSearch
            hidelost: $hidelost
            hideBundles: $hideBundles
            hideRefurbished: $hideRefurbished
            hideMarketplace: $hideMarketplace
            pgcategoryid: $pgcategoryid
            instock: $instock
            fromsuite: $fromsuite
            betterway: $betterway
            loadGfk: $loadGfk
            access: $access
            task: $task
            user: $user
            export: $export
            fileName: $fileName
            reviewedAccount: $reviewedAccount
            loadReviewed: $loadReviewed
            gfkId: $gfkId
            onlyGfk: $onlyGfk
            firstTimeDiscoverd: $firstTimeDiscoverd
            brands: $brands
        ) {
            error
            exportResponse
            queryHash
            avgrelevance
            loadedFromCache
            totalIndex
            total
            exclude
            data
        }
    }
`;

/**
 * Listing Wizard queries
 */
export const LISTING_STATS = gql`
    query listingStats(
        $region: String!
        $website: WebsiteArgs
        $websites: [WebsiteArgs!]
        $from: Int
        $size: Int
        $keywords: String
        $advancedSearch: JSON
        $hideBundles: Boolean
        $hideRefurbished: Boolean
        $hideMarketplace: Boolean
    ) {
        listingStats(
            region: $region
            website: $website
            websites: $websites
            from: $from
            size: $size
            keywords: $keywords
            advancedSearch: $advancedSearch
            hideBundles: $hideBundles
            hideRefurbished: $hideRefurbished
            hideMarketplace: $hideMarketplace
        ) {
            listingStats {
                total
                totalMms
                avgrelevance
                bellowAvg
                toMaintain
                aboveAvg
            }
            error
            website
        }
    }
`;
export const LISTING_DIST_STATS = gql`
    query listingDistChart(
        $region: String!
        $website: WebsiteArgs
        $websites: [WebsiteArgs!]
        $from: Int
        $size: Int
        $keywords: String
        $advancedSearch: JSON
        $hideBundles: Boolean
        $hideRefurbished: Boolean
        $hideMarketplace: Boolean
    ) {
        listingDistChart(
            region: $region
            website: $website
            websites: $websites
            from: $from
            size: $size
            keywords: $keywords
            advancedSearch: $advancedSearch
            hideBundles: $hideBundles
            hideRefurbished: $hideRefurbished
            hideMarketplace: $hideMarketplace
        ) {
            listingStats {
                data
                total
            }
            error
            website
        }
    }
`;
export const LISTING_DATA_QUERY = gql`
    query listingData(
        $region: String!
        $website: WebsiteArgs
        $websites: [WebsiteArgs!]
        $from: Int
        $size: Int
        $aboveRelevance: Float
        $bellowRelevance: Float
        $bellowAvg: Boolean
        $aboveAvg: Boolean
        $keywords: String
        $advancedSearch: JSON
        $hideBundles: Boolean
        $hideRefurbished: Boolean
        $hideMarketplace: Boolean
        $sort: [String!]
        $loadGfk: Boolean
        $access: String
        $task: String
        $user: String
        $export: Boolean
        $fileName: String
        $reviewedAccount: String
        $loadReviewed: Boolean
        $config: JSON
    ) {
        listingData(
            region: $region
            website: $website
            websites: $websites
            from: $from
            size: $size
            keywords: $keywords
            advancedSearch: $advancedSearch
            hideBundles: $hideBundles
            hideRefurbished: $hideRefurbished
            hideMarketplace: $hideMarketplace
            aboveRelevance: $aboveRelevance
            bellowRelevance: $bellowRelevance
            bellowAvg: $bellowAvg
            aboveAvg: $aboveAvg
            sort: $sort
            loadGfk: $loadGfk
            access: $access
            task: $task
            user: $user
            export: $export
            fileName: $fileName
            reviewedAccount: $reviewedAccount
            loadReviewed: $loadReviewed
            config: $config
        ) {
            exportResponse
            data
            total
            error
            exclude
        }
    }
`;

/**
 * Google Listing Wizard queries
 */
export const GOOGLE_LISTING_STATS = gql`
    query GoogleListingStats(
        $region: String!
        $website: WebsiteArgs
        $websites: [WebsiteArgs!]
        $from: Int
        $size: Int
        $googleRef: String
        $keywords: String
        $advancedSearch: JSON
        $hideBundles: Boolean
        $hideRefurbished: Boolean
        $hideMarketplace: Boolean
    ) {
        GoogleListingStats(
            region: $region
            website: $website
            websites: $websites
            from: $from
            size: $size
            googleRef: $googleRef
            keywords: $keywords
            advancedSearch: $advancedSearch
            hideBundles: $hideBundles
            hideRefurbished: $hideRefurbished
            hideMarketplace: $hideMarketplace
        ) {
            listingStats {
                total
                totalMms
                avgrelevance
                bellowAvg
                toMaintain
                aboveAvg
            }
            error
            website
        }
    }
`;
export const GOOGLE_LISTING_DIST_STATS = gql`
    query GoogleListingDistChart(
        $region: String!
        $website: WebsiteArgs
        $websites: [WebsiteArgs!]
        $from: Int
        $size: Int
        $keywords: String
        $advancedSearch: JSON
        $hideBundles: Boolean
        $hideRefurbished: Boolean
        $hideMarketplace: Boolean
    ) {
        GoogleListingDistChart(
            region: $region
            website: $website
            websites: $websites
            from: $from
            size: $size
            keywords: $keywords
            advancedSearch: $advancedSearch
            hideBundles: $hideBundles
            hideRefurbished: $hideRefurbished
            hideMarketplace: $hideMarketplace
        ) {
            listingStats {
                data
                total
            }
            error
            website
        }
    }
`;
export const GOOGLE_LISTING_DATA_QUERY = gql`
    query GoogleListingData(
        $region: String!
        $website: WebsiteArgs
        $websites: [WebsiteArgs!]
        $from: Int
        $size: Int
        $aboveRelevance: Float
        $bellowRelevance: Float
        $bellowAvg: Boolean
        $aboveAvg: Boolean
        $keywords: String
        $advancedSearch: JSON
        $hideBundles: Boolean
        $hideRefurbished: Boolean
        $hideMarketplace: Boolean
        $sort: [String!]
        $loadGfk: Boolean
        $access: String
        $task: String
        $user: String
        $export: Boolean
        $fileName: String
        $reviewedAccount: String
        $loadReviewed: Boolean
        $config: JSON
    ) {
        GoogleListingData(
            region: $region
            website: $website
            websites: $websites
            from: $from
            size: $size
            keywords: $keywords
            advancedSearch: $advancedSearch
            hideBundles: $hideBundles
            hideRefurbished: $hideRefurbished
            hideMarketplace: $hideMarketplace
            aboveRelevance: $aboveRelevance
            bellowRelevance: $bellowRelevance
            bellowAvg: $bellowAvg
            aboveAvg: $aboveAvg
            sort: $sort
            loadGfk: $loadGfk
            access: $access
            task: $task
            user: $user
            export: $export
            fileName: $fileName
            reviewedAccount: $reviewedAccount
            loadReviewed: $loadReviewed
            config: $config
        ) {
            exportResponse
            queryHash
            data
            total
            error
            exclude
        }
    }
`;

/**
 * Stock Exclusive queries
 */
export const EXCLUSIVES_CHART = gql`
    query exclusivesChart(
        $region: String!
        $website: WebsiteArgs
        $config: JSON
        $fromdate: String
        $todate: String
        $websites: [WebsiteArgs!]
        $marketplace: Int
        $availability: String
        $keywords: String
        $advancedSearch: JSON
        $hideBundles: Boolean
        $hideRefurbished: Boolean
        $hideMarketplace: Boolean
    ) {
        exclusivesChart(
            region: $region
            website: $website
            config: $config
            fromdate: $fromdate
            todate: $todate
            websites: $websites
            marketplace: $marketplace
            availability: $availability
            keywords: $keywords
            advancedSearch: $advancedSearch
            hideBundles: $hideBundles
            hideRefurbished: $hideRefurbished
            hideMarketplace: $hideMarketplace
        ) {
            error
            xAxisMarket
            seriesMarket
            colorsMarket
            legendMarket
            seriesBrand
            legendBrand
            seriesCategory
            legendCategory
            queryHash
        }
    }
`;
export const EXCLUSIVES_PRODUCTS = gql`
    query exclusivesProducts(
        $region: String!
        $website: WebsiteArgs
        $sort: [String!]
        $date: String
        $config: JSON
        $websites: [WebsiteArgs!]
        $from: Int
        $size: Int
        $marketplace: Int
        $availability: String
        $keywords: String
        $advancedSearch: JSON
        $hideBundles: Boolean
        $hideRefurbished: Boolean
        $hideMarketplace: Boolean
        $access: String
        $task: String
        $user: String
        $export: Boolean
        $fileName: String
        $loadGfk: Boolean
    ) {
        exclusivesProducts(
            region: $region
            website: $website
            sort: $sort
            date: $date
            config: $config
            websites: $websites
            from: $from
            size: $size
            marketplace: $marketplace
            availability: $availability
            keywords: $keywords
            advancedSearch: $advancedSearch
            hideBundles: $hideBundles
            hideRefurbished: $hideRefurbished
            hideMarketplace: $hideMarketplace
            access: $access
            task: $task
            user: $user
            export: $export
            fileName: $fileName
            loadGfk: $loadGfk
        ) {
            error
            exportResponse
            queryHash
            avgrelevance
            loadedFromCache
            totalIndex
            total
            exclude
            data
        }
    }
`;

/**
 * Custom Collections queries
 */
export const NEW_CUSTOM_COLLECTION_MUTATION = gql`
    mutation newCustomCollection(
        $region: String!
        $user: String
        $name: String
        $filter: JSON
        $website: WebsiteArgs
        $websites: [WebsiteArgs!]
    ) {
        newCustomCollection(region: $region, user: $user, filter: $filter, name: $name, website: $website, websites: $websites) {
            filter
            result
            name
            user
            id
        }
    }
`;
export const CUSTOM_COLLECTION_QUERY = gql`
    query customCategoriesSearch($region: String!, $q: String, $user: String, $size: Int) {
        customCategoriesSearch(region: $region, q: $q, user: $user, size: $size) {
            categories
            error
        }
    }
`;
export const GET_CUSTOM_COLLECTION_QUERY = gql`
    query GetCustomCategory($region: String!, $id: String) {
        GetCustomCategory(region: $region, id: $id) {
            category {
                data
                id
            }
            error
        }
    }
`;

/**
 * Catalog Tracking queries
 */
export const FRESH_CATALOG_DISTRIBUTION = gql`
    query freshCatalogChart(
        $region: String!
        $website: WebsiteArgs
        $website_amazon: WebsiteArgs
        $config: JSON
        $websites: [WebsiteArgs!]
        $from: Int
        $size: Int
        $marketplace: Int
        $availability: String
        $keywords: String
        $advancedSearch: JSON
        $hideBundles: Boolean
        $hideRefurbished: Boolean
        $hideMarketplace: Boolean
        $pgcategoryid: String
        $instock: Boolean
        $fromsuite: Boolean
        $date: String
        $brands: String
    ) {
        freshCatalogChart(
            region: $region
            website: $website
            website_amazon: $website_amazon
            config: $config
            websites: $websites
            from: $from
            size: $size
            marketplace: $marketplace
            availability: $availability
            keywords: $keywords
            advancedSearch: $advancedSearch
            hideBundles: $hideBundles
            hideRefurbished: $hideRefurbished
            hideMarketplace: $hideMarketplace
            pgcategoryid: $pgcategoryid
            instock: $instock
            fromsuite: $fromsuite
            date: $date
            brands: $brands
        ) {
            error
            series
            xAxis {
                data
                axisLabel
                type
            }
            color
            legend {
                data
                left
                right
                type
            }
            date
        }
    }
`;
export const FRESH_CATALOG_AVAILABILITY_RATE = gql`
    query freshCatalogAvailabilityRateChart(
        $region: String!
        $website: WebsiteArgs
        $website_amazon: WebsiteArgs
        $config: JSON
        $websites: [WebsiteArgs!]
        $from: Int
        $size: Int
        $marketplace: Int
        $availability: String
        $keywords: String
        $advancedSearch: JSON
        $hideBundles: Boolean
        $hideRefurbished: Boolean
        $hideMarketplace: Boolean
        $pgcategoryid: String
        $instock: Boolean
        $fromsuite: Boolean
        $date: String
        $brands: String
    ) {
        freshCatalogAvailabilityRateChart(
            region: $region
            website: $website
            website_amazon: $website_amazon
            config: $config
            websites: $websites
            from: $from
            size: $size
            marketplace: $marketplace
            availability: $availability
            keywords: $keywords
            advancedSearch: $advancedSearch
            hideBundles: $hideBundles
            hideRefurbished: $hideRefurbished
            hideMarketplace: $hideMarketplace
            pgcategoryid: $pgcategoryid
            instock: $instock
            fromsuite: $fromsuite
            date: $date
            brands: $brands
        ) {
            error
            series
            xAxis {
                data
                type
            }
            queryHash
            date
        }
    }
`;
export const SHIPPING_DISTRIBUTION = gql`
    query shippingDistribution(
        $region: String!
        $website: WebsiteArgs
        $website_amazon: WebsiteArgs
        $config: JSON
        $websites: [WebsiteArgs!]
        $from: Int
        $size: Int
        $marketplace: Int
        $availability: String
        $keywords: String
        $advancedSearch: JSON
        $hideBundles: Boolean
        $hideRefurbished: Boolean
        $hideMarketplace: Boolean
        $pgcategoryid: String
        $instock: Boolean
        $fromsuite: Boolean
        $brands: String
    ) {
        shippingDistribution(
            region: $region
            website: $website
            website_amazon: $website_amazon
            config: $config
            websites: $websites
            from: $from
            size: $size
            marketplace: $marketplace
            availability: $availability
            keywords: $keywords
            advancedSearch: $advancedSearch
            hideBundles: $hideBundles
            hideRefurbished: $hideRefurbished
            hideMarketplace: $hideMarketplace
            pgcategoryid: $pgcategoryid
            instock: $instock
            fromsuite: $fromsuite
            brands: $brands
        ) {
            error
            series
            xAxis {
                data
                type
            }
            queryHash
            date
        }
    }
`;
export const PRODUCT_LISTING = gql`
    query catalogProducts(
        $region: String!
        $website: WebsiteArgs
        $brands: String
        $sort: [String!]
        $website_amazon: WebsiteArgs
        $config: JSON
        $websites: [WebsiteArgs!]
        $from: Int
        $size: Int
        $marketplace: Int
        $availability: String
        $keywords: String
        $advancedSearch: JSON
        $hideBundles: Boolean
        $hideRefurbished: Boolean
        $hideMarketplace: Boolean
        $pgcategoryid: String
        $instock: Boolean
        $fromsuite: Boolean
        $access: String
        $task: String
        $user: String
        $export: Boolean
        $fileName: String
    ) {
        catalogProducts(
            region: $region
            website: $website
            brands: $brands
            sort: $sort
            website_amazon: $website_amazon
            config: $config
            websites: $websites
            from: $from
            size: $size
            marketplace: $marketplace
            availability: $availability
            keywords: $keywords
            advancedSearch: $advancedSearch
            hideBundles: $hideBundles
            hideRefurbished: $hideRefurbished
            hideMarketplace: $hideMarketplace
            pgcategoryid: $pgcategoryid
            instock: $instock
            fromsuite: $fromsuite
            access: $access
            task: $task
            user: $user
            export: $export
            fileName: $fileName
        ) {
            error
            total
            data
            message
            date
            exportResponse
        }
    }
`;

/**
 * Amazon Best Seller queries
 */
export const AMAZON_BEST_SELLER_PRODUCTS = gql`
    query amazonBestSellers(
        $region: String!
        $category: CategoryArgs
        $website: WebsiteArgs
        $website_amazon: WebsiteArgs!
        $date: String
        $websites: [WebsiteArgs!]
        $from: Int
        $size: Int
        $keywords: String
        $searchkey: String
        $advancedSearch: JSON
        $access: String
        $task: String
        $user: String
        $export: Boolean
        $fileName: String
    ) {
        amazonBestSellers(
            region: $region
            category: $category
            website: $website
            website_amazon: $website_amazon
            date: $date
            websites: $websites
            from: $from
            size: $size
            keywords: $keywords
            searchkey: $searchkey
            advancedSearch: $advancedSearch
            access: $access
            task: $task
            user: $user
            export: $export
            fileName: $fileName
        ) {
            exportResponse
            data
            products
            total
            totalindex
            date
            default {
                id
                name
                alias
                url
                data
            }
            website_amazon {
                id
                name
                alias
                url
                data
            }
            error
            bestsellerCat
        }
    }
`;

/**
 * Market Prices queries
 */
export const COMPETITOR_CHART = gql`
    query dailyAvgPrice(
        $region: String!
        $website: WebsiteArgs
        $fromdate: String
        $todate: String
        $websites: [WebsiteArgs!]
        $from: Int
        $size: Int
        $keywords: String
        $advancedSearch: JSON
        $hideBundles: Boolean
        $hideRefurbished: Boolean
        $hideMarketplace: Boolean
        $brands: String
    ) {
        dailyAvgPrice(
            region: $region
            website: $website
            fromdate: $fromdate
            todate: $todate
            websites: $websites
            from: $from
            size: $size
            keywords: $keywords
            advancedSearch: $advancedSearch
            hideBundles: $hideBundles
            hideRefurbished: $hideRefurbished
            hideMarketplace: $hideMarketplace
            brands: $brands
        ) {
            series
            xAxis {
                data
                axisLabel
            }
            error
        }
    }
`;
export const COMPETITOR_PRODUCTS = gql`
    query competitorsPricingProducts(
        $region: String!
        $website: WebsiteArgs
        $sort: [String!]
        $websites: [WebsiteArgs!]
        $from: Int
        $size: Int
        $keywords: String
        $brands: String
        $advancedSearch: JSON
        $hideBundles: Boolean
        $hideRefurbished: Boolean
        $hideMarketplace: Boolean
        $access: String
        $task: String
        $user: String
        $export: Boolean
        $fileName: String
        $reviewedAccount: String
        $loadReviewed: Boolean
        $loadGfk: Boolean
        $config: JSON
    ) {
        competitorsPricingProducts(
            region: $region
            sort: $sort
            website: $website
            websites: $websites
            from: $from
            size: $size
            keywords: $keywords
            brands: $brands
            advancedSearch: $advancedSearch
            hideBundles: $hideBundles
            hideRefurbished: $hideRefurbished
            hideMarketplace: $hideMarketplace
            access: $access
            task: $task
            user: $user
            export: $export
            fileName: $fileName
            reviewedAccount: $reviewedAccount
            loadReviewed: $loadReviewed
            loadGfk: $loadGfk
            config: $config
        ) {
            exportResponse
            queryHash
            data
            total
            message
            error
            website
            websites {
                id
                name
                alias
                url
                data
            }
        }
    }
`;

/**
 * Best Rated queries
 */
export const BEST_RATED_PRODUCTS = gql`
    query bestRatedProducts(
        $region: String!
        $website: WebsiteArgs
        $websites: [WebsiteArgs!]
        $sort: [String!]
        $from: Int
        $size: Int
        $keywords: String
        $advancedSearch: JSON
        $hideBundles: Boolean
        $hidelost: Boolean
        $hideRefurbished: Boolean
        $hideMarketplace: Boolean
        $loadGfk: Boolean
        $access: String
        $task: String
        $user: String
        $export: Boolean
        $fileName: String
        $reviewedAccount: String
        $loadReviewed: Boolean
        $brands: String
    ) {
        bestRatedProducts(
            region: $region
            website: $website
            websites: $websites
            sort: $sort
            from: $from
            size: $size
            keywords: $keywords
            advancedSearch: $advancedSearch
            hidelost: $hidelost
            hideBundles: $hideBundles
            hideRefurbished: $hideRefurbished
            hideMarketplace: $hideMarketplace
            loadGfk: $loadGfk
            access: $access
            task: $task
            user: $user
            export: $export
            fileName: $fileName
            reviewedAccount: $reviewedAccount
            loadReviewed: $loadReviewed
            brands: $brands
        ) {
            data
            exportResponse
            total
            queryHash
            websites {
                id
                name
                alias
                url
                data
            }
            website
            error
            message
        }
    }
`;

/**
 * Opportunities queries
 */
export const OPPORTUNITIES_OVERVIEW = gql`
    query opportunitiesChart(
        $region: String!
        $website: WebsiteArgs
        $website_amazon: WebsiteArgs
        $config: JSON
        $websites: [WebsiteArgs!]
        $from: Int
        $size: Int
        $marketplace: Int
        $availability: String
        $keywords: String
        $advancedSearch: JSON
        $hideBundles: Boolean
        $hideRefurbished: Boolean
        $hideMarketplace: Boolean
        $pgcategoryid: String
        $instock: Boolean
        $fromsuite: Boolean
        $onlyGfk: Boolean
        $access: String
        $reviewedAccount: String
        $loadReviewed: Boolean
    ) {
        opportunitiesChart(
            region: $region
            website: $website
            website_amazon: $website_amazon
            config: $config
            websites: $websites
            from: $from
            size: $size
            marketplace: $marketplace
            availability: $availability
            keywords: $keywords
            advancedSearch: $advancedSearch
            hideBundles: $hideBundles
            hideRefurbished: $hideRefurbished
            hideMarketplace: $hideMarketplace
            pgcategoryid: $pgcategoryid
            instock: $instock
            fromsuite: $fromsuite
            onlyGfk: $onlyGfk
            access: $access
            reviewedAccount: $reviewedAccount
            loadReviewed: $loadReviewed
        ) {
            error
            seriesBrand
            legendBrand
            seriesCategory
            legendCategory
            queryHash
        }
    }
`;
export const OPPORTUNITIES_PRODUCTS = gql`
    query opportunitiesProducts(
        $region: String!
        $website: WebsiteArgs
        $website_amazon: WebsiteArgs
        $config: JSON
        $websites: [WebsiteArgs!]
        $sort: [String!]
        $from: Int
        $size: Int
        $marketplace: Int
        $availability: String
        $keywords: String
        $advancedSearch: JSON
        $hidelost: Boolean
        $hideBundles: Boolean
        $hideRefurbished: Boolean
        $hideMarketplace: Boolean
        $pgcategoryid: String
        $instock: Boolean
        $fromsuite: Boolean
        $loadGfk: Boolean
        $access: String
        $task: String
        $user: String
        $export: Boolean
        $fileName: String
        $reviewedAccount: String
        $loadReviewed: Boolean
        $onlyGfk: Boolean
    ) {
        opportunitiesProducts(
            region: $region
            website: $website
            sort: $sort
            website_amazon: $website_amazon
            config: $config
            websites: $websites
            from: $from
            size: $size
            marketplace: $marketplace
            availability: $availability
            keywords: $keywords
            advancedSearch: $advancedSearch
            hidelost: $hidelost
            hideBundles: $hideBundles
            hideRefurbished: $hideRefurbished
            hideMarketplace: $hideMarketplace
            pgcategoryid: $pgcategoryid
            instock: $instock
            fromsuite: $fromsuite
            loadGfk: $loadGfk
            access: $access
            task: $task
            user: $user
            export: $export
            fileName: $fileName
            reviewedAccount: $reviewedAccount
            loadReviewed: $loadReviewed
            onlyGfk: $onlyGfk
        ) {
            exportResponse
            error
            queryHash
            totalIndex
            total
            exclude
            data
        }
    }
`;

/**
 * Newly Listed Products queries
 */
export const NEWLY_DISCOVERED_CHART = gql`
    query newlyDiscoveredChart(
        $region: String!
        $website: WebsiteArgs
        $config: JSON
        $fromdate: String
        $todate: String
        $websites: [WebsiteArgs!]
        $marketplace: Int
        $availability: String
        $keywords: String
        $advancedSearch: JSON
        $hideBundles: Boolean
        $hideRefurbished: Boolean
        $hideMarketplace: Boolean
        $brands: String
    ) {
        newlyDiscoveredChart(
            region: $region
            website: $website
            config: $config
            fromdate: $fromdate
            todate: $todate
            websites: $websites
            marketplace: $marketplace
            availability: $availability
            keywords: $keywords
            advancedSearch: $advancedSearch
            hideBundles: $hideBundles
            hideRefurbished: $hideRefurbished
            hideMarketplace: $hideMarketplace
            brands: $brands
        ) {
            error
            series
            xAxis {
                data
                axisLabel
            }
        }
    }
`;
export const NEWLY_DISCOVERED_PRODUCTS = gql`
    query newlyDiscoveredProducts(
        $region: String!
        $website: WebsiteArgs
        $sort: [String!]
        $date: String
        $config: JSON
        $websites: [WebsiteArgs!]
        $from: Int
        $size: Int
        $marketplace: Int
        $fromdate: String
        $todate: String
        $availability: String
        $keywords: String
        $advancedSearch: JSON
        $hideBundles: Boolean
        $hideRefurbished: Boolean
        $hideMarketplace: Boolean
        $access: String
        $task: String
        $user: String
        $export: Boolean
        $fileName: String
        $brands: String
    ) {
        newlyDiscoveredProducts(
            region: $region
            website: $website
            sort: $sort
            date: $date
            config: $config
            websites: $websites
            from: $from
            size: $size
            marketplace: $marketplace
            fromdate: $fromdate
            todate: $todate
            availability: $availability
            keywords: $keywords
            advancedSearch: $advancedSearch
            hideBundles: $hideBundles
            hideRefurbished: $hideRefurbished
            hideMarketplace: $hideMarketplace
            access: $access
            task: $task
            user: $user
            export: $export
            fileName: $fileName
            brands: $brands
        ) {
            exportResponse
            error
            data
            total
            message
            date
            website
        }
    }
`;

/**
 * Daily Monitored products queries
 */
export const DAILY_MONITORED_PRODUCT_QUERY = gql`
    query dailyMonitoredProducts(
        $region: String!
        $CategoryName: String
        $website_amazon: WebsiteArgs
        $config: JSON
        $websites: [WebsiteArgs!]
        $category: CategoryArgs
        $categoryLevel: String
        $brand: BrandArgs
        $fromdate: String
        $todate: String
        $marketplace: Int
        $availability: String
        $keywords: String
        $advancedSearch: JSON
        $hideBundles: Boolean
        $hideRefurbished: Boolean
        $hideMarketplace: Boolean
        $pgcategoryid: String
        $instock: Boolean
        $fromsuite: Boolean
        $brands: String
    ) {
        dailyMonitoredProducts(
            region: $region
            CategoryName: $CategoryName
            website_amazon: $website_amazon
            config: $config
            websites: $websites
            category: $category
            categoryLevel: $categoryLevel
            brand: $brand
            fromdate: $fromdate
            todate: $todate
            marketplace: $marketplace
            availability: $availability
            keywords: $keywords
            advancedSearch: $advancedSearch
            hideBundles: $hideBundles
            hideRefurbished: $hideRefurbished
            hideMarketplace: $hideMarketplace
            pgcategoryid: $pgcategoryid
            instock: $instock
            fromsuite: $fromsuite
            brands: $brands
        ) {
            series {
                name
                type
                symbolSize
                data
                itemStyle
            }
            xAxis {
                data
                axisLabel
            }
            error
            data {
                website
                dates
                data {
                    key
                    value
                }
            }
        }
    }
`;
export const DAILY_AVAILABILITY_RATE_QUERY = gql`
    query dailyAvailabilityRate(
        $region: String!
        $CategoryName: String
        $website_amazon: WebsiteArgs
        $config: JSON
        $websites: [WebsiteArgs!]
        $category: CategoryArgs
        $categoryLevel: String
        $brand: BrandArgs
        $fromdate: String
        $todate: String
        $marketplace: Int
        $availability: String
        $keywords: String
        $advancedSearch: JSON
        $hideBundles: Boolean
        $hideRefurbished: Boolean
        $hideMarketplace: Boolean
        $pgcategoryid: String
        $instock: Boolean
        $fromsuite: Boolean
        $brands: String
    ) {
        dailyAvailabilityRate(
            region: $region
            CategoryName: $CategoryName
            website_amazon: $website_amazon
            config: $config
            websites: $websites
            category: $category
            categoryLevel: $categoryLevel
            brand: $brand
            fromdate: $fromdate
            todate: $todate
            marketplace: $marketplace
            availability: $availability
            keywords: $keywords
            advancedSearch: $advancedSearch
            hideBundles: $hideBundles
            hideRefurbished: $hideRefurbished
            hideMarketplace: $hideMarketplace
            pgcategoryid: $pgcategoryid
            instock: $instock
            fromsuite: $fromsuite
            brands: $brands
        ) {
            series {
                name
                type
                symbolSize
                data
                itemStyle
            }
            xAxis {
                data
                axisLabel
            }
            error
            data {
                website
                dates
                data {
                    key
                    value
                }
            }
        }
    }
`;
export const DAILY_MONITORED_BRANDS_QUERY = gql`
    query dailyMonitoredBrands(
        $region: String!
        $CategoryName: String
        $website_amazon: WebsiteArgs
        $config: JSON
        $websites: [WebsiteArgs!]
        $category: CategoryArgs
        $categoryLevel: String
        $brand: BrandArgs
        $fromdate: String
        $todate: String
        $marketplace: Int
        $availability: String
        $keywords: String
        $advancedSearch: JSON
        $hideBundles: Boolean
        $hideRefurbished: Boolean
        $hideMarketplace: Boolean
        $pgcategoryid: String
        $instock: Boolean
        $fromsuite: Boolean
        $brands: String
    ) {
        dailyMonitoredBrands(
            region: $region
            CategoryName: $CategoryName
            website_amazon: $website_amazon
            config: $config
            websites: $websites
            category: $category
            categoryLevel: $categoryLevel
            brand: $brand
            fromdate: $fromdate
            todate: $todate
            marketplace: $marketplace
            availability: $availability
            keywords: $keywords
            advancedSearch: $advancedSearch
            hideBundles: $hideBundles
            hideRefurbished: $hideRefurbished
            hideMarketplace: $hideMarketplace
            pgcategoryid: $pgcategoryid
            instock: $instock
            fromsuite: $fromsuite
            brands: $brands
        ) {
            series {
                name
                type
                symbolSize
                data
                connectNulls
            }
            xAxis {
                data
                axisLabel
            }
            error
        }
    }
`;
export const TOP_BRANDS_QUERY = gql`
    query topbrands(
        $region: String!
        $website: WebsiteArgs
        $CategoryName: String
        $website_amazon: WebsiteArgs
        $date: String
        $config: JSON
        $sort: [String!]
        $websites: [WebsiteArgs!]
        $category: CategoryArgs
        $categoryLevel: String
        $brand: BrandArgs
        $fromdate: String
        $todate: String
        $marketplace: Int
        $availability: String
        $keywords: String
        $advancedSearch: JSON
        $hideBundles: Boolean
        $hideRefurbished: Boolean
        $hideMarketplace: Boolean
        $pgcategoryid: String
        $instock: Boolean
        $fromsuite: Boolean
        $brands: String
    ) {
        topBrands(
            region: $region
            website: $website
            CategoryName: $CategoryName
            website_amazon: $website_amazon
            date: $date
            config: $config
            sort: $sort
            websites: $websites
            category: $category
            categoryLevel: $categoryLevel
            brand: $brand
            fromdate: $fromdate
            todate: $todate
            marketplace: $marketplace
            availability: $availability
            keywords: $keywords
            advancedSearch: $advancedSearch
            hideBundles: $hideBundles
            hideRefurbished: $hideRefurbished
            hideMarketplace: $hideMarketplace
            pgcategoryid: $pgcategoryid
            instock: $instock
            fromsuite: $fromsuite
            brands: $brands
        ) {
            series {
                name
                type
                itemStyle
            }
            series {
                name
                type
                data
            }
            yAxis {
                data
            }
        }
    }
`;

/**
 * Pricing Intelligence queries
 */
export const PRICE_CHANGE_CHART = gql`
    query priceChangesChart(
        $region: String!
        $website: WebsiteArgs
        $fromdate: String
        $todate: String
        $websites: [WebsiteArgs!]
        $from: Int
        $size: Int
        $keywords: String
        $advancedSearch: JSON
        $hideBundles: Boolean
        $hideRefurbished: Boolean
        $hideMarketplace: Boolean
        $brands: String
    ) {
        priceChangesChart(
            region: $region
            website: $website
            fromdate: $fromdate
            todate: $todate
            websites: $websites
            from: $from
            size: $size
            keywords: $keywords
            advancedSearch: $advancedSearch
            hideBundles: $hideBundles
            hideRefurbished: $hideRefurbished
            hideMarketplace: $hideMarketplace
            brands: $brands
        ) {
            series
            xAxis {
                data
                axisLabel
            }
            error
        }
    }
`;
export const PRICE_CHANGE_PRODUCTS = gql`
    query priceChangesProducts(
        $region: String!
        $website: WebsiteArgs
        $sort: [String!]
        $fromdate: String
        $todate: String
        $date: String
        $websites: [WebsiteArgs!]
        $from: Int
        $size: Int
        $keywords: String
        $advancedSearch: JSON
        $hideBundles: Boolean
        $hideRefurbished: Boolean
        $hideMarketplace: Boolean
        $access: String
        $task: String
        $user: String
        $export: Boolean
        $fileName: String
        $brands: String
    ) {
        priceChangesProducts(
            region: $region
            website: $website
            sort: $sort
            fromdate: $fromdate
            todate: $todate
            date: $date
            websites: $websites
            from: $from
            size: $size
            keywords: $keywords
            advancedSearch: $advancedSearch
            hideBundles: $hideBundles
            hideRefurbished: $hideRefurbished
            hideMarketplace: $hideMarketplace
            access: $access
            task: $task
            user: $user
            export: $export
            fileName: $fileName
            brands: $brands
        ) {
            exportResponse
            data
            total
            message
            error
            website {
                id
                name
                alias
                url
                data
            }
        }
    }
`;

/**
 * Assortment Scoring
 */
export const ASSORTMENT_SCORING_CHART = gql`
    query assortmentScoringChart($websites: [WebsiteArgs!], $website: WebsiteArgs, $region: String!, $advancedSearch: JSON) {
        assortmentScoringChart(websites: $websites, website: $website, region: $region, advancedSearch: $advancedSearch) {
            data
        }
    }
`;

/**
 * Assortment Scoring
 */
export const SCORING_INSIGHTS = gql`
    query ScoringAnalytics($region: String!, $id: Int, $strategy: String, $module: String, $marketplaceAnalytics: Boolean) {
        ScoringAnalytics(region: $region, id: $id, strategy: $strategy, module: $module, marketplaceAnalytics: $marketplaceAnalytics) {
            data
            error
        }
    }
`;

/**
 * Assortment Scoring Data
 */
export const SCORING_DATA = gql`
    query ScoringAnalyticsData(
        $region: String!
        $websites: [WebsiteArgs!]
        $id: Int
        $strategy: String
        $module: String
        $sort: String
        $from: Int
        $size: Int
        $opportunities: Boolean
        $inStrategy: Boolean
        $outOfStrategy: Boolean
        $onlyOpportunities: Boolean
        $marketplace: Boolean
        $website: String
        $outOfStock: Boolean
        $reviewedAccount: String
        $task: String
        $user: String
        $export: Boolean
        $fileName: String
        $marketplaceAnalytics: Boolean
    ) {
        ScoringAnalyticsData(
            region: $region
            websites: $websites
            id: $id
            strategy: $strategy
            module: $module
            sort: $sort
            from: $from
            size: $size
            opportunities: $opportunities
            inStrategy: $inStrategy
            outOfStrategy: $outOfStrategy
            onlyOpportunities: $onlyOpportunities
            marketplace: $marketplace
            website: $website
            outOfStock: $outOfStock
            reviewedAccount: $reviewedAccount
            task: $task
            user: $user
            export: $export
            fileName: $fileName
            marketplaceAnalytics: $marketplaceAnalytics
        ) {
            data
            total
            error
            exportResponse
        }
    }
`;

export const SCORING_OVERVIEW_DATA = gql`
    query ScoringAnalyticsOverview($region: String!, $from: Int, $size: Int, $mainPG: String, $marketplaceAnalytics: Boolean) {
        ScoringAnalyticsOverview(region: $region, from: $from, size: $size, mainPG: $mainPG, marketplaceAnalytics: $marketplaceAnalytics) {
            data
            stats
            coveragePercentage
            totalSize
            coverage
            total
            series
            xAxis
            error
        }
    }
`;
