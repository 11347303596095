import { useState } from 'react';
import { Grid, Card, Stack, CardContent, Box, Button, CardActions } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import appoloClient from 'common/appolo-client';

import InputFilterItem from './InputFilterItem';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { addInputList, removeInputList, updateInputList, resetInputListItem } from 'store/slices/filter/filterSlice';
import {
    assortmentTypeAvailabilityOptions,
    assortmentTypeMarketplaceOptions,
    listingStatus,
    relevanceFilter,
    relevanceItemType
} from 'constants/filter/filterData';
import { useNotification } from 'hooks/useNotification';
import CustomCollectionSaveModal from 'components/common/modals/CustomCollectionSaveModal';
import { CUSTOM_COLLECTION_QUERY, NEW_CUSTOM_COLLECTION_MUTATION } from 'services/catalog/queries';

interface AdvancedFilterProps {
    filterData: any;
    filterFields?: string[];
    hideAddingInput?: boolean;
    inputListProps?: any;
    handleApply: () => void;
    handleReset: () => void;
}

function AdvancedFilter({ hideAddingInput, filterFields, filterData, handleApply, handleReset }: AdvancedFilterProps) {
    const dispatch = useAppDispatch();

    const { displayNotification } = useNotification();

    const { inputList } = useAppSelector((state) => state.filter);
    const { selectedRegion, userWebsites, isMMS, defaultWebsite, userInfo, selectedWebsites } = useAppSelector((state) => state.auth);

    const [open, setOpen] = useState(false);
    const [newCategoryName, setNewCategoryName] = useState<string>('');

    const handleAddInputFilter = () => {
        const inputData =
            isMMS == true
                ? { fieldSearch: 'Module', checkOption: 'Is' }
                : {
                      fieldSearch: 'Keywords',
                      checkOption: 'Containing',
                      includeExclude: 'Include',
                      searchkey: []
                  };
        dispatch(addInputList(inputData));
    };

    const handleRemoveInputFilter = (item: string) => {
        dispatch(removeInputList({ id: item }));
    };

    const handleChangeSearchField = (event: SelectChangeEvent) => {
        const currentId = event.target.name;
        const currentValue = event.target.value;

        const checkOptionValue =
            currentValue == 'Keywords' ||
            currentValue == 'Product Name' ||
            currentValue == 'Product Name/Specifications' ||
            currentValue == 'All Website Categories' ||
            currentValue == 'EAN/Asin/SKU' ||
            currentValue == 'Flags' ||
            currentValue == 'Brand' ||
            currentValue == 'Brand Name' ||
            currentValue == 'Keywords'
                ? 'Containing'
                : 'Is';

        dispatch(
            resetInputListItem({
                data: { id: currentId, fieldSearch: currentValue, checkOption: checkOptionValue, includeExclude: 'Include' }
            })
        );
    };

    const handleChangeCheckOption = (event: SelectChangeEvent) => {
        const currentId = event.target.name;
        const currentValue = event.target.value;

        if (currentValue === 'Is' || currentValue === 'Is Not') {
            const includeExclude = currentValue === 'Is' ? 'Include' : 'Exclude';
            dispatch(updateInputList({ id: currentId, data: { checkOption: 'Is', includeExclude } }));
        } else dispatch(updateInputList({ id: currentId, data: { checkOption: currentValue } }));
    };

    const handleChangeSearchTags = (id: string, items: string[]) => {
        dispatch(updateInputList({ id, data: { searchkey: items } }));
    };

    const handleBetterWayChangeValue = (id: string, betterWayValue: string) => {
        dispatch(updateInputList({ id, data: { betterWay: betterWayValue } }));
    };

    const handleChangeRelevanceOption = (event: SelectChangeEvent, id: string) => {
        const currentValue = event.target.value;
        const postIndex = inputList.findIndex((item: any) => item.id === id);
        const relevanceData = relevanceFilter.find((item: relevanceItemType) => (item.code = currentValue));

        if (relevanceData && postIndex !== -1)
            dispatch(
                updateInputList({
                    id,
                    data: {
                        relevance: {
                            ...inputList[postIndex]?.relevance,
                            op: {
                                name: relevanceData.name,
                                code: relevanceData.code
                            }
                        }
                    }
                })
            );
    };

    const handleChangeRelevance = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
        const currentValue = event.target.value;
        const postIndex = inputList.findIndex((item: any) => item.id === id);

        if (postIndex !== -1)
            dispatch(
                updateInputList({
                    id,
                    data: { relevance: { ...inputList[postIndex]?.relevance, value: parseFloat(currentValue) } }
                })
            );
    };

    const handleChangeFlags = (event: React.SyntheticEvent, value: string[], id: string) => {
        const currentValue = value.map((item: string) => item);
        dispatch(updateInputList({ id, data: { searchkey: currentValue } }));
    };

    const handleChangeMaxPrice = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
        const maxPrice = event.target.value;
        dispatch(
            updateInputList({
                id,
                data: {
                    maxprice: parseFloat(maxPrice)
                }
            })
        );
    };
    const handleChangeMinPrice = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
        const minPrice = event.target.value;
        dispatch(
            updateInputList({
                id,
                data: {
                    minprice: parseFloat(minPrice)
                }
            })
        );
    };

    const handleChangeMaxDelivery = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
        const maxDelivery = event.target.value;
        dispatch(
            updateInputList({
                id,
                data: {
                    maxdelivery: parseFloat(maxDelivery)
                }
            })
        );
    };

    const handleChangeMinDelivery = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
        const minDelivery = event.target.value;
        dispatch(
            updateInputList({
                id,
                data: {
                    mindelivery: parseFloat(minDelivery)
                }
            })
        );
    };

    const handleChangeRatingRange = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
        const ratingValue = event.target.value;
        const postIndex = inputList.findIndex((item: any) => item.id === id);

        if (postIndex !== -1)
            dispatch(
                updateInputList({
                    id,
                    data: {
                        rating: { ...inputList[postIndex]?.rating, value: parseFloat(ratingValue) }
                    }
                })
            );
    };

    const handleChangeRatingOption = (event: SelectChangeEvent, id: string) => {
        const postIndex = inputList.findIndex((item: any) => item.id === id);
        const ratingOption = ratingOptions.find((item) => item.code == event.target.value);

        if (postIndex !== -1 && ratingOption)
            dispatch(
                updateInputList({
                    id,
                    data: {
                        rating: { ...inputList[postIndex]?.rating, op: ratingOption }
                    }
                })
            );
    };

    const handleChangeReviewValue = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
        const ratingValue = event.target.value;
        const postIndex = inputList.findIndex((item: any) => item.id === id);

        if (postIndex !== -1)
            dispatch(
                updateInputList({
                    id,
                    data: {
                        reviews: { ...inputList[postIndex]?.reviews, value: ratingValue }
                    }
                })
            );
    };

    const handleChangeReviewOption = (event: SelectChangeEvent, id: string) => {
        const postIndex = inputList.findIndex((item: any) => item.id === id);
        const ratingOption = ratingOptions.find((item) => item.code == event.target.value);

        if (postIndex !== -1 && ratingOption)
            dispatch(
                updateInputList({
                    id,
                    data: {
                        reviews: { ...inputList[postIndex]?.reviews, op: ratingOption }
                    }
                })
            );
    };

    const handleChangeListingStatus = (event: SelectChangeEvent, id: string) => {
        const statusData = listingStatus.find((item) => item.status == event.target.value);
        dispatch(
            updateInputList({
                id,
                data: {
                    reviewed: statusData
                }
            })
        );
    };

    const handleAssortmentTypeChange = (event: SelectChangeEvent, id: string) => {
        const postIndex = inputList.findIndex((item: any) => item.id === id);

        if (postIndex !== -1)
            dispatch(
                updateInputList({
                    id,
                    data: {
                        marketplace: { id: assortmentTypeMarketplaceOptions[event.target.value], name: event.target.value }
                    }
                })
            );
    };

    const handleAssortmentAvailabilityChange = (event: SelectChangeEvent, id: string) => {
        const postIndex = inputList.findIndex((item: any) => item.id === id);

        if (postIndex !== -1)
            dispatch(
                updateInputList({
                    id,
                    data: {
                        availability: { id: assortmentTypeAvailabilityOptions[event.target.value], name: event.target.value }
                    }
                })
            );
    };

    const checkCustomFilter = (list: any) => {
        const catAmazonExist = list.filter((el: any) => el.category && el.category.id);
        const catAssortmentExist = list.filter((el: any) => el.fieldSearch == 'Assortment type');
        const catShippingExist = list.filter((el: any) => el.shippings && el.shippings.op && el.shippings.value && el.shippings.unity);
        const catCustomExist = list.filter((el: any) => el.customcategory && el.customcategory.id);
        const catMMSExist = list.filter((el: any) => el.mmscategory && el.mmscategory.id);
        const catModuleExist = list.filter((el: any) => el.mmsmodule && el.mmsmodule.id);
        const catMainPgExist = list.filter((el: any) => el.mainPG && el.mainPG.id);
        const catMMSMMExist = list.filter((el: any) => el.mmscategorymm && el.mmscategorymm.id);
        const catSpecsExist = list.filter((el: any) => el.specifications && (el.specifications.key || el.specifications.value.length));
        const catBrandExist = list.filter((el: any) => el.brand && el.brand.id);
        const catPriceExist = list.filter((el: any) => el.maxprice || el.minprice);
        const catDeliveryExist = list.filter((el: any) => el.maxdelivery || el.mindelivery);
        const catNameExist = list.filter((el: any) => el.searchkey && el.searchkey.length);
        const catWebsiteCategoryExist = list.filter((el: any) => el.websitecategory && el.websitecategory.category);
        const catRatingExist = list.filter((el: any) => el.rating && el.rating.op && el.rating.value);
        const catReviewsExist = list.filter((el: any) => el.reviews && el.reviews.op && el.reviews.value);
        const catRelevanceExist = list.filter((el: any) => el.relevance && el.relevance.op && el.relevance.value);
        if (
            catAmazonExist.length ||
            catNameExist.length ||
            catPriceExist.length ||
            catDeliveryExist.length ||
            catBrandExist.length ||
            catSpecsExist.length ||
            catCustomExist.length ||
            catShippingExist.length ||
            catAssortmentExist.length ||
            catWebsiteCategoryExist.length ||
            catRatingExist.length ||
            catReviewsExist.length ||
            catRelevanceExist.length ||
            catMMSExist.length ||
            catMMSMMExist.length ||
            catModuleExist.length ||
            catMainPgExist.length
        ) {
            return true;
        } else {
            return false;
        }
    };

    const saveAsNewCollection = () => {
        const filterExists = checkCustomFilter(inputList);

        if (!filterExists) {
            displayNotification({
                message: `Cannot save this filter as new Category without setting up some filter option like : category, brand, specifications, price range ...`,
                type: 'error',
                horizontal: 'right'
            });
        } else {
            setOpen(true);
        }
    };

    const handleNewCategoryNameChanged = (name: string) => {
        setNewCategoryName(name);
    };

    const showToastMessage = (msg: string, success = true) => {
        displayNotification({
            message: `${msg}`,
            type: success ? 'success' : 'error',
            horizontal: 'right'
        });
    };

    const checkIfCategoryNameExistAlready = async (name: string) => {
        let customCategoryExist = false;
        await appoloClient
            .query({
                query: CUSTOM_COLLECTION_QUERY,
                variables: {
                    user: userInfo?.id,
                    q: name,
                    region: selectedRegion
                }
            })
            .then((response) => {
                if (response.data.customCategoriesSearch.categories && response.data.customCategoriesSearch.categories.length) {
                    const existingCheck = response.data.customCategoriesSearch.categories.filter((el: any) => el.name == name);
                    customCategoryExist = existingCheck && existingCheck.length ? true : false;
                } else {
                    customCategoryExist = false;
                }
            })
            .catch((err) => {
                console.log('something go wrong ... ', err);
                customCategoryExist = true;
            });
        return customCategoryExist;
    };

    const saveFilterWithName = async () => {
        if (newCategoryName?.length) {
            // check if no category exist with this name
            const customCategoryExist = await checkIfCategoryNameExistAlready(newCategoryName);
            if (!customCategoryExist) {
                // save category
                await appoloClient
                    .mutate({
                        mutation: NEW_CUSTOM_COLLECTION_MUTATION,
                        variables: {
                            region: selectedRegion,
                            name: newCategoryName,
                            filter: JSON.stringify(inputList),
                            websites: selectedWebsites,
                            website: defaultWebsite,
                            user: userInfo?.id
                        }
                    })
                    .then((response) => {
                        if (response.data.newCustomCollection.result) {
                            // get refresh data
                            setOpen(false);
                            // notify
                            setNewCategoryName('');
                            showToastMessage(`Success 😊 the cutom collection '${newCategoryName}' is now added to the list`, true);
                        } else {
                            showToastMessage('Oops ! something go wrong retry later !', false);
                        }
                    })
                    .catch(() => {
                        showToastMessage(`Oops 😣, An error occurred, please contact us at support@crawlo.com`, false);
                    });
            } else {
                showToastMessage('Oops ! Cannot category already exist with the given name, please change it :) !', false);
            }
        } else {
            showToastMessage('Cannot add category with empty name !', false);
        }
    };

    return (
        <Grid item xs={12}>
            <Card>
                <CardContent>
                    <Stack direction="column" rowGap={2}>
                        {inputList.map((item: any) => (
                            <InputFilterItem
                                filterData={filterData}
                                id={item.id}
                                key={item.id}
                                hideAddingInput={hideAddingInput}
                                filterFields={filterFields}
                                fieldSearch={item.fieldSearch}
                                checkOption={item.checkOption}
                                includeExclude={item?.includeExclude}
                                mmsModuleValue={item.mmsmodule}
                                productGroupValue={item.mmscategory}
                                googleCategoryValue={item.google}
                                thirdPartyValue={item.thirdparties}
                                brandValue={item.brand}
                                exactWebsiteCategory={item?.websitecategory?.category}
                                exactWebsiteOption={item?.websitecategory?.website}
                                relevance={item?.relevance?.value}
                                searchData={item?.searchkey}
                                relevanceOption={item?.relevance?.op?.code}
                                maxPrice={item?.maxprice}
                                minPrice={item?.minprice}
                                minDelivery={item?.mindelivery}
                                maxDelivery={item?.maxdelivery}
                                ratingValue={item?.rating?.value}
                                ratingOption={item?.rating?.op?.code}
                                reviewValue={item?.reviews?.value}
                                reviewOption={item?.reviews?.op?.code}
                                listingStatusValue={item?.reviewed?.status}
                                customCollectionValue={item?.customcategory}
                                assortmentTypeValue={item?.marketplace?.name}
                                flags={item?.searchkey}
                                assortmentAvailabilityValue={item?.availability?.name}
                                isRemoveButton={inputList.length == 1 ? false : true}
                                websites={userWebsites.filter((item: any) => item.region === selectedRegion)[0].websites}
                                handleAdd={handleAddInputFilter}
                                handleRemove={() => handleRemoveInputFilter(item.id)}
                                handleChangeSearchField={handleChangeSearchField}
                                handleChangeCheckOption={handleChangeCheckOption}
                                handleChangeSearchTags={handleChangeSearchTags}
                                changeBetterWayValue={handleBetterWayChangeValue}
                                handleChangeRelevanceOption={handleChangeRelevanceOption}
                                handleChangeRelevance={handleChangeRelevance}
                                handleChangeFlags={handleChangeFlags}
                                handleChangeMaxPrice={handleChangeMaxPrice}
                                handleChangeMinPrice={handleChangeMinPrice}
                                handleChangeMaxDelivery={handleChangeMaxDelivery}
                                handleChangeMinDelivery={handleChangeMinDelivery}
                                handleChangeRatingRange={handleChangeRatingRange}
                                handleChangeRatingOption={handleChangeRatingOption}
                                handleChangeReviewOption={handleChangeReviewOption}
                                handleChangeReviewValue={handleChangeReviewValue}
                                handleChangeListingStatus={handleChangeListingStatus}
                                handleAssortmentTypeChange={handleAssortmentTypeChange}
                                handleAssortmentAvailabilityChange={handleAssortmentAvailabilityChange}
                            />
                        ))}
                    </Stack>
                </CardContent>

                <Box>
                    <CardActions>
                        <Box sx={{ flexGrow: 1 }} />
                        <Button variant="outlined" color="error" onClick={handleReset}>
                            Reset
                        </Button>
                        <Button variant="contained" color="success" onClick={handleApply}>
                            Apply
                        </Button>
                    </CardActions>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <Button onClick={saveAsNewCollection} sx={{ color: '#17b3cf' }}>
                            Save As New Custom Collection
                        </Button>
                    </Box>
                </Box>
            </Card>

            <CustomCollectionSaveModal
                open={open}
                customCollectionName={newCategoryName}
                handleNameChanged={handleNewCategoryNameChanged}
                handleCreate={saveFilterWithName}
                handleClose={() => setOpen(false)}
            />
        </Grid>
    );
}

const ratingOptions = [
    {
        code: 'gt',
        name: 'Greater than'
    },
    {
        code: 'gte',
        name: 'Greater than or equal to'
    },
    {
        code: 'lt',
        name: 'Less than'
    },
    {
        code: 'lte',
        name: 'Less than or equal to'
    }
];

export default AdvancedFilter;
