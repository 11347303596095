import { Grid, Skeleton } from '@mui/material';

function AnalysisLoading() {
    return (
        <Grid container spacing={4} sx={{ minHeight: 700, p: 3 }}>
            <Grid item xs={12} md={4} justifyContent={'center'} alignItems={'center'}>
                <Skeleton variant="rectangular" height={300} width={'auto'} />
            </Grid>
            <Grid item xs={12} md={8}>
                <Skeleton variant="rectangular" height={20} width={'auto'} />
                <Skeleton variant="rectangular" height={10} width={'50'} sx={{ mt: 3 }} />
                <Skeleton variant="rectangular" height={10} width={'20%'} sx={{ mt: 3 }} />
                <Skeleton variant="rectangular" height={10} width={'20%'} sx={{ mt: 3 }} />
            </Grid>
        </Grid>
    );
}

export default AnalysisLoading;
