const XS = ['XXS', 'XS', 'XSM', 'XSS'];
const S = ['XAS', 'S', 'SM', 'SS'];
const M = ['XAM', 'M', 'MM', 'MS'];
const L = ['XAL', 'L', 'LM', 'LS'];
const HLD = ['HLD', 'HL1'];
const OO = ['OO', 'OWA', 'WEB', 'OLO'];
const OA = ['OA', 'ZEO', 'OOP', 'OCT'];
const BTW = ['BW', 'BTW'];

export default (flag: string) => {
    if (flag) {
        flag = flag.toUpperCase();
        if (XS.filter((el) => el == flag).length) {
            return 'XS';
        }
        if (S.filter((el) => el == flag).length) {
            return 'S';
        }
        if (M.filter((el) => el == flag).length) {
            return 'M';
        }
        if (L.filter((el) => el == flag).length) {
            return 'L';
        }
        if (HLD.filter((el) => el == flag).length) {
            return 'HLD';
        }
        if (OO.filter((el) => el == flag).length) {
            return 'OO';
        }
        if (OA.filter((el) => el == flag).length) {
            return 'OA';
        }
        if (BTW.filter((el) => el == flag).length) {
            return 'BTW';
        }
    }

    return flag;
};
