import ReactECharts, { EChartsOption, EChartsReactProps } from 'echarts-for-react';
import { useAppSelector } from 'store/hooks';

interface EChartProps extends EChartsReactProps {
    height?: string;
    id: string;
    option: EChartsOption;
    showLoading?: boolean;
    onChartClick?: ((params: any) => void) | undefined;
    onChartLegendselectchanged?: ((params: any) => void) | undefined;
}

function EChart({ height, id, option, showLoading, onChartClick, onChartLegendselectchanged }: EChartProps) {
    const { isDark } = useAppSelector((state) => state.auth);

    const onEvents: Record<string, any> = {
        click: onChartClick
            ? (params: any) => onChartClick(params)
            : () => {
                  console.log('click');
              },
        legendselectchanged: onChartLegendselectchanged
            ? (params: any) => onChartLegendselectchanged(params)
            : () => {
                  console.log('legendselectchanged');
              }
    };

    const defaultOptions = {
        backgroundColor: 'transparent'
    };

    return (
        <div id={id}>
            <ReactECharts
                style={{
                    height: height || '400px',
                    width: '100%'
                }}
                showLoading={showLoading}
                option={{ ...defaultOptions, ...option }}
                notMerge
                lazyUpdate
                theme={isDark ? 'dark' : 'light'}
                onEvents={onEvents}
            />
        </div>
    );
}

export default EChart;
