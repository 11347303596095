import React, { useCallback, useEffect, useState } from 'react';

import { Box, Drawer, useMediaQuery, List, Typography, ListItemButton, Collapse, ListItemIcon, ListItemText, Theme } from '@mui/material';
import { Location, NavLink, useLocation } from 'react-router-dom';
import { FiChevronDown, FiChevronRight } from 'react-icons/fi';

import { SidebarWidth } from '../../../theme/theme-variable';
import Scrollbar from 'components/common/UI/ScrollBar';
import { sideBarItems } from 'constants/menu-items/dashboardMenu';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { handleMenuItem } from 'utils/functions';
import MenuItemLoading from 'components/common/skeletons/MenuItemLoading';
import { addMenuItem, loadMenuItem } from 'store/slices/auth/authSlice';
import { AppDispatch, RootState } from 'store';

interface SideBarProps {
    isMobileSidebarOpen: boolean;
    isSidebarOpen: boolean;
    handleCloseMobileDrawer: () => void;
}

function Sidebar({ isMobileSidebarOpen, isSidebarOpen, handleCloseMobileDrawer }: SideBarProps) {
    const dispatch: AppDispatch = useAppDispatch();
    const location: Location = useLocation();

    const { accessAccountUser, selectedRegion, loadingMenuItems, menuItem } = useAppSelector((state: RootState) => state.auth);

    const [open, setOpen] = useState<boolean | number>(8);
    const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

    let allowedComponents: string[] = [];
    let allowedRoutes: any[] = [];

    const handleClick = (index: number) => {
        if (open === index) {
            setOpen((prevopen) => !prevopen);
        } else {
            setOpen(index);
        }
        /** Close SideBar on mobile view */
        handleCloseMobileDrawer();
    };

    const handleMenu = useCallback(() => {
        dispatch(loadMenuItem(true));
        const accountComponents: AccountAccess[] = accessAccountUser.filter(
            (item: AccountAccess) => item?.account?.config?.region == selectedRegion
        );

        if (accountComponents.length) {
            allowedComponents = handleMenuItem(accountComponents[0]?.account?.components);
            allowedRoutes = sideBarItems.filter((item: any) => allowedComponents.includes(item.id));
            dispatch(addMenuItem([...allowedRoutes]));
            dispatch(loadMenuItem(false));
        }
    }, [accessAccountUser]);

    useEffect(() => {
        handleMenu();
    }, [loadingMenuItems, handleMenu]);

    const SidebarContent = (
        <List>
            {loadingMenuItems ? (
                <MenuItemLoading height={40} />
            ) : (
                menuItem.map((item: any, index: number) => {
                    if (item.subheader) {
                        return (
                            <li key={index}>
                                <Typography variant="subtitle2" fontWeight="500" sx={{ my: 2, mt: 0, opacity: '0.4', mb: '10px' }}>
                                    {`${item.subheader}`.toUpperCase()}
                                </Typography>
                            </li>
                        );
                    } else if (item.children) {
                        return (
                            <React.Fragment key={item.title}>
                                <ListItemButton
                                    component="li"
                                    onClick={() => handleClick(index)}
                                    selected={location.pathname === item.path}
                                    sx={{
                                        mb: 1,
                                        pl: '8px',
                                        pr: '8px',
                                        pt: '0px',
                                        pb: '0px',
                                        ...(location.pathname === item.path && {
                                            color: 'white',
                                            backgroundColor: (theme) => `${theme.palette.primary.main}!important`
                                        })
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            ...(location.pathname === item.path && {
                                                color: 'white'
                                            })
                                        }}
                                    >
                                        {item.icon}
                                    </ListItemIcon>
                                    <ListItemText>{item.title}</ListItemText>
                                    {index === open || location.pathname === item.path ? (
                                        <FiChevronDown size="16" />
                                    ) : (
                                        <FiChevronRight size="16" />
                                    )}
                                </ListItemButton>
                                <Collapse in={index === open} timeout="auto" unmountOnExit>
                                    <List component="li" disablePadding sx={{ ml: '10px' }}>
                                        {item.children.map((child: any) => {
                                            return (
                                                <ListItemButton
                                                    key={child.title}
                                                    component={NavLink}
                                                    to={child.path}
                                                    onClick={handleCloseMobileDrawer}
                                                    selected={location.pathname === child.path}
                                                    sx={{
                                                        pb: '0px',
                                                        pt: '0px',
                                                        mb: 1,
                                                        ...(location.pathname === child.path && {
                                                            color: 'primary.main',
                                                            backgroundColor: 'transparent!important'
                                                        })
                                                    }}
                                                >
                                                    <ListItemIcon
                                                        sx={{
                                                            svg: { width: '14px', marginLeft: '3px' },
                                                            ...(location.pathname === child.path && {
                                                                color: 'primary.main'
                                                            })
                                                        }}
                                                    >
                                                        {child.icon}
                                                    </ListItemIcon>
                                                    <ListItemText>{child.title}</ListItemText>
                                                </ListItemButton>
                                            );
                                        })}
                                    </List>
                                </Collapse>
                            </React.Fragment>
                        );
                        // {/********If Sub No Menu**********/}
                    } else {
                        return (
                            <List component="li" disablePadding key={item.title}>
                                <ListItemButton
                                    component={NavLink}
                                    to={item.path}
                                    selected={location.pathname === item.path}
                                    sx={{
                                        mb: 1,
                                        pl: '8px',
                                        pr: '8px',
                                        pt: '0px',
                                        pb: '0px',
                                        ...(location.pathname === item.path && {
                                            color: 'white',
                                            backgroundColor: (theme) => `${theme.palette.primary.main}!important`
                                        })
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            ...(location.pathname === item.path && { color: 'white' })
                                        }}
                                    >
                                        {item.icon}
                                    </ListItemIcon>
                                    <ListItemText> {item.title}</ListItemText>
                                </ListItemButton>
                            </List>
                        );
                    }
                })
            )}
        </List>
    );

    if (lgUp) {
        return (
            <Drawer
                anchor="left"
                open={isSidebarOpen}
                variant="persistent"
                PaperProps={{
                    sx: {
                        width: SidebarWidth,
                        top: lgUp ? '100px' : '0px',
                        left: lgUp ? '30px' : '',
                        borderRadius: lgUp ? '9px' : '0',
                        border: '0',
                        height: 'calc(100vh - 130px)',
                        boxShadow: '0px 7px 30px 0px rgb(90 114 123 / 11%)'
                    }
                }}
            >
                <Scrollbar style={{ height: 'calc(100vh - 5px)' }}>
                    <Box sx={{ p: 2 }}>
                        <Box> {SidebarContent}</Box>
                    </Box>
                </Scrollbar>
            </Drawer>
        );
    } else
        return (
            <Drawer
                anchor="left"
                open={isMobileSidebarOpen}
                onClose={handleCloseMobileDrawer}
                PaperProps={{
                    sx: {
                        width: SidebarWidth,
                        border: '0 !important'
                    }
                }}
                variant="temporary"
            >
                <Scrollbar style={{ height: 'calc(100vh - 5px)' }}>
                    <Box sx={{ p: 2 }}>
                        <Box> {SidebarContent}</Box>
                    </Box>
                </Scrollbar>
            </Drawer>
        );
}

export default Sidebar;
