import { Card, CardContent, FormControl, Grid, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { Fragment } from 'react';
import AmazonCategory from './AmazonCategory';
import AmazonKeyword from './AmazonKeyword';
import { updateAmazonBestSeller, updateAmazonBestSellerKeyword, updateAmazonFilterField } from 'store/slices/filter/filterSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

type filterFieldType = 'amazon-category' | 'keyword-search';

function AmazonFilter() {
    const dispatch = useAppDispatch();

    const { amazonFilterField } = useAppSelector((state) => state.filter);

    const handleChangeFilterField = (event: SelectChangeEvent) => {
        dispatch(updateAmazonFilterField(event.target.value as filterFieldType));
        if (amazonFilterField == 'keyword-search') {
            dispatch(updateAmazonBestSellerKeyword(''));
        } else if (amazonFilterField == 'amazon-category') {
            dispatch(updateAmazonBestSeller(null));
        }
    };

    return (
        <Fragment>
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Grid container spacing={2} justifyContent={'center'} alignItems={'center'}>
                            <Grid item xs={12} md={2}>
                                <FormControl fullWidth size="small">
                                    <Select
                                        defaultValue={'amazon-category' as filterFieldType}
                                        displayEmpty
                                        value={amazonFilterField || 'amazon-category'}
                                        onChange={handleChangeFilterField}
                                    >
                                        <MenuItem value={'amazon-category' as filterFieldType}>Amazon Category</MenuItem>
                                        <MenuItem value={'keyword-search' as filterFieldType}>Keyword Search</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            {amazonFilterField == 'amazon-category' && (
                                <Grid item xs={12} md={10}>
                                    <AmazonCategory />
                                </Grid>
                            )}
                            {amazonFilterField == 'keyword-search' && (
                                <Grid item xs={12} md={10}>
                                    <AmazonKeyword />
                                </Grid>
                            )}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Fragment>
    );
}

export default AmazonFilter;
