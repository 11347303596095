import { gql } from '@apollo/client';

/**
 * MARKET SHARE queries
 */
export const MARKET_SHARE_DIST_OVERVIEW = gql`
    query GFKMarketShareDist(
        $region: String!
        $website: WebsiteArgs
        $website_amazon: WebsiteArgs
        $config: JSON
        $websites: [WebsiteArgs!]
        $from: Int
        $size: Int
        $marketplace: Int
        $availability: String
        $keywords: String
        $advancedSearch: JSON
        $hideBundles: Boolean
        $hideRefurbished: Boolean
        $hideMarketplace: Boolean
        $pgcategoryid: String
        $instock: Boolean
        $fromsuite: Boolean
        $onlyGfk: Boolean
        $access: String
        $reviewedAccount: String
        $loadReviewed: Boolean
    ) {
        GFKMarketShareDist(
            region: $region
            website: $website
            website_amazon: $website_amazon
            config: $config
            websites: $websites
            from: $from
            size: $size
            marketplace: $marketplace
            availability: $availability
            keywords: $keywords
            advancedSearch: $advancedSearch
            hideBundles: $hideBundles
            hideRefurbished: $hideRefurbished
            hideMarketplace: $hideMarketplace
            pgcategoryid: $pgcategoryid
            instock: $instock
            fromsuite: $fromsuite
            onlyGfk: $onlyGfk
            access: $access
            reviewedAccount: $reviewedAccount
            loadReviewed: $loadReviewed
        ) {
            error
            data
            queryHash
        }
    }
`;
export const MARKET_SHARE_DIST_OVERVIEW_BY_CATEGORY = gql`
    query GFKMarketShareDistByCategory(
        $region: String!
        $website: WebsiteArgs
        $website_amazon: WebsiteArgs
        $config: JSON
        $websites: [WebsiteArgs!]
        $from: Int
        $size: Int
        $marketplace: Int
        $availability: String
        $keywords: String
        $advancedSearch: JSON
        $hideBundles: Boolean
        $hideRefurbished: Boolean
        $hideMarketplace: Boolean
        $pgcategoryid: String
        $instock: Boolean
        $fromsuite: Boolean
        $onlyGfk: Boolean
        $access: String
        $reviewedAccount: String
        $loadReviewed: Boolean
    ) {
        GFKMarketShareDistByCategory(
            region: $region
            website: $website
            website_amazon: $website_amazon
            config: $config
            websites: $websites
            from: $from
            size: $size
            marketplace: $marketplace
            availability: $availability
            keywords: $keywords
            advancedSearch: $advancedSearch
            hideBundles: $hideBundles
            hideRefurbished: $hideRefurbished
            hideMarketplace: $hideMarketplace
            pgcategoryid: $pgcategoryid
            instock: $instock
            fromsuite: $fromsuite
            onlyGfk: $onlyGfk
            access: $access
            reviewedAccount: $reviewedAccount
            loadReviewed: $loadReviewed
        ) {
            error
            data
            inMarketProducts
            opportunitiesProducts
            queryHash
        }
    }
`;
export const MARKET_SHARE_DIST_OVERVIEW_BY_BRANDS = gql`
    query GFKMarketShareDistByBrands(
        $region: String!
        $website: WebsiteArgs
        $website_amazon: WebsiteArgs
        $config: JSON
        $websites: [WebsiteArgs!]
        $from: Int
        $size: Int
        $marketplace: Int
        $availability: String
        $keywords: String
        $advancedSearch: JSON
        $hideBundles: Boolean
        $hideRefurbished: Boolean
        $hideMarketplace: Boolean
        $pgcategoryid: String
        $instock: Boolean
        $fromsuite: Boolean
        $onlyGfk: Boolean
        $access: String
        $reviewedAccount: String
        $loadReviewed: Boolean
    ) {
        GFKMarketShareDistByBrands(
            region: $region
            website: $website
            website_amazon: $website_amazon
            config: $config
            websites: $websites
            from: $from
            size: $size
            marketplace: $marketplace
            availability: $availability
            keywords: $keywords
            advancedSearch: $advancedSearch
            hideBundles: $hideBundles
            hideRefurbished: $hideRefurbished
            hideMarketplace: $hideMarketplace
            pgcategoryid: $pgcategoryid
            instock: $instock
            fromsuite: $fromsuite
            onlyGfk: $onlyGfk
            access: $access
            reviewedAccount: $reviewedAccount
            loadReviewed: $loadReviewed
        ) {
            error
            data
            inMarketProducts
            opportunitiesProducts
            queryHash
        }
    }
`;

/**
 * MMS Validation queries
 */
export const ACCURACY_STATS = gql`
    query accuracyStats($region: String!, $fromdate: String, $todate: String, $filtertype: String) {
        accuracyStats(region: $region, fromdate: $fromdate, todate: $todate, filtertype: $filtertype) {
            data {
                key
                value
                totalMarket
                accuracy
            }
            xAxis
            series
            error
        }
    }
`;
export const ACCURACY_DATA = gql`
    query accuracyData($region: String!, $from: Int, $size: Int, $filtertype: String, $sort: [String!]) {
        accuracyData(region: $region, from: $from, size: $size, filtertype: $filtertype, sort: $sort) {
            data
            total
            error
        }
    }
`;
export const ANALYTICS_COVERAGE = gql`
    query AnalyticsCoverage(
        $region: String!
        $id: String
        $loadModule: String
        $thirdparties: String
        $advancedSearch: JSON
        $website: WebsiteArgs
        $websites: [WebsiteArgs!]
    ) {
        AnalyticsCoverage(
            region: $region
            id: $id
            loadModule: $loadModule
            thirdparties: $thirdparties
            advancedSearch: $advancedSearch
            website: $website
            websites: $websites
        ) {
            loadModule
            thirdparties
            exclude
            stats
            marketcrawlo
            mms
            error
            missing
            totalmms
            notmissed
            totalApi
            correctEans
            wrongEans
            missingEans
            uncategorizedEans
        }
    }
`;
export const WRONG_EAN = gql`
    query WrongEANsCheck($region: String!, $eans: [String!], $loadModule: String) {
        WrongEANsCheck(region: $region, eans: $eans, loadModule: $loadModule) {
            total
            eans
            error
        }
    }
`;
export const VALIDATION_PRODUCTS_LIST = gql`
    query ValidationProductsList(
        $region: String!
        $loadModule: String
        $from: Int
        $size: Int
        $onlymm: Boolean
        $advancedSearch: JSON
        $website: WebsiteArgs
        $websites: [WebsiteArgs!]
    ) {
        ValidationProductsList(
            region: $region
            loadModule: $loadModule
            from: $from
            size: $size
            onlymm: $onlymm
            advancedSearch: $advancedSearch
            website: $website
            websites: $websites
        ) {
            total
            region
            exclude
            googlecategories
            data
            error
        }
    }
`;
export const MMS_PRODUCTS_LIST = gql`
    query MmsProductsList(
        $region: String!
        $eans: [String!]
        $from: Int
        $size: Int
        $onlymm: Boolean
        $loadModule: String
        $loadMMS: String
        $advancedSearch: JSON
        $website: WebsiteArgs
        $websites: [WebsiteArgs!]
    ) {
        MmsProductsList(
            region: $region
            eans: $eans
            from: $from
            size: $size
            onlymm: $onlymm
            loadModule: $loadModule
            loadMMS: $loadMMS
            advancedSearch: $advancedSearch
            website: $website
            websites: $websites
        ) {
            total
            exclude
            data
            error
        }
    }
`;
export const CORRECT_PRODUCTS_LIST = gql`
    query CorrectProductsList(
        $region: String!
        $eans: [String!]
        $from: Int
        $size: Int
        $onlymm: Boolean
        $loadModule: String
        $loadMMS: String
        $advancedSearch: JSON
        $website: WebsiteArgs
        $websites: [WebsiteArgs!]
    ) {
        CorrectProductsList(
            region: $region
            eans: $eans
            from: $from
            size: $size
            onlymm: $onlymm
            loadModule: $loadModule
            loadMMS: $loadMMS
            advancedSearch: $advancedSearch
            website: $website
            websites: $websites
        ) {
            total
            exclude
            data
            error
        }
    }
`;
export const WRONG_PRODUCTS_LIST = gql`
    query WrongProductsList(
        $region: String!
        $eans: [String!]
        $from: Int
        $size: Int
        $onlymm: Boolean
        $loadModule: String
        $loadMMS: String
        $advancedSearch: JSON
        $website: WebsiteArgs
        $websites: [WebsiteArgs!]
    ) {
        WrongProductsList(
            region: $region
            eans: $eans
            from: $from
            size: $size
            onlymm: $onlymm
            loadModule: $loadModule
            loadMMS: $loadMMS
            advancedSearch: $advancedSearch
            website: $website
            websites: $websites
        ) {
            total
            exclude
            data
            error
        }
    }
`;
export const MISSING_PRODUCTS_LIST = gql`
    query MissingProductsList(
        $region: String!
        $eans: [String!]
        $from: Int
        $size: Int
        $onlymm: Boolean
        $loadModule: String
        $loadMMS: String
        $advancedSearch: JSON
        $website: WebsiteArgs
        $websites: [WebsiteArgs!]
    ) {
        MissingProductsList(
            region: $region
            eans: $eans
            from: $from
            size: $size
            onlymm: $onlymm
            loadModule: $loadModule
            loadMMS: $loadMMS
            advancedSearch: $advancedSearch
            website: $website
            websites: $websites
        ) {
            total
            exclude
            data
            error
        }
    }
`;
export const UNCATEGORIZED_PRODUCTS_LIST = gql`
    query UncategorizedProductsList(
        $region: String!
        $eans: [String!]
        $from: Int
        $size: Int
        $onlymm: Boolean
        $loadMMS: String
        $loadModule: String
        $advancedSearch: JSON
        $website: WebsiteArgs
        $websites: [WebsiteArgs!]
    ) {
        UncategorizedProductsList(
            region: $region
            eans: $eans
            from: $from
            size: $size
            onlymm: $onlymm
            loadMMS: $loadMMS
            loadModule: $loadModule
            advancedSearch: $advancedSearch
            website: $website
            websites: $websites
        ) {
            total
            exclude
            data
            error
        }
    }
`;
export const COMPETITORS_CATEGORIES = gql`
    query competitorscategories(
        $region: String!
        $loadModule: String
        $thirdparties: String
        $advancedSearch: JSON
        $websites: [WebsiteArgs!]
        $website: WebsiteArgs
    ) {
        competitorscategories(
            region: $region
            loadModule: $loadModule
            thirdparties: $thirdparties
            advancedSearch: $advancedSearch
            websites: $websites
            website: $website
        ) {
            categories {
                list
                website
                total
            }
            competitorsCategoriesList
            instantinputsList
            errorMMsCategory
            timestamp
            error
        }
    }
`;
export const SAVE_PG_CATEGORY_FILTER = gql`
    mutation savePGCategoryFilter($region: String!, $id: String, $name: String, $user: String, $strategy: String, $filter: [JSON!]) {
        savePGCategoryFilter(region: $region, id: $id, name: $name, user: $user, strategy: $strategy, filter: $filter) {
            categories
            result
            error
        }
    }
`;
export const SAVE_MODULE_CATEGORY_FILTER = gql`
    mutation MMSModuleSaving($region: String!, $id: String, $name: String, $user: String, $filter: [JSON!]) {
        MMSModuleSaving(region: $region, id: $id, name: $name, user: $user, filter: $filter) {
            categories
            result
            error
        }
    }
`;

export const GET_MMS_CATEGORY = gql`
    query getmmscategory($region: String!, $id: String) {
        getmmscategory(region: $region, id: $id) {
            category
            timestamp
            error
        }
    }
`;
export const GET_MMS_MODULE = gql`
    query getmmsmodule($region: String!, $id: String) {
        getmmsmodule(region: $region, id: $id) {
            category
            timestamp
            error
        }
    }
`;

/**
 * multivariate queries
 */
export const MULTIVARIATES_ANALYSIS = gql`
    query multivariate($region: String!, $websites: [WebsiteArgs!], $fromDate: String, $toDate: String, $ref: String!) {
        multivariate(region: $region, websites: $websites, ref: $ref, fromDate: $fromDate, toDate: $toDate) {
            data
            defaultProduct
            error
        }
    }
`;

/**
 * price history queries
 */
export const PRICE_HISTORY_ANALYSIS = gql`
    query priceHistory(
        $region: String!
        $website: WebsiteArgs
        $websites: [WebsiteArgs!]
        $fromDate: String
        $toDate: String
        $ref: String!
        $includeCategories: Boolean
    ) {
        priceHistory(
            region: $region
            website: $website
            websites: $websites
            ref: $ref
            fromDate: $fromDate
            toDate: $toDate
            includeCategories: $includeCategories
        ) {
            series
            website
            xAxis
            products
            defaultProduct
            categoriesData
            error
        }
    }
`;

/**
 * GFK variants queries
 */
export const GET_GFK_VARIANTS = gql`
    query getGfkVariants($region: String!, $gfkId: String, $monthAbrv: String) {
        getGfkVariants(region: $region, gfkId: $gfkId, monthAbrv: $monthAbrv) {
            data
            total
            error
        }
    }
`;
