export const filterTypeData = [
    { name: 'MM Module', value: 'Module' },
    { name: 'MM Product Group', value: 'Product Group' },
    { name: 'Category', value: 'Google Category' },
    { name: 'Keywords', value: 'Keywords' },
    { name: 'BetterWay', value: 'BetterWay' },
    { name: 'Internal category', value: 'Website Category' },
    { name: 'Brand', value: 'Brand Name' },
    { name: 'Relevance', value: 'Relevance' },
    { name: 'MM Flags', value: 'Flags' },
    { name: 'Price Range', value: 'Price Range' },
    { name: 'Shipping days', value: 'Delivery Duration' },
    { name: 'Rating', value: 'Rating' },
    { name: 'Reviews', value: 'Reviews' },
    { name: 'Custom Collection', value: 'Custom Category' },
    { name: 'Assortment type', value: 'Assortment type' }
];

export const exactFilterType = [
    'Keywords',
    'Website Category',
    'Brand',
    'BetterWay',
    'Brand Name',
    'Relevance',
    'EAN/Asin/SKU',
    'Flags',
    'Price Range',
    'Delivery Duration',
    'Specifications',
    'Rating',
    'Reviews',
    'Listing Status',
    'Assortment type'
];

export type relevanceItemType = {
    name: string;
    code: string;
};
export const relevanceFilter: relevanceItemType[] = [
    { name: 'Equal to', code: 'eq' },
    { name: 'Greater than', code: 'gt' },
    { name: 'Greater than or equal to', code: 'gte' },
    { name: 'Less than', code: 'lt' },
    { name: 'Less than or equal to', code: 'lte' }
];

export const listingStatus = [
    {
        name: 'Opportunity',
        status: 'OPPORTUNITIES'
    },
    {
        name: 'Already Listed (Same EAN)',
        status: 'LISTED'
    },
    {
        name: 'Already Listed (different EAN)',
        status: 'LISTED_DIFF_EAN'
    },
    {
        name: 'Marketplace Opportunity',
        status: 'MARKETPLACE_OPPORTUNITY'
    },
    {
        name: 'Cannot be listed',
        status: 'CANNOT_LISTED'
    },
    {
        name: 'Can be listed',
        status: 'CAN_LISTED'
    },
    {
        name: 'In process to be listed',
        status: 'PROCESSING'
    },
    {
        name: 'Delisting candidate',
        status: 'DELISTING'
    },
    {
        name: 'Others',
        status: 'OTHERS'
    }
];

export const availability = ['Bundles', 'Marketplace', 'Out of Stock', 'Refurbished'];

export const assortmentTypeMarketplaceOptions: any = {
    All: '0',
    Marketplace: '4',
    Inventory: '2'
};

export const assortmentTypeAvailabilityOptions: any = {
    'All Products': '4',
    'In Stock': '2',
    'Out Of Stock': '6',
    'Store Pick Up Only': '3',
    'Online Only': '5'
};
