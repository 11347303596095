import { TooltipParams } from 'types/ui/chart';
import { priceFormatter } from 'utils/functions';

export const priceHistoryOption = {
    backgroundColor: 'transparent',
    title: {
        text: '',
        textStyle: {
            color: 'grey',
            fontSize: 20
        },
        left: 'center',
        top: 'center'
    },
    tooltip: {
        formatter: function (params: TooltipParams) {
            const selectedRegion: string = localStorage.getItem('selectedRegion') || 'es';
            let strTooltip: string = params.seriesName ? params.seriesName + '<br/>' : '';
            if (params.name) {
                strTooltip += params.marker + params.name + '\xa0\xa0' + priceFormatter(params.value, selectedRegion);
            }
            return strTooltip;
        }
    },
    toolbox: {
        feature: {}
    },
    xAxis: {
        axisLabel: {
            rotate: 45
        },
        data: []
    },
    yAxis: {
        scale: true
    },
    legend: {
        type: 'scroll',
        left: '2%',
        right: '1%',
        data: []
    },
    dataZoom: [
        {
            type: 'slider',
            xAxisIndex: 0,
            realtime: true,
            filterMode: 'empty',
            start: 0,
            end: 100
        },
        {
            type: 'inside',
            xAxisIndex: 0,
            realtime: true,
            filterMode: 'empty',
            start: 0,
            end: 100
        }
    ],
    grid: {
        show: true
    },
    series: []
};

export const positionsHistoryOption = {
    title: {
        text: '',
        textStyle: {
            color: 'grey',
            fontSize: 20
        },
        left: 'center',
        top: 'center'
    },
    tooltip: {
        formatter: function (params: TooltipParams) {
            const selectedRegion: string = localStorage.getItem('selectedRegion') || 'es';

            if (!/price/.test(params.seriesName))
                return params.seriesName + '<br/>' + params.marker + params.name + '\xa0\xa0position:\xa0' + params.value;
            else
                return (
                    params.seriesName + '<br/>' + params.marker + params.name + '\xa0\xa0' + priceFormatter(params.value, selectedRegion)
                );
        }
    },
    toolbox: {
        feature: {}
    },
    xAxis: {
        data: []
    },
    yAxis: [
        {
            type: 'value',
            name: 'Price',
            position: 'right',
            alignTicks: true,
            axisLine: {
                show: true
            }
        },
        {
            type: 'value',
            name: 'Position',
            position: 'left',
            alignTicks: true,
            axisLine: {
                show: true
            }
        }
    ],
    legend: {
        type: 'scroll',
        left: '2%',
        right: '1%',
        data: []
    },
    dataZoom: [
        {
            type: 'slider',
            xAxisIndex: 0,
            realtime: true,
            filterMode: 'empty',
            start: 0,
            end: 100
        },
        {
            type: 'inside',
            xAxisIndex: 0,
            realtime: true,
            filterMode: 'empty',
            start: 0,
            end: 100
        }
    ],
    grid: {
        wdith: 500,
        show: true,
        right: '10%',
        left: 80
    },
    series: []
};

export const visibilityHistoryOption = {
    title: {
        text: '',
        textStyle: {
            color: 'grey',
            fontSize: 20
        },
        left: 'center',
        top: 'center'
    },
    tooltip: {
        formatter: function (params: TooltipParams) {
            return params.seriesName + '<br/>' + params.marker + params.name + '\xa0\xa0' + params.value + '\xa0point';
        }
    },
    toolbox: {
        feature: {}
    },
    xAxis: {
        data: []
    },
    yAxis: [
        {
            type: 'value',
            name: 'Price',
            position: 'right',
            alignTicks: true,
            axisLine: {
                show: true
            }
        },
        {
            type: 'value',
            name: 'Visibility',
            position: 'left',
            alignTicks: true,
            axisLine: {
                show: true
            }
        }
    ],
    legend: {
        type: 'scroll',
        left: '2%',
        right: '1%',
        data: []
    },
    dataZoom: [
        {
            type: 'slider',
            xAxisIndex: 0,
            realtime: true,
            filterMode: 'empty',
            start: 0,
            end: 100
        },
        {
            type: 'inside',
            xAxisIndex: 0,
            realtime: true,
            filterMode: 'empty',
            start: 0,
            end: 100
        }
    ],
    grid: {
        wdith: 500,
        show: true,
        right: '10%',
        left: 80
    },
    series: []
};

export const visibilityRadarOption = {
    title: {
        text: ''
    },
    legend: {
        data: ['Visibility'],
        right: 'right',
        show: false
    },
    tooltip: {
        trigger: 'axis'
    },
    radar: {
        indicator: [{ name: '' }]
    },
    series: [
        {
            name: 'Visibility distribution',
            type: 'radar',
            tooltip: {
                trigger: 'item'
            },
            data: [
                {
                    value: [],
                    name: 'Visibility'
                }
            ]
        }
    ]
};

export const priceHistoryDetailOption = {
    backgroundColor: 'transparent',
    title: {
        text: '',
        textStyle: {
            color: 'grey',
            fontSize: 20
        },
        left: 'center',
        top: 'center'
    },
    tooltip: {},
    toolbox: {
        feature: {}
    },
    xAxis: {
        data: []
    },
    yAxis: {
        scale: true
    },
    legend: {
        type: 'scroll',
        left: '2%',
        right: '1%',
        data: []
    },
    dataZoom: [
        {
            type: 'slider',
            xAxisIndex: 0,
            realtime: true,
            filterMode: 'empty',
            start: 0,
            end: 100
        },
        {
            type: 'inside',
            xAxisIndex: 0,
            realtime: true,
            filterMode: 'empty',
            start: 0,
            end: 100
        }
    ],
    grid: {
        show: true
    },
    series: []
};

export const categoriesVisibilityOption = {
    tooltip: {
        trigger: 'axis',
        axisPointer: {
            // Use axis to trigger tooltip
            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
        }
    },
    dataset: {
        source: []
    },
    grid: {
        top: '0%',
        right: '5%',
        containLabel: true
    },
    visualMap: {
        orient: 'horizontal',
        left: 'center',
        text: ['More products', 'Less products'],
        // Map the score column to color
        dimension: 0,
        min: 200,
        max: 1000,
        inRange: {
            color: ['#5DBFDB', '#E1E4E5', '#E76F5C']
        }
    },
    xAxis: {
        name: 'Visibility',
        position: 'bottom',
        nameGap: 25,
        nameLocation: 'middle'
    },
    yAxis: {
        type: 'category',
        barCategoryGap: '10%',
        axisLabel: { show: false }
    },
    series: [
        {
            type: 'bar',
            label: {
                show: true,
                formatter: '{b}',
                verticalAlign: 'middle',
                position: 'insideLeft',
                align: 'left'
            },
            encode: {
                // Map the "amount" column to X axis.
                x: 'amount',
                // Map the "product" column to Y axis
                y: 'product'
            }
        }
    ]
};
