import { Dialog, Box, TextField, DialogContent, Button, Typography, DialogActions, IconButton, DialogTitle } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import { useState } from 'react';
import SearchTag from 'components/filter/SearchTag';
import { useAppSelector } from 'store/hooks';
import { useNotification } from 'hooks/useNotification';

interface PriceHistoryModalProps {
    open: boolean;
    handleClose: () => void;
    handleCreated: (percentage: number, emails: string[]) => void;
}

function FilterPriceAlertTrackingModal({ open, handleClose, handleCreated }: PriceHistoryModalProps) {
    const { displayNotification } = useNotification();
    const [percentage, setPercentage] = useState<number>(-5);
    const { userInfo } = useAppSelector((state) => state.auth);

    const [emails, setEmails] = useState<string[]>([userInfo.email]);

    const showToastMessage = (msg: string, success = true) => {
        displayNotification({
            message: `${msg}`,
            type: success ? 'success' : 'error',
            horizontal: 'right'
        });
    };

    const handleCreateAlert = async () => {
        if (!percentage) {
            showToastMessage(`Please enter a percentage !`, false);
            return;
        }
        if (!emails.length) {
            showToastMessage(`Please enter at least one email !`, false);
            return;
        }
        handleCreated(percentage, emails);
    };

    const handleChangeEmails = (tags: string[]) => {
        setEmails(tags);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
            maxWidth={'md'}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{ sx: { borderRadius: '20px' } }}
        >
            <DialogTitle id="scroll-dialog-title">
                <Typography variant="h5" component="p">
                    💰 Create Price Alert Tracking for this Collection
                </Typography>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers={true}>
                <form onSubmit={handleCreateAlert}>
                    <TextField
                        type="number"
                        variant="outlined"
                        color="secondary"
                        size="small"
                        label="Percentage (%)"
                        sx={{ mt: '10px', mb: '10px' }}
                        onChange={(e) => setPercentage(parseFloat(e.target.value))}
                        value={percentage}
                        fullWidth
                        required
                    />
                    <Box sx={{ mt: '10px', mb: '10px' }}>
                        <SearchTag
                            tagsData={emails || []}
                            tagPlaceholder="Emails"
                            onChangeTag={(tags: string[]) => handleChangeEmails(tags)}
                        />
                    </Box>
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleCreateAlert}>Ok</Button>
            </DialogActions>
        </Dialog>
    );
}

export default FilterPriceAlertTrackingModal;
