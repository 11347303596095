import React from 'react';
import { FormControl, MenuItem, OutlinedInput, Select, SelectChangeEvent, Stack } from '@mui/material';

interface RatingProps {
    option?: any;
    value?: number | null;
    handleChangeOption?: (event: SelectChangeEvent) => void;
    handleChangeValue?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

function Rating({ option, value, handleChangeOption, handleChangeValue }: RatingProps) {
    return (
        <Stack direction={'row'} spacing={1}>
            <FormControl fullWidth size="small">
                <Select displayEmpty value={option} onChange={handleChangeOption}>
                    <MenuItem value={'gt'}>Greater than (gt)</MenuItem>
                    <MenuItem value={'gte'}>Greater than or equal to (gte)</MenuItem>
                    <MenuItem value={'lt'}>Less than (lt)</MenuItem>
                    <MenuItem value={'lte'}>Less than or equal to (lte)</MenuItem>
                </Select>
            </FormControl>
            <FormControl fullWidth size="small">
                <OutlinedInput
                    fullWidth
                    id="rating-range"
                    type={'number'}
                    name="rating-range"
                    placeholder="Rating range"
                    value={value}
                    onChange={handleChangeValue}
                />
            </FormControl>
        </Stack>
    );
}

export default Rating;
