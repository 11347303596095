import { useEffect, useState } from 'react';
import {
    Backdrop,
    Box,
    CircularProgress,
    ListItemButton,
    ListItemIcon,
    MenuItem,
    MenuList,
    Popover,
    Theme,
    Typography
} from '@mui/material';

import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { GrStatusInfo } from 'react-icons/gr';
import { useMutation } from '@apollo/client';
import { FiDatabase, FiList, FiSliders, FiLifeBuoy, FiRss, FiBarChart2, FiLogOut, FiUserCheck } from 'react-icons/fi';
import SpaceDashboardOutlinedIcon from '@mui/icons-material/SpaceDashboardOutlined';

import { userLoggingOut } from 'store/slices/auth/authSlice';
import { useAppSelector } from 'store/hooks';
import MenuItemLoading from 'components/common/skeletons/MenuItemLoading';
import { resetInitialStateFilter } from 'store/slices/filter/filterSlice';
import { resetAccuracyFilter } from 'store/slices/filter/customFilterSlice';
import { LOGOUT_MUTATION } from 'services/authService/queries';

interface SettingsPropoverProps {
    anchorEl: Element | null;
    handleClose: () => void;
    open: boolean;
}

function SettingsPropover({ anchorEl, handleClose, open }: SettingsPropoverProps) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const { loadingMenuItems, accessAccountUser, selectedRegion } = useAppSelector((state) => state.auth);

    const [isMMSValidator, setIsMMSValidator] = useState<boolean>(false);
    const [isMMSAccuracy, setIsMMSAccuracy] = useState<boolean>(false);
    const [isReportTraking, setIsReportTraking] = useState<boolean>(false);
    const [isProductStatus, setIsProductStatus] = useState<boolean>(false);
    const [isUsersManagementTraking, setIsUsersManagementTraking] = useState<boolean>(false);
    const [chatDashboardItemShow, setchatDashboardItemShow] = useState(false);

    const [logoutUser, { loading, data }] = useMutation(LOGOUT_MUTATION);

    const handleLogOut = () => {
        handleClose;
        dispatch(userLoggingOut());
        dispatch(resetInitialStateFilter());
        dispatch(resetAccuracyFilter());
        navigate('/');
    };

    useEffect(() => {
        if (data) {
            handleLogOut();
        }
    }, [loading]);

    useEffect(() => {
        const accountComponents: any[] = accessAccountUser.filter((item: any) => item.account.config.region == selectedRegion);

        if (accountComponents.length) {
            const components = accountComponents[0].account.components;
            const accessConfig = accountComponents[0].config;
            if (accessConfig) {
                const mmsvalidation = accessConfig?.mmsvalidator;

                setIsMMSValidator(mmsvalidation ? true : false);

                const mmsaccuracy = accessConfig?.mmsaccuracy;
                setIsMMSAccuracy(mmsaccuracy ? true : false);
            }

            const reportTraking = components.find((item: any) => item.name == 'reporttracking' && item.allowed);
            setIsReportTraking(reportTraking ? true : false);

            const productstatus = components.find((item: any) => item.name == 'productstatus' && item.allowed);
            setIsProductStatus(productstatus ? true : false);

            const userConfig = accountComponents[0]?.user?.config;
            if (userConfig && userConfig.usersManagement) {
                setIsUsersManagementTraking(userConfig.usersManagement ? true : false);
            }

            if (userConfig && userConfig?.assistantDashboard) {
                setchatDashboardItemShow(true);
            }
        }
    }, [loadingMenuItems]);

    return (
        <>
            <Popover
                id="account-settings-popover"
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
            >
                <MenuList
                    disablePadding
                    dense
                    sx={{
                        p: 1,
                        '& > *': {
                            borderRadius: 2
                        }
                    }}
                >
                    {loadingMenuItems ? (
                        <Box sx={{ width: 150 }}>
                            <MenuItemLoading height={20} radius={2} />
                        </Box>
                    ) : (
                        <Box>
                            <ListItemButton
                                onClick={() => {
                                    logoutUser();
                                }}
                            >
                                <ListItemIcon>
                                    <FiLogOut size={20} />
                                </ListItemIcon>
                                <Typography>Sign Out</Typography>
                            </ListItemButton>
                            <MenuItem
                                sx={{
                                    mb: 1,
                                    borderRadius: 1,
                                    ...(location.pathname === '/settings/profile' && {
                                        color: 'white',
                                        backgroundColor: (theme) => `${theme.palette.primary.main}!important`
                                    })
                                }}
                                selected={location.pathname === '/settings/profile'}
                                onClick={() => {
                                    handleClose();
                                    navigate('/settings/profile');
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        ...(location.pathname === '/settings/profile' && {
                                            color: 'white'
                                        })
                                    }}
                                >
                                    <FiSliders fontSize="small" />
                                </ListItemIcon>
                                <Typography>My Profile</Typography>
                            </MenuItem>
                            <MenuItem
                                sx={{
                                    mb: 1,
                                    borderRadius: 1,
                                    ...(location.pathname === '/exportationlist' && {
                                        color: 'white',
                                        backgroundColor: (theme) => `${theme.palette.primary.main}!important`
                                    })
                                }}
                                selected={location.pathname === '/exportationlist'}
                                onClick={() => {
                                    handleClose();
                                    navigate('/exportationlist');
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        ...(location.pathname === '/exportationlist' && {
                                            color: 'white'
                                        })
                                    }}
                                >
                                    <FiList fontSize="small" />
                                </ListItemIcon>
                                <Typography>Export List</Typography>
                            </MenuItem>
                            <MenuItem
                                sx={{
                                    mb: 1,
                                    borderRadius: 1,
                                    ...(location.pathname === '/settings/stats' && {
                                        color: 'white',
                                        backgroundColor: (theme) => `${theme.palette.primary.main}!important`
                                    })
                                }}
                                selected={location.pathname === '/settings/stats'}
                                onClick={() => {
                                    handleClose();
                                    navigate('/settings/stats');
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        ...(location.pathname === '/settings/stats' && {
                                            color: 'white'
                                        })
                                    }}
                                >
                                    <FiDatabase fontSize="small" />
                                </ListItemIcon>
                                <Typography>State Of Matching</Typography>
                            </MenuItem>

                            {/* assistant dashboard of chats */}
                            {chatDashboardItemShow && (
                                <MenuItem
                                    sx={{
                                        mb: 1,
                                        borderRadius: 1,
                                        ...(location.pathname.includes('/assistant/dashboard') && {
                                            color: 'white',
                                            backgroundColor: (theme) => `${theme.palette.primary.main}!important`
                                        })
                                    }}
                                    selected={location.pathname.includes('/assistant/dashboard')}
                                    onClick={() => {
                                        handleClose();
                                        navigate('/assistant/dashboard');
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            ...(location.pathname.includes('/assistant/dashboard') && {
                                                color: 'white'
                                            })
                                        }}
                                    >
                                        <SpaceDashboardOutlinedIcon fontSize="small" />
                                    </ListItemIcon>
                                    <Typography>Assitant Dashboard</Typography>
                                </MenuItem>
                            )}

                            {/* users management config */}
                            {isUsersManagementTraking && (
                                <MenuItem
                                    sx={{
                                        mb: 1,
                                        borderRadius: 1,
                                        ...(location.pathname === '/users/management' && {
                                            color: 'white',
                                            backgroundColor: (theme) => `${theme.palette.primary.main}!important`
                                        })
                                    }}
                                    selected={location.pathname === '/users/management'}
                                    onClick={() => {
                                        handleClose();
                                        navigate('/users/management');
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            ...(location.pathname === '/users/management' && {
                                                color: 'white'
                                            })
                                        }}
                                    >
                                        <FiUserCheck fontSize="small" />
                                    </ListItemIcon>
                                    <Typography>Users Management</Typography>
                                </MenuItem>
                            )}
                            {/* report config */}
                            {isReportTraking && (
                                <MenuItem
                                    sx={{
                                        mb: 1,
                                        borderRadius: 1,
                                        ...(location.pathname === '/settings/reports' && {
                                            color: 'white',
                                            backgroundColor: (theme) => `${theme.palette.primary.main}!important`
                                        })
                                    }}
                                    selected={location.pathname === '/settings/reports'}
                                    onClick={() => {
                                        handleClose();
                                        navigate('/settings/reports');
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            ...(location.pathname === '/settings/reports' && {
                                                color: 'white'
                                            })
                                        }}
                                    >
                                        <FiRss fontSize="small" />
                                    </ListItemIcon>
                                    <Typography>Tracking Reports</Typography>
                                </MenuItem>
                            )}
                            {/* MMS Validator pages */}
                            {isMMSValidator && (
                                <MenuItem
                                    sx={{
                                        mb: 1,
                                        borderRadius: 1,
                                        ...(location.pathname === '/validation' && {
                                            color: 'white',
                                            backgroundColor: (theme) => `${theme.palette.primary.main}!important`
                                        })
                                    }}
                                    selected={location.pathname === '/validation'}
                                    onClick={() => {
                                        handleClose();
                                        navigate('/validation');
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            ...(location.pathname === '/validation' && {
                                                color: 'white'
                                            })
                                        }}
                                    >
                                        <FiLifeBuoy fontSize="small" />
                                    </ListItemIcon>
                                    <Typography>MMS Validator</Typography>
                                </MenuItem>
                            )}
                            {isMMSAccuracy && (
                                <MenuItem
                                    sx={{
                                        mb: 1,
                                        borderRadius: 1,
                                        ...(location.pathname === '/mms-accuracy' && {
                                            color: 'white',
                                            backgroundColor: (theme) => `${theme.palette.primary.main}!important`
                                        })
                                    }}
                                    selected={location.pathname === '/mms-accuracy'}
                                    onClick={() => {
                                        handleClose();
                                        navigate('/mms-accuracy');
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            ...(location.pathname === '/mms-accuracy' && {
                                                color: 'white'
                                            })
                                        }}
                                    >
                                        <FiBarChart2 fontSize="small" />
                                    </ListItemIcon>
                                    <Typography>MMS Accuracy</Typography>
                                </MenuItem>
                            )}
                            {/* Products Status */}
                            {isProductStatus && (
                                <MenuItem
                                    sx={{
                                        mb: 1,
                                        borderRadius: 1,
                                        ...(location.pathname === '/productstatus' && {
                                            color: 'white',
                                            backgroundColor: (theme) => `${theme.palette.primary.main}!important`
                                        })
                                    }}
                                    selected={location.pathname === '/productstatus'}
                                    onClick={() => {
                                        handleClose();
                                        navigate('/productstatus');
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            ...(location.pathname === '/productstatus' && {
                                                color: 'white'
                                            })
                                        }}
                                    >
                                        <GrStatusInfo size={20} />
                                    </ListItemIcon>
                                    <Typography>Bulk Status Update</Typography>
                                </MenuItem>
                            )}
                        </Box>
                    )}
                </MenuList>
            </Popover>
            <div>
                <Backdrop sx={{ color: '#fff', zIndex: (theme: Theme) => theme.zIndex.modal + 1 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        </>
    );
}

export default SettingsPropover;
