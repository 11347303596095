import { gql } from '@apollo/client';

export const BETTER_WAY_DIST_QUERY = gql`
    query betterWayDist(
        $region: String!
        $website: WebsiteArgs
        $website_amazon: WebsiteArgs
        $config: JSON
        $websites: [WebsiteArgs!]
        $from: Int
        $size: Int
        $marketplace: Int
        $availability: String
        $keywords: String
        $advancedSearch: JSON
        $hideBundles: Boolean
        $hideRefurbished: Boolean
        $hideMarketplace: Boolean
        $hidelost: Boolean
        $pgcategoryid: String
        $instock: Boolean
        $fromsuite: Boolean
        $onlyGfk: Boolean
        $access: String
        $reviewedAccount: String
        $loadReviewed: Boolean
        $firstTimeDiscoverd: String
        $brands: String
    ) {
        betterWayDist(
            region: $region
            website: $website
            website_amazon: $website_amazon
            config: $config
            websites: $websites
            from: $from
            size: $size
            hidelost: $hidelost
            marketplace: $marketplace
            availability: $availability
            keywords: $keywords
            advancedSearch: $advancedSearch
            hideBundles: $hideBundles
            hideRefurbished: $hideRefurbished
            hideMarketplace: $hideMarketplace
            pgcategoryid: $pgcategoryid
            instock: $instock
            fromsuite: $fromsuite
            onlyGfk: $onlyGfk
            access: $access
            reviewedAccount: $reviewedAccount
            loadReviewed: $loadReviewed
            firstTimeDiscoverd: $firstTimeDiscoverd
            brands: $brands
        ) {
            error
            xAxis
            series
            legend
            queryHash
            categories
        }
    }
`;
