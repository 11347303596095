import React from 'react';

import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { RootState } from 'store';

type ProtectedRouteProps = {
    children?: JSX.Element | JSX.Element[] | React.ReactNode;
    routeName: string;
};

function ProtectedRoute({ children, routeName }: ProtectedRouteProps) {
    const { accessAccountUser, selectedRegion } = useSelector((state: RootState) => state.auth);

    const accountComponents: AccountAccess | undefined = accessAccountUser.find(
        (item: AccountAccess) => item?.account?.config.region == selectedRegion
    ) as AccountAccess | undefined;

    if (accountComponents && accountComponents?.account && accountComponents?.account?.components?.length) {
        const allComponents = accountComponents?.account?.components;

        const overview = allComponents.find((item: AccountComponent) => item.name == 'assortment') || { name: 'assortment', allowed: true };
        const freshCatalog = allComponents.find((item: AccountComponent) => item.name == 'freshcatalog') || {
            name: 'freshcatalog',
            allowed: true
        };
        const relevance = allComponents.find((item: AccountComponent) => item.name == 'relevance') || { name: 'relevance', allowed: true };
        const opportunities = allComponents.find((item: AccountComponent) => item.name == 'opportunities') || {
            name: 'opportunities',
            allowed: true
        };
        const newProducts = allComponents.find((item: AccountComponent) => item.name == 'newproducts') || {
            name: 'newproducts',
            allowed: true
        };
        const bestRated = allComponents.find((item: AccountComponent) => item.name == 'bestrated') || { name: 'bestrated', allowed: true };
        const priceHistory = allComponents.find((item: AccountComponent) => item.name == 'pricinghistory') || {
            name: 'pricinghistory',
            allowed: true
        };
        const multivariates = allComponents.find((item: AccountComponent) => item.name === 'pricingmultivariates') || {
            name: 'pricingmultivariates',
            allowed: true
        };

        const accessConfig = accountComponents?.config;
        const mmsvalidator =
            accessConfig && accessConfig?.mmsvalidator
                ? {
                      name: 'mmsvalidator',
                      allowed: true
                  }
                : {
                      name: 'mmsvalidator',
                      allowed: false
                  };
        const mmsaccuracy =
            accessConfig && accessConfig?.mmsaccuracy
                ? {
                      name: 'mmsaccuracy',
                      allowed: true
                  }
                : {
                      name: 'mmsaccuracy',
                      allowed: false
                  };

        const assistant =
            accessConfig && accessConfig?.assistant
                ? {
                      name: 'assistant',
                      allowed: true
                  }
                : {
                      name: 'assistant',
                      allowed: false
                  };

        const userConfig = accountComponents?.user?.config;

        const assistantDashboard =
            userConfig && userConfig.assistantDashboard
                ? { name: 'assistantDashboard', allowed: true }
                : { name: 'assistantDashboard', allowed: false };

        const usersManagement =
            userConfig && userConfig.usersManagement
                ? { name: 'usersManagement', allowed: true }
                : { name: 'usersManagement', allowed: false };

        const components = [
            ...(accountComponents?.account?.components ?? []),
            overview,
            freshCatalog,
            relevance,
            opportunities,
            newProducts,
            bestRated,
            priceHistory,
            multivariates,
            mmsvalidator,
            mmsaccuracy,
            assistant,
            assistantDashboard,
            usersManagement
        ];
        const routeItem = components.find((item: any) => item.name === routeName && item.allowed);

        if (routeItem) return <>{children}</>;
        else return <Navigate to={'/unauthorized'} replace />;
    } else {
        return <></>;
    }
}

export default ProtectedRoute;
