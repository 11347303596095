import { useEffect, useRef, useState } from 'react';
import {
    Box,
    Drawer,
    useMediaQuery,
    List,
    ListItem,
    Button,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Theme,
    TextField
} from '@mui/material';
// import ChatIcon from '@mui/icons-material/Chat';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import IconButton from '@mui/material/IconButton';
import Scrollbar from 'components/common/UI/ScrollBar';
import AddIcon from '@mui/icons-material/Add';
import { SidebarWidth } from '../../../theme/theme-variable';
import { useLazyQuery } from '@apollo/client';
import useChat from 'hooks/useChat';
import { NavLink, useNavigate } from 'react-router-dom';
import { GET_CHATS, DELETE_CHAT, RENAME_CHAT } from 'views/dashboard/AssistantPage/queries/queries';
import { useAppSelector } from 'store/hooks';
import { useNotification } from 'hooks/useNotification';
import IosShareTwoToneIcon from '@mui/icons-material/IosShareTwoTone';
interface SideBarProps {
    isMobileSidebarOpen: boolean;
    isSidebarOpen: boolean;
    handleCloseMobileDrawer: () => void;
}

function SidebarChat({ isMobileSidebarOpen, isSidebarOpen, handleCloseMobileDrawer }: SideBarProps) {
    const navigate = useNavigate();
    const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
    const { currentAccess, id } = useChat();
    const { isDark } = useAppSelector((state: any) => state.auth);

    // State to hold chat items
    const [chats, setChats] = useState<any[]>([]);

    const [anchorEl, setAnchorEl] = useState(null);
    const [menuChatId, setMenuChatId] = useState(null);
    const open = Boolean(anchorEl);
    const [renameMode, setRenameMode] = useState(null); // State to track which item is in rename mode
    const [newName, setNewName] = useState(''); // State to hold the new name
    const { displayNotification } = useNotification();

    const handleShareClick = () => {
        const sharePath = '/assistant/share/' + menuChatId; // Example share path
        const linkToCopy = `${window.location.origin}${sharePath}`; // Generate the link dynamically
        navigator.clipboard.writeText(linkToCopy).then(
            () => {
                showToastMessage('Link copied to clipboard');
                handleMenuClose(); // Close the menu after copying
            },
            (err) => {
                console.log('Something wrong happend during sharing :', err);
                showToastMessage('Error copying link to clipboard', false);
            }
        );
    };

    const handleRenameClick = () => {
        setRenameMode(menuChatId); // Activate rename mode for the chat
        setNewName(newName); // Prepopulate new name with current chat name
        handleMenuClose(); // Close the menu
    };

    const handleMenuOpen = (event: any, chatId: any) => {
        setAnchorEl(event.currentTarget);
        setMenuChatId(chatId); // Keep track of which chat is selected
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setMenuChatId(null); // Clear the selected chat
        // setRenameMode(null); // Exit rename mode
    };

    const handleDeleteChat = async () => {
        if (!menuChatId || !currentAccess.user.id) {
            console.error('Missing required parameters: menuChatId or userID');
            return;
        }
        // Call DeleteThread to delete the selected thread
        try {
            // Call DeleteThread to delete the selected thread
            const { data } = await DeleteThread({
                variables: {
                    userID: currentAccess.user.id,
                    threadId: menuChatId
                }
            });
            if (data.deleteThread) {
                if (id == menuChatId) navigate('/assistant/chat');
                else setChats(data.deleteThread.data);
                showToastMessage('Chat deleted successfully');
            }
            handleMenuClose();
        } catch (err) {
            console.error('Error deleting chat:', err);
            showToastMessage('Error deleting chat', false);
        }
    };

    const showToastMessage = (msg: string, success = true) => {
        displayNotification({
            message: `${msg}`,
            type: success ? 'success' : 'error',
            horizontal: 'right'
        });
    };

    const handleRenameSubmit = async (chatId: any) => {
        try {
            const { data } = await renameThread({
                variables: {
                    userID: currentAccess.user.id,
                    threadId: chatId,
                    newName: newName
                }
            });
            if (data.renameThread) {
                setChats(data.renameThread.data);
            }
            setRenameMode(null);
            setNewName(''); // Clear the new name
            showToastMessage('Chat renamed successfully');
        } catch (err) {
            console.error('Error Renaming chat:', err);
            showToastMessage('Error renaming chat', false);
        }
    };

    const [getThereads, { data: dataChats, error: errorChats }] = useLazyQuery(GET_CHATS, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache'
    });

    const [DeleteThread] = useLazyQuery(DELETE_CHAT, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache'
    });

    const [renameThread] = useLazyQuery(RENAME_CHAT, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache'
    });

    // const [CreateThread, { data: dataCreateThread, loading: loadingCreateThread, error: errorCreateThread }] = useLazyQuery(CREATE_ID, {
    //     notifyOnNetworkStatusChange: true,
    //     fetchPolicy: 'no-cache'
    // });

    useEffect(() => {
        const userID = currentAccess.user.id; // Example user ID, if applicable
        if (userID) {
            getThereads({
                variables: {
                    userID: userID
                }
            });
        }
    }, [currentAccess.user.id, id]); // Ensure it updates on user ID change

    // Log dataChats when it changes
    useEffect(() => {
        // setChatStarted(true);
        if (dataChats) {
            if (dataChats.getThereads) {
                if (dataChats.getThereads.data) {
                    setChats(dataChats.getThereads.data);
                }
            } else {
                console.error('Unexpected structure of dataChats', dataChats);
            }
        }
    }, [dataChats]);

    // Function to add a new chat
    const addNewChat = async () => {
        // Call CreateThread to create a new thread
        try {
            navigate(`/assistant/chat`);
        } catch (err) {
            console.error('Error creating thread:', err);
        }
    };

    if (errorChats) {
        console.error('Error loading chats:', errorChats);
        <div>Error loading chats</div>;
    }

    const inputRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
                setRenameMode(null); // Exit rename mode when clicking outside
            }
        };

        // Add event listener
        document.addEventListener('mousedown', handleClickOutside);

        // Clean up event listener
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [renameMode]);

    const SidebarContent = (
        <List>
            {/* "New Chat" button */}
            <ListItem disablePadding sx={{ mb: 2 }}>
                <Button
                    startIcon={<AddIcon />}
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={{ mb: 1, textTransform: 'none' }}
                    onClick={addNewChat}
                >
                    New Chat
                </Button>
            </ListItem>
            {chats.map((item, index) => (
                <ListItem
                    key={item.id}
                    disablePadding
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        mb: 1,
                        pl: '4px',
                        pr: '4px',
                        bgcolor: id === item.id ? (isDark ? '#f96654' : '#f96654') : isDark ? '#212121' : '#e8eaed',
                        color: id === item.id ? 'white' : isDark ? '#ffffff' : '#3c4043',
                        borderRadius: '8px',
                        '&:hover': {
                            bgcolor: id === item.id ? (isDark ? '#FFA07A' : '#FFA07A') : isDark ? '#333333' : '#d3d3d3',
                            color: isDark ? 'white' : 'black'
                        }
                    }}
                >
                    {renameMode === item.id ? (
                        <TextField
                            ref={inputRef} // Attach the ref to the TextField
                            variant="outlined"
                            size="small"
                            value={newName} // Use the state to control the value
                            onChange={(e) => setNewName(e.target.value)} // Update state with user input
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handleRenameSubmit(item.id); // Submit when Enter is pressed
                                }
                            }}
                            autoFocus
                            fullWidth
                            sx={{ bgcolor: 'transparent', color: 'inherit' }}
                        />
                    ) : (
                        <ListItemButton
                            component={NavLink}
                            to={`/assistant/chat/${item.id}`}
                            sx={{ flex: 1 }}
                            onClick={() => handleCloseMobileDrawer()}
                        >
                            <ListItemText
                                primary={
                                    item.name ? item.name.substring(0, 15) + (item.name.length > 15 ? '...' : '') : `New Chat ${index + 1}`
                                }
                                title={item.name} // Shows the full name on hover
                            />
                        </ListItemButton>
                    )}
                    <IconButton
                        edge="end"
                        aria-label="options"
                        onClick={(e) => handleMenuOpen(e, item.id)}
                        sx={{
                            color: isDark ? '#ffffff' : '#3c4043',
                            mr: 1, // Add margin-right for spacing
                            p: 0.5 // Optional: reduce padding to make the button smaller
                        }}
                    >
                        <MoreHorizOutlinedIcon />
                    </IconButton>
                </ListItem>
            ))}

            {/* Menu for options */}
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                sx={{
                    mt: 1, // Slight spacing below the button
                    '& .MuiMenu-paper': {
                        bgcolor: isDark ? '#2b2b2b' : '#ffffff',
                        color: isDark ? '#ffffff' : '#000000',
                        borderRadius: '8px',
                        width: 150,
                        overflow: 'visible',
                        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)'
                    }
                }}
            >
                <MenuItem onClick={handleShareClick}>
                    <ListItemIcon>
                        <IosShareTwoToneIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit">Share</Typography>
                </MenuItem>
                <MenuItem onClick={handleRenameClick}>
                    <ListItemIcon>
                        <EditOutlinedIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit">Rename</Typography>
                </MenuItem>
                <MenuItem
                    onClick={handleDeleteChat}
                    sx={{
                        color: 'red',
                        '&:hover': {
                            bgcolor: isDark ? '#FFB4A9' : '#FFCCCC' // Light red hover effect
                        }
                    }}
                >
                    <ListItemIcon>
                        <DeleteOutlineOutlinedIcon fontSize="small" sx={{ color: 'red' }} />
                    </ListItemIcon>
                    <Typography variant="inherit">Delete</Typography>
                </MenuItem>
            </Menu>
        </List>
    );

    if (lgUp) {
        return (
            <Drawer
                anchor="left"
                open={isSidebarOpen}
                variant="persistent"
                PaperProps={{
                    sx: {
                        width: SidebarWidth,
                        top: lgUp ? '100px' : '0px',
                        left: lgUp ? '30px' : '',
                        borderRadius: lgUp ? '9px' : '0',
                        border: '0',
                        height: 'calc(100vh - 130px)',
                        boxShadow: '0px 7px 30px 0px rgb(90 114 123 / 11%)'
                    }
                }}
            >
                <Scrollbar style={{ height: 'calc(100vh - 5px)' }}>
                    <Box sx={{ p: 2 }}>{SidebarContent}</Box>
                </Scrollbar>
            </Drawer>
        );
    } else {
        return (
            <Drawer
                anchor="left"
                open={isMobileSidebarOpen}
                onClose={handleCloseMobileDrawer}
                PaperProps={{
                    sx: {
                        width: SidebarWidth,
                        border: '0 !important'
                    }
                }}
                variant="temporary"
            >
                <Scrollbar style={{ height: 'calc(100vh - 5px)' }}>
                    <Box sx={{ p: 2 }}>{SidebarContent}</Box>
                </Scrollbar>
            </Drawer>
        );
    }
}

export default SidebarChat;
