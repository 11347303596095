import { createAsyncThunk } from '@reduxjs/toolkit';
import AuthService from 'services/authService';

interface loginUserType {
    email: string;
    password: string;
    isOAuth?: boolean;
}

export const loginUser = createAsyncThunk('auth/login', async ({ email, password, isOAuth }: loginUserType, thunkAPI) => {
    try {
        const authService = new AuthService();
        const requestLogin = await authService.userLogin(email, password, isOAuth);

        return requestLogin;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

export const logout = createAsyncThunk('auth/logout', async ({ email, password }: loginUserType, thunkAPI) => {
    try {
        const authService = new AuthService();
        const requestLogin = await authService.userLogin(email, password);

        const userSessionId = requestLogin?.login?.user?.id;
        if (userSessionId) {
            const appVersion = localStorage.getItem('appVersion');
            localStorage.clear();
            if (appVersion) localStorage.setItem('appVersion', appVersion);
            localStorage.setItem('sessionId', userSessionId);
        }
        return requestLogin;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

interface accessAccountType {
    userId: string;
}

export const accessAccountUser = createAsyncThunk('auth/access', async ({ userId }: accessAccountType, thunkAPI) => {
    try {
        const authService = new AuthService();
        const requestAceessAccount = await authService.userAccess(userId);

        return requestAceessAccount;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

export const userSession = createAsyncThunk('auth/session', async (_, thunkAPI) => {
    try {
        const authService = new AuthService();
        const requestUserSession = await authService.userSession();

        return requestUserSession;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});
