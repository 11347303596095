import React, { useEffect, useState } from 'react';
import { Card, Box, Stack, Typography, CardHeader, IconButton, FormControl, Select, MenuItem, Tooltip } from '@mui/material';

import { useMutation, useLazyQuery } from '@apollo/client';

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ReplayIcon from '@mui/icons-material/Replay';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import NotificationsOffOutlinedIcon from '@mui/icons-material/NotificationsOffOutlined';
import FilterPriceAlertTrackingModal from 'components/product/modal/FilterPriceAlertTrackingModal';
import { useAppSelector } from 'store/hooks';
import FilterPriceAlertRemove from 'components/product/modal/FilterPriceAlertRemove';
import { useNotification } from 'hooks/useNotification';
import { GET_PRICING_ALERT_FILTER_BY_USER, REMOVE_PRICING_ALERT_FILTER_BY_ID } from 'services/settings/queries';

export interface SortingLabelType {
    label?: string;
    value?: string;
    field?: string;
    direction?: string;
}

interface ProductsMainContainerProps {
    query?: string;
    exportVariable?: any;
    showPriceNotifications?: boolean;
    showReload?: boolean;
    showDownload?: boolean;
    showSortingLabels?: boolean;
    headerTitle?: string;
    subtitle?: string;
    sortingLabels?: SortingLabelType[];
    defaultSorting?: SortingLabelType;
    children?: JSX.Element | JSX.Element[] | React.ReactNode;
    total?: number | null;
    handleReload?: () => void;
    handleExport?: () => void;
    handleSorting?: (sorting: SortingLabelType) => void;
    handlePriceNotif?: (percentage: number, emails: string[]) => string | Promise<string>;
}

function ProductsMainContainer({
    query,
    exportVariable,
    showPriceNotifications,
    showReload,
    showDownload,
    showSortingLabels,
    defaultSorting,
    sortingLabels,
    headerTitle,
    subtitle,
    total,
    children,
    handleReload,
    handleExport,
    handlePriceNotif,
    handleSorting
}: ProductsMainContainerProps) {
    const { displayNotification } = useNotification();
    const { selectedRegion, userInfo } = useAppSelector((state) => state.auth);

    const [priceAlertsOpen, setPriceAlertsOpen] = useState(false);
    const [sortingHandler, setSortingHandler] = useState<SortingLabelType>(defaultSorting as SortingLabelType);
    const [isPriceAlertExist, setIsPriceAlertExist] = useState<boolean>(false);
    const [openRemove, setOpenRemove] = useState<boolean>(false);
    const [filterId, setFilterId] = useState<string>('');

    const [getPricingAlertFilter, { loading, data }] = useLazyQuery(GET_PRICING_ALERT_FILTER_BY_USER, {
        notifyOnNetworkStatusChange: true
    });

    const [removePriceAlertTracking, { data: dataRemove, loading: loadingRemove }] = useMutation(REMOVE_PRICING_ALERT_FILTER_BY_ID);

    function changeSorting(newSort: SortingLabelType) {
        newSort.direction = sortingHandler.direction;
        setSortingHandler(newSort);
        if (handleSorting) {
            handleSorting(newSort);
        }
    }
    function changeSortingDirection(direction: string) {
        const sorting: SortingLabelType = { ...sortingHandler };
        sorting.direction = direction;
        setSortingHandler(sorting);
        if (handleSorting) {
            handleSorting(sorting);
        }
    }

    const handleClickNotification = () => {
        if (!isPriceAlertExist) setPriceAlertsOpen(true);
        else setOpenRemove(true);
    };

    const handleRemoveItem = () => {
        setOpenRemove(false);
        removePriceAlertTracking({ variables: { region: selectedRegion, id: filterId } });
    };

    useEffect(() => {
        if (dataRemove) {
            if (dataRemove.removePricingAlertFilter.result == true) {
                displayNotification({
                    message: `Success, Alert will no longer received :) !`,
                    type: 'success',
                    horizontal: 'right'
                });
                loadFilter(query, exportVariable);
                setIsPriceAlertExist(false);
                setFilterId('');
            } else {
                displayNotification({
                    message: `Sorry Something bad happend during Removing This Alert From Config ! Please contact us to help !`,
                    type: 'error',
                    horizontal: 'right'
                });
            }
        }
    }, [loadingRemove]);

    useEffect(() => {
        if (data) {
            setIsPriceAlertExist(data.getPricingAlertFilterByUser.isAdvancedSearchExist);
            setFilterId(data.getPricingAlertFilterByUser.filterId);
        }
    }, [loading]);

    const loadFilter = (query: string | undefined, exportVariable: any | undefined) => {
        getPricingAlertFilter({
            variables: {
                user: userInfo.id,
                region: selectedRegion,
                query,
                filter: exportVariable
            }
        });
    };

    useEffect(() => {
        if (showPriceNotifications && exportVariable && exportVariable.region && query) {
            loadFilter(query, exportVariable);
        }
    }, [exportVariable]);

    return (
        <div id="container">
            <Card>
                <CardHeader
                    title={<Typography variant="h3">{headerTitle}</Typography>}
                    subheader={subtitle}
                    action={
                        <>
                            <Stack direction="row" alignItems={'center'} spacing={1}>
                                {showSortingLabels && (
                                    <Stack direction="row" alignItems={'center'} spacing={1}>
                                        <Typography variant="body1" fontSize={14}>
                                            Order by :
                                        </Typography>
                                        <FormControl size="small">
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={sortingHandler?.value}
                                                defaultValue="positiveRelevence"
                                            >
                                                {sortingLabels?.map((sort, index) => {
                                                    return (
                                                        <MenuItem onClick={() => changeSorting(sort)} key={index} value={sort.value}>
                                                            {sort.label}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                        <FormControl size="small">
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={sortingHandler?.direction || null}
                                                defaultValue="desc"
                                            >
                                                <MenuItem value="desc" onClick={() => changeSortingDirection('desc')}>
                                                    Desc
                                                </MenuItem>
                                                <MenuItem value="asc" onClick={() => changeSortingDirection('asc')}>
                                                    Asc
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Stack>
                                )}
                                {showReload && (
                                    <IconButton aria-label="reload" onClick={handleReload}>
                                        <ReplayIcon />
                                    </IconButton>
                                )}
                                {showDownload && (
                                    <IconButton aria-label="file-download" onClick={handleExport}>
                                        <FileDownloadIcon />
                                    </IconButton>
                                )}
                                {showPriceNotifications && (
                                    <Tooltip
                                        title={
                                            isPriceAlertExist ? 'Remove price Tracking for this list' : 'Add Price Tracking for this list'
                                        }
                                    >
                                        <span>
                                            <IconButton
                                                aria-label="price-notification"
                                                onClick={handleClickNotification}
                                                color={isPriceAlertExist ? 'success' : 'error'}
                                                disabled={total && total <= 20000 ? false : true}
                                            >
                                                {isPriceAlertExist ? <NotificationsOffOutlinedIcon /> : <NotificationsActiveOutlinedIcon />}
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                )}
                            </Stack>
                        </>
                    }
                />
            </Card>
            {children && <Box>{children}</Box>}
            <FilterPriceAlertTrackingModal
                open={priceAlertsOpen}
                handleCreated={async (percentage, emails) => {
                    if (handlePriceNotif) {
                        const result = await handlePriceNotif(percentage, emails);
                        if (result) {
                            setPriceAlertsOpen(false);
                            setFilterId(result);
                            setIsPriceAlertExist(true);
                        }
                    }
                }}
                handleClose={() => {
                    setPriceAlertsOpen(false);
                }}
            />
            <FilterPriceAlertRemove open={openRemove} handleClose={() => setOpenRemove(false)} handleRemove={handleRemoveItem} />
        </div>
    );
}

export default ProductsMainContainer;
