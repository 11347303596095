import { useQuery } from '@apollo/client';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { SyntheticEvent, useEffect, useState } from 'react';
import { THIRD_PARTY_CATEGORY } from 'services/filterService/queries';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { submitFilter, updateInputList, updateThirdPartyQuery } from 'store/slices/filter/filterSlice';

interface ThirdPartyProps {
    id?: string;
    thirdPartyValue: any;
}

function ThirdParty({ id, thirdPartyValue }: ThirdPartyProps) {
    const dispatch = useAppDispatch();

    const { selectedRegion } = useAppSelector((state) => state.auth);
    const { queryThirdParty } = useAppSelector((state) => state.filter);

    const [categoryData, setCategoryData] = useState<any[]>([]);

    const { loading, data } = useQuery(THIRD_PARTY_CATEGORY, {
        variables: {
            region: selectedRegion,
            q: queryThirdParty
        },
        notifyOnNetworkStatusChange: true
    });

    const handleChange = (event: SyntheticEvent<Element, Event>, value: any) => {
        dispatch(updateInputList({ id, data: { checkOption: 'Is', thirdparties: value } }));
        dispatch(submitFilter());
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch(updateThirdPartyQuery(event.target.value));
    };

    useEffect(() => {
        if (data) {
            setCategoryData(data.thirdpartycategoryresult.categories);
        }
    }, [loading]);
    console.log('thirdPartyValue', thirdPartyValue);
    return (
        <Autocomplete
            size="small"
            onChange={handleChange}
            clearOnEscape
            value={thirdPartyValue || null}
            getOptionLabel={(item: any) => (item?.name ? item.name : '')}
            loading={loading}
            options={categoryData}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            renderInput={(params) => (
                <TextField
                    {...params}
                    onChange={handleInputChange}
                    placeholder="Third Party Category"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        )
                    }}
                />
            )}
        />
    );
}

export default ThirdParty;
