import { useQuery } from '@apollo/client';
import { Autocomplete, CircularProgress, Typography, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { GOOGLE_CATEGORY } from 'services/filterService/queries';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { submitFilter, updateGoogleCategoryQuery, updateInputList } from 'store/slices/filter/filterSlice';

interface GoogleCategoryProps {
    id?: string;
    googleCategoryValue: any[];
    isCustom?: boolean;
    customQuery?: string;
    customHandleInputChange?: (query: string) => void;
    customHandleChange?: (option: any) => void;
}

function SingleGoogleCategory({
    id,
    googleCategoryValue,
    isCustom,
    customQuery,
    customHandleChange,
    customHandleInputChange
}: GoogleCategoryProps) {
    const dispatch = useAppDispatch();

    const { selectedRegion } = useAppSelector((state) => state.auth);
    const { queryGoogleCategory } = useAppSelector((state) => state.filter);

    const [googleCategoryData, setGoogleCategoryData] = useState<any[]>([]);

    const { loading, data } = useQuery(GOOGLE_CATEGORY, {
        variables: {
            region: selectedRegion,
            q: customQuery ? customQuery : queryGoogleCategory
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache'
    });

    const handleChange = (event: React.SyntheticEvent, option: any, id?: string) => {
        if (isCustom && customHandleChange) {
            customHandleChange(option);
        } else {
            dispatch(updateInputList({ id, data: { google: option } }));
            dispatch(submitFilter());

            if (!option) {
                dispatch(updateGoogleCategoryQuery(''));
            }
        }
    };

    useEffect(() => {
        if (data) setGoogleCategoryData(data.googlecategoriessearch.categories);
    }, [loading]);

    return (
        <Autocomplete
            id="search-google-category"
            size="small"
            clearOnEscape
            fullWidth
            onChange={(event: React.SyntheticEvent, option: any) => handleChange(event, option, id)}
            value={googleCategoryValue || null}
            loading={loading}
            options={googleCategoryData}
            isOptionEqualToValue={(option, value) => option.ref == value.ref}
            getOptionLabel={(item: any) => (item?.name ? item.ref + ' - ' + item.name : '')}
            renderOption={(props, option: any) => {
                return (
                    <li {...props} key={option.ref}>
                        <strong>
                            {option.ref} - {option.name}
                        </strong>
                        {option.parents && option.parents.length && (
                            <Typography sx={{ ml: '5px' }} variant="body2" color="text.secondary">
                                {option.parents && option.parents.map((parent: any) => parent.name).join(' > ')}
                            </Typography>
                        )}
                    </li>
                );
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder={'Search to see more categories'}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        )
                    }}
                    onChange={(e) => {
                        if (isCustom && customHandleInputChange) {
                            customHandleInputChange(e.target.value);
                        } else {
                            dispatch(updateGoogleCategoryQuery(e.target.value));
                        }
                    }}
                />
            )}
        />
    );
}

export default SingleGoogleCategory;
