import { Fragment, HTMLAttributes, useState } from 'react';

import {
    Grid,
    Tooltip,
    Typography,
    Box,
    Stack,
    TextField,
    IconButton,
    Popover,
    MenuItem,
    FormControl,
    Select,
    Autocomplete,
    Checkbox,
    ListItemText,
    FormControlLabel,
    AutocompleteRenderInputParams
} from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import { Location, useLocation } from 'react-router-dom';
import { DateRangePicker, DateRange } from 'mui-daterange-picker';

import AvailabilityFilter from './AvailabilityFilter';
import AdvancedFilter from './AdvancedFilter';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
    resetInputList,
    submitFilter,
    updateDatePicker,
    updateDateRangePicker,
    updateFirstTimeDiscoverd,
    updateFirstTimeDiscoverdLabel
} from 'store/slices/filter/filterSlice';

import AmazonFilter from './amazon/AmazonFilter';
import AccuracyFilter from './instant/AccuracyFilter';
import GoogleListingWizard from './wizard/GoogleListingWizard';
import ListingWizard from './wizard/ListingWizard';
import { filterTypeData } from 'constants/filter/filterData';
import WebsitesFilter from './websites/WebsitesFilter';
import { updateSelectedBrands } from 'store/slices/auth/authSlice';

export interface DateRangeLabelType {
    label?: string;
    value?: string;
    field?: string;
}

interface AdvancedFilterProps {
    title?: string;
    subtitle?: string;
    tooltipText?: string;
    hideAddingInput?: boolean;
    filterFields?: string[];
    inputListProps?: any;
    showAvailability?: boolean;
    showWebsitesFilter?: boolean;
    showOutOfStock?: boolean;
    showCompetitors?: boolean;
    showAdvancedFilter?: boolean;
    hideBrandsFilter?: boolean;
    showDatePicker?: boolean;
    showDateRangePicker?: boolean;
    showAmazonFilter?: boolean;
    showAccuracyFilter?: boolean;
    showGoogleFilter?: boolean;
    showListingWizard?: boolean;
    showInstantCategory?: boolean;
    showDateRanging?: boolean;
    dateRangesLabels?: DateRangeLabelType[];
}

function Filter({
    title,
    subtitle,
    tooltipText,
    hideAddingInput,
    filterFields,
    inputListProps,
    showOutOfStock,
    showAvailability,
    showAdvancedFilter,
    showDatePicker,
    showDateRangePicker,
    showAmazonFilter,
    showGoogleFilter,
    showWebsitesFilter,
    showAccuracyFilter,
    showListingWizard,
    showInstantCategory,
    showDateRanging,
    hideBrandsFilter,
    dateRangesLabels
}: AdvancedFilterProps) {
    const dispatch = useAppDispatch();
    const location: Location = useLocation();

    const { selectedRegion, userBrands, isMMS, selectedBrands, accessAccountUser } = useAppSelector((state) => state.auth);
    const { dateRangePicker, firstTimeDiscoverdLabel } = useAppSelector((state) => state.filter);

    const brandsRegion = userBrands.filter((item: any) => item.region === selectedRegion);
    const brands = brandsRegion.length ? brandsRegion[0]?.brands : [];

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const togglePicker = () => {
        setAnchorEl(null);
    };
    const [selectAllBrands, setSelectAllBrands] = useState(brands.length === selectedBrands.length ? true : false);

    let filterData =
        isMMS == true
            ? filterTypeData
            : filterTypeData.filter(
                  (item: any) =>
                      item.value != 'Module' && item.value != 'Product Group' && item.value != 'Flags' && item.value != 'BetterWay'
              );

    if (
        isMMS &&
        (location.pathname === '/assortment/relevant' ||
            location.pathname == '/assortment/opportunities' ||
            location.pathname == '/assortment/gfkproducts' ||
            location.pathname == '/assortment/gfkopportunities' ||
            location.pathname == '/assortment/bestrated' ||
            location.pathname == '/assortment/betterway' ||
            location.pathname == '/assortment/scoring')
    ) {
        filterData = [...filterData, { name: 'Listing Status', value: 'Listing Status' }];
    }

    const currentAccount: any = accessAccountUser.find((item: any) => item.account.config.region == selectedRegion);

    if (currentAccount?.account?.config?.thirdparties === true)
        filterData = [...filterData, { name: '3rd Party Category', value: '3rd Party Category' }];

    const currentAccess: any[] = accessAccountUser.filter((item: any) => item.account.config.region == selectedRegion);

    const config = currentAccess.length ? currentAccess[0].account.config : {};

    const showBrandsFilter = config?.isBrandAcount || false;

    const handleSelectedBrandsChange = (event: any, newValue: any[]) => {
        if (newValue.length) {
            dispatch(updateSelectedBrands(newValue));
            dispatch(submitFilter());
        } else if (newValue.length === 0) {
            dispatch(updateSelectedBrands(newValue));
        }
    };

    const handleChangeDatePicker = (dateValue: Date | null) => {
        dispatch(updateDatePicker(moment(dateValue, 'MM-DD-YYYY')));
        dispatch(submitFilter());
    };

    const handleChangeDateRangePicker = (item: DateRange) => {
        const startDate = moment(item.startDate, 'MM/DD/YYYY');
        const endDate = moment(item.endDate, 'MM/DD/YYYY');

        const isSameDay = startDate.isSame(endDate, 'day');

        dispatch(
            updateDateRangePicker({
                startDate: moment(item.startDate, 'MM-DD-YYYY'),
                endDate: moment(item.endDate, 'MM-DD-YYYY')
            })
        );

        if (!isSameDay) {
            handleClose();
            dispatch(submitFilter());
        }
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleApply = () => {
        dispatch(submitFilter());
    };

    const handleReset = () => {
        dispatch(resetInputList());
        dispatch(submitFilter());
    };

    const changeDateRange = (newDateRange: DateRangeLabelType) => {
        dispatch(updateFirstTimeDiscoverdLabel(newDateRange.value));
        if (newDateRange.field && newDateRange.value) {
            const spiltter = newDateRange.value.split('_');
            const unit = spiltter[0];
            const type = spiltter[1];
            const newFirstTimeDiscoverd = moment()
                .subtract(unit as any, type as any)
                .format('YYYY-MM-DD');
            dispatch(updateFirstTimeDiscoverd(newFirstTimeDiscoverd));
            dispatch(submitFilter());
        }
    };

    const handleSelectAllBrands = () => {
        if (!selectAllBrands) {
            dispatch(updateSelectedBrands(brands));
            dispatch(submitFilter());
        } else dispatch(updateSelectedBrands([]));

        setSelectAllBrands((prev) => !prev);
    };

    return (
        <Fragment>
            <Grid item xs={12} sx={{ mx: 2, mb: 2, mt: 1.5 }}>
                <Stack direction={{ xs: 'column', md: 'row' }} alignItems={{ md: 'center' }} spacing={1}>
                    <Box sx={{ flexGrow: 1 }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography
                                fontWeight="700"
                                variant="h1"
                                sx={{
                                    lineHeight: '1.235',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                {title}
                            </Typography>

                            {tooltipText && (
                                <Tooltip title={tooltipText} arrow>
                                    <IconButton
                                        sx={{
                                            fontSize: '0.8rem',
                                            marginLeft: '8px',
                                            marginTop: '-4px'
                                        }}
                                    >
                                        <HelpOutlineIcon fontSize="inherit" />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </div>
                    </Box>

                    {showAvailability && !showBrandsFilter && (
                        <Box>
                            <AvailabilityFilter showOutOfStock={showOutOfStock} />
                        </Box>
                    )}
                    {/* competitors websites  */}
                    {showDateRanging && (
                        <Box>
                            <FormControl size="small">
                                <Select
                                    labelId="date-range-select-label"
                                    id="date-range-select"
                                    value={firstTimeDiscoverdLabel || ''}
                                    defaultValue="7days"
                                >
                                    {dateRangesLabels?.map((range, index) => {
                                        return (
                                            <MenuItem onClick={() => changeDateRange(range)} key={index} value={range.value}>
                                                {range.label}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Box>
                    )}
                    <Box>
                        <Stack direction="row" alignItems={'center'} spacing={1}>
                            {showBrandsFilter && !hideBrandsFilter ? (
                                <Autocomplete
                                    size="small"
                                    multiple
                                    clearOnEscape
                                    id="selected-brands"
                                    onChange={handleSelectedBrandsChange}
                                    isOptionEqualToValue={(option, value) => option.name === value.name}
                                    value={selectedBrands || null}
                                    renderTags={() => null}
                                    options={[{ name: 'Select All', id: 'all' }, ...brands]}
                                    disableCloseOnSelect
                                    getOptionLabel={(option: any) => option.name}
                                    sx={{
                                        width: 210
                                    }}
                                    disableClearable
                                    renderOption={(props: HTMLAttributes<HTMLLIElement>, option: any) => {
                                        return option.name == 'Select All' ? (
                                            <MenuItem selected={false} sx={{ p: 0 }} onClick={handleSelectAllBrands} key={option?.id}>
                                                <Checkbox size="small" sx={{ mr: 1 }} checked={selectAllBrands} />
                                                <ListItemText>Select All</ListItemText>
                                            </MenuItem>
                                        ) : (
                                            <Box key={option?.id} component="li" {...props} sx={{ m: 0, p: 0 }}>
                                                <FormControlLabel
                                                    control={<Checkbox size="small" checked={props['aria-selected'] as boolean} />}
                                                    label={
                                                        option?.default ? `${option?.name?.toUpperCase()} ⭐` : option?.name?.toUpperCase()
                                                    }
                                                    labelPlacement="end"
                                                />
                                            </Box>
                                        );
                                    }}
                                    renderInput={(params: AutocompleteRenderInputParams) => (
                                        <TextField {...params} placeholder={`${selectedBrands.length} Selected Brands`} />
                                    )}
                                />
                            ) : (
                                ''
                            )}

                            {showDatePicker && (
                                <Box>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <DatePicker
                                            onChange={handleChangeDatePicker}
                                            sx={{
                                                width: 180,
                                                '& .MuiInputBase-input': {
                                                    padding: '8px'
                                                }
                                            }}
                                        />
                                    </LocalizationProvider>
                                </Box>
                            )}
                            {showDateRangePicker && (
                                <Box sx={{ width: 180 }}>
                                    <TextField
                                        id="input-date-range-picker"
                                        fullWidth
                                        size="small"
                                        disabled
                                        placeholder={'Date Range'}
                                        InputProps={{
                                            endAdornment: (
                                                <IconButton onClick={handleClick} size="small" sx={{ pr: 0 }}>
                                                    <EventIcon fontSize="small" />
                                                </IconButton>
                                            )
                                        }}
                                        variant="outlined"
                                    />
                                </Box>
                            )}
                        </Stack>
                    </Box>
                </Stack>
                <Typography variant="body2" color="text.secondary">
                    {subtitle}
                </Typography>
            </Grid>

            {/* Component Filter */}
            {showAdvancedFilter && (
                <Grid item xs={12}>
                    <AdvancedFilter
                        filterData={filterData}
                        inputListProps={inputListProps}
                        hideAddingInput={hideAddingInput}
                        filterFields={filterFields}
                        handleApply={handleApply}
                        handleReset={handleReset}
                    />
                </Grid>
            )}

            {showAmazonFilter && (
                <Grid item xs={12}>
                    <AmazonFilter />
                </Grid>
            )}

            {showWebsitesFilter && (
                <Grid item xs={12}>
                    <WebsitesFilter />
                </Grid>
            )}

            {showAccuracyFilter && (
                <Grid item xs={12}>
                    <AccuracyFilter showInstantCategory={showInstantCategory} />
                </Grid>
            )}
            {showGoogleFilter && (
                <Grid item xs={12}>
                    <GoogleListingWizard />
                </Grid>
            )}

            {showListingWizard && (
                <Grid item xs={12}>
                    <ListingWizard />
                </Grid>
            )}
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
            >
                <DateRangePicker
                    open={Boolean(anchorEl)}
                    initialDateRange={{
                        startDate: new Date(dateRangePicker.startDate),
                        endDate: new Date(dateRangePicker.endDate)
                    }}
                    toggle={togglePicker}
                    onChange={handleChangeDateRangePicker}
                />
            </Popover>
        </Fragment>
    );
}

export default Filter;
