import React, { useEffect, useState } from 'react';
import { Autocomplete, Avatar, Box, Chip, CircularProgress, Link, Stack, TextField } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateExactWebsiteQuery, updateInputList } from 'store/slices/filter/filterSlice';
import { useQuery } from '@apollo/client';
import { WEBSITE_CATEGORY } from 'services/filterService/queries';
import WebsiteImage from 'components/product/Websites/WebsiteImage';

interface WebsiteCategoryProps {
    id?: string;
    exactWebsiteCategory?: any;
    exactWebsiteOption?: any;
}

function WebsiteCategory({ id, exactWebsiteCategory, exactWebsiteOption }: WebsiteCategoryProps) {
    const dispatch = useAppDispatch();

    const { selectedRegion, userWebsites } = useAppSelector((state) => state.auth);
    const { inputList, queryExactWebsite } = useAppSelector((state) => state.filter);

    const websites = userWebsites.filter((item: any) => item.region === selectedRegion)[0].websites;

    const [websiteCategoryData, setWebsiteCategoryData] = useState<any[]>([]);
    const [currentWebsiteCategory, setCurrentWebsiteCategory] = useState<any>(exactWebsiteOption);

    const { loading, data } = useQuery(WEBSITE_CATEGORY, {
        variables: {
            region: selectedRegion,
            website: currentWebsiteCategory,
            q: queryExactWebsite
        },
        skip: !currentWebsiteCategory,
        notifyOnNetworkStatusChange: true
    });

    const handleChangeExactWebsiteOption = (event: React.SyntheticEvent, option: any, id?: string) => {
        const postIndex = inputList.findIndex((item: any) => item.id === id);

        dispatch(
            updateInputList({
                id,
                data: {
                    websitecategory: option && option.id ? { ...inputList[postIndex]?.websitecategory, website: option } : undefined
                }
            })
        );

        setCurrentWebsiteCategory({
            id: option.id,
            name: option.name
        });
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch(updateExactWebsiteQuery(event.target.value));
    };

    const handleChange = (event: React.SyntheticEvent, option: any, id?: string) => {
        const postIndex = inputList.findIndex((item: any) => item.id === id);

        if (postIndex !== -1) {
            dispatch(updateExactWebsiteQuery(''));
            dispatch(
                updateInputList({
                    id,
                    data: {
                        websitecategory: { ...inputList[postIndex]?.websitecategory, category: option }
                    }
                })
            );
        }
    };

    useEffect(() => {
        if (data) {
            setWebsiteCategoryData(data.websiteCategories.list);
        }
    }, [loading]);

    return (
        <Stack direction={'row'} spacing={1} justifyContent={'space-between'}>
            <Autocomplete
                size="small"
                clearOnEscape
                fullWidth
                disableClearable
                id="selected-websites"
                value={exactWebsiteOption || null}
                options={websites}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option: any) => (option?.alias ? option?.alias : '')}
                renderOption={(props, option: any) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 }, my: 0.3 }} {...props}>
                        <WebsiteImage name={option?.name} websiteId={option?.id} websiteUrl={option?.url} height={20} width={20} />
                        {option?.name}
                    </Box>
                )}
                renderInput={(params) => <TextField {...params} placeholder="Websites" variant="outlined" />}
                onChange={(event: React.SyntheticEvent, option: any | null) => handleChangeExactWebsiteOption(event, option, id)}
            />
            <Autocomplete
                size="small"
                multiple
                id="tags-standard"
                fullWidth
                value={exactWebsiteCategory || []}
                disableClearable
                onChange={(event: React.SyntheticEvent, option: any) => handleChange(event, option, id)}
                getOptionLabel={(option) => (option?.name ? option.name : '')}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                options={websiteCategoryData}
                loading={loading}
                inputValue={queryExactWebsite || ''}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        onChange={handleInputChange}
                        placeholder={'Search to see more categories'}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </>
                            )
                        }}
                    />
                )}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                        const { key, ...tagProps } = getTagProps({ index });
                        const website = websites.find((item: Website) => item.id === option?.website?.id);
                        return (
                            <Chip
                                key={key}
                                avatar={
                                    <Link
                                        href={`${website?.url}${option?.url}`}
                                        underline="none"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        variant="caption"
                                    >
                                        <Avatar
                                            alt={option?.website?.alias}
                                            sx={{ height: 20, width: 20 }}
                                            src={`${process.env.PUBLIC_URL}/websites/${option?.website?.id}.png`}
                                        />
                                    </Link>
                                }
                                label={option.name}
                                size="small"
                                {...tagProps}
                            />
                        );
                    })
                }
            />
        </Stack>
    );
}

export default WebsiteCategory;
