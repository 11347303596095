import { useQuery } from '@apollo/client';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import React, { ChangeEvent, SyntheticEvent, useEffect } from 'react';
import { THIRD_PARTY_CATEGORY_RESULT } from 'services/filterService/queries';
import { useAppSelector } from 'store/hooks';

interface ThirdPartyCategoryProps {
    value: any;
    customQuery: string;
    customHandleInputChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    customHandleChange: (event: SyntheticEvent<Element, Event>, value: any) => void;
}

function ThirdPartyCategory({ value, customQuery, customHandleChange, customHandleInputChange }: ThirdPartyCategoryProps) {
    const { selectedRegion } = useAppSelector((state) => state.auth);

    const [categoryData, setCategoryData] = React.useState<any[]>([]);

    const { loading, data } = useQuery(THIRD_PARTY_CATEGORY_RESULT, {
        variables: {
            region: selectedRegion,
            show: true,
            q: customQuery
        },
        notifyOnNetworkStatusChange: true
    });

    useEffect(() => {
        if (data) {
            setCategoryData(data.thirdpartycategoryresult.categories);
        }
    }, [loading]);

    return (
        <Autocomplete
            size="small"
            onChange={customHandleChange}
            clearOnEscape
            value={value}
            getOptionLabel={(item: any) => (item?.name ? item.name : '')}
            loading={loading}
            options={categoryData}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            renderInput={(params) => (
                <TextField
                    {...params}
                    onChange={customHandleInputChange}
                    placeholder="Third Party Category"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        )
                    }}
                />
            )}
        />
    );
}

export default ThirdPartyCategory;
