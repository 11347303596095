import { Box, CircularProgress } from '@mui/material';

function ChartLoading({ minHeight = 300 }: { minHeight?: number }) {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight }}>
            <CircularProgress />
        </Box>
    );
}

export default ChartLoading;
