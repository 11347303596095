import { Button, Dialog, DialogContent, DialogContentText, DialogProps, DialogTitle, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useAppDispatch } from 'store/hooks';
import { updateNewAppVersion } from 'store/slices/auth/authSlice';

function NewVersionModalUpdate() {
    const dispatch = useAppDispatch();
    const auth = useSelector((state: RootState) => state.auth);

    const handleClose: DialogProps['onClose'] = (event, reason) => {
        if (reason && reason === 'backdropClick') return;
        dispatch(updateNewAppVersion(false));
        localStorage.setItem('appVersion', auth.newVersion);
        window.location.reload();
    };

    const handleRefresh = () => {
        dispatch(updateNewAppVersion(false));
        localStorage.setItem('appVersion', auth.newVersion);
        window.location.reload();
    };

    return (
        <>
            <Dialog
                open={auth.newAppVersion}
                onClose={handleClose}
                disableEscapeKeyDown={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography variant="h2" alignContent={'center'} alignItems={'center'} align="center">
                        New Version Available 🎉
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" alignContent={'center'} alignItems={'center'} align="center">
                        A new version of the app is available. Please click on refresh to get the latest version
                        <Button onClick={handleRefresh}>Refresh 🔄️</Button>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </>
    );
}
export default NewVersionModalUpdate;
