import { ChangeEvent, KeyboardEvent, useState } from 'react';
import { FormControl, TextField } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { submitFilter, updateAmazonBestSellerKeyword } from 'store/slices/filter/filterSlice';

function AmazonKeyword() {
    const dispatch = useAppDispatch();
    const { amazonBestSellerKeyword } = useAppSelector((state) => state.filter);

    const [keyword, setKeyword] = useState(amazonBestSellerKeyword || '');

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setKeyword(event.target.value);
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            dispatch(updateAmazonBestSellerKeyword(keyword));
            dispatch(submitFilter());
        }
    };

    return (
        <FormControl size="small" fullWidth>
            <TextField
                size="small"
                value={keyword}
                onKeyDown={handleKeyDown}
                onChange={handleChange}
                placeholder="Exp : Category, Brand, EAN ..."
            />
        </FormControl>
    );
}

export default AmazonKeyword;
