import {
    Typography,
    Card,
    CardContent,
    CardHeader,
    Box,
    Stack,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Link,
    Tooltip,
    Grid,
    Chip,
    IconButton
} from '@mui/material';
import moment from 'moment';

import Relevance from './detail/Relevance';
import RatingProduct from './detail/RatingProduct';
import AmazonBestSeller from './detail/AmazonBestSeller';
import Websites from './Websites';
import { useEffect, useState } from 'react';
import PriceHistoryModal from './modal/PriceHistoryModal';
import PriceChange from './detail/PriceChange';
import Flags from './detail/Flags';
import ImageComponent from 'components/common/UI/ImageComponent';
import GFK from './detail/GFK';
import { useAppSelector } from 'store/hooks';
import GfkVariantsModal from './modal/GfkVariantsModal';

import { FaCheckCircle } from 'react-icons/fa';
import { useMutation } from '@apollo/client';
import { CHANGE_REVIEWED_STATUS_MUTATION, REMOVE_ALERT_PRICE_MUTATION } from 'services/settings/queries';
import { useNotification } from 'hooks/useNotification';
import WrongValidationInsight from './insight/WrongValidationInsight';
import MissingValidationInsight from './insight/MissingValidationInsight';
import CorrectValidationInsight from './insight/CorrectValidationInsight';

import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import NotificationsOffOutlinedIcon from '@mui/icons-material/NotificationsOffOutlined';
import PriceAlertTrackingModal from './modal/PriceAlertTrackingModal';
import { scaleLogarithmically } from 'utils/scale';
import AmazonSales from './detail/AmazonSales';

interface ProductWebsiteType {
    websiteId: string;
    id: string;
    websiteAlias: string;
    price: number;
    name: string;
    availability: string;
    marketplace?: boolean;
    url: string;
    websiteLastDataDate?: string;
    productLastUpdate?: string;
    brand: string;
    offers?: any[] | null;
}

interface WebSiteItem {
    name: string;
    image: string;
    price: number;
    currency: string;
}

export interface ReviewedStatusType {
    name?: string;
    status?: string;
    color?: string;
    textColor?: string;
    disabled?: boolean;
}

interface ProductCardProps {
    title?: string;
    CQI?: number;
    brand?: string;
    hideRelevance?: boolean;
    relevance?: any;
    position?: number;
    ean?: string;
    isOpportunities?: boolean;
    productRef?: string;
    trackingDate?: string;
    url?: string;
    image: string;
    amazonPosition?: number;
    amazonBestSellerPosition?: number;
    isContainSelect?: boolean;
    isBetterWay?: boolean;
    isChoice?: boolean;
    isModal?: boolean;
    singleRating?: boolean;
    ratingCount?: number;
    ratingValue?: number;
    oldPrice?: number;
    newPrice?: number;
    dateChange?: string;
    lastProductUpdateDate?: string;
    flags?: string[];
    showPriceAlert?: boolean;
    alertId?: string;
    gfkId?: string;
    gfkSku?: string;
    gfkMonthAbrv?: string;
    gfkIsWinning?: boolean;
    gfkPosition?: number;
    gfkPositionInCategory?: number;
    googleCategory?: string;
    gfkSalesValue?: string;
    gfkUnit?: string;
    gfkMonth?: string;
    gfkMethod?: string;
    gfkVariants?: string;
    websites?: WebSiteItem[];
    productWebsites?: ProductWebsiteType[];
    reviewedStatus?: ReviewedStatusType;
    isOutlined?: boolean;
    visibility?: number;
    showVisibility?: boolean;
    isCrawloPG?: boolean;
    gfkVariantsLoad?: boolean;
    amazonRank?: number;
    amazonGoogleCategoriesRank?: number;
    googleCategories?: any;
    amazonSales?: string;
    amazonSalesCount?: string;
    amazonMonthAbrv?: string;
    reviewedId?: string;
    showReviewStatus?: boolean;
    showGFK?: boolean;
    showFlags?: boolean;
    showAmazonBestSeller?: boolean;
    mmsPGdata?: string;
    crawloPG?: string;
    validationGoogleCategory?: any;
    isModule?: boolean;
    validationInsights?: any;
    queryCacheId?: string;
}

export const listedReviewerdStatusAlreadyOptions: ReviewedStatusType[] = [
    {
        name: 'Already Listed (Same EAN)',
        status: 'LISTED',
        textColor: '#474C5A',
        color: '#EEF0F1'
    },
    {
        name: 'Delisting candidate',
        status: 'DELISTING',
        textColor: '#fff',
        color: '#20C2DE'
    }
];
export const defaultReviewerdStatusOptions: ReviewedStatusType[] = [
    {
        name: 'Opportunity',
        status: 'OPPORTUNITIES',
        textColor: '#fff',
        color: '#20C2DE',
        disabled: false
    },
    {
        name: 'Already Listed (Same EAN)',
        status: 'LISTED',
        textColor: '#474C5A',
        color: '#EEF0F1',
        disabled: true
    },
    {
        name: 'Already Listed (different EAN)',
        status: 'LISTED_DIFF_EAN',
        textColor: '#474C5A',
        color: '#EEF0F1',
        disabled: true
    },
    {
        name: 'Marketplace Opportunity',
        status: 'MARKETPLACE_OPPORTUNITY',
        textColor: '#fff',
        color: '#20C2DE',
        disabled: false
    },
    {
        name: 'Cannot be listed',
        status: 'CANNOT_LISTED',
        textColor: '#fff',
        color: '#20C2DE',
        disabled: false
    },
    {
        name: 'Can be listed',
        status: 'CAN_LISTED',
        textColor: '#fff',
        color: '#20C2DE',
        disabled: false
    },
    {
        name: 'In process to be listed',
        status: 'PROCESSING',
        textColor: '#fff',
        color: '#20C2DE',
        disabled: false
    },
    {
        name: 'Delisting candidate',
        status: 'DELISTING',
        textColor: '#fff',
        color: '#20C2DE',
        disabled: false
    },
    {
        name: 'Others',
        status: 'OTHERS',
        textColor: '#fff',
        color: '#20C2DE',
        disabled: false
    }
];

function ProductCard({
    title,
    visibility,
    showVisibility,
    CQI,
    hideRelevance,
    isOpportunities,
    ean,
    singleRating,
    productRef,
    trackingDate,
    brand,
    relevance,
    position,
    image,
    amazonPosition,
    amazonBestSellerPosition,
    ratingCount,
    ratingValue,
    oldPrice,
    newPrice,
    dateChange,
    flags,
    productWebsites,
    lastProductUpdateDate,
    showPriceAlert,
    alertId,
    gfkId,
    gfkSku,
    gfkMonthAbrv,
    gfkMethod,
    gfkIsWinning,
    gfkPosition,
    gfkPositionInCategory,
    googleCategory,
    gfkSalesValue,
    gfkUnit,
    gfkMonth,
    gfkVariants,
    amazonRank,
    amazonGoogleCategoriesRank,
    googleCategories,
    amazonSales,
    amazonSalesCount,
    amazonMonthAbrv,
    reviewedStatus,
    isOutlined,
    isModal,
    isBetterWay,
    isChoice,
    gfkVariantsLoad = true,
    showReviewStatus,
    showGFK,
    showAmazonBestSeller,
    showFlags,
    isCrawloPG,
    reviewedId,
    isModule,
    mmsPGdata,
    crawloPG,
    validationGoogleCategory,
    validationInsights,
    queryCacheId
}: ProductCardProps) {
    const { displayNotification } = useNotification();
    const [openPrice, setOpenPrice] = useState<boolean>(false);
    const [idAlert, setIdAlert] = useState<string | undefined>(alertId);
    const [openAlertPrice, setOpenAlertPrice] = useState<boolean>(false);
    const [openGfkVariant, setOpenGfkVariant] = useState<boolean>(false);
    const [alertStatus, setAlertStatus] = useState<boolean>(alertId ? true : false);
    const [isProductsVisibility, setIsProductsVisibility] = useState<boolean>(false);
    const [reviewed, setReviewed] = useState<ReviewedStatusType>();
    const { selectedRegion, accessAccountUser, userInfo, isBrand } = useAppSelector((state) => state.auth);
    const [reviewedStatusOptions, setReviewedStatusOptions] = useState<ReviewedStatusType[]>([]);
    const [changeMatchStatus] = useMutation(CHANGE_REVIEWED_STATUS_MUTATION);
    const [removeAlertPrice] = useMutation(REMOVE_ALERT_PRICE_MUTATION);

    const currentAccess: any[] = accessAccountUser.filter((item: any) => item.account.config.region == selectedRegion);

    useEffect(() => {
        if (reviewedStatus && reviewedStatus.status) {
            setReviewed(reviewedStatus);
            setReviewedStatusOptions(
                reviewedStatus.status == 'LISTED' ? listedReviewerdStatusAlreadyOptions : defaultReviewerdStatusOptions
            );
        }
    }, [reviewedStatus]);

    useEffect(() => {
        const accountComponents: any[] = accessAccountUser.filter((item: any) => item.account.config.region == selectedRegion);

        if (accountComponents.length) {
            const components = accountComponents[0].account.components;

            const productsVisibility = components.filter((item: any) => item.name == 'productsvisibility' && item.allowed);
            setIsProductsVisibility(productsVisibility.length ? true : false);
        }
    }, []);

    const handleClickPriceHistory = () => {
        setOpenPrice(true);
    };

    const getBrandName = () => {
        if (brand) {
            return brand.toUpperCase();
        } else {
            if (productWebsites?.length) {
                const withBrand = productWebsites.filter((elm: any) => elm?.brand);
                if (withBrand.length) {
                    return withBrand[0]?.brand;
                }
            }
            return 'No Brand';
        }
    };

    const showToastMessage = (msg: string, success = true) => {
        displayNotification({
            message: `${msg}`,
            type: success ? 'success' : 'error',
            horizontal: 'right'
        });
    };

    const handleClickAddAlert = () => {
        setOpenAlertPrice(true);
    };

    const handleClickRemoveAlert = async () => {
        if (!idAlert) {
            showToastMessage(`There is no alert Id ! please retry later !`, false);
            return;
        }
        await removeAlertPrice({
            variables: {
                id: idAlert,
                region: selectedRegion
            }
        })
            .then((res) => {
                if (res.data.removeAlertPrice.removed) {
                    setAlertStatus(false);
                    showToastMessage(`Cool ! The alert just got removed from your settings `, true);
                } else {
                    showToastMessage(`Oops ! something go wrong retry later !`, false);
                }
            })
            .catch((err) => {
                console.log('error :', err);
                showToastMessage(`Oops ! something go wrong retry later !`, false);
            });
    };

    const changeProductReviewedStatus = async (id?: string, match?: any, status?: string, currentReviewed?: ReviewedStatusType) => {
        if (match) {
            await changeMatchStatus({
                variables: {
                    region: selectedRegion,
                    cacheID: queryCacheId || '',
                    id,
                    match,
                    status,
                    user: userInfo?.id,
                    account: currentAccess.length ? currentAccess[0].account.id : ''
                }
            })
                .then((res) => {
                    if (res.data.changeReviewedStatus.id) {
                        showToastMessage(`Cool ! your changes just been saved to our database :) `, true);
                        setReviewed(currentReviewed);
                    } else {
                        showToastMessage(`Oops ! something go wrong retry later !`, false);
                    }
                })
                .catch((err) => {
                    console.log('error :', err);
                });
        }
    };

    const handleClickGfkVariants = () => {
        setOpenGfkVariant(true);
    };

    useEffect(() => {
        setIdAlert(alertId || '');
        setAlertStatus(alertId ? true : false);
    }, [alertId]);

    return (
        <Card
            variant={isOutlined ? 'outlined' : 'elevation'}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                '& .MuiCardHeader-content': {
                    width: '95%'
                },
                m: 0,
                p: 0,
                ...(isOpportunities &&
                    !isBrand && {
                        border: '1px solid #20C2DE'
                    })
            }}
        >
            {amazonBestSellerPosition && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                        color={'white'}
                        fontSize={13}
                        style={{ backgroundColor: '#ff9900', padding: '5px 10px', borderEndEndRadius: 4 }}
                    >
                        {`#${amazonBestSellerPosition}`}
                    </Typography>
                </div>
            )}

            <CardHeader
                title={
                    <Tooltip title={title} placement="top-start">
                        <Link
                            className={`title_${ean || productRef}`}
                            underline="none"
                            onClick={handleClickPriceHistory}
                            color="secondary"
                            sx={{ cursor: 'pointer' }}
                        >
                            {title ? title : 'No Title'}
                        </Link>
                    </Tooltip>
                }
                titleTypographyProps={{
                    noWrap: true,
                    color: 'secondary',
                    style: { overflow: 'hidden', textOverflow: 'ellipsis' }
                }}
                subheader={getBrandName()}
                subheaderTypographyProps={{
                    noWrap: true,
                    style: { overflow: 'hidden', textOverflow: 'ellipsis' }
                }}
                action={
                    showPriceAlert && (
                        <Box>
                            {!alertStatus ? (
                                <Tooltip title="Add Price Tracking on this Product">
                                    <span>
                                        <IconButton size="small" color="error" onClick={handleClickAddAlert} aria-label="add-alert">
                                            <NotificationsActiveOutlinedIcon />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            ) : (
                                <Tooltip title="Remove Price Tracking for this Product">
                                    <span>
                                        <IconButton size="small" color="success" onClick={handleClickRemoveAlert} aria-label="remove-alert">
                                            <NotificationsOffOutlinedIcon />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            )}
                        </Box>
                    )
                }
            />
            {!hideRelevance && (
                <Box sx={{ px: 2 }}>
                    <Relevance value={Number(relevance || 0)} />
                </Box>
            )}

            {/* visibility */}
            {visibility && showVisibility && isProductsVisibility ? (
                <Box sx={{ px: 2 }}>
                    <Stack direction={'row'} spacing={2}>
                        <Typography variant="body2" color="text.secondary">
                            <strong>P.V :</strong>
                        </Typography>
                        <Box>
                            <Typography variant="body2" color="text.secondary">
                                <strong>{`${scaleLogarithmically(visibility)} `} pt</strong>
                            </Typography>
                        </Box>
                    </Stack>
                </Box>
            ) : (
                ''
            )}

            {/* CQI */}
            {CQI ? (
                <Box sx={{ px: 2 }}>
                    <Stack direction={'row'} spacing={2}>
                        <Typography variant="body2" color="text.secondary">
                            <strong>CQI :</strong>
                        </Typography>
                        <Box>
                            <Typography variant="body2" color="text.secondary">
                                <strong>{`${CQI} `} %</strong>
                            </Typography>
                        </Box>
                    </Stack>
                </Box>
            ) : (
                ''
            )}

            <Box sx={{ mx: 2, m: '20px', mt: '50px', height: '170px' }}>
                <Link
                    className={`image_${ean || productRef}`}
                    onClick={handleClickPriceHistory}
                    underline="none"
                    sx={{ cursor: 'pointer' }}
                    rel="noopener noreferrer"
                >
                    <ImageComponent
                        imageSource={image}
                        alt={title}
                        height={'100%'}
                        isBetterWay={isBetterWay}
                        isChoice={isChoice}
                        sx={{
                            maxWidth: '100%',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            marginBottom: '20px'
                        }}
                    />
                </Link>
            </Box>

            <CardContent>
                {showFlags && !isBrand ? <Flags flags={flags} /> : ''}
                {isCrawloPG && (
                    <Stack direction={'column'} spacing={1} sx={{ mb: 2 }}>
                        <Chip
                            label={
                                mmsPGdata
                                    ? `${isModule ? 'MMS Module' : 'MMS PG'} : ${mmsPGdata}`
                                    : `${isModule ? 'MMS Module' : 'MMS PG'} : Not Found`
                            }
                            variant="outlined"
                            color="primary"
                            sx={{ width: '100%' }}
                        />
                        {crawloPG && (
                            <Chip
                                label={`${isModule ? 'Crawlo Module' : 'Crawlo PG'} : ${crawloPG}`}
                                variant="outlined"
                                color="secondary"
                                sx={{ width: '100%' }}
                            />
                        )}
                        <Chip
                            label={'Category : ' + (validationGoogleCategory || 'Not Found')}
                            variant="outlined"
                            color="success"
                            sx={{ width: '100%' }}
                        />
                    </Stack>
                )}
                {oldPrice && newPrice ? (
                    <PriceChange
                        oldPrice={oldPrice}
                        newPrice={newPrice}
                        dateChange={dateChange}
                        websiteId={productWebsites?.length ? productWebsites[0].websiteId : undefined}
                    />
                ) : null}
                {showGFK && gfkPosition && (
                    <Box sx={{ mb: 2 }}>
                        <GFK
                            handleClickGfkVariants={handleClickGfkVariants}
                            gfkPosition={gfkPosition}
                            gfkPositionInCategory={gfkPositionInCategory}
                            googleCategory={googleCategory}
                            isWinning={gfkIsWinning}
                            gfkSalesValue={gfkSalesValue}
                            gfkUnit={gfkUnit}
                            gfkMonth={gfkMonth}
                            gfkMethod={gfkMethod}
                            gfkVariants={gfkVariants}
                            gfkVariantsLoad={gfkVariantsLoad}
                        />
                    </Box>
                )}
                {amazonSales ? (
                    <Box sx={{ mb: 2 }}>
                        <AmazonSales
                            amazonPosition={amazonRank}
                            amazonPositionInCategory={amazonGoogleCategoriesRank}
                            googleCategory={googleCategories}
                            amazonSalesValue={amazonSales}
                            amazonUnit={amazonSalesCount}
                            amazonMonth={amazonMonthAbrv}
                            isModal={isModal}
                        />
                    </Box>
                ) : null}
                {showAmazonBestSeller && amazonPosition && lastProductUpdateDate ? (
                    <Box sx={{ mb: 2 }}>
                        <AmazonBestSeller position={amazonPosition} lastUpdateDate={lastProductUpdateDate} />
                    </Box>
                ) : (
                    ''
                )}
                <Stack spacing={0.7} sx={{ mb: 2 }}>
                    <RatingProduct singleRating={singleRating} value={ratingValue || 0} count={ratingCount || 0} />
                    <Stack direction={'row'} spacing={2}>
                        {position ? (
                            <Typography fontSize={12} variant="body2" color="text.secondary">
                                <strong>Avg. position : </strong>
                                {position}
                            </Typography>
                        ) : null}
                        {trackingDate ? (
                            <Typography
                                className={`trackingDate_${ean || productRef}`}
                                fontSize={12}
                                variant="body2"
                                color="text.secondary"
                            >
                                <strong>Tracking since : </strong>
                                {`${moment(trackingDate).format('YYYY-MM-DD')}`}
                            </Typography>
                        ) : null}
                    </Stack>

                    <Stack direction={'row'} spacing={2}>
                        {ean && (
                            <Typography className={`ean_${ean || productRef}`} fontSize={12} variant="body2" color="text.secondary">
                                <strong>GTIN : </strong>
                                {ean}
                            </Typography>
                        )}
                    </Stack>
                </Stack>
                <Box className={`websites_${ean || productRef}`}>
                    <Websites websites={productWebsites || []} />
                </Box>
                {/* <!-- validation insights --> */}
                {validationInsights && (
                    <Box>
                        {validationInsights.isWrong && <WrongValidationInsight validationInsights={validationInsights} />}
                        {validationInsights.isMissing && <MissingValidationInsight validationInsights={validationInsights} />}
                        {validationInsights.isCorrect && <CorrectValidationInsight validationInsights={validationInsights} />}
                    </Box>
                )}
            </CardContent>

            <Box sx={{ flexGrow: 1 }} />

            {/* reviewed status */}
            {showReviewStatus && reviewed && reviewed.status && (
                <Grid justifyContent="center" alignItems="center" container spacing={1} sx={{ p: 2 }}>
                    <Grid item xs={4}>
                        <Chip
                            label={reviewed?.name}
                            avatar={<FaCheckCircle color={reviewed?.textColor} />}
                            disabled={reviewed?.disabled}
                            size="small"
                            sx={{ backgroundColor: reviewed?.color, color: reviewed?.textColor }}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <FormControl fullWidth size="small">
                            <InputLabel id="demo-simple-select-label">Listing</InputLabel>
                            <Select
                                defaultValue=""
                                value={reviewed.status}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Listing"
                            >
                                {reviewedStatusOptions.map((rev: ReviewedStatusType, index: number) => {
                                    return (
                                        <MenuItem
                                            value={rev.status}
                                            onClick={() => changeProductReviewedStatus(reviewedId, ean || productRef, rev.status, rev)}
                                            key={index}
                                        >
                                            {rev.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            )}
            <PriceHistoryModal
                open={openPrice}
                showListingStatus={showReviewStatus}
                reviewedStatus={reviewed}
                reviewed={reviewed}
                productRef={ean || productRef}
                handleClose={() => {
                    setOpenPrice(false);
                }}
                trackingSince={trackingDate}
            />

            <PriceAlertTrackingModal
                open={openAlertPrice}
                productRef={ean || productRef}
                websites={
                    productWebsites && productWebsites.length
                        ? productWebsites.map((el) => {
                              return { ...el, id: el.websiteId };
                          })
                        : []
                }
                handleCreated={(saved, id) => {
                    setAlertStatus(saved);
                    setIdAlert(id);
                }}
                handleClose={() => {
                    setOpenAlertPrice(false);
                }}
            />

            {gfkId && (
                <GfkVariantsModal
                    open={openGfkVariant}
                    gfkId={gfkId}
                    gfkSku={gfkSku}
                    gfkMonthAbrv={gfkMonthAbrv}
                    handleClose={() => {
                        setOpenGfkVariant(false);
                    }}
                />
            )}
        </Card>
    );
}

export default ProductCard;
