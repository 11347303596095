import { Navigate } from 'react-router-dom';

import Loadable from 'components/common/UI/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import lazyWithRetry from 'hooks/lazyWithRetry';

const AuthLogin = Loadable(lazyWithRetry(() => import('views/auth/Login')));
const OAuthLogin = Loadable(lazyWithRetry(() => import('views/auth/OAuthLogin')));
const AuthForgotPassword = Loadable(lazyWithRetry(() => import('views/auth/ForgotPassword')));
const NewPassword = Loadable(lazyWithRetry(() => import('views/auth/NewPassword')));
const SSOAuthPage = Loadable(lazyWithRetry(() => import('views/auth/SSOAuthPage')));

const AuthRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/',
            element: <AuthLogin />
        },
        {
            path: '/login',
            element: <Navigate replace to="/" />
        },
        {
            path: '/login/sso',
            element: <SSOAuthPage />
        },
        {
            path: '/login/oauthredirect',
            element: <OAuthLogin />
        },
        {
            path: '/forgot',
            element: <AuthForgotPassword />
        },
        {
            path: '/new-password',
            element: <NewPassword />
        },
        {
            path: '/assistant/chat',
            element: <Navigate to="/login" />
        },
        {
            path: '/assistant/dashboard',
            element: <Navigate to="/login" />
        },
        {
            path: '/assortment',
            element: <Navigate to="/login" />
        },
        {
            path: '/assortment/listing',
            element: <Navigate to="/login" />
        },
        {
            path: '/assortment/googlelisting',
            element: <Navigate to="/login" />
        },
        {
            path: '/assortment/googlelistingcustom',
            element: <Navigate to="/login" />
        },
        {
            path: '/assortment/scoring',
            element: <Navigate to="/login" />
        },
        {
            path: '/assortment/scoring-overview',
            element: <Navigate to="/login" />
        },
        {
            path: '/catalog',
            element: <Navigate to="/login" />
        },
        {
            path: '/catalog/availability',
            element: <Navigate to="/login" />
        },
        {
            path: '/catalog/shipping',
            element: <Navigate to="/login" />
        },
        {
            path: '/assortment/gfkcoverage',
            element: <Navigate to="/login" />
        },
        {
            path: '/assortment/scoring',
            element: <Navigate to="/login" />
        },
        {
            path: '/brands/overview',
            element: <Navigate to="/login" />
        },
        {
            path: '/brands/pricing',
            element: <Navigate to="/login" />
        },
        {
            path: '/brands/visibility',
            element: <Navigate to="/login" />
        },
        {
            path: '/assortment/relevant',
            element: <Navigate to="/login" />
        },
        {
            path: '/assortment/gfkproducts',
            element: <Navigate to="/login" />
        },
        {
            path: '/assortment/opportunities',
            element: <Navigate to="/login" />
        },
        {
            path: '/assortment/gfkopportunities',
            element: <Navigate to="/login" />
        },
        {
            path: '/assortment/exclusive',
            element: <Navigate to="/login" />
        },
        {
            path: '/assortment/newdescoveries',
            element: <Navigate to="/login" />
        },
        {
            path: '/assortment/newlydiscovered',
            element: <Navigate to="/login" />
        },
        {
            path: '/amazonanalytics/amazonbestseller',
            element: <Navigate to="/login" />
        },
        {
            path: '/assortment/bestrated',
            element: <Navigate to="/login" />
        },
        {
            path: '/campaign/manager',
            element: <Navigate to="/login" />
        },
        {
            path: '/pricing/changes',
            element: <Navigate to="/login" />
        },
        {
            path: '/pricing/competitors',
            element: <Navigate to="/login" />
        },
        {
            path: '/pricing/multivariate',
            element: <Navigate to="/login" />
        },
        {
            path: '/exportationlist',
            element: <Navigate to="/login" />
        },
        {
            path: '/settings/stats',
            element: <Navigate to="/login" />
        },
        {
            path: '/users/management',
            element: <Navigate to="/login" />
        },
        {
            path: '/assistant/share/:id',
            element: <Navigate to="/login" />
        },
        {
            path: '/assistant/dashboard/:id?',
            element: <Navigate to="/login" />
        },
        {
            path: '/assistant/chat/:id?',
            element: <Navigate to="/login" />
        },
        {
            path: '/validation',
            element: <Navigate to="/login" />
        },
        {
            path: '/mms-accuracy',
            element: <Navigate to="/login" />
        },
        {
            path: '/settings/profile',
            element: <Navigate to="/login" />
        },
        {
            path: '/settings/billing',
            element: <Navigate to="/login" />
        }
    ]
};

export default AuthRoutes;
