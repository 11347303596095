import React, { useEffect, useState, Fragment } from 'react';
import { Card, CardContent, Autocomplete, Grid, Box, CircularProgress, TextField } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateExactWebsiteQuery, updateWebsiteFilterField, updateWebsitesValues } from 'store/slices/filter/filterSlice';
import { useQuery } from '@apollo/client';
import { WEBSITE_CATEGORY } from 'services/filterService/queries';
import WebsiteImage from 'components/product/Websites/WebsiteImage';

function WebsitesFilter() {
    const dispatch = useAppDispatch();

    const { selectedRegion, userWebsites } = useAppSelector((state) => state.auth);
    const { queryExactWebsite } = useAppSelector((state) => state.filter);
    const { websiteFilterField, websitesValues } = useAppSelector((state) => state.filter);

    const websites = userWebsites.filter((item: any) => item.region === selectedRegion)[0].websites;

    const [websiteCategoryData, setWebsiteCategoryData] = useState<any[]>([]);
    const [currentWebsiteCategory, setCurrentWebsiteCategory] = useState<any>([]);

    const { loading, data } = useQuery(WEBSITE_CATEGORY, {
        variables: {
            region: selectedRegion,
            website: currentWebsiteCategory,
            q: queryExactWebsite
        },
        skip: !currentWebsiteCategory,
        notifyOnNetworkStatusChange: true
    });

    const handleChangeExactWebsiteOption = (event: React.SyntheticEvent, option: any) => {
        dispatch(updateWebsiteFilterField(option));

        setCurrentWebsiteCategory({
            id: option.id,
            name: option.name
        });
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch(updateExactWebsiteQuery(event.target.value));
    };

    const handleChange = (event: React.SyntheticEvent, option: any) => {
        dispatch(updateWebsitesValues(option));
    };

    useEffect(() => {
        if (data) setWebsiteCategoryData(data.websiteCategories.list);
    }, [loading]);

    return (
        <Fragment>
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Grid container spacing={2} justifyContent={'center'} alignItems={'center'}>
                            <Grid item xs={12} md={2}>
                                <Autocomplete
                                    size="small"
                                    clearOnEscape
                                    fullWidth
                                    disableClearable
                                    id="selected-websites"
                                    value={websiteFilterField || null}
                                    options={websites}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    getOptionLabel={(option: any) => (option?.alias ? option?.alias : '')}
                                    renderOption={(props, option: any) => (
                                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 }, my: 0.3 }} {...props}>
                                            <WebsiteImage
                                                name={option?.name}
                                                websiteId={option?.id}
                                                websiteUrl={option.url}
                                                height={20}
                                                width={20}
                                            />
                                            {option?.name}
                                        </Box>
                                    )}
                                    renderInput={(params) => <TextField {...params} placeholder="Websites" variant="outlined" />}
                                    onChange={(event: React.SyntheticEvent, option: any | null) =>
                                        handleChangeExactWebsiteOption(event, option)
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} md={10}>
                                <Autocomplete
                                    size="small"
                                    clearOnEscape
                                    disablePortal
                                    id="tags-standard"
                                    fullWidth
                                    value={websitesValues || null}
                                    disableClearable
                                    onChange={(event: React.SyntheticEvent, option: any) => handleChange(event, option)}
                                    getOptionLabel={(option) => (option?.breadcrumbs ? option.breadcrumbs : '')}
                                    options={websiteCategoryData}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    loading={loading}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            onChange={handleInputChange}
                                            placeholder={'Website Category'}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <>
                                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </>
                                                )
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Fragment>
    );
}

export default WebsitesFilter;
