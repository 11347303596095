import { MouseEvent, useState } from 'react';
import { Dialog, DialogContent, IconButton, useMediaQuery, CardHeader, Stack, Popover } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import DateRangeIcon from '@mui/icons-material/DateRange';

import { DateRangePicker, DateRange } from 'mui-daterange-picker';
import moment from 'moment';

import PriceHistoryAnalysis from 'components/analysis/PriceHistoryAnalysis';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { AppDispatch, RootState } from 'store';
import { updateDateRangePicker } from 'store/slices/filter/filterSlice';

interface PriceHistoryModalProps {
    productRef?: string;
    trackingSince?: string;
    open: boolean;
    reviewed?: any;
    reviewedStatus?: any;
    showListingStatus?: boolean;
    handleClose: () => void;
}

function PriceHistoryModal({
    open,
    handleClose,
    trackingSince,
    reviewed,
    reviewedStatus,
    showListingStatus,
    productRef
}: PriceHistoryModalProps) {
    const dispatch: AppDispatch = useAppDispatch();
    const { dateRangePicker } = useAppSelector((state: RootState) => state.filter);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleCloseDateRangePicker = () => {
        setAnchorEl(null);
    };

    const togglePicker = () => {
        setAnchorEl(null);
    };

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleChangeDateRangePicker = (item: DateRange) => {
        const startDate = moment(item.startDate, 'MM/DD/YYYY');
        const endDate = moment(item.endDate, 'MM/DD/YYYY');

        const isSameDay = startDate.isSame(endDate, 'day');

        dispatch(
            updateDateRangePicker({
                startDate: moment(item.startDate, 'MM-DD-YYYY'),
                endDate: moment(item.endDate, 'MM-DD-YYYY')
            })
        );

        if (!isSameDay) {
            handleCloseDateRangePicker();
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullScreen={fullScreen}
            fullWidth
            maxWidth={'xl'}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{ sx: { borderRadius: '20px' } }}
        >
            <CardHeader
                title="Product Details"
                action={
                    <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
                        <IconButton aria-label="reload" size="small" onClick={handleClick} sx={{ mr: 4 }}>
                            <DateRangeIcon />
                        </IconButton>
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500]
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                }
            />
            <DialogContent dividers={true}>
                <PriceHistoryAnalysis
                    reviewed={reviewed}
                    reviewedStatus={reviewedStatus}
                    showListingStatus={showListingStatus}
                    productRef={productRef}
                    sx={{ p: 3, pt: '5px' }}
                    trackingSince={trackingSince}
                />
            </DialogContent>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleCloseDateRangePicker}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
            >
                <DateRangePicker
                    open={Boolean(anchorEl)}
                    initialDateRange={{
                        startDate: new Date(dateRangePicker.startDate),
                        endDate: new Date(dateRangePicker.endDate)
                    }}
                    toggle={togglePicker}
                    onChange={handleChangeDateRangePicker}
                />
            </Popover>
        </Dialog>
    );
}

export default PriceHistoryModal;
