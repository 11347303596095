import { Stack, Typography, Box, useTheme } from '@mui/material';
import { FiArrowDown } from 'react-icons/fi';

import CurrencyFormatter from 'components/common/CurrencyFormatter';

interface PriceChangeProps {
    oldPrice: number;
    newPrice: number;
    websiteId?: string;
    dateChange?: string;
}

function PriceChange({ oldPrice, newPrice, websiteId, dateChange }: PriceChangeProps) {
    const theme = useTheme();

    return (
        <Stack direction={'column'} spacing={0.5} sx={{ p: 1, mb: 2, borderRadius: 2, border: `1px dashed ${theme.palette.grey[600]}` }}>
            <Stack direction={'row'} justifyContent={'space-between'}>
                <Typography
                    fontSize={12}
                    variant="body2"
                    color={oldPrice >= newPrice ? theme.palette.success.main : theme.palette.error.main}
                >
                    <CurrencyFormatter amount={newPrice - oldPrice} websiteId={websiteId} />
                </Typography>
                <Box
                    sx={{
                        p: 0.5,
                        borderRadius: 1,
                        border: `1px solid ${oldPrice >= newPrice ? theme.palette.success.dark : theme.palette.error.dark}}`
                    }}
                >
                    <Typography
                        fontSize={12}
                        variant="body2"
                        color={oldPrice >= newPrice ? theme.palette.success.dark : theme.palette.error.dark}
                    >
                        {(((newPrice - oldPrice) / oldPrice) * 100).toFixed(2)}%
                    </Typography>
                </Box>
            </Stack>
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
                <Typography fontWeight={'bold'} fontSize={12} variant="body2" color="text.secondary">
                    Date changes :
                </Typography>
                <Typography fontSize={12} variant="body2" color="text.secondary">
                    {dateChange}
                </Typography>
            </Stack>
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
                <Typography fontWeight={'bold'} fontSize={12} variant="body2" color="text.secondary">
                    Old price :
                </Typography>
                <Typography fontSize={12} variant="body2" color="text.secondary">
                    <CurrencyFormatter amount={oldPrice} websiteId={websiteId} />
                </Typography>
            </Stack>
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
                <Typography fontWeight={'bold'} fontSize={12} variant="body2" color="text.secondary">
                    New price :
                </Typography>
                <FiArrowDown
                    size={12}
                    color={oldPrice >= newPrice ? theme.palette.success.dark : theme.palette.error.dark}
                    style={{ transform: oldPrice >= newPrice ? 'none' : 'rotate(180deg)' }}
                />
                <Typography fontSize={12} variant="body2" color="text.secondary">
                    <CurrencyFormatter amount={newPrice} websiteId={websiteId} />
                </Typography>
            </Stack>
        </Stack>
    );
}

export default PriceChange;
