import { useQuery } from '@apollo/client';
import {
    Grid,
    TableBody,
    TableContainer,
    Table,
    TableHead,
    Typography,
    useTheme,
    Stack,
    Link,
    Box,
    SxProps,
    Autocomplete,
    TextField
} from '@mui/material';

import EChart from 'components/common/UI/EChart';
import AnalysisLoading from 'components/common/skeletons/AnalysisLoading';
import { configureChartOption } from 'utils/functions';
import { StyledTableCell, StyledTableRow } from 'components/common/UI/tables';
import { positionsHistoryOption } from 'constants/chart-config/analysis';
import moment from 'moment';
import { useEffect, useState, SyntheticEvent } from 'react';
import { FiExternalLink } from 'react-icons/fi';
import { useAppSelector } from 'store/hooks';
import WebsiteImage from 'components/product/Websites/WebsiteImage';
import { PRICE_HISTORY_ANALYSIS } from 'services/analysis/queries';

interface PositionAnalysisProps {
    productRef?: string;
    reload?: boolean;
    sx?: SxProps;
}

function PositionAnalysis({ productRef, reload, sx }: PositionAnalysisProps) {
    const theme = useTheme();

    const { selectedRegion, userWebsites, selectedWebsites } = useAppSelector((state) => state.auth);
    const { dateRangePicker } = useAppSelector((state) => state.filter);

    const [products, setProducts] = useState<Product[]>([]);
    const [allproducts, setAllProducts] = useState<Product[]>([]);
    const [defaultProduct, setDefaultProduct] = useState<Product>();
    const [options, setOptions] = useState(positionsHistoryOption);
    const [allChartOptions, setAllChartOptions] = useState(positionsHistoryOption);
    const [productNotFound, setProductNotFound] = useState<boolean>(false);
    const [selectedWebsite, setSelectedWebsite] = useState<any>(null);
    const [productWebsites, setProductWebsites] = useState<any[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<any>(null);
    const [websiteCategories, setWebsiteCategories] = useState<any[]>([]);
    const [allWebsitesCategories, setAllWebsiteCategories] = useState<any[]>([]);
    const [allWebsitesCategoriesData, setAllWebsiteCategoriesData] = useState<any[]>([]);

    const websites = userWebsites.filter((item: any) => item.region === selectedRegion)[0]?.websites;
    const months = ['es', 'pt'].includes(selectedRegion.toLowerCase()) ? { count: 1, type: 'months' } : { count: 8, type: 'weeks' };

    const fromDateRange = moment(dateRangePicker.endDate)
        .subtract(months.count as any, months.type as any)
        .format('YYYY-MM-DD');

    const { loading, error, data, refetch } = useQuery(PRICE_HISTORY_ANALYSIS, {
        variables: {
            region: selectedRegion,
            ref: productRef,
            includeCategories: true,
            websites: selectedWebsites.length == 0 ? websites : selectedWebsites,
            fromDate: fromDateRange,
            toDate: dateRangePicker.endDate
        },
        notifyOnNetworkStatusChange: true
    });

    const handleSelectedWebsiteChange = (event: SyntheticEvent<Element, Event>, newValue: any) => {
        setSelectedWebsite(newValue && newValue.id ? newValue : undefined);
        const listofprods = allproducts.filter((ll) => ll?.website?.id == newValue.id);
        setProducts(listofprods);

        const currentChartOptions = { ...allChartOptions };
        currentChartOptions.legend.data = [newValue?.alias as never].map((lg: string) => `${lg} price` as never);
        currentChartOptions.series = allChartOptions.series
            .filter((ss: any) => ss.name == newValue.alias)
            .map((serie: any) => {
                return { ...serie, name: `${serie.name} price` } as never;
            });

        // defaultProduct
        const tempdefaultProduct: any = { ...listofprods[0] };
        tempdefaultProduct.name = listofprods[0].name;
        tempdefaultProduct.ean = defaultProduct?.ean;
        tempdefaultProduct.images = defaultProduct?.images;
        setDefaultProduct(tempdefaultProduct);

        // categories
        const currentWebsiteCategories = allWebsitesCategories.filter((ll: any) => ll.website.id == newValue.id);
        setWebsiteCategories(currentWebsiteCategories);
        setSelectedCategory('');
        const currentWebsiteCategoriesDt: any = allWebsitesCategoriesData.filter((ll: any) => ll.website.id == newValue.id);
        if (currentWebsiteCategoriesDt.length) {
            const catsdata: any[] = [];
            currentWebsiteCategoriesDt[0].data.map((el: any) => {
                el.categories.map((cc: any) => {
                    catsdata.push({ ...cc, date: el.date });
                });
            });
            const groups = catsdata.reduce((groups, item) => {
                const group = groups[item.name] || [];
                group.push(item);
                groups[item.name] = group;
                return groups;
            }, {});
            Object.entries(groups).map(([key, value]: any) => {
                const catObject = {
                    connectNulls: true,
                    data: [],
                    name: key,
                    symbolSize: 5,
                    yAxisIndex: 1,
                    type: 'line'
                };
                currentChartOptions.legend.data.push(key as never);

                currentChartOptions.xAxis.data.map((el: any) => {
                    const checkDate = value.filter((dt: any) => dt.date == el);
                    if (checkDate.length) {
                        const vv = checkDate[0];
                        const position = vv.position || null;
                        catObject.data.push(position as never);
                    } else {
                        catObject.data.push(null as never);
                    }
                });

                currentChartOptions.series.push(catObject as never);
            });
        }

        // set options tio chart
        setOptions({ ...currentChartOptions });
    };

    const handleSelectedCategoryChange = (event: SyntheticEvent<Element, Event>, newValue: any) => {
        setSelectedCategory(newValue && newValue.id ? newValue : undefined);
        const currentWebsiteCategoriesDt: any = allWebsitesCategoriesData.filter((ll: any) => ll.website.id == selectedWebsite.id);
        if (currentWebsiteCategoriesDt.length) {
            const currentChartOptions = { ...allChartOptions };
            currentChartOptions.legend.data = [`${selectedWebsite?.alias} price` as never, newValue.name as never];
            currentChartOptions.series = allChartOptions.series
                .filter((ss: any) => ss.name == selectedWebsite.alias)
                .map((serie: any) => {
                    return { ...serie, name: `${serie.name} price` } as never;
                });
            const catObject = {
                connectNulls: true,
                data: [],
                name: newValue.name,
                symbolSize: 5,
                type: 'line'
            };
            currentChartOptions.xAxis.data.map((el: any) => {
                const checkDate = currentWebsiteCategoriesDt[0].data.filter((dt: any) => dt.date == el);
                if (checkDate.length) {
                    const getTheCategory: any = checkDate[0].categories.filter((cc: any) => cc.id == newValue.id);
                    if (getTheCategory.length) {
                        const position = getTheCategory[0].position || null;
                        catObject.data.push(position as never);
                    }
                }
            });
            currentChartOptions.series.push(catObject as never);
            setOptions({ ...currentChartOptions });
        }
    };

    useEffect(() => {
        if (!loading) refetch();
    }, [reload]);

    useEffect(() => {
        if (data) {
            if (data?.priceHistory?.products?.length == 0) {
                setProductNotFound(true);
            } else {
                setProductNotFound(false);
                const listofprods: Product[] = [];
                const allprods: Product[] = [];
                const prdWebsites: any[] = [];
                let defaultSelectedWebsite: any = null;
                data.priceHistory.products.map((el: any) => {
                    el.categories.map((cat: any) => {
                        allprods.push({ ...el, cat });
                        if (!listofprods.length) {
                            setSelectedWebsite(el.website && el.website.id ? el.website : undefined);
                            defaultSelectedWebsite = el.website;
                            listofprods.push({ ...el, cat });
                        }
                    });
                    prdWebsites.push(el.website);
                });
                const allCategories: any[] = [];
                setAllWebsiteCategoriesData(data.priceHistory.categoriesData.data);
                data.priceHistory.categoriesData.data.map((el: any) => {
                    el.data.map((cat: any) => {
                        cat.categories.map((cc: any) => {
                            const check = allCategories.filter((ll: any) => ll.id == cc.id);
                            if (!check.length) {
                                allCategories.push({ ...cc, website: el.website });
                            }
                        });
                    });
                });
                // websites category

                setAllWebsiteCategories(allCategories);
                const currentWebsiteCategories =
                    allCategories.length && defaultSelectedWebsite
                        ? allCategories.filter((ll) => ll.website.id == defaultSelectedWebsite?.id)
                        : [];
                setWebsiteCategories(currentWebsiteCategories);

                // current website selection
                const currentListofprods = allprods.filter((ll) => ll?.website?.id == defaultSelectedWebsite?.id);
                setProducts(currentListofprods);
                setProductWebsites(prdWebsites);

                // all products list
                setAllProducts(allprods);
                setDefaultProduct(data.priceHistory.defaultProduct);

                const chartOptions = configureChartOption(positionsHistoryOption, data, 'priceHistory', 'xAxis');
                setAllChartOptions({ ...chartOptions });
                const currentChartOptions = { ...chartOptions };
                currentChartOptions.legend.data = currentChartOptions.legend.data
                    .filter((ss: string) => ss == defaultSelectedWebsite?.alias)
                    .map((lg: string) => `${lg} price`);
                currentChartOptions.series = currentChartOptions.series
                    .filter((ss: any) => ss.name == defaultSelectedWebsite?.alias)
                    .map((serie: any) => {
                        return { ...serie, name: `${serie.name} price` };
                    });

                // categories to chart
                const currentWebsiteCategoriesDt: any = data.priceHistory.categoriesData.data.filter(
                    (ll: any) => ll.website.id == defaultSelectedWebsite?.id
                );
                if (currentWebsiteCategoriesDt.length) {
                    const catsdata: any[] = [];
                    currentWebsiteCategoriesDt[0].data.map((el: any) => {
                        el.categories.map((cc: any) => {
                            catsdata.push({ ...cc, date: el.date });
                        });
                    });
                    const groups = catsdata.reduce((groups, item) => {
                        const group = groups[item.name] || [];
                        group.push(item);
                        groups[item.name] = group;
                        return groups;
                    }, {});
                    Object.entries(groups).map(([key, value]: any) => {
                        const catObject = {
                            connectNulls: true,
                            data: [],
                            name: key,
                            symbolSize: 5,
                            yAxisIndex: 1,
                            type: 'line'
                        };
                        currentChartOptions.legend.data.push(key as never);

                        currentChartOptions.xAxis.data.map((el: any) => {
                            const checkDate = value.filter((dt: any) => dt.date == el);
                            if (checkDate.length) {
                                const vv = checkDate[0];
                                const position = vv.position || null;
                                catObject.data.push(position as never);
                            } else {
                                catObject.data.push(null as never);
                            }
                        });

                        currentChartOptions.series.push(catObject as never);
                    });
                }
                setOptions(currentChartOptions);
            }
        }
    }, [loading]);

    if (loading) return <AnalysisLoading />;

    if (error) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 300 }}>
                <Typography>An error occurred. Please try again later.</Typography>
            </Box>
        );
    }

    if (productNotFound) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 300 }}>
                <Typography>Product Not Found.</Typography>
            </Box>
        );
    }

    return (
        <Grid container spacing={1} sx={sx} justifyContent={'center'} alignItems={'center'}>
            <Grid item xs={12}>
                <Stack direction="row" sx={{ mb: '5px' }} alignItems={'center'} spacing={1}>
                    <Box>
                        <Autocomplete
                            size="small"
                            id="selected-position-websites"
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            onChange={(event: SyntheticEvent<Element, Event>, newValue: any) =>
                                handleSelectedWebsiteChange(event, newValue)
                            }
                            value={selectedWebsite || null}
                            renderTags={() => null}
                            options={productWebsites}
                            disableClearable
                            color="secondary"
                            getOptionLabel={(option: any) => (option?.name ? option?.name : '')}
                            sx={{ width: 200 }}
                            renderOption={(props, option: any) => (
                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 }, my: 0.3 }} {...props}>
                                    <WebsiteImage
                                        name={option?.name}
                                        websiteUrl={option.url}
                                        websiteId={option?.id}
                                        height={20}
                                        width={20}
                                    />
                                    {option?.name}
                                </Box>
                            )}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </Box>

                    <Box>
                        <Autocomplete
                            size="small"
                            id="selected-position-category"
                            isOptionEqualToValue={(option, value) => option.id == value.id}
                            onChange={(event: SyntheticEvent<Element, Event>, newValue: any) =>
                                handleSelectedCategoryChange(event, newValue)
                            }
                            value={selectedCategory || null}
                            renderTags={() => null}
                            options={websiteCategories}
                            disableClearable
                            color="secondary"
                            getOptionLabel={(option: any) => (option?.name ? option?.name : '')}
                            sx={{ width: 200 }}
                            renderOption={(props, option: any) => (
                                <Box component="li" key={option?.id} sx={{ '& > img': { mr: 2, flexShrink: 0 }, my: 0.3 }} {...props}>
                                    {option?.name}
                                </Box>
                            )}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </Box>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <EChart height="300px" id="price-history-chart" option={options} />
            </Grid>
            <Grid item xs={12} sx={{ mt: '2px' }}>
                <Grid container spacing={2} justifyContent={'center'} alignItems={'flex-start'}>
                    <Grid item xs={12} md={12}>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <StyledTableRow>
                                        <StyledTableCell>Website</StyledTableCell>
                                        <StyledTableCell>Category</StyledTableCell>
                                        <StyledTableCell>URL</StyledTableCell>
                                        <StyledTableCell>Position</StyledTableCell>
                                        <StyledTableCell>Date</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {products.map((item: Product, index) => (
                                        <StyledTableRow key={index.toString()}>
                                            <StyledTableCell component="th" scope="row" width={150}>
                                                <Stack direction="row" alignItems={'center'} spacing={0.7}>
                                                    <WebsiteImage
                                                        name={item?.website?.name || ''}
                                                        websiteUrl={item?.website?.url || ''}
                                                        websiteId={item?.website?.id || ''}
                                                        height={20}
                                                        width={20}
                                                    />

                                                    <Link
                                                        underline="hover"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        href={item?.website?.url + item.url}
                                                        variant="h4"
                                                        color={theme.palette.secondary.main}
                                                        fontSize={13}
                                                    >
                                                        {item?.website?.name}
                                                    </Link>
                                                    <FiExternalLink color={theme.palette.secondary.main} />
                                                </Stack>
                                            </StyledTableCell>
                                            <StyledTableCell component="th" scope="row" width={150}>
                                                <Typography fontSize={13}>{item?.cat?.category?.name}</Typography>
                                            </StyledTableCell>

                                            <StyledTableCell component="th" scope="row" width={150}>
                                                <Link
                                                    underline="hover"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href={item?.website?.url + item?.cat?.category?.url}
                                                    variant="h4"
                                                    color={theme.palette.secondary.main}
                                                    fontSize={13}
                                                >
                                                    {item?.website?.url + item?.cat?.category?.url}
                                                </Link>
                                            </StyledTableCell>

                                            <StyledTableCell component="th" scope="row" width={150}>
                                                <Typography fontSize={13}>
                                                    {item.cat.position} {item.cat.order == 'bestsellers' ? `(BestSeller)` : ''}
                                                </Typography>
                                            </StyledTableCell>

                                            <StyledTableCell component="th" scope="row" width={150}>
                                                <Typography fontSize={13}>{item.lastUpdate}</Typography>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default PositionAnalysis;
