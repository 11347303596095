import { useEffect, useState } from 'react';
import { Box, MenuItem, Stack, IconButton, Popover } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import ReactCountryFlag from 'react-country-flag';

import { updateIsBrand, updateIsMMS, updateIsVisibility, updateRegion, updateSelectedWebsites } from 'store/slices/auth/authSlice';
import { getCountryCode } from 'utils/functions';
import Scrollbar from 'components/common/UI/ScrollBar';
import { initializeInputList, resetFilter } from 'store/slices/filter/filterSlice';
import getRegionDisplayName from 'utils/getRegionDisplayName';
import { resetAccuracyFilter } from 'store/slices/filter/customFilterSlice';
import { RootState } from 'store';

interface WEBSITE_TYPE {
    value: string;
    label: string;
    icon: string;
}

export default function CountryPopover() {
    const dispatch = useDispatch();
    const { userRegions, userWebsites, selectedRegion, accessAccountUser, isBrand } = useSelector((state: RootState) => state.auth);

    const [open, setOpen] = useState<HTMLButtonElement | null>(null);
    const [websites, setWebsites] = useState<WEBSITE_TYPE[]>([]);
    const [reloadFlag, setReloadFlag] = useState(false);

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    const handleClick = (region: string) => {
        if (region && region !== selectedRegion) {
            dispatch(
                resetFilter({
                    region,
                    isBrandValue: isBrand
                })
            );
            dispatch(resetAccuracyFilter());
            dispatch(updateRegion(region));

            const websites = userWebsites.filter((item: any) => item.region === region)[0].websites;

            const currentAccount: any = accessAccountUser.find((item: any) => item.account.config.region == region);
            let isMMSValue;

            if (currentAccount?.account?.config?.mms) {
                isMMSValue = true;
                updateIsMMS(true);
            } else {
                isMMSValue = false;
                updateIsMMS(false);
            }
            if (currentAccount?.account?.config?.isBrandAcount) {
                updateIsBrand(true);
            } else {
                updateIsBrand(false);
            }
            if (currentAccount?.account?.config?.isVisibility) {
                updateIsVisibility(true);
            } else {
                updateIsVisibility(false);
            }

            const inputListData =
                isMMSValue === true
                    ? [{ id: '1', fieldSearch: 'Module', checkOption: 'Is' }]
                    : [{ id: '1', fieldSearch: 'Keywords', checkOption: 'Containing', includeExclude: 'Include', searchkey: [] }];

            dispatch(initializeInputList(inputListData));

            const limitDefaultSelectedWebsites = currentAccount?.account?.config?.limitDefaultSelected;
            dispatch(updateSelectedWebsites(limitDefaultSelectedWebsites ? websites.slice(0, limitDefaultSelectedWebsites) : websites));

            reloadTabs();
        }
        handleClose();
    };

    const reloadTabs = () => {
        const broadcastChannel: BroadcastChannel = new BroadcastChannel('switch-region');
        broadcastChannel.postMessage({ action: 'reload' });
    };

    useEffect(() => {
        const broadcastChannel: BroadcastChannel = new BroadcastChannel('switch-region');
        broadcastChannel.onmessage = (event: MessageEvent) => {
            if (event.data.action === 'reload') {
                setReloadFlag(true);
            }
        };
    }, []);

    useEffect(() => {
        if (reloadFlag) {
            const storedIsBrand = localStorage.getItem('isBrand');

            const storedIsVisibility = localStorage.getItem('isVisibility');

            const checkIsBrand =
                storedIsBrand && String(storedIsBrand) == 'true' && storedIsVisibility && String(storedIsVisibility) == 'true'
                    ? true
                    : false;

            window.location.href = checkIsBrand ? '/brands/overview' : '/assortment/relevant';
        }
    }, [reloadFlag]);

    useEffect(() => {
        const newWebsites = userRegions.map((lan: string) => {
            return {
                value: lan,
                label: getCountryCode(lan),
                icon: `/assets/icons/ic_flag_${lan}.svg`
            };
        });
        setWebsites(newWebsites);
    }, []);

    return (
        <>
            <IconButton
                onClick={handleOpen}
                sx={{
                    padding: 0,
                    width: 44,
                    height: 44
                }}
            >
                <ReactCountryFlag countryCode={getCountryCode(selectedRegion)} svg />
            </IconButton>

            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        mt: 1.5,
                        ml: 0.75,
                        width: 180,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75
                        }
                    }
                }}
            >
                <Scrollbar style={{ maxHeight: 300 }}>
                    <Stack spacing={0.75}>
                        {websites.map((option) => (
                            <MenuItem
                                key={option.value}
                                selected={option.value === selectedRegion}
                                onClick={() => handleClick(option.value)}
                            >
                                <Box sx={{ width: 28, mr: 2 }}>
                                    <ReactCountryFlag
                                        countryCode={option.label}
                                        svg
                                        style={{
                                            width: '22px',
                                            height: 'auto'
                                        }}
                                    />
                                </Box>

                                {getRegionDisplayName(option.label.toUpperCase())}
                            </MenuItem>
                        ))}
                    </Stack>
                </Scrollbar>
            </Popover>
        </>
    );
}
