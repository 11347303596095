import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { CUSTOM_COLLECTION } from 'services/filterService/queries';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { submitFilter, updateCustomCollectionQuery, updateInputList } from 'store/slices/filter/filterSlice';

interface CustomCollectionProps {
    id: string;
    value?: any;
}

function CustomCollection({ id, value }: CustomCollectionProps) {
    const dispatch = useAppDispatch();

    const { selectedRegion, userInfo } = useAppSelector((state) => state.auth);
    const { queryCustomCollection } = useAppSelector((state) => state.filter);

    const [inputValue, setInputValue] = useState(queryCustomCollection);

    const [collectionData, setCollectionData] = useState<any[]>([]);

    const { loading, data, refetch } = useQuery(CUSTOM_COLLECTION, {
        variables: {
            user: userInfo?.id,
            region: selectedRegion,
            q: inputValue
        },
        notifyOnNetworkStatusChange: true
    });

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setInputValue(event.target.value);
        dispatch(updateCustomCollectionQuery(event.target.value));
    };

    const handleChange = (event: React.SyntheticEvent, option: any, id: string) => {
        dispatch(updateInputList({ id, data: { checkOption: 'Is', customcategory: option && option.id ? { ...option } : undefined } }));
        dispatch(submitFilter());
        dispatch(updateCustomCollectionQuery(''));
        setInputValue('');
    };

    useEffect(() => {
        if (data) setCollectionData(data?.customCategoriesSearch?.categories);
    }, [loading]);

    useEffect(() => {
        refetch({
            user: userInfo?.id,
            region: selectedRegion,
            q: inputValue
        });
    }, [inputValue]);

    return (
        <Autocomplete
            size="small"
            id="tags-standard"
            clearOnEscape
            value={value || null}
            loading={loading}
            onChange={(event: React.SyntheticEvent, option: any) => handleChange(event, option, id)}
            options={collectionData}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(item: any) => (item?.name ? item?.name : '')}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Collection"
                    size="small"
                    onChange={handleInputChange}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        )
                    }}
                />
            )}
        />
    );
}

export default CustomCollection;
