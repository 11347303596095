import { useEffect, useState } from 'react';
// import axios from 'axios';
// import config from 'config';
import { useAppSelector } from 'store/hooks';
import { RootState } from 'store';
import { useNavigate, useParams } from 'react-router-dom';
import { useLazyQuery, useSubscription } from '@apollo/client';
import { CHAT, MESSAGE_CHUNK_SUBSCRIPTION } from 'views/dashboard/AssistantPage/queries/queries';
// import { closeWebSocketConnection, wsClient } from './wsClient';
// import { WebSocketLink } from '@apollo/client/link/ws';

interface ChatMessage {
    text: string;
    sender: string;
}

const useChat = () => {
    const [messages, setMessages] = useState<ChatMessage[]>([]);
    const [chatStarted, setChatStarted] = useState(false);
    const [chatloading, setLoading] = useState(false);
    const [suggestions, setSuggestions] = useState<string[]>([]);
    const [showMoreOptions, setShowMoreOptions] = useState(false);
    const { id } = useParams(); // Access the optional ID parameter
    const navigate = useNavigate();
    const [currentMessage, setCurrentMessage] = useState<string>('');
    // Use `skip` to only subscribe when `id` exists
    const { data: chunkData, error: chunkError } = useSubscription(MESSAGE_CHUNK_SUBSCRIPTION, {
        variables: { threadId: id },
        skip: !id // Skip subscription if `id` is not available
    });

    // Append each received chunk to messages
    useEffect(() => {
        if (chunkData?.messageChunk && chunkData?.messageChunk !== 'done generating responce from backend') {
            if (chatloading) setLoading(false); // Set loading to false
            setCurrentMessage((prev) => prev + chunkData.messageChunk); // Accumulate chunks in the current message
        } else if (chunkData?.messageChunk && chunkData?.messageChunk === 'done generating responce from backend') {
            const aiMessage = { text: currentMessage, sender: 'ai' };
            setMessages((prevMessages) => [...prevMessages, aiMessage]);
            setCurrentMessage(''); // Reset the current message
        }
    }, [chunkData]);
    // console.log('id use chat', id);

    const { selectedRegion, accessAccountUser } = useAppSelector((state: RootState) => state.auth);

    const currentAccess: any = accessAccountUser.find((item: any) => item.account.config.region == selectedRegion);

    const handleMoreClick = () => {
        setShowMoreOptions(!showMoreOptions); // Toggle more options visibility
    };
    const handleClickOnSuggestion = (suggestion: string) => {
        console.log('suggestion', suggestion);
        // setInputValue(suggestion); // Set the input value to the selected suggestion
        setSuggestions([]); // Clear the suggestions
    };

    const handleClickOnOption = async (label: string) => {
        console.log('label', label);
        // setInputValue(label); // Set the input value to the selected label
        await handleSuggestions(); // Send the message
    };

    const [Chat, { data: dataChat, loading: loadingChat, error: errorChat }] = useLazyQuery(CHAT, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache'
    });

    const handleSuggestions = async () => {
        try {
            // setLoading(true);
            // const response = await axios.post("http://localhost:5000/api/suggest", {
            //   // Adjust this endpoint to your backend
            //   prompt: inputValue, // Send the input prompt
            // });

            // // console.log("response", response);
            // const aiMessage = JSON.parse(response.data);
            const aiMessage = [
                'Create image',
                'Code',
                'Make a plan',
                'Analyze images',
                'Schedule meeting',
                'Get directions',
                'Translate text',
                'Send email'
            ];
            setSuggestions(aiMessage);
            // setMessages((prev) => [...prev, aiMessage]);
        } catch (error: any) {
            console.error('Error sending message:', error.response?.data || error.message);
        } finally {
            // setLoading(false);
        }
    };

    const handleSend = async (inputValue: string) => {
        if (inputValue.trim()) {
            setLoading(true);
            const newMessage = { text: inputValue, sender: 'user' };
            setMessages((prevMessages) => [...prevMessages, newMessage]);

            try {
                // Trigger the lazy query with the required variables
                await Chat({
                    variables: {
                        threadID: id ? id : null,
                        prompt: inputValue,
                        currentAccess,
                        region: selectedRegion
                    }
                });
            } catch (error) {
                console.error('Error sending message:', error);
            }
        }
    };

    // Check if the data is available, then set AI response
    useEffect(() => {
        if (dataChat) {
            // const aiMessage = { text: dataChat.Chat.message.replace(/\\n/g, '\n'), sender: 'ai' };
            // setMessages((prevMessages) => [...prevMessages, aiMessage]);

            const threadInfo = dataChat.Chat.threadInfo;
            console.log('threadId', threadInfo);
            if (threadInfo && threadInfo.id && threadInfo.id !== id) {
                setLoading(false);
                navigate(`/assistant/${threadInfo.id}`);
            }
        }
    }, [dataChat]); // Only run this effect when dataChat is updated

    useEffect(() => {
        if (errorChat) {
            console.error('Error loading chat:', errorChat);
        }
    }, [errorChat]);

    return {
        showMoreOptions,
        handleMoreClick,
        messages,
        setMessages,
        chatStarted,
        setChatStarted,
        chatloading: chatloading,
        handleSend,
        handleClickOnOption,
        suggestions,
        setSuggestions,
        handleClickOnSuggestion,
        currentAccess,
        id,
        currentMessage
    };
};

export default useChat;
