export type Region =
    | 'es'
    | 'de'
    | 'fr'
    | 'nl'
    | 'tr'
    | 'pt'
    | 'swe'
    | 'at'
    | 'pl'
    | 'it'
    | 'ch'
    | 'be'
    | 'hu'
    | 'uk'
    | 'usa'
    | 'me'
    | 'sa'
    | 'ae';

type RegionConfig = {
    ISO: string;
    style: string;
    currency: string;
    minimumFractionDigits: number;
};

type RegionCurrency = Record<Region, RegionConfig>;

export const regionCurrency: RegionCurrency = {
    es: {
        ISO: 'es-ES',
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2
    },
    de: {
        ISO: 'de-DE',
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2
    },
    fr: {
        ISO: 'fr-FR',
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2
    },
    nl: {
        ISO: 'nl-NL',
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2
    },
    tr: {
        ISO: 'tr-TR',
        style: 'currency',
        currency: 'TRY',
        minimumFractionDigits: 2
    },
    pt: {
        ISO: 'pt-PT',
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2
    },
    swe: {
        ISO: 'se-SE',
        style: 'currency',
        currency: 'SEK',
        minimumFractionDigits: 2
    },
    at: {
        ISO: 'at-AT',
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2
    },
    pl: {
        ISO: 'pl-PL',
        style: 'currency',
        currency: 'PLN',
        minimumFractionDigits: 2
    },
    it: {
        ISO: 'it-IT',
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2
    },
    ch: {
        ISO: 'ch-CH',
        style: 'currency',
        currency: 'CHF',
        minimumFractionDigits: 2
    },
    be: {
        ISO: 'be-BE',
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2
    },
    hu: {
        ISO: 'hu-HU',
        style: 'currency',
        currency: 'HUF',
        minimumFractionDigits: 2
    },
    uk: {
        ISO: 'en-GB',
        style: 'currency',
        currency: 'GBP',
        minimumFractionDigits: 2
    },
    usa: {
        ISO: 'en-US',
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
    },
    me: {
        ISO: 'en-SA',
        style: 'currency',
        currency: 'SAR',
        minimumFractionDigits: 2
    },
    sa: {
        ISO: 'en-SA',
        style: 'currency',
        currency: 'SAR',
        minimumFractionDigits: 2
    },
    ae: {
        ISO: 'en-AE',
        style: 'currency',
        currency: 'AED',
        minimumFractionDigits: 2
    }
};
