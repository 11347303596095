import { Card, Pagination, Typography, CardContent, Stack, useMediaQuery, Theme } from '@mui/material';

interface PaginationProps {
    currentPagination: number;
    totalProduct: number | null;
    productPerPage?: number;
    handleChangePagination: (event: React.ChangeEvent<unknown>, page: number) => void;
    notFountComponent?: React.ReactNode;
}

export default function PaginationProduct({
    currentPagination,
    totalProduct,
    productPerPage = 21,
    notFountComponent,
    handleChangePagination
}: PaginationProps) {
    const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    const isLastPage = totalProduct && Math.ceil(totalProduct / productPerPage) == currentPagination;
    const paginationCount = totalProduct && totalProduct > productPerPage ? Math.ceil(totalProduct / productPerPage) : 0;
    const paginationTitle =
        totalProduct && totalProduct > productPerPage
            ? `Displaying ${(currentPagination - 1) * productPerPage + 1} to ${
                  isLastPage ? totalProduct : currentPagination * productPerPage
              } of ${totalProduct} items`
            : `Displaying ${totalProduct} items`;

    if (totalProduct)
        return (
            <Card>
                <Stack direction={mdDown ? 'column' : 'row'} justifyContent={'space-between'} spacing={2} sx={{ p: 1 }}>
                    <Typography variant="h5">{paginationTitle}</Typography>
                    <Pagination count={paginationCount} page={currentPagination} color="primary" onChange={handleChangePagination} />
                </Stack>
            </Card>
        );

    if (totalProduct === 0)
        return (
            <Card>
                <CardContent>
                    <Stack direction={'column'} justifyContent={'center'} alignItems={'center'}>
                        {notFountComponent ? notFountComponent : <Typography align="center">Products Not Found</Typography>}
                    </Stack>
                </CardContent>
            </Card>
        );
    else return null;
}
