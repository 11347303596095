import { Region, regionCurrency } from 'constants/region-currency/regionCurrency';

type websiteType = {
    region: string;
    websites: [];
};

const sanitizeWbsites = (websites: websiteType[], selectedRegion: string) => {
    return websites.find((item: websiteType) => {
        if (item.region == selectedRegion) {
            return item;
        }
    });
};

function initChartData(chart: any, type = '') {
    chart.title.text = '';
    chart.series = [];
    chart.legend.data = [];

    if (type && type == 'bar') {
        // chart.color = [];
        // chart.xAxis = {
        //     type: 'category',
        //     data: []
        // };
        // chart.yAxis = {
        //     type: 'value',
        //     scale: true
        // };
    }
    return chart;
}

function configureChartOption(options: any, products: any, dataObj: string, axis: string) {
    options.title.text = '';
    if (!products[dataObj][axis].data || !products[dataObj][axis].data.length) {
        options.title.text = "Nothing to Show 🥺. There's no data to load, please check your filter.";
        options.series = [];
        options.legend.data = [];

        return options;
    }
    if (products && products.error) {
        options.title.text = `${products.error}`;
        return;
    }
    options[axis].data = products[dataObj][axis].data;
    options.series = products[dataObj].series;

    const names: any = [];
    options.series.forEach((item: any) => {
        if (item.name) names.push(item.name as never);
    });
    options.legend.data = names;

    return options;
}

function getCountryCode(countryCode: any) {
    switch (countryCode.toLowerCase()) {
        case 'swe':
            return 'se';
        case 'se':
            return 'swe';
        case 'usa':
            return 'us';
        case 'uk':
            return 'gb';
        case 'me':
            return 'ae';

        default:
            return countryCode;
    }
}

function handleMenuItem(allComponents?: AccountComponent[]) {
    const components: AccountComponent[] = [];

    const overview = allComponents?.find((item: AccountComponent) => item.name == 'assortment') || { name: 'assortment', allowed: true };
    const freshCatalog = allComponents?.find((item: AccountComponent) => item.name == 'freshcatalog') || {
        name: 'freshcatalog',
        allowed: true
    };
    const relevance = allComponents?.find((item: AccountComponent) => item.name == 'relevance') || { name: 'relevance', allowed: true };
    const opportunities = allComponents?.find((item: AccountComponent) => item.name == 'opportunities') || {
        name: 'opportunities',
        allowed: true
    };
    const newProducts = allComponents?.find((item: AccountComponent) => item.name == 'newproducts') || {
        name: 'newproducts',
        allowed: true
    };
    const bestRated = allComponents?.find((item: AccountComponent) => item.name == 'bestrated') || { name: 'bestrated', allowed: true };
    const priceHistory = allComponents?.find((item: AccountComponent) => item.name == 'pricinghistory') || {
        name: 'pricinghistory',
        allowed: true
    };
    const multivariates = allComponents?.find((item: AccountComponent) => item.name == 'pricingmultivariates') || {
        name: 'pricingmultivariates',
        allowed: true
    };

    const marketshare = allComponents?.find((item: AccountComponent) => item.name == 'marketshare') || {
        name: 'marketshare',
        allowed: false
    };

    const marketsharebybrands = allComponents?.find((item: AccountComponent) => item.name == 'marketsharebybrands') || {
        name: 'marketsharebybrands',
        allowed: false
    };

    const brandsvisibility = allComponents?.find((item: AccountComponent) => item.name == 'brandsvisibility') || {
        name: 'brandsvisibility',
        allowed: false
    };

    const brandpricing = allComponents?.find((item: AccountComponent) => item.name == 'brandpricing') || {
        name: 'brandpricing',
        allowed: false
    };

    const brandsoverview = allComponents?.find((item: AccountComponent) => item.name == 'brandsoverview') || {
        name: 'brandsoverview',
        allowed: false
    };

    const brandslisting = allComponents?.find((item: AccountComponent) => item.name == 'brandslisting') || {
        name: 'brandslisting',
        allowed: false
    };

    const brands = allComponents?.find((item: AccountComponent) => item.name == 'brands') || {
        name: 'brands',
        allowed: false
    };

    const content = allComponents?.find((item: AccountComponent) => item.name == 'content') || {
        name: 'content',
        allowed: false
    };

    const contentOverview = allComponents?.find((item: AccountComponent) => item.name == 'contentOverview') || {
        name: 'contentOverview',
        allowed: false
    };

    const contentAdvancedAnalytics = allComponents?.find((item: AccountComponent) => item.name == 'contentAdvancedAnalytics') || {
        name: 'contentAdvancedAnalytics',
        allowed: false
    };

    const contentRating = allComponents?.find((item: AccountComponent) => item.name == 'contentRating') || {
        name: 'contentRating',
        allowed: false
    };

    const contentTitle = allComponents?.find((item: AccountComponent) => item.name == 'contentTitle') || {
        name: 'contentTitle',
        allowed: false
    };

    const contentQaulityScore = allComponents?.find((item: AccountComponent) => item.name == 'contentQaulityScore') || {
        name: 'contentQaulityScore',
        allowed: false
    };

    if (allComponents && allComponents?.length > 0) {
        allComponents?.map((item: AccountComponent) => {
            if (item.allowed == true) components.push(item);
        });
    }

    const menuData = [
        ...components,
        overview,
        freshCatalog,
        brands,
        brandslisting,
        brandsvisibility,
        brandpricing,
        brandsoverview,
        marketshare,
        marketsharebybrands,
        relevance,
        opportunities,
        newProducts,
        bestRated,
        priceHistory,
        multivariates,
        content,
        contentOverview,
        contentAdvancedAnalytics,
        contentRating,
        contentTitle,
        contentQaulityScore
    ];

    return menuData.filter((accountComponent: AccountComponent) => accountComponent.allowed).map((item: AccountComponent) => item.name);
}

function formatElasticsearchNumber(value: any) {
    if (!value || value === undefined || value === null) return 0;
    // Convert to a number if it's in scientific notation
    const number = parseFloat(value.toFixed(2));

    return number;
}

function hexToRGB(hex: string, alpha: number) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
    } else {
        return 'rgb(' + r + ', ' + g + ', ' + b + ')';
    }
}

function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function isValidUrl(urlString: string) {
    const urlPattern = new RegExp(
        '^(https?:\\/\\/)?' + // validate protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
            '(\\#[-a-z\\d_]*)?$',
        'i'
    ); // validate fragment locator
    return !!urlPattern.test(urlString);
}

const WEBSITE_SA = ['64f9a6d6357738e7c251821a', '64f88973357738e7c226a6ec', '5fbe5b6711073c3deafe911a'];
function checkCurruncy(region: string, websiteId: string) {
    if (region === 'me') {
        if (WEBSITE_SA.includes(websiteId)) return true;
    }
    return false;
}

function priceFormatter(price: number, region: string): string {
    const currentRegionConfig = regionCurrency[region as Region];

    const formatter = new Intl.NumberFormat(currentRegionConfig.ISO, {
        currency: currentRegionConfig.currency,
        minimumFractionDigits: currentRegionConfig.minimumFractionDigits
    });

    if (price || price === 0) return formatter.format(price);
    else return 'no price';
}

export {
    sanitizeWbsites,
    configureChartOption,
    getCountryCode,
    initChartData,
    handleMenuItem,
    hexToRGB,
    capitalizeFirstLetter,
    isValidUrl,
    checkCurruncy,
    priceFormatter,
    formatElasticsearchNumber
};
