import {
    Dialog,
    DialogTitle,
    Typography,
    IconButton,
    DialogContent,
    DialogContentText,
    TextField,
    Button,
    DialogActions
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

interface ExportModalProps {
    open: boolean;
    handleClose: () => void;
    handleExport: () => void;
    handleNameChanged: (str: string) => void;
    fileName?: string;
}

function ExportModal({ open, handleClose, handleExport, handleNameChanged, fileName }: ExportModalProps) {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="exportation-products"
            aria-describedby="exportation-dialog-description"
            PaperProps={{ sx: { borderRadius: '20px' } }}
        >
            <DialogTitle>
                <Typography variant="h5" component="p">
                    Exportation
                </Typography>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please provide The Name of the file to export data, you will find the export on your download-list, once it is ready.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="exportName"
                    label="Exportation name"
                    type="text"
                    value={fileName}
                    onChange={(event) => handleNameChanged(event.target.value)}
                    fullWidth
                    variant="standard"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleExport} aria-label="save">
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ExportModal;
