import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { accessAccountUser } from '../auth/authActions';

const userWebsites = localStorage.getItem('userWebsites') || null;
const selectedRegion = localStorage.getItem('selectedRegion') || 'es';

const websitesRegions = userWebsites ? JSON.parse(userWebsites)?.filter((el: any) => el.region == selectedRegion)[0].websites : [];
const storedDefaultWebsite = websitesRegions?.filter((elm: any) => elm.default)[0];
const storedIsMMS: any = localStorage.getItem('isMMS') || false;
const storedIsBrand = localStorage.getItem('isBrand') || false;

const months = ['es', 'pt'].includes(selectedRegion.toLowerCase()) ? { count: 1, type: 'months' } : { count: 8, type: 'weeks' };

const initialState: any = {
    inputList:
        String(storedIsMMS) == 'true'
            ? [{ id: '1', fieldSearch: 'Module', checkOption: 'Is' }]
            : [{ id: '1', checkOption: 'Is', includeExclude: 'Include', fieldSearch: 'Google Category', google: null }],
    checkFilter: {
        bundles: storedIsBrand && storedIsBrand && String(storedIsBrand) == 'true' ? false : true,
        refurbished: storedIsBrand && storedIsBrand && String(storedIsBrand) == 'true' ? false : true,
        instock: storedIsBrand && storedIsBrand && String(storedIsBrand) == 'true' ? false : true,
        hideMarketplace: storedIsBrand && storedIsBrand && String(storedIsBrand) == 'true' ? false : true
    },
    datePicker: storedDefaultWebsite
        ? moment(
              storedDefaultWebsite && storedDefaultWebsite.data ? new Date(storedDefaultWebsite?.data?.lastDataDate) : new Date(),
              'MM-DD-YYYY'
          )
        : moment(new Date(), 'MM-DD-YYYY'),
    firstTimeDiscoverd: moment(storedDefaultWebsite?.data?.lastDataDate).subtract(1, 'months').format('YYYY-MM-DD'),
    firstTimeDiscoverdLabel: '30_days',
    dateRangePicker: {
        startDate: moment(
            moment(
                storedDefaultWebsite && storedDefaultWebsite.data ? new Date(storedDefaultWebsite?.data?.lastDataDate) : new Date()
            ).subtract(months.count as any, months.type as any),
            'MM-DD-YYYY'
        ),
        endDate: moment(
            moment(storedDefaultWebsite && storedDefaultWebsite.data ? new Date(storedDefaultWebsite?.data?.lastDataDate) : new Date()),
            'MM-DD-YYYY'
        )
    },
    websiteFilterField: '',
    websitesValues: null,
    amazonFilterField: 'amazon-category',
    amazonBestSeller: null,
    amazonBestSellerKeyword: '',
    listingFilterField: 'Module',
    listingThirdParty: null,
    queryModule: '',
    queryProductGroup: '',
    queryGoogleCategory: '',
    queryBrand: '',
    queryExactWebsite: '',
    queryAmazonBestSeller: '',
    queryCustomCollection: '',
    queryThirdParty: '',
    isSubmitting: false
};

const filterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        addInputList: (state, { payload }) => {
            const generateId = Math.random().toString(36).substring(8);
            state.inputList.push({
                id: generateId,
                ...payload
            });
        },
        updateInputList: (state, { payload }) => {
            const postIndex = state.inputList.findIndex((item: any) => item.id === payload.id);
            if (postIndex !== -1) {
                state.inputList[postIndex] = { ...state.inputList[postIndex], ...payload.data };
            }
        },
        resetInputListItem: (state, { payload }) => {
            const postIndex = state.inputList.findIndex((item: any) => item.id === payload.data.id);
            if (postIndex !== -1) {
                state.inputList[postIndex] = { ...payload.data };
            }
        },
        removeInputList: (state, { payload }) => {
            state.inputList = state?.inputList?.filter((item: any) => item.id !== payload.id);
        },
        changeInputListValue: (state, { payload }) => {
            state.inputList = payload;
        },
        resetInputList: (state) => {
            const isMMSValue = localStorage.getItem('isMMS');
            const isBrandValue = localStorage.getItem('isBrand');

            state.inputList =
                String(isMMSValue) == 'true'
                    ? [{ id: '1', fieldSearch: 'Module', checkOption: 'Is' }]
                    : [{ id: '1', checkOption: 'Is', includeExclude: 'Include', fieldSearch: 'Google Category', google: null }];

            state.checkFilter.bundles = isBrandValue && String(isBrandValue) == 'true' ? false : true;
            state.checkFilter.refurbished = isBrandValue && String(isBrandValue) == 'true' ? false : true;
            state.checkFilter.instock = isBrandValue && String(isBrandValue) == 'true' ? false : true;
            state.checkFilter.hideMarketplace = isBrandValue && String(isBrandValue) == 'true' ? false : true;
            state.firstTimeDiscoverd = moment().subtract(1, 'months').format('YYYY-MM-DD');
            state.firstTimeDiscoverdLabel = '30_days';
        },
        initializeInputList: (state, { payload }) => {
            state.inputList = payload;
        },
        updateFirstTimeDiscoverd: (state, { payload }) => {
            state.firstTimeDiscoverd = payload;
        },
        updateFirstTimeDiscoverdLabel: (state, { payload }) => {
            state.firstTimeDiscoverdLabel = payload;
        },
        updateBundle: (state, { payload }) => {
            state.checkFilter.bundles = payload;
        },
        updateStock: (state, { payload }) => {
            state.checkFilter.instock = payload;
        },
        updateMarketPlace: (state, { payload }) => {
            state.checkFilter.hideMarketplace = payload;
        },
        updateRefurbished: (state, { payload }) => {
            state.checkFilter.refurbished = payload;
        },
        updateDatePicker: (state, { payload }) => {
            state.datePicker = payload;
        },
        updateDateRangePicker: (state, { payload }) => {
            state.dateRangePicker = payload;
        },
        updateAmazonBestSeller: (state, { payload }) => {
            state.amazonBestSeller = payload;
        },
        updateAmazonBestSellerKeyword: (state, { payload }) => {
            state.amazonBestSellerKeyword = payload;
        },
        updateModuleQuery: (state, { payload }) => {
            state.queryModule = payload;
        },
        updateProductGroupQuery: (state, { payload }) => {
            state.queryProductGroup = payload;
        },
        updateGoogleCategoryQuery: (state, { payload }) => {
            state.queryGoogleCategory = payload;
        },
        updateBrandQuery: (state, { payload }) => {
            state.queryBrand = payload;
        },
        updateExactWebsiteQuery: (state, { payload }) => {
            state.queryExactWebsite = payload;
        },
        updateCustomCollectionQuery: (state, { payload }) => {
            state.queryCustomCollection = payload;
        },
        updateThirdPartyQuery: (state, { payload }) => {
            state.queryThirdParty = payload;
        },
        updateAmazonBestSellerQuery: (state, { payload }) => {
            state.queryAmazonBestSeller = payload;
        },
        updateAmazonFilterField: (state, { payload }) => {
            state.amazonFilterField = payload;
        },
        updateWebsiteFilterField: (state, { payload }) => {
            state.websiteFilterField = payload;
        },
        updateWebsitesValues: (state, { payload }) => {
            state.websitesValues = payload;
        },
        updateListingGoogleCategory: (state, { payload }) => {
            state.inputList = [
                {
                    id: '1',
                    checkOption: 'Is',
                    includeExclude: 'Include',
                    fieldSearch: 'Google Category',
                    google: payload
                }
            ];
        },
        updateListingModule: (state, { payload }) => {
            state.inputList = [
                {
                    checkOption: 'Is',
                    fieldSearch: 'Module',
                    id: '1',
                    mmsmodule: payload
                }
            ];
        },
        updateListingProductGroup: (state, { payload }) => {
            state.inputList = [
                {
                    checkOption: 'Is',
                    fieldSearch: 'Product Group',
                    id: '1',
                    mmscategory: payload
                }
            ];
        },

        updateListingThirdParty: (state, { payload }) => {
            state.listingThirdParty = payload;
            state.inputList = [
                {
                    checkOption: 'Is',
                    fieldSearch: 'Module',
                    id: '1',
                    mmsmodule: payload
                }
            ];
        },
        updateListingFilterField: (state, { payload }) => {
            state.listingFilterField = payload;
        },

        submitFilter: (state) => {
            state.isSubmitting = true;
        },
        unSubmitFilter: (state) => {
            state.isSubmitting = false;
        },
        resetFilter: (state, { payload }) => {
            const currentWebsites = userWebsites ? JSON.parse(userWebsites)?.filter((el: any) => el.region == payload.region) : [];
            const websitesRegions = currentWebsites.length ? currentWebsites[0].websites : [];
            const defaultWebsiteData = websitesRegions?.filter((elm: any) => elm.default)[0];

            return {
                inputList:
                    String(storedIsMMS) == 'true'
                        ? [{ id: '1', fieldSearch: 'Module', checkOption: 'Is' }]
                        : [{ id: '1', checkOption: 'Is', includeExclude: 'Include', fieldSearch: 'Google Category', google: null }],
                checkFilter: {
                    bundles: String(payload.isBrandValue) == 'true' ? false : true,
                    refurbished: String(payload.isBrandValue) == 'true' ? false : true,
                    instock: String(payload.isBrandValue) == 'true' ? false : true,
                    hideMarketplace: String(payload.isBrandValue) == 'true' ? false : true
                },
                firstTimeDiscoverd: moment(defaultWebsiteData?.data?.lastDataDate).subtract(1, 'months').format('YYYY-MM-DD'),
                firstTimeDiscoverdLabel: '30_days',
                datePicker: defaultWebsiteData
                    ? moment(new Date(defaultWebsiteData?.data?.lastDataDate), 'MM-DD-YYYY')
                    : moment(new Date(), 'MM-DD-YYYY'),
                dateRangePicker: {
                    startDate: moment(moment(new Date(defaultWebsiteData?.data?.lastDataDate)).subtract(3, 'months'), 'MM-DD-YYYY'),
                    endDate: moment(moment(new Date(defaultWebsiteData?.data?.lastDataDate)), 'MM-DD-YYYY')
                },
                amazonFilterField: 'amazon-category',
                amazonBestSeller: null,
                amazonBestSellerKeyword: '',
                listingFilterField: 'Module',
                listingThirdParty: null,
                queryModule: '',
                queryProductGroup: '',
                queryGoogleCategory: '',
                queryBrand: '',
                queryExactWebsite: '',
                queryAmazonBestSeller: '',
                queryThirdParty: '',
                isSubmitting: false
            };
        },
        resetInitialStateFilter: () => {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(accessAccountUser.fulfilled, (state) => {
            // Call resetFilter when loading access action is dispatched
            filterSlice.caseReducers.resetInputList(state);
        });
    }
});

export const {
    addInputList,
    updateInputList,
    changeInputListValue,
    resetInputListItem,
    removeInputList,
    resetInputList,
    initializeInputList,
    updateFirstTimeDiscoverd,
    updateFirstTimeDiscoverdLabel,
    updateBundle,
    updateStock,
    updateMarketPlace,
    updateRefurbished,
    updateDatePicker,
    updateDateRangePicker,
    updateAmazonBestSeller,
    updateAmazonBestSellerKeyword,
    updateModuleQuery,
    updateProductGroupQuery,
    updateGoogleCategoryQuery,
    updateBrandQuery,
    updateExactWebsiteQuery,
    updateAmazonBestSellerQuery,
    updateCustomCollectionQuery,
    updateAmazonFilterField,
    updateWebsiteFilterField,
    updateWebsitesValues,
    updateListingGoogleCategory,
    updateListingModule,
    updateListingProductGroup,
    updateListingThirdParty,
    updateListingFilterField,
    updateThirdPartyQuery,
    submitFilter,
    unSubmitFilter,
    resetFilter,
    resetInitialStateFilter
} = filterSlice.actions;

export default filterSlice.reducer;
