import { useState, MouseEvent } from 'react';
import { Popover, Stack, Box, Typography, Divider, Tooltip, IconButton, useTheme } from '@mui/material';

import { FiAlertCircle } from 'react-icons/fi';
import { BsShop } from 'react-icons/bs';

import Scrollbar from 'components/common/UI/ScrollBar';
import CurrencyFormatter from 'components/common/CurrencyFormatter';
import { checkLostProduct, getColorBasedOnAvailability } from 'utils/availability';

function OffersList({ product }: { product?: Product }) {
    const theme = useTheme();

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'offers-popover' : undefined;

    return (
        <>
            <Tooltip title="Offers">
                <span>
                    <IconButton aria-describedby={id} onClick={handleClick}>
                        <FiAlertCircle fontSize={18} />
                    </IconButton>
                </span>
            </Tooltip>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
            >
                <Scrollbar style={{ maxHeight: '250px', maxWidth: '200px' }}>
                    <Stack direction={'column'} divider={<Divider flexItem />} spacing={2} sx={{ p: 1 }}>
                        {product?.offers?.list?.map((offer: Offer, index: number) => (
                            <Box
                                key={index}
                                sx={{ cursor: offer.url ? 'pointer' : 'text' }}
                                onClick={() => {
                                    if (offer.url) window.open(product?.website?.url + offer.url, '_blank');
                                }}
                            >
                                <Stack key={index} direction={'row'} spacing={1} divider={<Divider flexItem />}>
                                    {/* marketplace */}
                                    {offer?.marketplace && (
                                        <Tooltip title={`Markeplace`} placement="top" sx={{ cursor: 'pointer' }}>
                                            <Typography fontSize={13} fontWeight={600} variant="body2">
                                                <BsShop />
                                            </Typography>
                                        </Tooltip>
                                    )}
                                    <Typography fontSize={13}>
                                        <CurrencyFormatter amount={offer.price} websiteId={product?.website?.id} />
                                    </Typography>
                                    <Typography
                                        fontSize={13}
                                        color={getColorBasedOnAvailability(
                                            offer.availability,
                                            product?.website?.data?.lastDataDate,
                                            product?.lastUpdate,
                                            theme
                                        )}
                                    >
                                        {checkLostProduct(offer.availability, product?.website?.data?.lastDataDate, product?.lastUpdate)}
                                    </Typography>
                                </Stack>
                            </Box>
                        ))}
                    </Stack>
                </Scrollbar>
            </Popover>
        </>
    );
}

export default OffersList;
