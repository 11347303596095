import moment from 'moment';

export const getColorBasedOnAvailability = (av?: string, websiteLastDataDate?: string, date?: string, theme?: any) => {
    if (!av || !websiteLastDataDate) {
        return '#777e89';
    }

    if (date == 'Invalid date') {
        date = '';
    }
    if (date) {
        const yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD'),
            daybefore = moment().subtract(2, 'days').format('YYYY-MM-DD');
        if (date >= websiteLastDataDate || date >= yesterday || date >= daybefore || new Date(date) >= new Date(yesterday)) {
            if (av == 'InStock') {
                return theme.palette.success.main;
            } else {
                return theme.palette.error.dark;
            }
        } else {
            return '#8c919d';
        }
    }

    return '#8c919d';
};

export const getProductAvailability = (av?: string, websiteLastDataDate?: string, date?: string) => {
    if (!av || !websiteLastDataDate) {
        return 'Lost';
    }

    if (date == 'Invalid date') {
        date = '';
    }
    if (date) {
        const yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD'),
            daybefore = moment().subtract(2, 'days').format('YYYY-MM-DD');
        if (date >= websiteLastDataDate || date >= yesterday || date >= daybefore || new Date(date) >= new Date(yesterday)) {
            return av;
        } else {
            return 'Lost';
        }
    }

    return 'Lost';
};

export const checkLostProduct = (availability?: string, websiteLastDataDate?: string, productLastUpdate?: string) => {
    const yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD');
    const daybefore = moment().subtract(2, 'days').format('YYYY-MM-DD');

    if (!availability) availability = '';

    if (websiteLastDataDate && productLastUpdate) {
        if (
            productLastUpdate >= websiteLastDataDate ||
            productLastUpdate >= yesterday ||
            productLastUpdate >= daybefore ||
            new Date(productLastUpdate) >= new Date(yesterday)
        ) {
            return availability;
        } else {
            return `Lost (${productLastUpdate})`;
        }
    }
    return `Lost (${productLastUpdate})`;
};
