/**
 * @fileoverview global config file for the app
 * @module config
 */

const config = {
    graphqlAPIUrl: process.env.NODE_ENV === 'production' ? `${process.env.PUBLIC_URL}/graphql` : `${process.env.REACT_APP_API_URL}/graphql`,
    backendAPIUrl: process.env.NODE_ENV === 'production' ? `${process.env.PUBLIC_URL}` : `${process.env.REACT_APP_API_URL}`,
    googleAnalyticsToken: 'GTM-N4QL6ZJ',
    dateFormat: 'YYYY-MM-DD',
    paginationSize: 21
};

export default config;
