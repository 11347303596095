import { useRef } from 'react';
import { Autocomplete, Chip, Stack, TextField } from '@mui/material';

interface SearchTagsProps {
    tags: string[];
    placeholder?: string;
    handleAddTag?: (inputValue: string) => void;
    handleDeleteTag?: (tagToDelete: string) => void;
}

function SearchTags({ tags, placeholder, handleAddTag, handleDeleteTag }: SearchTagsProps) {
    const tagRef = useRef<HTMLInputElement | null>(null);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const inputValue = tagRef?.current?.value?.trim();
        if (event.key === 'Enter' && tagRef?.current && inputValue) {
            const duplicatedValues = tags.indexOf(inputValue);

            if (duplicatedValues !== -1) {
                tagRef.current.value = '';
                return;
            }

            event.preventDefault();
            handleAddTag && handleAddTag(inputValue);

            tagRef.current.value = '';
        }
    };

    return (
        <Autocomplete
            multiple
            disableClearable
            value={tags}
            size="small"
            fullWidth
            options={[]}
            renderTags={() => (
                <Stack direction="row" flexWrap="wrap" rowGap={0.5} columnGap={0.5}>
                    {tags?.map((item: string, index: number) => (
                        <Chip
                            key={index}
                            label={item}
                            onDelete={() => {
                                handleDeleteTag && handleDeleteTag(item);
                            }}
                            size="small"
                        />
                    ))}
                </Stack>
            )}
            renderInput={(params: any) => (
                <TextField {...params} inputRef={tagRef} placeholder={placeholder || ''} onKeyDown={handleKeyDown} />
            )}
        />
    );
}

export default SearchTags;
