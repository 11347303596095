import { useRef, useState, useEffect } from 'react';
import { TextField, Chip, Autocomplete, Box } from '@mui/material';

interface SearchTagProps {
    tagPlaceholder?: string;
    tagsData?: string[];
    maxTags?: number;
    onChangeTag: (tags: string[]) => void;
}

function SearchTag({ tagPlaceholder, onChangeTag, maxTags, tagsData }: SearchTagProps) {
    const [inputValue, setInputValue] = useState('');
    const [tags, setTags] = useState(tagsData || []);

    const tagRef = useRef<HTMLInputElement | null>(null);

    const handleChangeTag = () => {
        onChangeTag(tags);
    };

    const handleChangeInputValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
    };

    const handleDelete = (tagValue: string) => {
        const newtags = tags.filter((tag: string) => tag !== tagValue);
        setTags(newtags);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const inputValue: string | undefined = tagRef?.current?.value.trim();

        if (event.key === 'Enter' && inputValue) {
            if (maxTags && tags.length == maxTags) return;

            const duplicatedValues = tags.indexOf(inputValue);

            if (duplicatedValues !== -1) {
                setInputValue('');
                return;
            }

            setTags([...tags, inputValue]);
            setInputValue('');
        }
    };

    const handleBlur = () => {
        if (tags.length === 0 && inputValue) {
            setTags([inputValue]);
            setInputValue('');
        }
    };

    useEffect(() => {
        handleChangeTag();
    }, [tags]);

    useEffect(() => {
        setTags(tagsData || []);
    }, [tagsData]);

    return (
        <Box sx={{ '&.MuiAutocomplete-noOptions': { display: 'none' } }}>
            <Autocomplete
                multiple
                disableClearable
                value={tags}
                noOptionsText={`Search with ${tagPlaceholder}`}
                renderOption={(props, option) => {
                    if (option) {
                        return <li {...props}>{option}</li>;
                    }
                    return null;
                }}
                size="small"
                fullWidth
                options={[]}
                renderTags={() =>
                    tagsData?.map((item: string) => (
                        <Chip key={item} label={item} onDelete={() => handleDelete(item)} size="small" sx={{ mr: 0.4 }} />
                    ))
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        inputRef={tagRef}
                        value={inputValue}
                        placeholder={tagPlaceholder}
                        onChange={handleChangeInputValue}
                        onKeyDown={handleKeyDown}
                        onBlur={handleBlur}
                    />
                )}
            />
        </Box>
    );
}

export default SearchTag;
