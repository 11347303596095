import { SortingLabelType } from 'components/cards/ProductsMainContainer';
import { DateRangeLabelType } from 'components/filter/Filter';

export const sortingLabels: SortingLabelType[] = [
    {
        label: 'Crawlo Relevance',
        field: 'positiveRelevence',
        value: 'positiveRelevence'
    },
    {
        label: 'Reviews',
        field: 'overAllRating.count',
        value: 'reviews'
    },
    {
        label: 'Rating',
        field: 'overAllRating.value',
        value: 'rating'
    },
    {
        label: 'Newly added',
        field: 'listing.firstTimeDiscoverd',
        value: 'firstTimeDiscoverd'
    }
];
export const defaultSorting: SortingLabelType = {
    label: 'Crawlo Relevance',
    field: 'positiveRelevence',
    value: 'positiveRelevence',
    direction: 'desc'
};

export const sortingContentIndexQualityLabels: SortingLabelType[] = [
    {
        label: 'Content Quality Index',
        field: 'qualityindex',
        value: 'qualityindex'
    },
    {
        label: 'Reviews',
        field: 'reviews',
        value: 'reviews'
    },
    {
        label: 'Rating',
        field: 'rating',
        value: 'rating'
    }
];
export const defaultContentIndexQualitySorting: SortingLabelType = {
    label: 'Content Quality Index',
    field: 'qualityindex',
    value: 'qualityindex',
    direction: 'desc'
};

export const isBrandNewInMarketDefaultSorting: SortingLabelType = {
    label: 'Newly added',
    field: 'listing.firstTimeDiscoverd',
    value: 'firstTimeDiscoverd',
    direction: 'desc'
};

export const isBrandNewInMarketDateRangeLabels: DateRangeLabelType[] = [
    {
        label: 'last 7 days',
        field: 'firstTimeDiscoverd',
        value: '7_days'
    },
    {
        label: 'last 15 days',
        field: 'firstTimeDiscoverd',
        value: '15_days'
    },
    {
        label: 'last 30 days',
        field: 'firstTimeDiscoverd',
        value: '30_days'
    },
    {
        label: 'last 2 months',
        field: 'firstTimeDiscoverd',
        value: '2_months'
    },
    {
        label: 'last 3 months',
        field: 'firstTimeDiscoverd',
        value: '3_months'
    },
    {
        label: 'last 6 months',
        field: 'firstTimeDiscoverd',
        value: '6_months'
    }
];
export const isBrandNewInMarketDefaultDateRange: DateRangeLabelType = {
    label: 'last 30 days',
    field: 'firstTimeDiscoverd',
    value: '30_days'
};

export const gfksortingLabels: SortingLabelType[] = [
    {
        label: 'Crawlo Relevance',
        field: 'positiveRelevence',
        value: 'positiveRelevence'
    },
    {
        label: 'GFK Rank',
        field: 'gfk.rank',
        value: 'rank'
    },

    {
        label: 'Reviews',
        field: 'overAllRating.count',
        value: 'reviews'
    },
    {
        label: 'Rating',
        field: 'overAllRating.value',
        value: 'rating'
    },
    {
        label: 'Newly added',
        field: 'listing.firstTimeDiscoverd',
        value: 'firstTimeDiscoverd'
    }
];
export const gfkdefaultSorting: SortingLabelType = {
    label: 'GFK Rank',
    field: 'gfk.rank',
    value: 'rank',
    direction: 'asc'
};

export const bestRatedsortingLabels: SortingLabelType[] = [
    {
        label: 'Reviews',
        field: 'overAllRating.count',
        value: 'reviews'
    },
    {
        label: 'Crawlo Relevance',
        field: 'positiveRelevence',
        value: 'positiveRelevence'
    },
    {
        label: 'Rating',
        field: 'overAllRating.value',
        value: 'rating'
    },
    {
        label: 'Newly added',
        field: 'listing.firstTimeDiscoverd',
        value: 'firstTimeDiscoverd'
    }
];
export const bestRateddefaultSorting: SortingLabelType = {
    label: 'Reviews',
    field: 'overAllRating.count',
    value: 'reviews',
    direction: 'desc'
};

export const catalogsortingLabels: SortingLabelType[] = [
    {
        label: 'Date',
        field: 'timestamp',
        value: 'timestamp',
        direction: 'Asc'
    },
    {
        label: 'Price',
        field: 'price',
        value: 'price',
        direction: 'Asc'
    },
    {
        label: 'Brand Name',
        field: 'brand',
        value: 'brand',
        direction: 'Asc'
    },
    {
        label: 'Rating',
        field: 'rating.value',
        value: 'rating',
        direction: 'Asc'
    },
    {
        label: 'Reviews',
        field: 'rating.count',
        value: 'reviews',
        direction: 'Asc'
    }
];
export const catalogdefaultSorting: SortingLabelType = {
    label: 'Date',
    field: 'timestamp',
    value: 'timestamp',
    direction: 'desc'
};

export const catalogvisibilitysortingLabels: SortingLabelType[] = [
    {
        label: 'Visibility',
        field: 'visibility',
        value: 'visibility',
        direction: 'Asc'
    },
    {
        label: 'Position',
        field: 'position',
        value: 'position',
        direction: 'Asc'
    },
    {
        label: 'Price',
        field: 'price',
        value: 'price',
        direction: 'Asc'
    },
    {
        label: 'Rating',
        field: 'rating.value',
        value: 'rating',
        direction: 'Asc'
    },
    {
        label: 'Reviews',
        field: 'rating.count',
        value: 'reviews',
        direction: 'Asc'
    }
];
export const catalogvisibilitydefaultSorting: SortingLabelType = {
    label: 'Visibility',
    field: 'visibility',
    value: 'visibility',
    direction: 'desc'
};

export const newaddedSortingLabels: SortingLabelType[] = [
    {
        label: 'Discovery time',
        field: 'foundat1',
        value: 'foundat1',
        direction: 'desc'
    },
    {
        label: 'Visibility',
        field: 'visibility',
        value: 'visibility',
        direction: 'Asc'
    },
    {
        label: 'Position',
        field: 'position',
        value: 'position',
        direction: 'Asc'
    },
    {
        label: 'Price',
        field: 'price',
        value: 'price',
        direction: 'Asc'
    },
    {
        label: 'Rating',
        field: 'rating.value',
        value: 'rating',
        direction: 'Asc'
    },
    {
        label: 'Reviews',
        field: 'rating.count',
        value: 'reviews',
        direction: 'Asc'
    }
];
export const newaddedDefaultSorting: SortingLabelType = {
    label: 'Discovery time',
    field: 'foundat1',
    value: 'foundat1',
    direction: 'desc'
};
