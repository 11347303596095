import { gql } from '@apollo/client';

export const MMS_MODULE_RESULT = gql`
    query mmsmoduleresult($region: String!, $show: Boolean, $size: Int, $q: String) {
        mmsmoduleresult(region: $region, show: $show, size: $size, q: $q) {
            categories {
                id
                name
                category {
                    id
                    name
                }
                parents {
                    id
                    name
                }
                user
                filter
                updatable
            }
        }
    }
`;

export const MMS_GET_FLAGS_RESULT = gql`
    query GetMMFlags($region: String!) {
        GetMMFlags(region: $region) {
            total
            data
        }
    }
`;

export const MMS_PRODUCT_GROUP_RESULT = gql`
    query mmscategoryresult($region: String!, $show: Boolean, $size: Int, $q: String) {
        mmscategoryresult(region: $region, show: $show, size: $size, q: $q) {
            categories {
                id
                name
                category {
                    id
                    name
                }
                parents {
                    id
                    name
                }
                user
                filter
                updatable
                mainPG
                defaultStrategy
            }
        }
    }
`;

export const MMS_SCORING_MAIN_PRODUCT_GROUP_RESULT = gql`
    query ScoringMainPGResult($region: String!, $size: Int, $q: String) {
        ScoringMainPGResult(region: $region, size: $size, q: $q) {
            categories
        }
    }
`;

export const THIRD_PARTY_CATEGORY_RESULT = gql`
    query thirdpartycategoryresult($region: String!, $show: Boolean, $size: Int, $q: String) {
        thirdpartycategoryresult(region: $region, show: $show, size: $size, q: $q) {
            categories {
                id
                name
                category {
                    id
                    name
                }
                parents {
                    id
                    name
                }
                user
                filter
                updatable
            }
        }
    }
`;

export const GOOGLE_CATEGORY = gql`
    query googlecategoriessearch($region: String!, $show: Boolean, $size: Int, $q: String) {
        googlecategoriessearch(region: $region, show: $show, size: $size, q: $q) {
            region
            categories {
                ref
                name
                breadcrumbs
                parents {
                    ref
                    name
                }
            }
        }
    }
`;

export const GET_GOOGLE_CATEGORY = gql`
    query googlecategory($region: String!, $ref: String) {
        googlecategory(region: $region, ref: $ref) {
            error
            found
            category {
                ref
                name
                breadcrumbs
                parents {
                    ref
                    name
                }
            }
        }
    }
`;

export const BRAND_SEARCH = gql`
    query brandsearch($region: String!, $show: Boolean, $size: Int, $q: String) {
        brandsearch(region: $region, show: $show, size: $size, q: $q) {
            total
            page
            limit
            q
            list {
                id
                name
            }
        }
    }
`;

export const WEBSITE_CATEGORY = gql`
    query websiteCategories($region: String!, $website: WebsiteArgs!, $q: String) {
        websiteCategories(region: $region, website: $website, q: $q) {
            total
            list {
                id
                name
                ref
                url
                breadcrumbs
                parents {
                    id
                    ref
                    name
                }
                website {
                    id
                }
            }
        }
    }
`;

export const AMAZON_BEST_SELLER_CATEGORY = gql`
    query BestSellersCategorySearch($region: String!, $website_amazon: WebsiteArgs!, $q: String) {
        BestSellersCategorySearch(region: $region, website_amazon: $website_amazon, q: $q) {
            categoryresult {
                id
                ref
                name
                parents {
                    id
                    ref
                    name
                }
                breadcrumbs
            }
            error
        }
    }
`;

export const CUSTOM_COLLECTION = gql`
    query customCategoriesSearch($region: String!, $q: String, $user: String, $size: Int) {
        customCategoriesSearch(region: $region, q: $q, user: $user, size: $size) {
            categories
            error
        }
    }
`;

export const THIRD_PARTY_CATEGORY = gql`
    query thirdpartycategoryresult($region: String!, $q: String, $size: Int) {
        thirdpartycategoryresult(region: $region, q: $q, size: $size) {
            categories {
                id
                name
                category {
                    id
                    name
                }
                parents {
                    id
                    name
                }
                user
                show
                filter
            }
        }
    }
`;
