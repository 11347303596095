export const formatNumberEuropean = (num: number) => {
    return new Intl.NumberFormat('es-ES').format(num);
};

export const calculateProgressPercentage = (oldValue: number, newValue: number) => {
    if (oldValue === 0) {
        if (newValue === 0) {
            return 0; // No change
        }
        return Infinity; // Avoid division by zero
    }

    return ((newValue - oldValue) / oldValue) * 100;
};
