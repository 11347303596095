import { useState } from 'react';
import { Box, CircularProgress, Tooltip, SxProps, Theme } from '@mui/material';

interface ImageComponentProps {
    imageSource: string;
    isBetterWay?: boolean;
    isChoice?: boolean;
    height?: number | string;
    alt?: string;
    sx?: SxProps<Theme>;
    maxHeight?: string;
}

function ImageComponent({ imageSource, isBetterWay, isChoice, alt, height, sx, maxHeight }: ImageComponentProps) {
    const [loading, setLoading] = useState<boolean>(true);

    const handleImageLoad = () => {
        setLoading(false);
    };

    const handleImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
        setLoading(false);
        event.currentTarget.src = require('assets/images/image-not-found.png');
    };

    return (
        <Box sx={{ position: 'relative', height }}>
            {loading && (
                <Box
                    sx={{
                        height,
                        display: 'flex',
                        overflow: 'hidden',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <CircularProgress color="secondary" size={20} />
                </Box>
            )}
            {!loading && isBetterWay && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: '-50px',
                        height: '50px', // Adjust the badge size as needed
                        width: 'auto', // Adjust the badge size as needed
                        zIndex: 2 // Ensure the tag is above the image
                    }}
                >
                    <Tooltip title="MM Best Way.">
                        <span>
                            <img src={`${process.env.PUBLIC_URL}/better-way.png`} height="50px" width="auto" alt="Is Betterway" />
                        </span>
                    </Tooltip>
                </Box>
            )}
            {!loading && isChoice && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: '-35px',
                        left: '300px',
                        height: '30px', // Adjust the badge size as needed
                        width: 'auto', // Adjust the badge size as needed
                        zIndex: 2 // Ensure the tag is above the image
                    }}
                >
                    <Tooltip title="Coolblue Choice.">
                        <span>
                            <img src={`${process.env.PUBLIC_URL}/is-choice.svg`} height="30px" width="auto" alt="Is Choice" />
                        </span>
                    </Tooltip>
                </Box>
            )}
            <Box
                component={'img'}
                sx={{ height, ...sx, maxHeight }}
                src={imageSource}
                alt={alt || ''}
                onLoad={handleImageLoad}
                onError={handleImageError}
                style={{ display: loading ? 'none' : 'block' }}
            />
        </Box>
    );
}

export default ImageComponent;
