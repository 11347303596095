import { useState, useEffect, useCallback, forwardRef, useImperativeHandle } from 'react';
import { Button, FormControl, Grid, IconButton, MenuItem, OutlinedInput, Select, SelectChangeEvent, Stack, Tab, Card } from '@mui/material';

import { Formik, Form, FieldArray } from 'formik';
import { throttle } from 'lodash';
import { FiStar, FiTrash } from 'react-icons/fi';

import Rating from '../../field/Rating';
import SearchTags from '../SearchTags';
import { useAppSelector } from 'store/hooks';
import { RootState } from 'store';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import FormSearch from './FormSearch';
import { useQuery } from '@apollo/client';
import { MMS_OTHER_REGIONS_CONFIG } from 'services/accuracy/queries';

interface AdvancedFilterType {
    fieldSearch: string;
    includeExclude: string;
    checkOption: string;
    searchkey: string[];
    minprice?: number;
    maxprice?: number;
    reviews?: {
        op: {
            code: string;
            name: string;
        };
        value: number;
    };
    rating?: {
        op: {
            code: string;
            name: string;
        };
        value: number;
    };
    specifications?: {
        key: string;
        value: string[];
    };
}

const initialValues: { advancedFilter: AdvancedFilterType[] } = {
    advancedFilter: [
        {
            fieldSearch: 'Product Name',
            includeExclude: 'Exclude',
            checkOption: 'Containing',
            searchkey: []
        }
    ]
};

const AdvancedSearch = forwardRef(function AdvancedSearch({ advancedFilterData }: { advancedFilterData: any }, ref) {
    const { moduleValue, productGroupValue } = useAppSelector((state: RootState) => state.customFilter);
    const { selectedRegion } = useAppSelector((state: RootState) => state.auth);

    const mmsFilterCache = localStorage.getItem('cache-mms-filter') || null;
    const cacheData = mmsFilterCache && JSON.parse(mmsFilterCache)?.value ? [{ advancedFilter: JSON.parse(mmsFilterCache)?.value }] : [];

    const [history, setHistory] = useState<any[]>(cacheData);
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [value, setValue] = useState<string>(selectedRegion?.toLowerCase());
    const [otherRegionsData, setOtherRegionsData] = useState<any[]>([]);

    const { data, loading } = useQuery(MMS_OTHER_REGIONS_CONFIG, {
        variables: {
            excludedRegion: selectedRegion.toUpperCase(),
            fromsuite: false,
            id: `${moduleValue?.category?.id || productGroupValue?.category?.id}`,
            modules: moduleValue?.category?.id ? true : false
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache'
    });

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const saveState = useCallback(
        (values: any) => {
            const newHistory: any = history.slice(0, currentIndex + 1);
            newHistory.push(values);
            setHistory(newHistory);
            setCurrentIndex(newHistory.length - 1);
        },
        [history, currentIndex]
    );

    const throttledSaveState = useCallback(throttle(saveState, 1000), [saveState]);

    const handleUndo = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    const handleRedo = () => {
        if (currentIndex < history.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    useEffect(() => {
        if (data) {
            console.log(data.MMSOtherRegionsConfig.data);
            setOtherRegionsData(data.MMSOtherRegionsConfig.data);
        }
    }, [loading]);
    return (
        <TabContext value={value}>
            <Card variant="outlined" sx={{ p: 0 }}>
                <TabList onChange={handleChange} aria-label="lab API tabs example" variant="scrollable">
                    <Tab
                        label={`${selectedRegion?.toUpperCase()} Region`}
                        value={selectedRegion.toLowerCase()}
                        color="secondary"
                        sx={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}
                        icon={<FiStar />}
                        iconPosition="end"
                    />
                    {otherRegionsData.map((itemRegion: any, index: number) => (
                        <Tab
                            key={index}
                            label={`${itemRegion.region.toUpperCase()} ${parseFloat(itemRegion.accuracy) >= 50 ? 'Good' : 'Bad '} ${
                                itemRegion.accuracy
                            }% Miss. ${(100 - parseFloat(itemRegion.accuracy))?.toFixed(2)}%`}
                            value={itemRegion.region.toLowerCase()}
                        />
                    ))}
                </TabList>
            </Card>

            <Formik
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);
                }}
                enableReinitialize={true}
                validateOnBlur={false}
                validateOnChange={false}
                validateOnMount={false}
            >
                {({ values, setFieldValue, setValues }) => {
                    const throttledHandleChange = throttle((field, value, index) => {
                        const newValues = {
                            ...values,
                            advancedFilter: values.advancedFilter.map((item, idx) => (idx === index ? { ...item, [field]: value } : item))
                        };
                        setFieldValue(`advancedFilter.${index}.${field}`, value, false);
                        throttledSaveState(newValues);
                    }, 1000);

                    const handleAdd = () => {
                        const newValues = {
                            ...values,
                            advancedFilter: [...values.advancedFilter, { ...initialValues.advancedFilter[0] }]
                        };
                        setValues(newValues, false);
                        throttledSaveState(newValues);
                    };

                    const handleCopyFilter = (filterItem: any) => {
                        const newValues = {
                            ...values,
                            advancedFilter: [...values.advancedFilter, filterItem]
                        };
                        setValues(newValues, false);
                        throttledSaveState(newValues);
                    };

                    const handleRemove = (index: number) => {
                        const newValues = {
                            ...values,
                            advancedFilter: values?.advancedFilter?.filter((_, idx) => idx !== index)
                        };
                        setValues(newValues, false);
                        throttledSaveState(newValues);
                    };

                    useEffect(() => {
                        if (currentIndex >= 0 && currentIndex < history.length) {
                            setValues(history[currentIndex]);
                        }
                    }, [currentIndex, history, setValues]);

                    useEffect(() => {
                        const newValues = {
                            advancedFilter: advancedFilterData
                        };
                        setFieldValue('advancedFilter', advancedFilterData);
                        throttledSaveState(newValues);
                    }, []);

                    const getData = () => {
                        return {
                            values,
                            history
                        };
                    };

                    useImperativeHandle(ref, () => ({
                        getData
                    }));

                    return (
                        <>
                            <TabPanel value={selectedRegion.toLowerCase()}>
                                <Form>
                                    <FieldArray name="advancedFilter">
                                        {() => (
                                            <Grid display="flex" flexDirection="column" gap={2}>
                                                {values.advancedFilter.map((criteria, index) => (
                                                    <Grid container key={index} gap={1}>
                                                        {/* include exclude */}
                                                        <Grid item md={2}>
                                                            <Select
                                                                labelId={`includeExclude-label-${index}`}
                                                                id={`advancedFilter.${index}.includeExclude`}
                                                                name={`advancedFilter.${index}.includeExclude`}
                                                                value={criteria.includeExclude}
                                                                onChange={(e: any) =>
                                                                    throttledHandleChange('includeExclude', e.target.value, index)
                                                                }
                                                                size="small"
                                                                fullWidth
                                                            >
                                                                <MenuItem value="Include">Include</MenuItem>
                                                                <MenuItem value="Exclude">Exclude</MenuItem>
                                                            </Select>
                                                        </Grid>

                                                        {/* field search */}
                                                        <Grid item md={2}>
                                                            <Select
                                                                labelId={`fieldSearch-label-${index}`}
                                                                id={`advancedFilter.${index}.fieldSearch`}
                                                                name={`advancedFilter.${index}.fieldSearch`}
                                                                value={criteria.fieldSearch}
                                                                onChange={(e: any) =>
                                                                    throttledHandleChange('fieldSearch', e.target.value, index)
                                                                }
                                                                size="small"
                                                                fullWidth
                                                            >
                                                                <MenuItem value="Keywords">Keywords</MenuItem>
                                                                <MenuItem value="Product Name">Product Name</MenuItem>
                                                                <MenuItem value="Product Name/Specifications">
                                                                    Product Name/Specifications
                                                                </MenuItem>
                                                                <MenuItem value="Brand">Brand</MenuItem>
                                                                <MenuItem value="EAN/Asin/SKU">EAN/Asin/SKU</MenuItem>
                                                                <MenuItem value="Price Range">Price Range</MenuItem>
                                                                <MenuItem value="Specifications">Specifications</MenuItem>
                                                                <MenuItem value="Rating">Rating</MenuItem>
                                                                <MenuItem value="Reviews">Reviews</MenuItem>
                                                            </Select>
                                                        </Grid>

                                                        {/* checkOption */}
                                                        {criteria.fieldSearch == 'Keywords' ||
                                                        criteria.fieldSearch == 'Product Name' ||
                                                        criteria.fieldSearch == 'Product Name/Specifications' ||
                                                        criteria.fieldSearch == 'Brand' ||
                                                        criteria.fieldSearch == 'EAN/Asin/SKU' ? (
                                                            <Grid item md={2}>
                                                                <Select
                                                                    labelId={`checkOption-label-${index}`}
                                                                    id={`advancedFilter.${index}.checkOption`}
                                                                    name={`advancedFilter.${index}.checkOption`}
                                                                    value={criteria.checkOption}
                                                                    onChange={(e: any) =>
                                                                        throttledHandleChange('checkOption', e.target.value, index)
                                                                    }
                                                                    fullWidth
                                                                    size="small"
                                                                >
                                                                    <MenuItem value="Containing">Containing</MenuItem>
                                                                    <MenuItem value="Not Containing">Not Containing</MenuItem>
                                                                    <MenuItem value="Equal">Equal</MenuItem>
                                                                    <MenuItem value="Not Equal">Not Equal</MenuItem>
                                                                </Select>
                                                            </Grid>
                                                        ) : (
                                                            <Grid item xs={12} md={2}>
                                                                <Select
                                                                    labelId={`checkOption-label-${index}`}
                                                                    id={`advancedFilter.${index}.checkOption`}
                                                                    name={`advancedFilter.${index}.checkOption`}
                                                                    value={criteria.checkOption}
                                                                    onChange={(e: any) =>
                                                                        throttledHandleChange('checkOption', e.target.value, index)
                                                                    }
                                                                    size="small"
                                                                    fullWidth
                                                                >
                                                                    <MenuItem value="Is">Is</MenuItem>
                                                                </Select>
                                                            </Grid>
                                                        )}

                                                        {/* Search Tag */}
                                                        {(criteria.fieldSearch == 'Keywords' ||
                                                            criteria.fieldSearch == 'Product Name' ||
                                                            criteria.fieldSearch == 'Product Name/Specifications' ||
                                                            criteria.fieldSearch == 'Brand' ||
                                                            criteria.fieldSearch == 'EAN/Asin/SKU') && (
                                                            <Grid item xs={12} md={5}>
                                                                <SearchTags
                                                                    tags={criteria.searchkey}
                                                                    handleAddTag={(inputValue) => {
                                                                        const newTags = [...criteria.searchkey, inputValue];
                                                                        throttledHandleChange('searchkey', newTags, index);
                                                                    }}
                                                                    handleDeleteTag={(tagToDelete: string) => {
                                                                        const newTags = criteria?.searchkey?.filter(
                                                                            (tag) => tag !== tagToDelete
                                                                        );
                                                                        throttledHandleChange('searchkey', newTags, index);
                                                                    }}
                                                                />
                                                            </Grid>
                                                        )}

                                                        {/* Price Change */}
                                                        {criteria.fieldSearch == 'Price Range' && (
                                                            <Grid item md={5}>
                                                                <Stack direction={'row'} gap={1}>
                                                                    <FormControl fullWidth size="small">
                                                                        <OutlinedInput
                                                                            fullWidth
                                                                            id="min-price"
                                                                            type={'number'}
                                                                            name="min-price"
                                                                            placeholder="Min Price"
                                                                            value={criteria?.minprice}
                                                                            inputProps={{ min: 0 }}
                                                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                                                throttledHandleChange(
                                                                                    'minprice',
                                                                                    event.target.value,
                                                                                    index
                                                                                );
                                                                            }}
                                                                        />
                                                                    </FormControl>
                                                                    <FormControl fullWidth size="small">
                                                                        <OutlinedInput
                                                                            fullWidth
                                                                            id="max-price"
                                                                            type={'number'}
                                                                            name="max-price"
                                                                            placeholder="Max Price"
                                                                            inputProps={{ min: 0 }}
                                                                            value={criteria?.maxprice}
                                                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                                                throttledHandleChange(
                                                                                    'maxprice',
                                                                                    event.target.value,
                                                                                    index
                                                                                );
                                                                            }}
                                                                        />
                                                                    </FormControl>
                                                                </Stack>
                                                            </Grid>
                                                        )}

                                                        {/* Specifications  */}
                                                        {criteria.fieldSearch == 'Specifications' && (
                                                            <Grid item md={5}>
                                                                <Stack direction={'row'} gap={1}>
                                                                    <FormControl fullWidth size="small">
                                                                        <OutlinedInput
                                                                            fullWidth
                                                                            id="key-spec"
                                                                            name="key-spec"
                                                                            placeholder="Key"
                                                                            value={criteria?.specifications?.key}
                                                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                                                const specsValue = {
                                                                                    ...values.advancedFilter[index].specifications,
                                                                                    key: event.target.value
                                                                                };
                                                                                throttledHandleChange('specifications', specsValue, index);
                                                                            }}
                                                                        />
                                                                    </FormControl>

                                                                    <FormControl fullWidth size="small">
                                                                        <SearchTags
                                                                            tags={criteria?.specifications?.value || []}
                                                                            handleAddTag={(inputValue) => {
                                                                                const specsValue = criteria?.specifications?.value || [];

                                                                                const newTags = {
                                                                                    ...criteria.specifications,
                                                                                    value: [...specsValue, inputValue]
                                                                                };

                                                                                throttledHandleChange('specifications', newTags, index);
                                                                            }}
                                                                            handleDeleteTag={(tagToDelete: string) => {
                                                                                const newValue = criteria?.specifications?.value.filter(
                                                                                    (tag) => tag !== tagToDelete
                                                                                );

                                                                                const newTags = {
                                                                                    ...criteria.specifications,
                                                                                    value: newValue
                                                                                };

                                                                                throttledHandleChange('specifications', newTags, index);
                                                                            }}
                                                                            placeholder="value"
                                                                        />
                                                                    </FormControl>
                                                                </Stack>
                                                            </Grid>
                                                        )}

                                                        {/* Reviews  */}
                                                        {criteria.fieldSearch == 'Reviews' && (
                                                            <Grid item md={5}>
                                                                <Rating
                                                                    value={criteria?.reviews?.value}
                                                                    option={criteria?.reviews?.op?.code}
                                                                    handleChangeOption={(event: SelectChangeEvent) => {
                                                                        const reviewsValue = {
                                                                            ...values.advancedFilter[index].reviews,
                                                                            op: { code: event.target.value, name: '' }
                                                                        };
                                                                        throttledHandleChange('reviews', reviewsValue, index);
                                                                    }}
                                                                    handleChangeValue={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                                        const reviewsValue = {
                                                                            ...values.advancedFilter[index].reviews,
                                                                            value: event.target.value
                                                                        };
                                                                        throttledHandleChange('reviews', reviewsValue, index);
                                                                    }}
                                                                />
                                                            </Grid>
                                                        )}

                                                        {/* Rating */}
                                                        {criteria.fieldSearch == 'Rating' && (
                                                            <Grid item md={5}>
                                                                <Rating
                                                                    value={criteria?.reviews?.value}
                                                                    option={criteria?.reviews?.op?.code}
                                                                    handleChangeOption={(event: SelectChangeEvent) => {
                                                                        const reviewsValue = {
                                                                            ...values.advancedFilter[index].reviews,
                                                                            op: { code: event.target.value, name: '' }
                                                                        };
                                                                        throttledHandleChange('reviews', reviewsValue, index);
                                                                    }}
                                                                    handleChangeValue={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                                        const reviewsValue = {
                                                                            ...values.advancedFilter[index].reviews,
                                                                            value: event.target.value
                                                                        };
                                                                        throttledHandleChange('reviews', reviewsValue, index);
                                                                    }}
                                                                />
                                                            </Grid>
                                                        )}

                                                        {/* Remove filter Item */}
                                                        <Grid item md>
                                                            <IconButton
                                                                color="error"
                                                                onClick={() => handleRemove(index)}
                                                                disabled={values.advancedFilter.length === 1}
                                                                size="small"
                                                            >
                                                                <FiTrash />
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                ))}

                                                <Grid container spacing={2} style={{ marginTop: 16 }}>
                                                    <Grid item>
                                                        <Button type="button" variant="contained" onClick={handleAdd}>
                                                            Add
                                                        </Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button
                                                            type="button"
                                                            onClick={handleUndo}
                                                            variant="contained"
                                                            color="secondary"
                                                            disabled={currentIndex <= 0}
                                                        >
                                                            Undo
                                                        </Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button
                                                            type="button"
                                                            onClick={handleRedo}
                                                            variant="contained"
                                                            color="secondary"
                                                            disabled={currentIndex >= history.length - 1}
                                                        >
                                                            Redo
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </FieldArray>
                                </Form>
                            </TabPanel>
                            {otherRegionsData.map((itemRegion: any, index: number) => (
                                <TabPanel key={index} value={itemRegion.region.toLowerCase()}>
                                    <FormSearch rules={itemRegion.rules} handleCopyFilter={handleCopyFilter} />
                                </TabPanel>
                            ))}
                        </>
                    );
                }}
            </Formik>
        </TabContext>
    );
});

export default AdvancedSearch;
