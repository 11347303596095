import React from 'react';

interface Props {
    websiteId: string;
    name: string;
    websiteUrl?: string;
    height?: number;
    width?: number;
}

function WebsiteImage({ name, websiteId, websiteUrl = '', height = 17, width = 17 }: Props) {
    const handleImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
        event.currentTarget.src = `https://www.google.com/s2/favicons?domain_url=${websiteUrl}`;
    };

    return (
        <img
            src={`${process.env.PUBLIC_URL}/websites/${websiteId}.png`}
            onError={handleImageError}
            height={height}
            width={width}
            alt={name}
        />
    );
}

export default WebsiteImage;
