import { useState } from 'react';
import { Theme, Container, styled, Box, useMediaQuery } from '@mui/material';

import { Outlet, Location, useLocation } from 'react-router-dom';

import { useAppSelector } from 'store/hooks';
import { RootState } from 'store';
import { TopbarHeight } from 'theme/theme-variable';
import Header from './Header/Header';
import Sidebar from './SideBar/SideBar';
import Footer from './Footer/Footer';
import SidebarChat from './SideBar/SideBarChat';
import SideBarchatsDashboard from './SideBar/SideBarchatsDashboard';

const MainWrapper = styled('div')(() => ({
    display: 'flex',
    minHeight: '100vh',
    overflow: 'hidden',
    width: '100%'
}));

const PageWrapper = styled('div')(({ theme }: { theme: Theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',

    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.up('lg')]: {
        paddingTop: TopbarHeight
    },
    [theme.breakpoints.down('lg')]: {
        paddingTop: '64px'
    }
}));

export const chatDashboardAssitantUrl = '/assistant/dashboard';
export const chatAssitantUrlChat = '/assistant/chat';

function MainLayout() {
    const { isDark } = useAppSelector((state: RootState) => state.auth);
    const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
    const location: Location = useLocation();

    const [isOpenDesktopSideBar, setIsOpenDesktopSideBar] = useState<boolean>(location.pathname.includes('/assistant') ? false : true);
    const [isOpenDesktopSideBarChat, setIsOpenDesktopSideBarChat] = useState<boolean>(
        location.pathname.includes(chatAssitantUrlChat) ? true : false
    );
    const [isOpenDesktopSideBarchatsDashboard, setIsOpenDesktopSideBarchatsDashboard] = useState<boolean>(
        location.pathname.includes(chatDashboardAssitantUrl) ? true : false
    );
    const [isOpenMobileSideBar, setIsOpenMobileSideBar] = useState<boolean>(false);
    const [isOpenMobileSideBarChat, setIsOpenMobileSideBarChat] = useState<boolean>(false);
    const [isOpenMobileSideBarchatsDashboard, setIsOpenMobileSideBarchatsDashboard] = useState<boolean>(false);

    const handleOpenDesktopDrawer = () => {
        setIsOpenDesktopSideBar(!isOpenDesktopSideBar);
        setIsOpenDesktopSideBarChat(false);
        setIsOpenDesktopSideBarchatsDashboard(false);
    };

    const handleOpenDesktopDrawerChat = () => {
        setIsOpenDesktopSideBarChat(!isOpenDesktopSideBarChat);
        setIsOpenDesktopSideBar(false);
        setIsOpenDesktopSideBarchatsDashboard(false);
    };

    const handleOpenDesktopDrawerchatsDashboard = () => {
        setIsOpenDesktopSideBarchatsDashboard(!isOpenDesktopSideBarchatsDashboard);
        setIsOpenDesktopSideBar(false);
        setIsOpenDesktopSideBarChat(false);
    };

    const handleCloseDesktopDrawer = () => {
        setIsOpenDesktopSideBar(false);
    };

    const handleCloseDesktopDrawerChat = () => {
        setIsOpenDesktopSideBarChat(false);
    };

    const handleCloseDesktopDrawerchatsDashboard = () => {
        setIsOpenDesktopSideBarchatsDashboard(false);
    };

    const handleOpenMobileDrawer = () => {
        setIsOpenMobileSideBar(true);
    };

    const handleCloseMobileDrawer = () => {
        setIsOpenMobileSideBar(false);
    };

    const handleOpenMobileDrawerChat = () => {
        setIsOpenMobileSideBarChat(true);
    };

    const handleOpenMobileDrawerchatsDashboard = () => {
        setIsOpenMobileSideBarchatsDashboard(true);
    };

    const handleCloseMobileDrawerChat = () => {
        setIsOpenMobileSideBarChat(false);
    };

    const handleCloseMobileDrawerchatsDashboard = () => {
        setIsOpenMobileSideBarchatsDashboard(false);
    };

    return (
        <MainWrapper>
            <Header
                sx={{
                    paddingLeft: isOpenDesktopSideBar && lgUp ? '0' : '',
                    backgroundColor: isDark ? '#20232a' : '#ffffff',
                    boxShadow: '0px 7px 30px 0px rgb(90 114 123 / 11%)'
                }}
                handleChangeDesktopDrawer={handleOpenDesktopDrawer}
                handleChangeDesktopDrawerChat={handleOpenDesktopDrawerChat}
                handleChangeDesktopDrawerchatsDashboard={handleOpenDesktopDrawerchatsDashboard}
                handleOpenMobileDrawer={handleOpenMobileDrawer}
                handleOpenMobileDrawerChat={handleOpenMobileDrawerChat}
                handleOpenMobileDrawerchatsDashboard={handleOpenMobileDrawerchatsDashboard}
                handleCloseMobileDrawer={handleCloseMobileDrawer}
                handleCloseMobileDrawerChat={handleCloseMobileDrawerChat}
                handleCloseMobileDrawerchatsDashboard={handleCloseMobileDrawerchatsDashboard}
                handleCloseDesktopDrawer={handleCloseDesktopDrawer}
                handleCloseDesktopDrawerChat={handleCloseDesktopDrawerChat}
                handleCloseDesktopDrawerchatsDashboard={handleCloseDesktopDrawerchatsDashboard}
            />

            <Sidebar
                isSidebarOpen={isOpenDesktopSideBar}
                isMobileSidebarOpen={isOpenMobileSideBar}
                handleCloseMobileDrawer={handleCloseMobileDrawer}
            />

            {(isOpenDesktopSideBarChat || isOpenMobileSideBarChat) && (
                <SidebarChat
                    isSidebarOpen={isOpenDesktopSideBarChat}
                    isMobileSidebarOpen={isOpenMobileSideBarChat}
                    handleCloseMobileDrawer={handleCloseMobileDrawerChat}
                />
            )}

            {(isOpenDesktopSideBarchatsDashboard || isOpenMobileSideBarchatsDashboard) && (
                <SideBarchatsDashboard
                    isSidebarOpen={isOpenDesktopSideBarchatsDashboard}
                    isMobileSidebarOpen={isOpenMobileSideBarchatsDashboard}
                    handleCloseMobileDrawer={handleCloseMobileDrawerchatsDashboard}
                />
            )}

            <PageWrapper>
                <Container
                    maxWidth={false}
                    sx={{
                        paddingTop: '20px',
                        paddingLeft:
                            (isOpenDesktopSideBar || isOpenDesktopSideBarChat || isOpenDesktopSideBarchatsDashboard) && lgUp
                                ? '310px!important'
                                : ''
                        // paddingRight: isOpenDesktopSideBarChat && lgUp ? '310px!important' : ''
                    }}
                >
                    <Box sx={{ minHeight: 'calc(100vh - 170px)' }}>
                        <Outlet />
                    </Box>
                    <Footer />
                </Container>
            </PageWrapper>
        </MainWrapper>
    );
}

export default MainLayout;
