import { Fragment, SyntheticEvent } from 'react';
import { Card, CardContent, FormControl, Grid, MenuItem, Select, SelectChangeEvent } from '@mui/material';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
    updateListingModuleValue,
    updateListingProductGroupValue,
    updateListingWizardField,
    updateListingWizardThirdPartyValue,
    updateQueryListingModule,
    updateQueryListingProductGroup,
    updateQueryListingWizardThirdParty
} from 'store/slices/filter/customFilterSlice';
import { submitFilter } from 'store/slices/filter/filterSlice';
import ThirdPartyCategory from '../field/ThirdPartyCategory';
import SingleModule from '../field/SingleModule';
import SingleProductGroup from '../field/SingleProductGroup';

function ListingWizard() {
    const dispatch = useAppDispatch();

    const {
        listingWizardField,
        listingModuleValue,
        queryListingModule,
        listingProductGroupValue,
        queryListingProductGroup,
        listingWizardThirdPartyValue,
        queryListingWizardThirdParty
    } = useAppSelector((state) => state.customFilter);
    const { selectedRegion, accessAccountUser } = useAppSelector((state) => state.auth);

    const handleChange = (event: SelectChangeEvent<any>) => {
        dispatch(updateListingWizardField(event.target.value));

        dispatch(updateListingProductGroupValue(null));
        dispatch(updateListingModuleValue(null));
        dispatch(updateListingWizardThirdPartyValue(null));
    };

    const handleInputChangeModule = (q: string) => {
        dispatch(updateQueryListingModule(q));
    };

    const handleChangeModule = (option: any) => {
        dispatch(updateListingModuleValue(option));
        dispatch(submitFilter());
    };

    const handleInputChangeProductGroup = (q: string) => {
        dispatch(updateQueryListingProductGroup(q));
    };

    const handleChangeProductGroup = (option: any) => {
        dispatch(updateListingProductGroupValue(option));
        dispatch(submitFilter());
    };

    const handleInputChangeThirdParty = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch(updateQueryListingWizardThirdParty(event.target.value));
    };

    const handleChangeThirdParty = (event: SyntheticEvent<Element, Event>, value: any) => {
        dispatch(updateListingWizardThirdPartyValue(value));
        dispatch(submitFilter());
    };

    const currentAccount: any = accessAccountUser.find((item: any) => item.account.config.region == selectedRegion);

    return (
        <Fragment>
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Grid container spacing={2} justifyContent={'center'} alignItems={'center'}>
                            <Grid item xs={12} md={2}>
                                <FormControl fullWidth size="small">
                                    <Select displayEmpty value={listingWizardField} onChange={handleChange}>
                                        <MenuItem value={'Module'}>MM Module</MenuItem>
                                        <MenuItem value={'Product Group'}>MM Product Group</MenuItem>
                                        {currentAccount?.account?.config?.thirdparties === true && (
                                            <MenuItem value={'3rd Party Category'}>3rd Party Category</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            {listingWizardField == 'Module' && (
                                <Grid item xs={12} md={10}>
                                    <SingleModule
                                        isCustomModule
                                        mmsModuleValue={listingModuleValue}
                                        customQuery={queryListingModule}
                                        customHandleInputChange={handleInputChangeModule}
                                        customHandleChange={handleChangeModule}
                                    />
                                </Grid>
                            )}
                            {listingWizardField == 'Product Group' && (
                                <Grid item xs={12} md={10}>
                                    <SingleProductGroup
                                        isCustomGroup
                                        productGroupValue={listingProductGroupValue}
                                        customQuery={queryListingProductGroup}
                                        customHandleInputChange={handleInputChangeProductGroup}
                                        customHandleChange={handleChangeProductGroup}
                                    />
                                </Grid>
                            )}

                            {listingWizardField == '3rd Party Category' && (
                                <Grid item xs={12} md={10}>
                                    <ThirdPartyCategory
                                        value={listingWizardThirdPartyValue}
                                        customQuery={queryListingWizardThirdParty}
                                        customHandleInputChange={handleInputChangeThirdParty}
                                        customHandleChange={handleChangeThirdParty}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Fragment>
    );
}

export default ListingWizard;
