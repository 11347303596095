import { Stack, Rating, Typography } from '@mui/material';

interface RatingProps {
    count: number | null;
    value: number | null;
    singleRating?: boolean;
}
function RatingProduct({ count, value }: RatingProps) {
    return (
        <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <Rating name="read-only" value={value} readOnly precision={0.5} size="small" />
            <Typography fontSize={12} fontWeight={600} variant="body2" color="text.secondary">
                {`${value?.toFixed(2)} - ${count} reviews`}
            </Typography>
        </Stack>
    );
}

export default RatingProduct;
