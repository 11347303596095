import { useEffect, useState, Fragment } from 'react';
import { Box, Button, Typography } from '@mui/material';

import { useLazyQuery } from '@apollo/client';

import ProductsGridView from 'components/product/ProductsGridView';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { RootState } from 'store';
import ProductsLoading from 'components/common/skeletons/ProductsLoading';
import { unSubmitFilter } from 'store/slices/filter/filterSlice';
import getFileNameFromSearch from 'utils/getFileNameFromSearch';
import { useNotification } from 'hooks/useNotification';
import ExportModal from 'components/common/modals/ExportModal';
import { addNewPriceAlertTracker, exportDataRequest } from 'services/settings/queries';
import ProductsMainContainer, { SortingLabelType } from 'components/cards/ProductsMainContainer';
import PaginationProduct from 'components/product/PaginationProduct';
import { defaultSorting, sortingLabels } from 'constants/sortings';
import { RELEVANT_PRODUCTS_QUERY } from 'services/catalog/queries';

function BetterWayProducts() {
    const dispatch = useAppDispatch();
    const { displayNotification } = useNotification();

    const { selectedRegion, defaultWebsite, accessAccountUser, userInfo, selectedWebsites, selectedBrands } = useAppSelector(
        (state: RootState) => state.auth
    );
    const { checkFilter, isSubmitting, inputList } = useAppSelector((state: RootState) => state.filter);

    const currentAccess: any[] = accessAccountUser.filter((item: any) => item.account.config.region == selectedRegion);
    const accountData =
        currentAccess.length &&
        currentAccess[0].account.components.find((item: { name: string; allowed: boolean }) => item.name == 'brandslisting');
    const isBrandAcount = accountData ? accountData.allowed : false;

    const [exportVariable, setExportVariable] = useState<any>({});
    const [products, setProducts] = useState([]);
    const [totalProduct, setTotalProduct] = useState<number | null>(null);
    const [totalIndex, setTotalIndex] = useState<number | null>(null);
    const [currentPagination, setCurrentPagination] = useState<number>(1);
    const [openExport, setOpenExport] = useState<boolean>(false);
    const [fileName, setFileName] = useState<string>('Relevant products');
    const [queryCacheId, setQueryCacheId] = useState<string>('');
    const [sort, setSort] = useState<string>('positiveRelevence:desc');
    const [isRefresh, setIsRefresh] = useState<boolean>(false);

    const [getRelevantProducts, { loading, data, error, refetch }] = useLazyQuery(RELEVANT_PRODUCTS_QUERY, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache'
    });

    const handleChangePagination = (event: React.ChangeEvent<unknown>, page: number) => {
        setCurrentPagination(page);

        if (totalProduct && Math.ceil(totalProduct / 21) == page) {
            refetch({
                from: (page - 1) * 21,
                size: totalProduct - (page - 1) * 21,
                hidelost: true
            });
        } else
            refetch({
                from: (page - 1) * 21,
                size: 21,
                hidelost: true
            });
    };

    const handleApply = () => {
        setIsRefresh(false);
        setCurrentPagination(1);
        setExportVariable({
            ...exportVariable,
            betterway: true,
            advancedSearch: inputList,
            hideBundles: checkFilter.bundles,
            hideRefurbished: checkFilter.refurbished,
            hideMarketplace: checkFilter.hideMarketplace,
            instock: checkFilter.instock,
            region: selectedRegion,
            website: defaultWebsite,
            websites: selectedWebsites,
            sort: [sort],
            loadGfk: true,
            hidelost: true,
            access: currentAccess.length ? currentAccess[0].id : '',
            config: currentAccess.length ? currentAccess[0].account.config : {},
            reviewedAccount: currentAccess.length ? currentAccess[0].account.id : '',
            loadReviewed: true,
            brands: selectedBrands.map((el: any) => el.name).join(',')
        });
        refetch({
            betterway: true,
            advancedSearch: inputList,
            hideBundles: checkFilter.bundles,
            hideRefurbished: checkFilter.refurbished,
            hideMarketplace: checkFilter.hideMarketplace,
            hidelost: true,
            instock: checkFilter.instock,
            from: 0,
            size: 21,
            region: selectedRegion,
            website: defaultWebsite,
            websites: selectedWebsites,
            sort: [sort],
            loadGfk: true,
            access: currentAccess.length ? currentAccess[0].id : '',
            config: currentAccess.length ? currentAccess[0].account.config : {},
            reviewedAccount: currentAccess.length ? currentAccess[0].account.id : '',
            loadReviewed: true,
            brands: selectedBrands.map((el: any) => el.name).join(',')
        });
    };

    // sorting
    const sortingHandler = (sort: SortingLabelType) => {
        setCurrentPagination(1);
        setSort(`${sort.field}:${sort.direction}`);
        setExportVariable({
            ...exportVariable,
            sort: [`${sort.field}:${sort.direction}`]
        });
        refetch({
            sort: [`${sort.field}:${sort.direction}`],
            from: 0,
            size: 21
        });
    };

    const exportHandler = () => {
        const currentFileName = getFileNameFromSearch({ inputsList: inputList }, 'Relevant data');
        setFileName(currentFileName);
        setOpenExport(true);
    };

    const handleFilNameChanged = (file: string) => {
        setFileName(file);
    };

    const priceNotifHandler = async (percentage: number, emails: string[]) => {
        const result = await addNewPriceAlertTracker(
            displayNotification,
            selectedRegion,
            percentage,
            emails,
            'relevantdata',
            userInfo?.id,
            {
                filter: exportVariable
            }
        );
        return result?.id;
    };

    useEffect(() => {
        setExportVariable({
            ...exportVariable,
            betterway: true,
            region: selectedRegion,
            website: defaultWebsite,
            websites: selectedWebsites,
            hideBundles: checkFilter.bundles,
            hideRefurbished: checkFilter.refurbished,
            hideMarketplace: checkFilter.hideMarketplace,
            hidelost: true,
            advancedSearch: inputList,
            sort: ['positiveRelevence:desc'],
            loadGfk: true,
            access: currentAccess.length ? currentAccess[0].id : '',
            config: currentAccess.length ? currentAccess[0].account.config : {},
            reviewedAccount: currentAccess.length ? currentAccess[0].account.id : '',
            loadReviewed: true,
            brands: selectedBrands.map((el: any) => el.name).join(',')
        });
        getRelevantProducts({
            variables: {
                betterway: true,
                region: selectedRegion,
                website: defaultWebsite,
                websites: selectedWebsites,
                hideBundles: checkFilter.bundles,
                hideRefurbished: checkFilter.refurbished,
                hideMarketplace: checkFilter.hideMarketplace,
                hidelost: true,
                from: 0,
                size: 21,
                advancedSearch: inputList,
                sort: ['positiveRelevence:desc'],
                loadGfk: true,
                access: currentAccess.length ? currentAccess[0].id : '',
                config: currentAccess.length ? currentAccess[0].account.config : {},
                reviewedAccount: currentAccess.length ? currentAccess[0].account.id : '',
                loadReviewed: true,
                brands: selectedBrands.map((el: any) => el.name).join(',')
            }
        });
    }, []);

    useEffect(() => {
        if (isSubmitting) {
            handleApply();
        }
        dispatch(unSubmitFilter());
    }, [isSubmitting]);

    useEffect(() => {
        if (data) {
            const productsData = isBrandAcount
                ? data.relevantProducts.data.map((item: any) => {
                      return {
                          ...item,
                          productMatch: {
                              ...item.productMatch,
                              products: item.productMatch.products
                          }
                      };
                  })
                : data.relevantProducts.data;

            setProducts(productsData);
            setQueryCacheId(data.relevantProducts.queryHash);
            setTotalIndex(data.relevantProducts.totalIndex);
            setTotalProduct(data.relevantProducts.total);
        }
    }, [loading]);

    if (error)
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 300 }}>
                <Typography>An error occurred. Please try again later.</Typography>
            </Box>
        );

    return (
        <ProductsMainContainer
            headerTitle="Products list"
            query="relevantdata"
            exportVariable={exportVariable}
            sortingLabels={sortingLabels}
            defaultSorting={defaultSorting}
            showDownload
            showReload
            showSortingLabels
            showPriceNotifications
            total={totalIndex}
            handleExport={exportHandler}
            handleReload={handleApply}
            handleSorting={sortingHandler}
            handlePriceNotif={async (percentage: number, emails: string[]) => {
                return await priceNotifHandler(percentage, emails);
            }}
        >
            {loading ? (
                <ProductsLoading />
            ) : (
                <Fragment>
                    <ProductsGridView
                        queryCacheId={queryCacheId}
                        products={products}
                        config={currentAccess.length ? currentAccess[0].account.config : {}}
                    />

                    <ExportModal
                        open={openExport}
                        fileName={fileName}
                        handleNameChanged={handleFilNameChanged}
                        handleExport={() => {
                            exportDataRequest(
                                displayNotification,
                                setOpenExport,
                                'relevantProducts',
                                RELEVANT_PRODUCTS_QUERY,
                                'relevant',
                                fileName,
                                userInfo?.id,
                                exportVariable
                            );
                        }}
                        handleClose={() => {
                            setOpenExport(false);
                        }}
                    />

                    <Box sx={{ mt: 2 }}>
                        <PaginationProduct
                            currentPagination={currentPagination}
                            totalProduct={totalProduct}
                            handleChangePagination={handleChangePagination}
                            notFountComponent={
                                isRefresh ? null : (
                                    <Fragment>
                                        <Typography align="center" maxWidth={500}>
                                            Products Not Found in market (In Last 60days) if you need to search for it in our full database
                                            please hit refresh
                                        </Typography>
                                        <Button
                                            variant="outlined"
                                            color="info"
                                            sx={{ mt: 2 }}
                                            onClick={() => {
                                                setIsRefresh(true);
                                                refetch({
                                                    from: 0,
                                                    size: 21,
                                                    hidelost: false
                                                });
                                            }}
                                        >
                                            Load From Full Database
                                        </Button>
                                    </Fragment>
                                )
                            }
                        />
                    </Box>
                </Fragment>
            )}
        </ProductsMainContainer>
    );
}

export default BetterWayProducts;
