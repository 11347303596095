import React, { useState, useEffect } from 'react';
import { Autocomplete, CircularProgress, Stack, TextField } from '@mui/material';
import { useLazyQuery } from '@apollo/client';

import { GOOGLE_CATEGORY } from 'services/filterService/queries';
import { useAppSelector } from 'store/hooks';
import SearchTags from '../instant/SearchTags';

interface GoogleCategoryWebsiteSelectProps {
    tags: string[];
    onSelectCategory: (option: any) => void;
    handleAddTag: (inputValue: string) => void;
    handleDeleteTag: (tagToDelete: string) => void;
}

function GoogleCategoryWebsiteSelect({ tags, onSelectCategory, handleAddTag, handleDeleteTag }: GoogleCategoryWebsiteSelectProps) {
    const { selectedRegion } = useAppSelector((state) => state.auth);
    const [currentCategory, setCurrentCategory] = useState<any>(null);
    const [query, setQuery] = useState<string | null>(null);
    const [websiteCategoryData, setWebsiteCategoryData] = useState<any[]>([]);

    const [googleCategoryload, { loading, data }] = useLazyQuery(GOOGLE_CATEGORY, {
        notifyOnNetworkStatusChange: true
    });

    const handleChange = (event: React.SyntheticEvent, option: any) => {
        setCurrentCategory(option);
        onSelectCategory(option);
        setCurrentCategory(null);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setQuery(event.target.value);
    };

    useEffect(() => {
        if (query) {
            googleCategoryload({
                variables: {
                    region: selectedRegion,
                    q: query
                }
            });
        }
    }, [query]);

    useEffect(() => {
        if (data) {
            const list = data.googlecategoriessearch.categories.map((cc: any) => {
                return {
                    ...cc,
                    id: cc.ref,
                    website: {
                        alias: 'Google',
                        id: '5af5ccfa76480c7a287fa6ee',
                        name: 'Google',
                        url: `https://www.google.${selectedRegion ? selectedRegion.toLowerCase() : 'es'}`
                    },
                    name: `${cc.ref} - ${cc.name}`,
                    parents:
                        cc.parents && cc.parents.length
                            ? cc.parents.map((p: any) => {
                                  return { ...p, id: p.ref, url: '/search?tbm=shop&q=' + p.name };
                              })
                            : [],
                    url: '/search?tbm=shop&q=' + cc.name
                };
            });
            setWebsiteCategoryData(list);
        }
    }, [loading]);

    return (
        <Stack direction={'column'} spacing={1}>
            <Autocomplete
                id="tags-standard"
                size="small"
                value={currentCategory}
                options={websiteCategoryData}
                loading={loading}
                disablePortal
                fullWidth
                disableClearable
                onChange={handleChange}
                getOptionLabel={(option: any) => (option?.breadcrumbs ? option.breadcrumbs : '')}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        value={query}
                        onChange={handleInputChange}
                        placeholder={'Google Category'}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </>
                            )
                        }}
                    />
                )}
            />
            <SearchTags
                tags={tags}
                handleAddTag={handleAddTag}
                handleDeleteTag={handleDeleteTag}
                placeholder="Product Name / Specifications"
            />
        </Stack>
    );
}

export default GoogleCategoryWebsiteSelect;
