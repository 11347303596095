import { Box, Stack, Typography } from '@mui/material';
import { Region, regionCurrency } from 'constants/region-currency/regionCurrency';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { resetInputListItem, submitFilter, updateGoogleCategoryQuery } from 'store/slices/filter/filterSlice';
import { nFormatter } from 'utils/nFormatter';

interface AmazonSalesProps {
    amazonPosition?: number;
    amazonPositionInCategory?: number;
    googleCategory?: any;
    amazonSalesValue?: string;
    amazonUnit?: string;
    amazonMonth?: string;
    isModal?: boolean;
}

function AmazonSales({ amazonPositionInCategory, googleCategory, amazonSalesValue, amazonUnit, amazonMonth, isModal }: AmazonSalesProps) {
    const dispatch = useAppDispatch();
    const { selectedRegion } = useAppSelector((state: RootState) => state.auth);

    const currentRegionConfig = regionCurrency[selectedRegion?.toLowerCase() as Region];

    const formatGFKDate = (str?: string) => {
        if (str) {
            const num = str.replace(/[^0-9]/g, '');
            const month = str.replace(num, '');
            return `${month} 20${num}`;
        }
        return '?';
    };

    const handleClickGoogleCategory = () => {
        if (isModal) return;

        const breadcrumbs = `${googleCategory?.ref} - ${googleCategory.name} > ${googleCategory.parents
            .map((item: { name: string }) => `${item.name}`)
            .join(' > ')}`;

        dispatch(updateGoogleCategoryQuery(googleCategory.ref));
        dispatch(
            resetInputListItem({
                data: {
                    id: '1',
                    fieldSearch: 'Google Category',
                    checkOption: 'Is',
                    includeExclude: 'Include',
                    // google: googleCategory && googleCategory.ref ? { ...googleCategory, breadcrumbs } : undefined
                    google: googleCategory && googleCategory.ref ? [{ ...googleCategory, breadcrumbs }] : undefined
                }
            })
        );
        dispatch(submitFilter());
    };

    const formatter = new Intl.NumberFormat(currentRegionConfig.ISO, {
        style: 'currency',
        currency: currentRegionConfig.currency,
        minimumFractionDigits: 0
    });

    const curruncy = formatter.format(0).replace(/\d+/g, '').trim();

    return (
        <Stack
            direction={'row'}
            alignItems={'center'}
            sx={{ border: '1px solid rgba(238, 240, 241, 1)', backgroundColor: 'rgba(238, 240, 241, 1)', borderRadius: 1, p: 0.5 }}
        >
            <Box
                sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: '1px solid #f96654',
                    borderRadius: 1,
                    background: '#f96654',
                    height: '100%',
                    p: '10px',
                    minWidth: '70px'
                }}
            >
                <Typography
                    sx={{ justifyContent: 'center', alignItems: 'center' }}
                    variant="body2"
                    fontSize={12}
                    fontWeight={600}
                    color="white"
                    align="center"
                >
                    Amazon
                </Typography>
                <Typography
                    sx={{ justifyContent: 'center', alignItems: 'center' }}
                    variant="body2"
                    fontSize={12}
                    fontWeight={600}
                    color="white"
                    align="center"
                >
                    Sales
                </Typography>
            </Box>

            <Box sx={{ p: 0.5 }}>
                <Typography variant="body2" fontSize={12} color={'black'}>
                    Ranked <strong style={{ color: '#f96654', fontWeight: 600 }}>{amazonPositionInCategory}</strong> in{' '}
                    <strong onClick={handleClickGoogleCategory} style={{ color: '#f96654', fontWeight: 600, cursor: 'pointer' }}>
                        {googleCategory?.name}
                    </strong>
                </Typography>
                {amazonSalesValue && (
                    <Typography variant="body2" fontSize={12} color={'black'}>
                        With <strong className="marketvalue">{nFormatter(parseFloat(amazonSalesValue)) + ' ' + curruncy} </strong> for (
                        <span className="marketvalue">{parseInt(amazonUnit || '')}</span> Units) -{' '}
                        <span className="marketvalue">{formatGFKDate(amazonMonth)}</span>
                    </Typography>
                )}
            </Box>
        </Stack>
    );
}

export default AmazonSales;
