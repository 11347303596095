import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { Button, Card, Checkbox, FormControlLabel, Grid, Link, Stack, Typography, useTheme } from '@mui/material';

import { FieldArray, Form, Formik } from 'formik';
import { throttle } from 'lodash';

import Scrollbar from 'components/common/UI/ScrollBar';
import WebsiteImage from 'components/product/Websites/WebsiteImage';
import WebsiteCategoryItem from './WebsiteCategoryItem';
import GoogleCategoryWebsiteSelect from './GoogleCategoryWebsiteSelect';

const initialValues: { websitesCategories: any[] } = {
    websitesCategories: []
};

const WebsiteCategory = forwardRef(function WebsiteCategory({ websitesCategoriesData }: { websitesCategoriesData: any }, ref) {
    const theme = useTheme();

    const mmsFilterCache = localStorage.getItem('cache-mms-website-category');
    const cacheData =
        mmsFilterCache && JSON.parse(mmsFilterCache)?.value ? [{ websitesCategories: JSON.parse(mmsFilterCache)?.value }] : [];

    const [history, setHistory] = useState<any[]>(cacheData);
    const [currentIndex, setCurrentIndex] = useState<number>(0);

    const saveState = useCallback(
        (values: any) => {
            const newHistory: any = history.slice(0, currentIndex + 1);
            newHistory.push(values);
            setHistory(newHistory);
            setCurrentIndex(newHistory.length - 1);
        },
        [history, currentIndex]
    );

    const throttledSaveState = useCallback(throttle(saveState, 1000), [saveState]);

    const handleUndo = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    const handleRedo = () => {
        if (currentIndex < history.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
            }}
            enableReinitialize={true}
            validateOnBlur={false}
            validateOnChange={false}
            validateOnMount={false}
        >
            {({ values, setFieldValue, setValues }) => {
                const throttledHandleChangeWebsiteCategory = throttle((field, value, index) => {
                    const newValues = {
                        websitesCategories: values.websitesCategories.map((item: any, idx) =>
                            idx === index ? { ...item, [field]: value } : item
                        )
                    };

                    setFieldValue(`websitesCategories.${index}.${field}`, value, false);
                    throttledSaveState(newValues);
                }, 1000);

                const getData = () => {
                    return values;
                };

                useEffect(() => {
                    if (currentIndex >= 0 && currentIndex < history.length) {
                        setValues(history[currentIndex]);
                    }
                }, [currentIndex, history, setValues]);

                useEffect(() => {
                    setFieldValue('websitesCategories', websitesCategoriesData, false);
                    throttledSaveState({
                        websitesCategories: websitesCategoriesData
                    });
                }, []);

                useImperativeHandle(ref, () => ({
                    getData
                }));

                return (
                    <Form>
                        <FieldArray name="websitesCategories">
                            {() => (
                                <Grid container spacing={2}>
                                    {values.websitesCategories.map((websiteCategory: any, index) => (
                                        <Grid item xs={12} md={4} key={index}>
                                            <Card variant="outlined" sx={{ m: 0, backgroundColor: 'transparent' }}>
                                                <Scrollbar style={{ height: 350 }}>
                                                    <Stack direction="column" spacing={1}>
                                                        <Stack direction="row" alignItems={'center'} spacing={1}>
                                                            <WebsiteImage
                                                                name={websiteCategory.website.name}
                                                                websiteId={websiteCategory?.website?.id}
                                                                websiteUrl={websiteCategory?.website?.url}
                                                                height={20}
                                                                width={20}
                                                            />
                                                            <Link
                                                                underline="hover"
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                href={websiteCategory?.website?.url}
                                                                variant="h5"
                                                                color={theme.palette.secondary.main}
                                                            >
                                                                {websiteCategory?.website?.name}
                                                            </Link>
                                                        </Stack>
                                                        {websiteCategory.website.alias != 'Google' && (
                                                            <WebsiteCategoryItem
                                                                onSelectCategory={(value) => {
                                                                    const newList = [
                                                                        ...(values?.websitesCategories[index]?.list ?? []),
                                                                        {
                                                                            category: value,
                                                                            check: false
                                                                        }
                                                                    ];

                                                                    throttledHandleChangeWebsiteCategory('list', newList, index);
                                                                }}
                                                                tags={websiteCategory.keywords || []}
                                                                website={websiteCategory.website}
                                                                handleAddTag={(inputValue: string) => {
                                                                    const newKeywords = [
                                                                        ...(values?.websitesCategories[index]?.keywords ?? []),
                                                                        inputValue
                                                                    ];

                                                                    throttledHandleChangeWebsiteCategory('keywords', newKeywords, index);
                                                                }}
                                                                handleDeleteTag={(tagToDelete: string) => {
                                                                    const newKeywords = values?.websitesCategories[index]?.keywords?.filter(
                                                                        (tag: string) => tag !== tagToDelete
                                                                    );

                                                                    throttledHandleChangeWebsiteCategory('keywords', newKeywords, index);
                                                                }}
                                                            />
                                                        )}
                                                        {/* <!-- competitors search --> */}
                                                        {websiteCategory.website.alias == 'Google' && (
                                                            <GoogleCategoryWebsiteSelect
                                                                tags={websiteCategory.keywords || []}
                                                                onSelectCategory={(value: any) => {
                                                                    const newList = [
                                                                        ...(values?.websitesCategories[index]?.list ?? []),

                                                                        {
                                                                            category: value,
                                                                            check: false
                                                                        }
                                                                    ];

                                                                    throttledHandleChangeWebsiteCategory('list', newList, index);
                                                                }}
                                                                handleAddTag={(inputValue: string) => {
                                                                    const newKeywords = [
                                                                        ...(values?.websitesCategories[index]?.keywords ?? []),
                                                                        inputValue
                                                                    ];

                                                                    throttledHandleChangeWebsiteCategory('keywords', newKeywords, index);
                                                                }}
                                                                handleDeleteTag={(tagToDelete: string) => {
                                                                    const newKeywords = values?.websitesCategories[index]?.keywords?.filter(
                                                                        (tag: string) => tag !== tagToDelete
                                                                    );

                                                                    throttledHandleChangeWebsiteCategory('keywords', newKeywords, index);
                                                                }}
                                                            />
                                                        )}
                                                        {/* List of categories selection */}
                                                        <Stack direction={'column'} spacing={0.3}>
                                                            {websiteCategory.list.map((item: any, categoryIndex: number) => (
                                                                <Stack
                                                                    direction={'row'}
                                                                    alignItems={'center'}
                                                                    spacing={1}
                                                                    key={categoryIndex}
                                                                    sx={{ width: '100%' }}
                                                                >
                                                                    <Checkbox
                                                                        checked={item.check}
                                                                        onChange={(event) => {
                                                                            const newList = values?.websitesCategories[index].list?.map(
                                                                                (listItem: any, idx: number) => {
                                                                                    if (idx === categoryIndex) {
                                                                                        return {
                                                                                            ...listItem,
                                                                                            check: event.target.checked
                                                                                        };
                                                                                    } else return listItem;
                                                                                }
                                                                            );

                                                                            throttledHandleChangeWebsiteCategory('list', newList, index);
                                                                        }}
                                                                        size="small"
                                                                        color="secondary"
                                                                    />
                                                                    <Link
                                                                        color="secondary"
                                                                        underline="hover"
                                                                        variant="body2"
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        href={websiteCategory?.website?.url + item?.category?.url}
                                                                        sx={{
                                                                            textOverflow: 'ellipsis',
                                                                            width: '70%'
                                                                        }}
                                                                    >
                                                                        {item.category.name}
                                                                    </Link>
                                                                    <Typography variant="subtitle2">({item.count})</Typography>
                                                                </Stack>
                                                            ))}
                                                        </Stack>
                                                    </Stack>
                                                </Scrollbar>
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>
                            )}
                        </FieldArray>

                        <Grid container spacing={0} sx={{ mt: 2 }}>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="secondary"
                                            size="small"
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                                                const newValues = values.websitesCategories.map((item: any) => {
                                                    return {
                                                        ...item,
                                                        list: item.list.map((listItem: any) => {
                                                            return {
                                                                ...listItem,
                                                                check: checked
                                                            };
                                                        })
                                                    };
                                                });
                                                setFieldValue('websitesCategories', newValues, false);
                                                throttledSaveState({ websitesCategories: newValues });
                                            }}
                                        />
                                    }
                                    label="Select All"
                                />
                            </Grid>
                            <Grid item>
                                <Stack direction="row" spacing={2}>
                                    <Button
                                        type="button"
                                        onClick={handleUndo}
                                        variant="contained"
                                        color="secondary"
                                        disabled={currentIndex <= 0}
                                    >
                                        Undo
                                    </Button>
                                    <Button
                                        type="button"
                                        onClick={handleRedo}
                                        variant="contained"
                                        color="secondary"
                                        disabled={currentIndex >= history.length - 1}
                                    >
                                        Redo
                                    </Button>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        </Formik>
    );
});

export default WebsiteCategory;
