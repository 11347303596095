export const betterWayChart = {
    color: ['#DB4D3B', '#464C5B'],
    legend: {
        selectedMode: true
    },
    tooltip: {
        trigger: 'axis',
        axisPointer: {
            type: 'shadow'
        }
    },
    toolbox: {
        show: true,
        orient: 'vertical',
        left: 'right',
        top: 'center',
        feature: {
            dataView: { show: true, readOnly: false },
            restore: { show: true },
            saveAsImage: { show: true }
        }
    },
    grid: {
        left: 100,
        right: 100,
        top: 50,
        bottom: 50
    },
    yAxis: {
        type: 'value'
    },
    xAxis: {
        type: 'category',
        data: []
    },
    series: []
};
