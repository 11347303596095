import { gql } from '@apollo/client';
import appoloClient from 'common/appolo-client';

/**
 * Exportations queries
 */
export const EXPORT_LIST = gql`
    query exportList($user: String!, $size: Int, $from: Int, $region: String) {
        exportList(user: $user, from: $from, size: $size, region: $region) {
            error
            exportations
        }
    }
`;
export const exportDataRequest = async (
    displayNotification: (obj: any) => void,
    setOpenExport: (close: boolean) => void,
    exportType: any,
    query: any,
    task: string,
    fileName: string,
    user: string,
    variabales: any
) => {
    const response = await appoloClient.query({
        query: query,
        variables: { ...variabales, export: true, task, fileName, user }
    });

    if (!response.data[`${exportType}`]) throw new Error('Somthing went wrong');
    setOpenExport(false);
    const result = response.data[`${exportType}`].exportResponse;
    if (!result) {
        displayNotification({
            message: `Sorry Something bad happend during download ! Please contact us to help !`,
            type: 'error',
            horizontal: 'right'
        });
    } else {
        displayNotification({
            message: `Success :) File will be avaiable in your <a href="/exportationlist" target="_blank">Export List</a> with name ${fileName} !`,
            type: 'success',
            horizontal: 'right'
        });
    }
    return response.data[`${exportType}`].exportResponse;
};

/**
 * Download files queries
 */
export const DOWNLOAD_FILE = gql`
    query downloadFile($region: String!, $fileName: String, $type: String, $id: String) {
        downloadFile(region: $region, fileName: $fileName, type: $type, id: $id)
    }
`;

/**
 * Price Alert queries
 */
export const newPriceAlertTrackerMutation = gql`
    mutation newPriceAlertTrackerMutation(
        $region: String!
        $query: String!
        $user: String!
        $filter: JSON!
        $percentage: Float!
        $emails: [String!]
    ) {
        newPriceAlertTrackerMutation(
            region: $region
            query: $query
            user: $user
            filter: $filter
            percentage: $percentage
            emails: $emails
        ) {
            id
            error
            query
            region
            percentage
            emails
        }
    }
`;
export const addNewPriceAlertTracker = async (
    displayNotification: (obj: any) => void,
    selectedRegion: string,
    percentage: number,
    emails: string[],
    query: string,
    user: string,
    variabales: any
) => {
    const response = await appoloClient.query({
        query: newPriceAlertTrackerMutation,
        variables: { ...variabales, percentage, emails, user, query, region: selectedRegion }
    });

    const result = response.data.newPriceAlertTrackerMutation;
    if (!result) {
        displayNotification({
            message: `Sorry Something bad happend during creating this config ! Please contact us to help !`,
            type: 'error',
            horizontal: 'right'
        });
    } else {
        displayNotification({
            message: `Success, The alert on this list is added :) !`,
            type: 'success',
            horizontal: 'right'
        });
    }
    return result;
};
export const ADD_ALERT_PRICE_MUTATION = gql`
    mutation addAlertPrice(
        $productRef: String!
        $region: String!
        $user: String!
        $selectedWebsites: [JSON!]
        $percentage: Float!
        $emails: [String!]
    ) {
        addAlertPrice(
            productRef: $productRef
            region: $region
            user: $user
            selectedWebsites: $selectedWebsites
            percentage: $percentage
            emails: $emails
        ) {
            id
            error
            productRef
            region
            selectedWebsites
            percentage
            emails
        }
    }
`;
export const REMOVE_ALERT_PRICE_MUTATION = gql`
    mutation removeAlertPrice($id: String!, $region: String!) {
        removeAlertPrice(id: $id, region: $region) {
            id
            removed
            error
        }
    }
`;
export const GET_PRICING_ALERT_FILTER_BY_USER = gql`
    query getPricingAlertFilterByUser($region: String!, $user: String!, $query: String, $filter: JSON) {
        getPricingAlertFilterByUser(region: $region, user: $user, query: $query, filter: $filter) {
            filterId
            user
            isAdvancedSearchExist
        }
    }
`;
export const REMOVE_PRICING_ALERT_FILTER_BY_ID = gql`
    mutation removePricingAlertFilter($region: String!, $id: String!) {
        removePricingAlertFilter(region: $region, id: $id) {
            filterId
            result
        }
    }
`;

/**
 *
 */

export const stateOfMatching = gql`
    query stateOfMatching($region: String!, $websites: [WebsiteArgs!]!) {
        stateOfMatching(region: $region, websites: $websites) {
            stats {
                percentage
                unmatched
                total
                matched
                percentageColor
                website {
                    id
                    alias
                    name
                    url
                    data
                }
            }
            error
        }
    }
`;

export const REPORTS_LIST_QUERY = gql`
    query ReportsList($region: String, $user: String) {
        ReportsList(region: $region, user: $user) {
            reports
            error
        }
    }
`;

export const DELETE_REPORT_MUTATION = gql`
    mutation deleteReport($id: String!) {
        deleteReport(id: $id) {
            deleted
            error
        }
    }
`;

export const EDIT_REPORT_MUTATION = gql`
    mutation editReportConfig(
        $id: String
        $email: String
        $region: String
        $user: String
        $websites: [JSON!]
        $brands: [JSON!]
        $type: String
    ) {
        editReportConfig(id: $id, email: $email, region: $region, user: $user, websites: $websites, brands: $brands, type: $type) {
            success
            id
            error
        }
    }
`;

export const ADD_NEW_REPORT_MUTATION = gql`
    mutation createNewReportConfig($email: String, $region: String, $user: String, $websites: [JSON!], $brands: [JSON!], $type: String) {
        createNewReportConfig(email: $email, region: $region, user: $user, websites: $websites, brands: $brands, type: $type) {
            success
            id
            error
        }
    }
`;

export const unmatchedProducts = gql`
    query unmatchedProducts($region: String!, $website: String!, $date: String, $from: Int, $size: Int) {
        unmatchedProducts(region: $region, website: $website, date: $date, from: $from, size: $size) {
            data
            total
            date
            error
        }
    }
`;

export const CHANGE_REVIEWED_STATUS_MUTATION = gql`
    mutation changeReviewedStatus(
        $region: String!
        $id: String
        $cacheID: String
        $match: String
        $status: String
        $user: String
        $account: String
    ) {
        changeReviewedStatus(region: $region, id: $id, cacheID: $cacheID, match: $match, status: $status, user: $user, account: $account) {
            status
            id
        }
    }
`;

export const RESET_PASSWORD = gql`
    mutation resetPassword($email: String!) {
        resetPassword(email: $email) {
            code
            error
        }
    }
`;

export const CHANGE_PASSWORD = gql`
    mutation changePassword($id: String!, $email: String, $username: String, $password: String) {
        changePassword(id: $id, email: $email, username: $username, password: $password) {
            code
            error
        }
    }
`;

export const NEW_PASSWORD = gql`
    mutation newPassword($email: String!, $token: String, $psw: String) {
        newPassword(email: $email, token: $token, psw: $psw) {
            code
            name
            params
            message
            error
        }
    }
`;

export const CHECK_USER_PRIVACY = gql`
    query checkPrivacyAccept($user: String!) {
        checkPrivacyAccept(user: $user) {
            accepted
            error
        }
    }
`;

export const ADD_USER_PRIVACY = gql`
    mutation addUserAcceptPrivacy($user: String!) {
        addUserAcceptPrivacy(user: $user) {
            accepted
            changed
            error
        }
    }
`;

export const PRODUCT_STATUS = gql`
    mutation productsStatus($data: [JSON!]!, $region: String!, $user: String!, $account: String!) {
        productsStatus(data: $data, region: $region, user: $user, account: $account) {
            status
            error
        }
    }
`;
