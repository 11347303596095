import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';

import { useQuery } from '@apollo/client';

import { MMS_GET_FLAGS_RESULT } from 'services/filterService/queries';
import { useAppSelector } from 'store/hooks';
import { RootState } from 'store';

interface FlagsProps {
    id: string;
    value: string[];
    handleChangeFlags: (event: React.SyntheticEvent, value: string[], id: string) => void;
}

function MMFlagsInput({ id, value, handleChangeFlags }: FlagsProps) {
    console.log('value', value);
    const { selectedRegion } = useAppSelector((state: RootState) => state.auth);

    const [flags, setFlags] = useState<string[]>([]);

    const { loading, data } = useQuery(MMS_GET_FLAGS_RESULT, {
        variables: {
            region: selectedRegion
        },
        notifyOnNetworkStatusChange: true
    });

    useEffect(() => {
        if (data) {
            const flagsLabel = data.GetMMFlags.data.map((item: { label: string; count: number }) => item.label);
            setFlags(flagsLabel);
        }
    }, [loading]);

    return (
        <Autocomplete
            multiple
            clearOnEscape
            size="small"
            id="tags-standard"
            value={value}
            onChange={(event: React.SyntheticEvent, value: string[]) => handleChangeFlags(event, value, id)}
            options={flags}
            isOptionEqualToValue={(option, value) => option === value}
            getOptionLabel={(option: string) => option || ''}
            renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Search" size="small" />}
        />
    );
}

export default MMFlagsInput;
