import React, { Ref, CSSProperties } from 'react';
import { Box } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';

interface ScrollbarProps {
    children?: React.ReactNode;
    style?: CSSProperties;
}

const Scrollbar = React.forwardRef(function Scrollbar(props: ScrollbarProps, ref: Ref<any>) {
    const { children, style, ...other } = props;

    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    if (isMobile) {
        return (
            <Box ref={ref} sx={{ overflowX: 'auto' }}>
                {children}
            </Box>
        );
    }

    return (
        <PerfectScrollbar ref={ref} style={style} {...other}>
            {children}
        </PerfectScrollbar>
    );
});

export default Scrollbar;
