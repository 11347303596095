import { useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { RootState } from 'store';
import AuthRoutes from './AuthRoutes';
import MainRoutes, { ErrorRoutes } from './MainRoutes';

export default function Routes() {
    const { isLoggedIn, selectedRegion } = useSelector((state: RootState) => state.auth);

    const currentRoutes = isLoggedIn && selectedRegion ? MainRoutes : AuthRoutes;

    return useRoutes([currentRoutes, ...ErrorRoutes]);
}
