import { Stack, Typography, Box } from '@mui/material';
import moment from 'moment';
import { FaAmazon } from 'react-icons/fa';

interface AmazonBestSellerProps {
    position?: number;
    lastUpdateDate?: string;
}

function AmazonBestSeller({ position, lastUpdateDate }: AmazonBestSellerProps) {
    return (
        <Stack direction={'row'} gap={1} flexWrap={'wrap'}>
            <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} sx={{ border: '1px solid #E6913A', borderRadius: 1 }}>
                {position && (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            background: '#E6913A',
                            height: '100%',
                            p: '5px'
                        }}
                    >
                        <FaAmazon size={15} />
                    </Box>
                )}

                {position && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: '5px' }}>
                        <Typography variant="body2" fontSize={12} fontWeight={600} color="#E6913A">
                            {position}
                        </Typography>
                    </Box>
                )}
            </Stack>
            {lastUpdateDate && (
                <Box sx={{ border: '1px solid #E6913A', p: '5px', borderRadius: 1 }}>
                    <Typography variant="body2" fontSize={12}>
                        <strong>Best seller </strong>- {moment(lastUpdateDate).format('MMM YYYY')}
                    </Typography>
                </Box>
            )}
        </Stack>
    );
}

export default AmazonBestSeller;
