import { useCallback, useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';

import moment from 'moment';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ACCESS_ACCOUNT_MUTATION } from 'services/authService/queries';
import { useAppDispatch } from 'store/hooks';
import {
    addUserConfig,
    updateIsMMS,
    updateIsBrand,
    updateUserInfo,
    updateUserRegions,
    updateUserWebsites,
    updatedefaultWebsite,
    userLoggingOut,
    updateSelectedWebsites,
    updateUserBrands,
    updateSelectedBrands,
    updateIsVisibility,
    updateNewAppVersion,
    setNewAppVersion
} from 'store/slices/auth/authSlice';
import { resetInitialStateFilter, updateDatePicker, updateDateRangePicker } from 'store/slices/filter/filterSlice';
import Logo from 'components/common/UI/Logo';
import AppLoader from 'components/common/UI/AppLoader/AppLoader';
import appoloClient from './appolo-client';

function AuthGuard({ children }: any) {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);

    const { isLoggedIn, selectedRegion, userInfo, isPrivacy } = useSelector((state: any) => state.auth);

    const fetchUser = useCallback(async () => {
        try {
            const response = await appoloClient.query({
                query: ACCESS_ACCOUNT_MUTATION,
                variables: { user: userInfo?.id }
            });

            const accesses = response.data.accesses.list;

            if (accesses && accesses.length) {
                dispatch(addUserConfig(accesses));

                const websites: any = accesses?.map((item: any) => {
                    const defaultWebsite = item.account.website;
                    return {
                        region: item.account.config.region,
                        websites: item.account.websites.map((website: Website) => ({
                            alias: website.alias,
                            name: website.name,
                            id: website.id,
                            data: website.data,
                            default: website.id == defaultWebsite.id,
                            url: website.url
                        }))
                    };
                });
                const regions: string[] = accesses?.map((item: any) => item?.account?.config?.region);
                const userWebsites = websites?.filter((item: any) => item.region === selectedRegion)[0];
                const userDefaultWebsite = userWebsites?.websites?.filter((website: any) => website.default)[0];
                const userData = accesses?.find((item: any) => item.account.config.region === selectedRegion).user;

                const currentAccount = accesses?.find((item: any) => item.account.config.region === selectedRegion);
                if (userData.acceptPrivacy === false) {
                    dispatch(userLoggingOut());
                    dispatch(resetInitialStateFilter());
                    navigate('/', { replace: true });
                }

                if (currentAccount?.account?.config?.mms == true) dispatch(updateIsMMS(true));
                else dispatch(updateIsMMS(false));

                if (currentAccount?.account?.config?.isBrandAcount == true) {
                    dispatch(updateIsBrand(true));
                } else {
                    dispatch(updateIsBrand(false));
                }

                if (currentAccount?.account?.config?.isVisibility == true) {
                    dispatch(updateIsVisibility(true));
                } else {
                    dispatch(updateIsVisibility(false));
                }

                if (userData) dispatch(updateUserInfo(userData));

                if (websites.length) {
                    const storedSelectedWebsites: any = localStorage.getItem('selectedWebsites') || '[]';

                    dispatch(updateUserWebsites(websites));
                    const websitesList = JSON.parse(storedSelectedWebsites);
                    if (websitesList?.length) {
                        const websitesData = websitesList;
                        const existingWebsitesIds = websitesData.map((item: any) => item.id);
                        const filteredWebsites = userWebsites.websites.filter((item: any) => existingWebsitesIds.includes(item.id));

                        dispatch(updateSelectedWebsites(filteredWebsites));
                    } else {
                        dispatch(updateSelectedWebsites(userWebsites.websites));
                    }

                    dispatch(updateUserRegions(regions));
                }

                const brands: any[] = accesses.map((item: any) => {
                    const defaultBrand = item.account.defaultBrand;
                    return {
                        region: item.account.config.region,
                        listOfCategories: item.account.listOfCategories.map((category: any, index: number) => {
                            return {
                                ref: category,
                                id: index
                            };
                        }),
                        brands: item.account.listOfBrands.map((brand: any, index: number) => ({
                            name: brand,
                            id: index,
                            default: brand == defaultBrand
                        }))
                    };
                });
                const userBrands = brands.filter((item: any) => item.region === selectedRegion)[0];
                if (brands.length) {
                    dispatch(updateUserBrands(brands));
                    const storedSelectedBrands: any = localStorage.getItem('selectedBrands') || '[]';
                    const brandsList = JSON.parse(storedSelectedBrands) || [];
                    if (brandsList?.length) {
                        const brandsData = brandsList;
                        const existingBrandsNames = brandsData.map((item: any) => item.name);
                        const filteredBrands = userBrands.brands.filter((item: any) => existingBrandsNames.includes(item.name));

                        dispatch(updateSelectedBrands(filteredBrands));
                    } else {
                        dispatch(updateSelectedBrands(userBrands.brands));
                    }
                }

                const months = ['es', 'pt'].includes(selectedRegion.toLowerCase())
                    ? { count: 1, type: 'months' }
                    : { count: 8, type: 'weeks' };
                dispatch(
                    updateDateRangePicker({
                        startDate: moment(
                            moment(new Date(userDefaultWebsite?.data?.lastDataDate)).subtract(months.count as any, months.type as any),
                            'MM-DD-YYYY'
                        ),
                        endDate: moment(moment(new Date(userDefaultWebsite?.data?.lastDataDate)), 'MM-DD-YYYY')
                    })
                );
                const dateValue = userDefaultWebsite
                    ? moment(new Date(userDefaultWebsite?.data?.lastDataDate), 'MM-DD-YYYY')
                    : moment(new Date(), 'MM-DD-YYYY');
                dispatch(updateDatePicker(moment(dateValue, 'MM-DD-YYYY')));

                if (userDefaultWebsite) {
                    dispatch(updatedefaultWebsite(userDefaultWebsite));
                }

                // handle app version
                const oldAppVersion = localStorage.getItem('appVersion');
                const newAppVersion = response.data.accesses.appVersion;
                if (!oldAppVersion) {
                    dispatch(updateNewAppVersion(true));
                    dispatch(setNewAppVersion(newAppVersion));
                } else {
                    if (oldAppVersion !== newAppVersion) {
                        dispatch(updateNewAppVersion(true));
                        dispatch(setNewAppVersion(newAppVersion));
                    }
                }
            } else {
                dispatch(userLoggingOut());
                dispatch(resetInitialStateFilter());
                navigate('/', { replace: true });
            }
        } catch (error) {
            console.log('errors :', error);
            navigate('/', { replace: true });
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        if (!isLoggedIn || !selectedRegion) {
            const appVersion = localStorage.getItem('appVersion');
            localStorage.clear();
            if (appVersion) localStorage.setItem('appVersion', appVersion);
            dispatch(userLoggingOut());
            dispatch(resetInitialStateFilter());
            navigate('/', { replace: true });
            setLoading(false);
        }
    }, [isLoggedIn, isPrivacy]);

    useEffect(() => {
        fetchUser();
    }, []);

    if (loading)
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Stack spacing={5} direction="column">
                    <Logo width={'250px'} />
                    <AppLoader />
                </Stack>
            </Box>
        );

    return children;
}

export default AuthGuard;
