import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';

interface Props {
    open: boolean;
    handleClose?: () => void;
    handleRemove?: () => void;
}

function FilterPriceAlertRemove({ open, handleClose, handleRemove }: Props) {
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Are you sure to remove price tracking for this list ?</DialogTitle>
            <DialogActions>
                <Button variant="contained" color="error" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="contained" color="success" onClick={handleRemove}>
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default FilterPriceAlertRemove;
