import { useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { Typography, Box } from '@mui/material';

import ProductsLoading from 'components/common/skeletons/ProductsLoading';
import ProductsGridView from 'components/product/ProductsGridView';

import { RELEVANT_PRODUCTS_QUERY } from 'services/catalog/queries';
import { useAppSelector } from 'store/hooks';
import { GET_GFK_VARIANTS } from 'services/analysis/queries';

interface GfkVariantsAnalysisProps {
    gfkId?: string;
    monthAbrv?: string;
}

function GfkVariantsAnalysis({ gfkId, monthAbrv }: GfkVariantsAnalysisProps) {
    const { selectedRegion, defaultWebsite, accessAccountUser, userWebsites } = useAppSelector((state) => state.auth);
    const websites = userWebsites.filter((item: any) => item.region === selectedRegion)[0]?.websites;
    const currentAccess: any[] = accessAccountUser.filter((item: any) => item.account.config.region == selectedRegion);

    const [products, setProducts] = useState([]);

    const [productNotFound, setProductNotFound] = useState<boolean>(false);
    const [productsLoading, setProductsLoading] = useState<boolean>(false);
    const [productsError, setProductsError] = useState<boolean>(false);

    const [loadProductsData] = useLazyQuery(RELEVANT_PRODUCTS_QUERY);

    const { loading, error, data } = useQuery(GET_GFK_VARIANTS, {
        variables: {
            region: selectedRegion,
            gfkId: gfkId,
            monthAbrv: monthAbrv
        },
        notifyOnNetworkStatusChange: true
    });

    useEffect(() => {
        if (data) {
            if (data?.getGfkVariants?.data?.length == 0) {
                setProductNotFound(true);
            } else {
                setProductNotFound(false);
                setProductsLoading(true);
                loadProductsData({
                    variables: {
                        region: selectedRegion,
                        website: defaultWebsite,
                        websites: websites,
                        from: 0,
                        size: 100,
                        keywords: data.getGfkVariants.data.join(','),
                        gfkId: gfkId,
                        loadGfk: true
                    },
                    notifyOnNetworkStatusChange: true
                })
                    .then((res) => {
                        setProducts(res.data.relevantProducts.data);
                        setProductsLoading(false);
                        setProductsError(false);
                    })
                    .catch(() => {
                        setProductsError(true);
                        setProductsLoading(false);
                    });
            }
        }
    }, [loading]);

    if (productsLoading || loading) return <ProductsLoading sx={{ py: 2 }} isOutlined />;

    if (error || productsError) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 300 }}>
                <Typography>An error occurred. Please try again later.</Typography>
            </Box>
        );
    }

    if (productNotFound) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 300 }}>
                <Typography>Product Not Found {gfkId}.</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ mb: '10px', mt: '10px ' }}>
            <ProductsGridView
                gfkVariantsLoad={false}
                products={products}
                config={currentAccess.length ? currentAccess[0].account.config : {}}
                isOutlined
                isModal
            />
        </Box>
    );
}

export default GfkVariantsAnalysis;
