import { Box, Grid } from '@mui/material';
import FlagsFormatter from './detail/FlagsFormatter';

import ProductCard from './ProductCard';

interface ProductCardType {
    stats?: any;
    productMatch?: any;
}

interface ProductCardProps {
    products: ProductCardType[];
    singleRating?: boolean;
    hideRelevance?: boolean;
    isOutlined?: boolean;
    showVisibility?: boolean;
    isModal?: boolean;
    gfkVariantsLoad?: boolean;
    config?: AccountConfig;
    queryCacheId?: string;
}

export const searchAmazonWebsite = (products: any) => {
    const data = products?.find((item: any) => /amazon/.test(item?.website?.name?.toLowerCase()));
    if (data) {
        return data?.product?.lastUpdate;
    } else return null;
};

export const getFlags = (competitors: any, flags = []): string[] => {
    if (flags && flags.length) {
        return flags;
    }
    if (competitors && competitors.length) {
        const findFlags = competitors.filter((el: any) => el.product.flags && el.product.flags.length);
        if (findFlags.length) {
            const flagsToReturn: string[] = [];
            findFlags.map((el: any) => {
                if (el.product.flags) {
                    el.product.flags.map((flag: string) => {
                        const flagToAdd = FlagsFormatter(flag);
                        if (!flagsToReturn.includes(flagToAdd)) {
                            flagsToReturn.push(flagToAdd);
                        }
                    });
                }
            });
            return flagsToReturn;
        }
    }
    return [];
};

function ProductsGridView({
    products,
    singleRating,
    showVisibility,
    hideRelevance,
    isOutlined = false,
    isModal,
    gfkVariantsLoad = true,
    config = {},
    queryCacheId
}: ProductCardProps) {
    return (
        <Box sx={{ px: 2 }}>
            <Grid container spacing={2}>
                {products.map((item: any, index: number) => {
                    const productListData = item?.productMatch?.products || [item];
                    return (
                        <Grid item xs={12} md={6} lg={4} key={index.toString()}>
                            <ProductCard
                                hideRelevance={hideRelevance}
                                showReviewStatus={config?.reviewed}
                                showGFK={config?.gfk}
                                showPriceAlert={config?.alertPrice}
                                alertId={item?.alertId}
                                showFlags={config?.flags}
                                showAmazonBestSeller={config?.amazonBestSeller}
                                isOutlined={isOutlined}
                                gfkVariantsLoad={gfkVariantsLoad}
                                singleRating={singleRating}
                                visibility={item?.visibility}
                                showVisibility={showVisibility}
                                productRef={item.stats?.defaultProduct ? item.stats.defaultProduct?.product.ref : item?.product?.ref}
                                title={item.stats?.defaultProduct ? item.stats.defaultProduct?.product.name : item?.product?.name}
                                url={
                                    item?.stats?.defaultProduct
                                        ? item?.stats?.defaultProduct?.website?.url + item?.stats?.defaultProduct?.product?.url
                                        : `${item?.website?.url}${item?.product?.url}`
                                }
                                image={item?.stats?.defaultProduct ? item?.stats?.defaultProduct?.product?.image : item?.product?.image}
                                brand={
                                    item?.stats?.defaultProduct
                                        ? item?.stats?.defaultProduct?.product?.brand?.name
                                        : item.product?.brand?.name
                                }
                                relevance={item?.stats?.relevance}
                                ean={item?.stats?.ean}
                                trackingDate={item?.stats?.trackingSince}
                                ratingValue={item?.stats?.rating?.value}
                                reviewedStatus={item?.stats?.status}
                                reviewedId={item?.stats?.reviewed?.id}
                                ratingCount={item?.stats?.rating?.count}
                                position={item?.stats?.overallAvgPos}
                                isOpportunities={item?.isOpportunities}
                                isModal={isModal}
                                isBetterWay={config?.mms ? item?.stats?.isBetterWay : false}
                                isChoice={config?.mms ? item?.stats?.isChoice : false}
                                productWebsites={productListData
                                    ?.filter((l: any) => l?.product)
                                    ?.map((productData: any) => ({
                                        brand: productData.product?.brand?.name,
                                        name: productData.product.name,
                                        price: productData.product.offers?.list[0]?.price,
                                        websiteId: productData.website.id,
                                        id: productData.website.id,
                                        websiteAlias: productData.website.name,
                                        availability: productData.product?.offers?.list?.length
                                            ? productData.product.offers.list[0]?.availability
                                            : '',
                                        marketplace: productData.product?.offers?.list?.length
                                            ? productData.product.offers.list[0]?.marketplace
                                            : false,
                                        url: productData.website.url + productData.product.url,
                                        websiteLastDataDate: productData.website.data.lastDataDate,
                                        productLastUpdate: productData.product.lastUpdate,
                                        offers: productData.product.offers.list
                                    }))}
                                flags={getFlags(item?.productMatch?.products || [], item?.product?.flags)}
                                amazonPosition={item?.stats?.bestAvgPosition}
                                lastProductUpdateDate={searchAmazonWebsite(item?.productMatch?.products)}
                                gfkId={item?.stats?.gfk?.GfK_ID}
                                gfkSku={item?.stats?.gfk?.SKU}
                                gfkMonthAbrv={item?.stats?.gfk?.monthAbrv}
                                gfkIsWinning={item?.stats?.gfk?.isWinning}
                                gfkPosition={item?.stats?.gfk?.rank}
                                gfkPositionInCategory={item?.stats?.gfk?.google_categories_rank}
                                googleCategory={item?.stats?.gfk?.google_categories}
                                gfkSalesValue={item?.stats?.gfk?.sales_value_in_euro}
                                gfkUnit={item?.stats?.gfk?.sales_units}
                                gfkMonth={item?.stats?.gfk?.monthAbrv}
                                gfkMethod={item?.stats?.gfk?.method}
                                gfkVariants={item?.stats?.gfk?.countOfEans}
                                amazonRank={item?.stats?.amazon?.amazonRank}
                                amazonGoogleCategoriesRank={item?.stats?.amazon?.amazonGoogleCategoriesRank}
                                googleCategories={item?.stats?.amazon?.googleCategories}
                                amazonSales={item?.stats?.amazon?.amazonSales}
                                amazonSalesCount={item?.stats?.amazon?.amazonSalesCount}
                                amazonMonthAbrv={item?.stats?.amazon?.amazonMonthAbrv}
                                queryCacheId={queryCacheId}
                            />
                        </Grid>
                    );
                })}
            </Grid>
        </Box>
    );
}

export default ProductsGridView;
