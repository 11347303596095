import { Fragment, useEffect, useState } from 'react';
import { Button, Box, Card, CardContent, FormControl, Grid, IconButton, MenuItem, Select, SelectChangeEvent, Stack } from '@mui/material';
import Replay from '@mui/icons-material/Replay';

import { useNavigate } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
    updateAccuracyFilterField,
    updateModuleValue,
    updateProductGroupValue,
    updateQueryModule,
    updateQueryProductGroup
} from 'store/slices/filter/customFilterSlice';
import { submitFilter } from 'store/slices/filter/filterSlice';
import { GET_MMS_CATEGORY, GET_MMS_MODULE } from 'services/accuracy/queries';
import InstantCategory from './InstantCategory';
import SingleModule from '../field/SingleModule';
import SingleProductGroup from '../field/SingleProductGroup';

type filterFieldType = 'Module' | 'Product Group';

interface AccuracyFilterProps {
    showInstantCategory?: boolean;
}

function AccuracyFilter({ showInstantCategory }: AccuracyFilterProps) {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { selectedRegion } = useAppSelector((state: any) => state.auth);

    const { accuracyFilterField, moduleValue, productGroupValue, queryModule, queryProductGroup } = useAppSelector(
        (state) => state.customFilter
    );

    const [open, setOpen] = useState(false);
    const [firstLoad, setFirstLoad] = useState<boolean>(true);

    const [getmmscategory, { data, loading, refetch: refetchMMSCategory }] = useLazyQuery(GET_MMS_CATEGORY, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache'
    });

    const [getmmsmodule, { data: moduleData, loading: moduleLoading, refetch: refetchMMSModule }] = useLazyQuery(GET_MMS_MODULE, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache'
    });

    const handleChangeFilterField = (event: SelectChangeEvent) => {
        dispatch(updateAccuracyFilterField(event.target.value as filterFieldType));
        dispatch(submitFilter());
    };

    const handleChangeModule = (option: any) => {
        dispatch(updateModuleValue(option));
        dispatch(updateProductGroupValue(null));
        navigate('/validation');
    };

    const handleModuleInputChange = (query: string) => {
        dispatch(updateQueryModule(query));
    };

    const handleChangeProductGroup = (option: any) => {
        dispatch(updateProductGroupValue(option));
        dispatch(updateModuleValue(null));
        navigate('/validation');
    };

    const handleProductGroupInputChange = (query: string) => {
        dispatch(updateQueryProductGroup(query));
    };

    const handleReload = () => {
        dispatch(submitFilter());

        if (productGroupValue && productGroupValue.id) {
            refetchMMSCategory({
                region: selectedRegion,
                id: productGroupValue.id
            });
        }
        if (moduleValue && moduleValue.id) {
            refetchMMSModule({
                region: selectedRegion,
                id: moduleValue.id
            });
        }
    };

    useEffect(() => {
        if (data) {
            if (data.getmmscategory && data.getmmscategory.category) {
                dispatch(updateProductGroupValue(data.getmmscategory.category));
                dispatch(updateModuleValue(null));
            }
        }
    }, [loading]);

    useEffect(() => {
        if (moduleData) {
            if (moduleData.getmmsmodule && moduleData.getmmsmodule.category) {
                dispatch(updateModuleValue(moduleData.getmmsmodule.category));
                dispatch(updateProductGroupValue(null));
            }
        }
    }, [moduleLoading]);

    useEffect(() => {
        if (moduleValue && moduleValue.id) {
            if (firstLoad) {
                setFirstLoad(false);
                getmmsmodule({
                    variables: {
                        region: selectedRegion,
                        id: moduleValue.id
                    },
                    fetchPolicy: 'no-cache'
                });
            }
        }
    }, [moduleValue]);

    useEffect(() => {
        if (productGroupValue && productGroupValue.id) {
            if (firstLoad) {
                setFirstLoad(false);
                getmmscategory({
                    variables: {
                        region: selectedRegion,
                        id: productGroupValue.id
                    },
                    fetchPolicy: 'no-cache'
                });
            }
        }
    }, [productGroupValue]);

    return (
        <Fragment>
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Grid container spacing={2} justifyContent={'center'} alignItems={'center'}>
                            <Grid item xs={2} md={2}>
                                <FormControl fullWidth size="small">
                                    <Select
                                        displayEmpty
                                        value={accuracyFilterField || 'module-category'}
                                        defaultValue={'Module' as filterFieldType}
                                        onChange={handleChangeFilterField}
                                    >
                                        <MenuItem value={'Module' as filterFieldType}>Module</MenuItem>
                                        <MenuItem value={'Product Group' as filterFieldType}>Product Group</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={10} md={10}>
                                <Stack direction="row" spacing={2}>
                                    {accuracyFilterField == 'Module' && (
                                        <SingleModule
                                            mmsModuleValue={moduleValue}
                                            isCustomModule
                                            customHandleChange={handleChangeModule}
                                            customQuery={queryModule}
                                            customHandleInputChange={handleModuleInputChange}
                                        />
                                    )}

                                    {accuracyFilterField == 'Product Group' && (
                                        <SingleProductGroup
                                            productGroupValue={productGroupValue}
                                            isCustomGroup
                                            customHandleChange={handleChangeProductGroup}
                                            customHandleInputChange={handleProductGroupInputChange}
                                            customQuery={queryProductGroup}
                                        />
                                    )}
                                    {showInstantCategory && (
                                        <IconButton
                                            disabled={productGroupValue || moduleValue ? false : true}
                                            aria-label="fingerprint"
                                            color="secondary"
                                            onClick={handleReload}
                                        >
                                            <Replay />
                                        </IconButton>
                                    )}
                                </Stack>
                            </Grid>
                        </Grid>
                        {showInstantCategory && (
                            <Box>
                                <Button onClick={() => setOpen(true)} disabled={productGroupValue || moduleValue ? false : true}>
                                    Instant Category
                                </Button>
                            </Box>
                        )}
                    </CardContent>
                </Card>
            </Grid>
            {open ? <InstantCategory open={open} handleClose={() => setOpen(false)} handleReload={handleReload} /> : null}
        </Fragment>
    );
}

export default AccuracyFilter;
