import { Stack } from '@mui/material';
import WebSiteItem from './WebSiteItem';

interface ProductWebsiteType {
    id: string;
    price: number;
    name: string;
    availability: string;
    marketplace?: boolean;
    url: string;
    websiteLastDataDate?: string;
    websiteId: string;
    websiteAlias: string;
    productLastUpdate?: string;
    brand: string;
    offers?: any[] | null;
}

function Websites({ websites }: { websites: ProductWebsiteType[] }) {
    return (
        <Stack direction={'row'} gap={0.5} flexWrap={'wrap'}>
            {websites.map((item, index) => (
                <WebSiteItem
                    key={index}
                    name={item.name}
                    websiteAlias={item.websiteAlias}
                    price={item.price}
                    availability={item.availability}
                    marketplace={item.marketplace}
                    websiteId={item.websiteId}
                    url={item.url}
                    websiteLastDataDate={item.websiteLastDataDate}
                    productLastUpdate={item.productLastUpdate}
                    product={item}
                />
            ))}
        </Stack>
    );
}

export default Websites;
