import { useEffect } from 'react';
import { Grid } from '@mui/material';

import useDocumentTitle from 'hooks/useDocumentTitle';
import BetterWayOverview from './tasks/BetterWayOverview';
import BetterWayProducts from './tasks/BetterWayProducts';
import Filter from 'components/filter/Filter';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { initializeInputList } from 'store/slices/filter/filterSlice';

function BetterWayPage(props: any) {
    useDocumentTitle(props.title);
    const dispatch = useAppDispatch();

    const { inputList } = useAppSelector((state) => state.filter);

    useEffect(() => {
        const listingStatus = inputList.find((item: any) => item?.fieldSearch == 'Listing Status');
        if (listingStatus && (listingStatus?.reviewed?.status == 'OPPORTUNITIES' || listingStatus?.reviewed?.status == 'LISTED')) {
            dispatch(
                initializeInputList([
                    {
                        id: 1,
                        fieldSearch: 'Listing Status',
                        checkOption: 'Is',
                        includeExclude: 'Include'
                    }
                ])
            );
        }
    }, []);

    return (
        <Grid container spacing={0}>
            <Filter
                title="Better Way"
                subtitle="Showcases potential additions based on market trends and consumer demands, revealing untapped opportunities"
                tooltipText="This report provides insights about products that are possible good opportunities by relevance"
                showAvailability
                showAdvancedFilter
                showCompetitors
            />

            {/* BetterWay Overview */}
            <Grid item xs={12}>
                <BetterWayOverview />
            </Grid>

            {/* BetterWay  Products*/}
            <Grid item xs={12}>
                <BetterWayProducts />
            </Grid>
        </Grid>
    );
}

export default BetterWayPage;
