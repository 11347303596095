import { FormControl, Grid, IconButton, MenuItem, OutlinedInput, Select, Stack } from '@mui/material';
import SearchTags from '../SearchTags';
import Rating from 'components/filter/field/Rating';
import { FiCopy } from 'react-icons/fi';

import { useNotification } from 'hooks/useNotification';

interface FormSearchProps {
    rules?: any[];
    handleCopyFilter: (filteritem: any) => void;
}

function FormSearch({ rules, handleCopyFilter }: FormSearchProps) {
    const { displayNotification } = useNotification();

    return (
        <Grid display="flex" flexDirection="column" gap={2}>
            {rules?.map((criteria: any, index: number) => (
                <Grid container key={index} gap={1}>
                    {/* include exclude */}
                    <Grid item md={2}>
                        <Select
                            labelId={`includeExclude-label-${index}`}
                            id={`advancedFilter.${index}.includeExclude`}
                            name={`advancedFilter.${index}.includeExclude`}
                            value={criteria.includeExclude}
                            size="small"
                            fullWidth
                        >
                            <MenuItem value="Include">Include</MenuItem>
                            <MenuItem value="Exclude">Exclude</MenuItem>
                        </Select>
                    </Grid>

                    {/* field search */}
                    <Grid item md={2}>
                        <Select
                            labelId={`fieldSearch-label-${index}`}
                            id={`advancedFilter.${index}.fieldSearch`}
                            name={`advancedFilter.${index}.fieldSearch`}
                            value={criteria.fieldSearch}
                            size="small"
                            fullWidth
                        >
                            <MenuItem value="Keywords">Keywords</MenuItem>
                            <MenuItem value="Product Name">Product Name</MenuItem>
                            <MenuItem value="Product Name/Specifications">Product Name/Specifications</MenuItem>
                            <MenuItem value="Brand">Brand</MenuItem>
                            <MenuItem value="EAN/Asin/SKU">EAN/Asin/SKU</MenuItem>
                            <MenuItem value="Price Range">Price Range</MenuItem>
                            <MenuItem value="Specifications">Specifications</MenuItem>
                            <MenuItem value="Rating">Rating</MenuItem>
                            <MenuItem value="Reviews">Reviews</MenuItem>
                        </Select>
                    </Grid>

                    {/* checkOption */}
                    {criteria.fieldSearch == 'Keywords' ||
                    criteria.fieldSearch == 'Product Name' ||
                    criteria.fieldSearch == 'Product Name/Specifications' ||
                    criteria.fieldSearch == 'Brand' ||
                    criteria.fieldSearch == 'EAN/Asin/SKU' ? (
                        <Grid item md={2}>
                            <Select
                                labelId={`checkOption-label-${index}`}
                                id={`advancedFilter.${index}.checkOption`}
                                name={`advancedFilter.${index}.checkOption`}
                                value={criteria.checkOption}
                                fullWidth
                                size="small"
                            >
                                <MenuItem value="Containing">Containing</MenuItem>
                                <MenuItem value="Not Containing">Not Containing</MenuItem>
                                <MenuItem value="Equal">Equal</MenuItem>
                                <MenuItem value="Not Equal">Not Equal</MenuItem>
                            </Select>
                        </Grid>
                    ) : (
                        <Grid item xs={12} md={2}>
                            <Select
                                labelId={`checkOption-label-${index}`}
                                id={`advancedFilter.${index}.checkOption`}
                                name={`advancedFilter.${index}.checkOption`}
                                value={criteria.checkOption}
                                size="small"
                                fullWidth
                            >
                                <MenuItem value="Is">Is</MenuItem>
                            </Select>
                        </Grid>
                    )}

                    {/* Search Tag */}
                    {(criteria.fieldSearch == 'Keywords' ||
                        criteria.fieldSearch == 'Product Name' ||
                        criteria.fieldSearch == 'Product Name/Specifications' ||
                        criteria.fieldSearch == 'Brand' ||
                        criteria.fieldSearch == 'EAN/Asin/SKU') && (
                        <Grid item xs={12} md={5}>
                            <SearchTags tags={criteria.searchkey} />
                        </Grid>
                    )}

                    {/* Price Change */}
                    {criteria.fieldSearch == 'Price Range' && (
                        <Grid item md={5}>
                            <Stack direction={'row'} gap={1}>
                                <FormControl fullWidth size="small">
                                    <OutlinedInput
                                        fullWidth
                                        id="min-price"
                                        type={'number'}
                                        name="min-price"
                                        placeholder="Min Price"
                                        value={criteria?.minprice}
                                        inputProps={{ min: 0 }}
                                    />
                                </FormControl>
                                <FormControl fullWidth size="small">
                                    <OutlinedInput
                                        fullWidth
                                        id="max-price"
                                        type={'number'}
                                        name="max-price"
                                        placeholder="Max Price"
                                        inputProps={{ min: 0 }}
                                        value={criteria?.maxprice}
                                    />
                                </FormControl>
                            </Stack>
                        </Grid>
                    )}

                    {/* Specifications  */}
                    {criteria.fieldSearch == 'Specifications' && (
                        <Grid item md={5}>
                            <Stack direction={'row'} gap={1}>
                                <FormControl fullWidth size="small">
                                    <OutlinedInput
                                        fullWidth
                                        id="key-spec"
                                        name="key-spec"
                                        placeholder="Key"
                                        value={criteria?.specifications?.key}
                                    />
                                </FormControl>

                                <FormControl fullWidth size="small">
                                    <SearchTags tags={criteria?.specifications?.value || []} placeholder="value" />
                                </FormControl>
                            </Stack>
                        </Grid>
                    )}

                    {/* Reviews  */}
                    {criteria.fieldSearch == 'Reviews' && (
                        <Grid item md={5}>
                            <Rating value={criteria?.reviews?.value} option={criteria?.reviews?.op?.code} />
                        </Grid>
                    )}

                    {/* Rating */}
                    {criteria.fieldSearch == 'Rating' && (
                        <Grid item md={5}>
                            <Rating value={criteria?.reviews?.value} option={criteria?.reviews?.op?.code} />
                        </Grid>
                    )}

                    {/* Remove filter Item */}
                    <Grid item md>
                        <IconButton
                            size="small"
                            onClick={() => {
                                handleCopyFilter(criteria);
                                displayNotification({
                                    message: 'Filter copied successfully',
                                    type: 'success',
                                    horizontal: 'right'
                                });
                            }}
                        >
                            <FiCopy />
                        </IconButton>
                    </Grid>
                </Grid>
            ))}
        </Grid>
    );
}

export default FormSearch;
