import { Box, Stack, Grid } from '@mui/material';

interface InsightCategoriesProps {
    missingWebsiteCategories: any[];
}

export default function InsightCategories({ missingWebsiteCategories }: InsightCategoriesProps) {
    const handleImageError = (event: React.SyntheticEvent<HTMLImageElement, Event>, websiteUrl: string) => {
        event.currentTarget.src = `https://www.google.com/s2/favicons?domain_url=${websiteUrl}`;
    };

    return (
        <Box>
            {missingWebsiteCategories.length > 0 && (
                <Box>
                    3. Website categories selected :
                    <Grid container>
                        {missingWebsiteCategories.map((webCat: any, indexx: any) => {
                            return (
                                <Grid sx={{ pt: '5px', pl: '5px' }} item xs="auto" key={indexx}>
                                    <Stack
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        direction={{ xs: 'column', sm: 'row' }}
                                        spacing={1}
                                    >
                                        <img
                                            src={webCat.icon}
                                            onError={(event) => handleImageError(event, webCat.websiteUrl)}
                                            height={17}
                                            width={17}
                                            alt={webCat.icon}
                                        />
                                        <a href={webCat.url} target="_blank" rel="noreferrer">
                                            {webCat.name}{' '}
                                        </a>
                                    </Stack>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Box>
            )}
        </Box>
    );
}
