import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { MMS_PRODUCT_GROUP_RESULT } from 'services/filterService/queries';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { submitFilter, updateInputList, updateProductGroupQuery } from 'store/slices/filter/filterSlice';

interface ProductGroupProps {
    id?: string;
    productGroupValue: any;
    isCustomGroup?: boolean;
    isMultiple?: boolean;
    customQuery?: string;
    customHandleInputChange?: (query: string) => void;
    customHandleChange?: (option: any) => void;
}

function ProductGroup({
    id,
    productGroupValue,
    customQuery,
    isCustomGroup,
    isMultiple = false,
    customHandleChange,
    customHandleInputChange
}: ProductGroupProps) {
    const dispatch = useAppDispatch();

    const { selectedRegion } = useAppSelector((state) => state.auth);
    const { queryProductGroup } = useAppSelector((state) => state.filter);

    const [productGroupData, setProductGroupData] = useState<any[]>([]);

    const { loading, data } = useQuery(MMS_PRODUCT_GROUP_RESULT, {
        variables: {
            region: selectedRegion,
            size: 100,
            q: customQuery ? customQuery : queryProductGroup,
            show: true
        },
        notifyOnNetworkStatusChange: true
    });

    const handleChange = (event: React.SyntheticEvent, option: any, id?: string) => {
        if (isCustomGroup && customHandleChange) {
            customHandleChange(option);
            customHandleInputChange && customHandleInputChange('');
        } else {
            dispatch(updateInputList({ id, data: { checkOption: 'Is', mmscategory: option } }));
            dispatch(updateProductGroupQuery(''));
            dispatch(submitFilter());
        }
    };

    useEffect(() => {
        if (data) {
            setProductGroupData(data.mmscategoryresult.categories);
        }
    }, [loading]);

    return (
        <Autocomplete
            id="product-group"
            size="small"
            clearOnEscape
            fullWidth
            multiple={isMultiple}
            onChange={(event: React.SyntheticEvent<Element, Event>, option: any) => handleChange(event, option, id)}
            value={productGroupValue || (isMultiple ? [] : null)}
            options={productGroupData}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            loading={loading}
            getOptionLabel={(item: any) => (item?.name ? item.name : '')}
            inputValue={isCustomGroup ? customQuery : queryProductGroup}
            onBlur={() => {
                if (isCustomGroup && customHandleInputChange) {
                    customHandleInputChange('');
                } else {
                    dispatch(updateProductGroupQuery(''));
                }
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder="Product Group"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        )
                    }}
                    onChange={(event) => {
                        if (isCustomGroup && customHandleInputChange) {
                            customHandleInputChange(event.target.value);
                        } else {
                            dispatch(updateProductGroupQuery(event.target.value));
                        }
                    }}
                />
            )}
        />
    );
}

export default ProductGroup;
