import { Card, CardHeader, Skeleton, Grid, Box, SxProps } from '@mui/material';

interface ProductsLoadingProps {
    sx?: SxProps;
    isOutlined?: boolean;
}

function ProductsLoading({ sx, isOutlined }: ProductsLoadingProps) {
    const items = Array.from(Array(6).keys());

    return (
        <Box sx={{ ...sx, px: 2 }}>
            <Grid container spacing={2}>
                {items.map((item: number) => (
                    <Grid item xs={12} md={4} key={item}>
                        <Card sx={{ p: 0, m: 0 }} variant={isOutlined ? 'outlined' : 'elevation'}>
                            <CardHeader
                                title={<Skeleton animation="pulse" height={10} width="80%" style={{ marginBottom: 6 }} />}
                                subheader={<Skeleton animation="pulse" height={10} width="40%" />}
                            />

                            <Skeleton sx={{ height: 190 }} animation="pulse" variant="rectangular" />
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

export default ProductsLoading;
