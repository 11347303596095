import React, { useState, useEffect } from 'react';
import { Autocomplete, CircularProgress, Stack, TextField } from '@mui/material';
import { useLazyQuery } from '@apollo/client';

import { WEBSITE_CATEGORY } from 'services/filterService/queries';
import { useAppSelector } from 'store/hooks';
import SearchTags from '../instant/SearchTags';
interface WebsiteCategoryProps {
    website: any;
    tags: string[];
    onSelectCategory: (option: any) => void;
    handleAddTag: (inputValue: string) => void;
    handleDeleteTag: (tagToDelete: string) => void;
}
function WebsiteCategoryItem({ website, tags, onSelectCategory, handleAddTag, handleDeleteTag }: WebsiteCategoryProps) {
    const { selectedRegion } = useAppSelector((state) => state.auth);
    const [currentCategory, setCurrentCategory] = useState<any>(null);
    const [query, setQuery] = useState<string | null>(null);
    const [websiteCategoryData, setWebsiteCategoryData] = useState<any[]>([]);

    const [websiteCategoryload, { loading, data }] = useLazyQuery(WEBSITE_CATEGORY, {
        notifyOnNetworkStatusChange: true
    });

    const handleChange = (event: React.SyntheticEvent, option: any) => {
        setCurrentCategory(option);
        onSelectCategory(option);
        setCurrentCategory(null);
    };
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setQuery(event.target.value);
    };

    useEffect(() => {
        if (query) {
            websiteCategoryload({
                variables: {
                    region: selectedRegion,
                    website,
                    q: query
                }
            });
        }
    }, [query]);

    useEffect(() => {
        if (data) {
            const list = data.websiteCategories.list.map((cc: any) => {
                const breadcrumbs = `${cc.name} ${
                    cc.parents && cc.parents.length ? ' > ' + cc.parents.map((cp: any) => cp.name).join(' > ') : ''
                }`;
                return { ...cc, breadcrumbs };
            });
            setWebsiteCategoryData(list);
        }
    }, [loading]);
    return (
        <Stack direction={'column'} spacing={1}>
            <Autocomplete
                size="small"
                disablePortal
                id="tags-standard"
                fullWidth
                value={currentCategory}
                onChange={handleChange}
                getOptionLabel={(option: any) => (option?.breadcrumbs ? option.breadcrumbs : '')}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                options={websiteCategoryData}
                loading={loading}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        value={query}
                        onChange={handleInputChange}
                        placeholder={'Website Category'}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </>
                            )
                        }}
                    />
                )}
            />
            <SearchTags
                tags={tags}
                handleAddTag={handleAddTag}
                handleDeleteTag={handleDeleteTag}
                placeholder="Product Name / Specifications"
            />
        </Stack>
    );
}
export default WebsiteCategoryItem;
