import { Box, LinearProgress, Stack, Typography } from '@mui/material';

interface RelevanceProps {
    value: number;
    showText?: boolean;
}

function Relevance({ value, showText = true }: RelevanceProps) {
    return (
        <Stack direction={'row'} spacing={2} alignItems={'center'} justifyContent={'center'}>
            {showText && (
                <Typography variant="body2" color="text.secondary">
                    Relevance:
                </Typography>
            )}

            <Box sx={{ width: '70%' }}>
                <LinearProgress variant="determinate" value={value} sx={{ height: 10, borderRadius: '2px' }} />
            </Box>
            <Box>
                <Typography variant="body2" color="text.secondary">{`${value.toFixed(2)}%`}</Typography>
            </Box>
        </Stack>
    );
}

export default Relevance;
