import { Fragment } from 'react';
import { Card, CardContent, FormControl, Grid, MenuItem, Select } from '@mui/material';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateGoogleListingWizardValue, updateQueryGoogleListingWizard } from 'store/slices/filter/customFilterSlice';
import { submitFilter } from 'store/slices/filter/filterSlice';
import SingleGoogleCategory from '../field/SingleGoogleCategory';

function GoogleListingWizard() {
    const dispatch = useAppDispatch();

    const { googleListingWizardValue, queryGoogleListingWizard } = useAppSelector((state) => state.customFilter);

    const handleChange = async (option: any) => {
        dispatch(updateGoogleListingWizardValue(option));
        dispatch(submitFilter());
    };

    const handleInputChange = (q: string) => {
        dispatch(updateQueryGoogleListingWizard(q));
    };

    return (
        <Fragment>
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Grid container spacing={2} justifyContent={'center'} alignItems={'center'}>
                            <Grid item xs={12} md={2}>
                                <FormControl fullWidth size="small">
                                    <Select displayEmpty value={'Category'}>
                                        <MenuItem value={'Category'}>Category</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={10}>
                                <SingleGoogleCategory
                                    isCustom
                                    googleCategoryValue={googleListingWizardValue}
                                    customHandleChange={handleChange}
                                    customHandleInputChange={handleInputChange}
                                    customQuery={queryGoogleListingWizard}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Fragment>
    );
}

export default GoogleListingWizard;
