import { useEffect, useState, MouseEvent } from 'react';
import {
    Card,
    Typography,
    Box,
    CardHeader,
    CardContent,
    CardActions,
    IconButton,
    Divider,
    SxProps,
    keyframes,
    Stack,
    Popover
} from '@mui/material';

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ReplayIcon from '@mui/icons-material/Replay';
import DateRangeIcon from '@mui/icons-material/DateRange';

import moment from 'moment';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { AppDispatch, RootState } from 'store';
import { submitFilter, updateDateRangePicker } from 'store/slices/filter/filterSlice';
import { DateRangePicker, DateRange } from 'mui-daterange-picker';

interface MainCardProps {
    isNotCard?: boolean;
    showReload?: boolean;
    showDownload?: boolean;
    showDateRange?: boolean;
    showReportTime?: boolean;
    headerTitle?: string;
    cardcolor?: string;
    sortingLabels?: [];
    showDivider?: boolean;
    sx?: SxProps;
    children?: JSX.Element | JSX.Element[] | React.ReactNode;
    loading?: boolean;
    handleExport?: () => void;
    handleReload?: () => void;
}

const rotateAnimation = keyframes`
from {
    transform: rotate(360deg);
}
to {
    transform: rotate(0deg);
}
`;

function MainCard({
    isNotCard,
    cardcolor,
    showReload,
    showDownload,
    headerTitle,
    showReportTime,
    showDateRange,
    showDivider = true,
    children,
    sx,
    loading,
    handleReload,
    handleExport
}: MainCardProps) {
    const dispatch: AppDispatch = useAppDispatch();
    const { dateRangePicker } = useAppSelector((state: RootState) => state.filter);

    const reportTime: string = moment(new Date(), 'MMMM Do YYYY, h:mm:ss a').toString();

    const [reload, setReload] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const togglePicker = () => {
        setAnchorEl(null);
    };

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleChangeDateRangePicker = (item: DateRange) => {
        const startDate = moment(item.startDate, 'MM/DD/YYYY');
        const endDate = moment(item.endDate, 'MM/DD/YYYY');

        const isSameDay = startDate.isSame(endDate, 'day');

        dispatch(
            updateDateRangePicker({
                startDate: moment(item.startDate, 'MM-DD-YYYY'),
                endDate: moment(item.endDate, 'MM-DD-YYYY')
            })
        );

        if (!isSameDay) {
            handleClose();
            dispatch(submitFilter());
        }
    };

    useEffect(() => {
        if (!loading) setReload(false);
    }, [loading]);

    return (
        <Card sx={sx ? sx : null}>
            {(headerTitle || showReload || showDownload) && (
                <CardHeader
                    title={<Typography variant="h3">{headerTitle}</Typography>}
                    action={
                        <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
                            {showDateRange && (
                                <IconButton aria-label="reload" disabled={loading} onClick={handleClick} size="small">
                                    <DateRangeIcon />
                                </IconButton>
                            )}
                            {showReload && (
                                <IconButton
                                    aria-label="reload"
                                    disabled={loading}
                                    onClick={() => {
                                        setReload(true);
                                        handleReload && handleReload();
                                    }}
                                    sx={{
                                        animation: reload && loading ? `${rotateAnimation} 2s linear infinite` : 'none'
                                    }}
                                    size="small"
                                >
                                    <ReplayIcon />
                                </IconButton>
                            )}
                            {showDownload && (
                                <>
                                    <IconButton aria-label="file-download" onClick={handleExport} disabled={loading} size="small">
                                        <FileDownloadIcon />
                                    </IconButton>
                                </>
                            )}
                        </Stack>
                    }
                />
            )}
            {children && (
                <>
                    {showDivider && <Divider />}
                    <CardContent sx={isNotCard ? { pr: '0px', pl: '0px', backgroundColor: cardcolor || '#f2f3f3' } : null}>
                        {children}
                    </CardContent>
                </>
            )}

            {showReportTime && (
                <CardActions>
                    <Box sx={{ flexGrow: 1 }} />
                    <Typography variant="body2" color="text.secondary">
                        This report was generated on {reportTime}
                    </Typography>
                </CardActions>
            )}
            {showDateRange && (
                <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                >
                    <DateRangePicker
                        open={Boolean(anchorEl)}
                        initialDateRange={{
                            startDate: new Date(dateRangePicker.startDate),
                            endDate: new Date(dateRangePicker.endDate)
                        }}
                        toggle={togglePicker}
                        onChange={handleChangeDateRangePicker}
                    />
                </Popover>
            )}
        </Card>
    );
}

export default MainCard;
