import { Box } from '@mui/material';
import InsightCategories from './InsightCategories';

interface CorrectValidationInsightProps {
    validationInsights: any;
}

export default function CorrectValidationInsight({ validationInsights }: CorrectValidationInsightProps) {
    return (
        <Box sx={{ mt: '10px', color: '#7c818e', fontSize: '11px', lineHeight: '24px' }}>
            {validationInsights.mmcrawlo && (
                <Box>
                    Included in <b>{validationInsights.mmcrawlo}</b> because:
                </Box>
            )}
            {validationInsights.existKeywords.length > 0 && (
                <Box>
                    1. It contains : <span style={{ color: 'red' }}>{validationInsights.existKeywords.join(' ; ')}</span>
                </Box>
            )}
            {validationInsights.missingKeywords.length > 0 && (
                <Box>
                    2. It doesn&apos;t contain any of the following words :
                    <span style={{ color: 'red' }}>{validationInsights.missingKeywords.join(' ; ')}</span>
                </Box>
            )}

            <InsightCategories missingWebsiteCategories={validationInsights.missingWebsiteCategories} />
        </Box>
    );
}
