import { Box, Stack, Typography } from '@mui/material';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';

interface FlagsProps {
    flags?: string[];
    sx?: any;
}

function Flags({ flags = [], sx = { my: 2 } }: FlagsProps) {
    const { isDark } = useAppSelector((state: RootState) => state.auth);

    return (
        <Stack direction={'row'} sx={sx} rowGap={1} columnGap={1} flexWrap={'wrap'}>
            {flags.map((flag: string, index: number) => (
                <Box key={index} sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: 1,
                            backgroundColor: `rgba(238, 240, 241, ${isDark ? 0.55 : 1})`,
                            width: 'auto',
                            height: 28,
                            borderTopLeftRadius: 5,
                            borderBottomLeftRadius: 5
                        }}
                    >
                        <Typography variant="body2" fontSize={11}>
                            {flag}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            borderTop: '14px solid transparent',
                            borderBottom: '14px solid transparent',
                            borderLeft: `14px solid rgba(238, 240, 241, ${isDark ? 0.55 : 1})`
                        }}
                    />
                </Box>
            ))}
        </Stack>
    );
}

export default Flags;
