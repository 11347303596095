import {
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    Typography,
    DialogContentText,
    TextField,
    Button,
    DialogActions
} from '@mui/material';
import { useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';

interface CustomCollectionSaveModalProps {
    open: boolean;
    handleClose: () => void;
    handleCreate: () => void;
    handleNameChanged: (tr: string) => void;
    customCollectionName?: string;
}

function CustomCollectionSaveModal({
    open,
    handleClose,
    handleCreate,
    customCollectionName,
    handleNameChanged
}: CustomCollectionSaveModalProps) {
    const [value, setValue] = useState<string>(customCollectionName || '');

    useEffect(() => {
        if (customCollectionName) {
            setValue(customCollectionName);
        }
    }, [customCollectionName]);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{ sx: { borderRadius: '20px' } }}
        >
            <DialogTitle>
                <Typography variant="h5" component="p">
                    New Custom Collection Name
                </Typography>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <CloseIcon />
                </IconButton>{' '}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>Please provide The Name of the new custom collection, once it is ready.</DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="customCollectionName"
                    label="Custom Collection Name"
                    type="text"
                    value={value}
                    onChange={(event: any) => {
                        setValue(event?.target.value);
                        handleNameChanged(event?.target.value);
                    }}
                    fullWidth
                    variant="standard"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleCreate}>Ok</Button>
            </DialogActions>
        </Dialog>
    );
}

export default CustomCollectionSaveModal;
