import { useEffect, useState } from 'react';
import { Box, Drawer, useMediaQuery, List, ListItem, Button, ListItemButton, ListItemIcon, ListItemText, Theme } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import AddIcon from '@mui/icons-material/Add';
import Scrollbar from 'components/common/UI/ScrollBar';
import { SidebarWidth } from '../../../theme/theme-variable';
import { useLazyQuery } from '@apollo/client';
import useChat from 'hooks/useChat';
import { NavLink, useNavigate } from 'react-router-dom';
import { GET_CHATS } from 'views/dashboard/AssistantPage/queries/queries';
import { useAppSelector } from 'store/hooks';

interface SideBarProps {
    isMobileSidebarOpen: boolean;
    isSidebarOpen: boolean;
    handleCloseMobileDrawer: () => void;
}

function SidebarChat({ isMobileSidebarOpen, isSidebarOpen, handleCloseMobileDrawer }: SideBarProps) {
    const navigate = useNavigate();
    const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
    const { currentAccess, id } = useChat();
    const { isDark } = useAppSelector((state: any) => state.auth);

    // State to hold chat items
    const [chats, setChats] = useState<any[]>([]);

    // Initialize chats once on component mount
    // useEffect(() => {
    //     setChats([
    //         { name: 'Improve Products In PG 124', id: '4787wy98734-924-109' },
    //         { name: 'Top 10 Products in Televisions', id: '4787wy98734-924-110' },
    //         { name: 'My Situation in PG 124', id: '4787wy98734-924-111' }
    //     ]);
    // }, []);

    const [getThereads, { data: dataChats, loading: loadingChats, error: errorChats }] = useLazyQuery(GET_CHATS, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache'
    });

    // const [CreateThread, { data: dataCreateThread, loading: loadingCreateThread, error: errorCreateThread }] = useLazyQuery(CREATE_ID, {
    //     notifyOnNetworkStatusChange: true,
    //     fetchPolicy: 'no-cache'
    // });

    useEffect(() => {
        const userID = currentAccess.user.id; // Example user ID, if applicable
        if (userID) {
            getThereads({
                variables: {
                    userID: userID
                }
            });
        }
    }, [currentAccess.user.id, id]); // Ensure it updates on user ID change

    // Log dataChats when it changes
    useEffect(() => {
        // setChatStarted(true);
        if (dataChats) {
            if (dataChats.getThereads) {
                if (dataChats.getThereads.data) {
                    setChats(dataChats.getThereads.data);
                }
            } else {
                console.error('Unexpected structure of dataChats', dataChats);
            }
        }
    }, [dataChats]);

    // Function to add a new chat
    const addNewChat = async () => {
        // Call CreateThread to create a new thread
        try {
            navigate(`/assistant`);
        } catch (err) {
            console.error('Error creating thread:', err);
        }
    };

    const SidebarContent = (
        <List>
            {/* "New Chat" button */}
            <ListItem disablePadding sx={{ mb: 2 }}>
                <Button
                    startIcon={<AddIcon />}
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={{ mb: 1, textTransform: 'none' }}
                    onClick={addNewChat}
                >
                    New Chat
                </Button>
            </ListItem>

            {/* Chat items */}
            {chats.map((item, index) => (
                <ListItemButton
                    key={item.id}
                    // selected={id === item.id}
                    component={NavLink}
                    to={`/assistant/${item.id}`}
                    sx={{
                        mb: 1,
                        pl: '8px',
                        pr: '8px',
                        bgcolor:
                            id === item.id
                                ? isDark
                                    ? '#f96654'
                                    : '#f96654' // Orange background for selected item
                                : isDark
                                ? '#212121'
                                : '#e8eaed', // Default background based on theme
                        color: id === item.id ? 'white' : isDark ? '#ffffff' : '#3c4043', // Text color based on selection and theme
                        borderRadius: '8px',
                        '&:hover': {
                            bgcolor:
                                id === item.id
                                    ? isDark
                                        ? '#FFA07A'
                                        : '#FFB84D' // Lighter orange on hover when selected
                                    : isDark
                                    ? '#333333'
                                    : '#d3d3d3', // Darker hover effect for unselected item
                            color: isDark ? 'white' : 'black' // White text in dark mode, black in light mode
                        }
                    }}
                    onClick={() => handleCloseMobileDrawer()}
                >
                    <ListItemIcon>
                        <ChatIcon
                            sx={{
                                color: id === item.id ? 'white' : isDark ? 'white' : 'black' // Icon color based on selection and theme
                            }}
                        />
                    </ListItemIcon>
                    <ListItemText
                        primary={item.name ? item.name.substring(0, 20) + (item.name.length > 20 ? '...' : '') : `New Chat ${index + 1}`}
                        title={item.name} // Shows the full name on hover
                    />
                </ListItemButton>
            ))}
        </List>
    );

    if (lgUp) {
        return (
            <Drawer
                anchor="right"
                open={isSidebarOpen}
                variant="persistent"
                PaperProps={{
                    sx: {
                        width: SidebarWidth,
                        top: lgUp ? '100px' : '0px',
                        right: lgUp ? '30px' : '',
                        borderRadius: lgUp ? '9px' : '0',
                        border: '0',
                        height: 'calc(100vh - 130px)',
                        boxShadow: '0px 7px 30px 0px rgb(90 114 123 / 11%)'
                    }
                }}
            >
                <Scrollbar style={{ height: 'calc(100vh - 5px)' }}>
                    <Box sx={{ p: 2 }}>{SidebarContent}</Box>
                </Scrollbar>
            </Drawer>
        );
    } else {
        return (
            <Drawer
                anchor="right"
                open={isMobileSidebarOpen}
                onClose={handleCloseMobileDrawer}
                PaperProps={{
                    sx: {
                        width: SidebarWidth,
                        border: '0 !important'
                    }
                }}
                variant="temporary"
            >
                <Scrollbar style={{ height: 'calc(100vh - 5px)' }}>
                    <Box sx={{ p: 2 }}>{SidebarContent}</Box>
                </Scrollbar>
            </Drawer>
        );
    }
}

export default SidebarChat;
