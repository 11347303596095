import { Grid, FormControl, Select, MenuItem, Link, Stack, Box, OutlinedInput } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { useLocation } from 'react-router-dom';

import SearchTag from './SearchTag';
import GoogleCategory from './field/GoogleCategory';
import Module from './field/Module';
import ProductGroup from './field/ProductGroup';
import WebsiteCategory from './field/WebsiteCategory';
import { useAppSelector } from 'store/hooks';
import Rating from './field/Rating';
import CustomCollection from './field/CustomCollection';
import ThirdParty from './field/ThirdParty';
import MMFlagsInput from './field/MMFlagsInput';

export interface ModuleOption {
    id?: string;
    label?: string;
}

export interface GoogleCategoryOption {
    ref?: string;
    label?: string;
}

interface InputFilterItemProps {
    filterData: any;
    id: string;
    checkOption?: string;
    includeExclude?: string;
    mmsModuleValue?: any;
    productGroupValue?: any;
    googleCategoryValue?: any;
    thirdPartyValue?: any;
    brandValue?: any;
    fieldSearch: string;
    exactWebsiteCategory: any;
    exactWebsiteOption: any;
    relevance?: string;
    relevanceOption?: string;
    maxPrice?: number;
    minPrice?: number;
    maxDelivery?: number;
    minDelivery?: number;
    ratingValue?: number;
    ratingOption?: any;
    listingStatusValue?: string;
    reviewValue?: number;
    reviewOption?: any;
    searchType?: string;
    searchData?: [];
    hideAddingInput?: boolean;
    filterFields?: string[];
    searchLoading?: boolean;
    websites: [];
    customCollectionValue: any;
    isRemoveButton: boolean;
    assortmentTypeValue: string;
    assortmentAvailabilityValue: string;
    flags: string[];
    handleAdd: () => void;
    handleRemove?: () => void;
    handleChangeSearchField: (event: SelectChangeEvent) => void;
    handleChangeCheckOption: (event: SelectChangeEvent) => void;
    handleChangeSearchTags: (id: string, tags: string[]) => void;
    changeBetterWayValue: (id: string, betterWayValue: string) => void;
    handleChangeRelevanceOption: (event: SelectChangeEvent, id: string) => void;
    handleChangeRelevance: (event: React.ChangeEvent<HTMLInputElement>, id: string) => void;
    handleChangeFlags: (event: React.SyntheticEvent, value: any[], id: string) => void;
    handleChangeMaxPrice: (event: React.ChangeEvent<HTMLInputElement>, id: string) => void;
    handleChangeMinPrice: (event: React.ChangeEvent<HTMLInputElement>, id: string) => void;
    handleChangeMaxDelivery: (event: React.ChangeEvent<HTMLInputElement>, id: string) => void;
    handleChangeMinDelivery: (event: React.ChangeEvent<HTMLInputElement>, id: string) => void;
    handleChangeRatingRange: (event: React.ChangeEvent<HTMLInputElement>, id: string) => void;
    handleChangeRatingOption: (event: SelectChangeEvent, id: string) => void;
    handleChangeReviewValue: (event: React.ChangeEvent<HTMLInputElement>, id: string) => void;
    handleChangeReviewOption: (event: SelectChangeEvent, id: string) => void;
    handleChangeListingStatus: (event: SelectChangeEvent, id: string) => void;
    handleAssortmentTypeChange: (event: SelectChangeEvent, id: string) => void;
    handleAssortmentAvailabilityChange: (event: SelectChangeEvent, id: string) => void;
}

function InputFilterItem(props: InputFilterItemProps) {
    const {
        filterData,
        id,
        fieldSearch,
        checkOption,
        includeExclude,
        mmsModuleValue,
        productGroupValue,
        googleCategoryValue,
        thirdPartyValue,
        searchData,
        relevance,
        relevanceOption,
        maxPrice,
        minPrice,
        maxDelivery,
        minDelivery,
        ratingValue,
        ratingOption,
        reviewValue,
        reviewOption,
        listingStatusValue,
        exactWebsiteCategory,
        exactWebsiteOption,
        hideAddingInput,
        filterFields,
        isRemoveButton,
        customCollectionValue,
        assortmentTypeValue,
        assortmentAvailabilityValue,
        flags,
        handleAdd,
        handleRemove,
        handleChangeSearchField,
        handleChangeCheckOption,
        handleChangeSearchTags,
        changeBetterWayValue,
        handleChangeRelevanceOption,
        handleChangeRelevance,
        handleChangeFlags,
        handleChangeMaxPrice,
        handleChangeMinPrice,
        handleChangeMaxDelivery,
        handleChangeMinDelivery,
        handleChangeRatingRange,
        handleChangeRatingOption,
        handleChangeReviewValue,
        handleChangeReviewOption,
        handleChangeListingStatus,
        handleAssortmentTypeChange,
        handleAssortmentAvailabilityChange
    } = props;

    const location = useLocation();
    const { isDark } = useAppSelector((state: any) => state.auth);

    const fieldSearchItem = filterData;
    return (
        <Stack direction="column">
            <Grid container spacing={1}>
                {/* Filter Field */}
                <Grid item xs={12} md={2}>
                    <FormControl fullWidth size="small">
                        <Select value={fieldSearch} displayEmpty onChange={handleChangeSearchField} name={id}>
                            {filterFields
                                ? filterFields.map((item: any) => (
                                      <MenuItem key={item} value={item}>
                                          {item}
                                      </MenuItem>
                                  ))
                                : fieldSearchItem.map((item: any) => (
                                      <MenuItem key={item.value} value={item.value}>
                                          {item.name}
                                      </MenuItem>
                                  ))}
                        </Select>
                    </FormControl>
                </Grid>

                {/* Filter Check Option */}
                {fieldSearch != 'Module' &&
                    fieldSearch != 'Product Group' &&
                    fieldSearch != 'Custom Category' &&
                    fieldSearch != '3rd Party Category' && (
                        <Grid item xs={12} md={2}>
                            <FormControl fullWidth size="small">
                                {fieldSearch != 'Keywords' && fieldSearch != 'Flags' && fieldSearch != 'Brand Name' ? (
                                    <Select
                                        name={id}
                                        value={includeExclude === 'Include' ? 'Is' : 'Is Not'}
                                        onChange={handleChangeCheckOption}
                                    >
                                        {checkOptionIncludeExclude.map((item: string, index: number) => (
                                            <MenuItem key={index} value={item}>
                                                {item}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                ) : (
                                    <Select name={id} value={checkOption} onChange={handleChangeCheckOption}>
                                        {checkOptionKeywords.map((item: string, index: number) => (
                                            <MenuItem key={index} value={item}>
                                                {item}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                            </FormControl>
                        </Grid>
                    )}

                {fieldSearch == 'Listing Status' && (
                    <Grid item xs={12} md>
                        <Select
                            size="small"
                            fullWidth
                            value={listingStatusValue}
                            onChange={(event: SelectChangeEvent) => handleChangeListingStatus(event, id)}
                        >
                            {location.pathname !== '/assortment/opportunities' && <MenuItem value={'OPPORTUNITIES'}>Opportunity</MenuItem>}
                            {location.pathname !== '/assortment/opportunities' && (
                                <MenuItem value={'LISTED'}>Already Listed (Same EAN)</MenuItem>
                            )}
                            <MenuItem value={'LISTED_DIFF_EAN'}>Already Listed (different EAN)</MenuItem>
                            <MenuItem value={'MARKETPLACE_OPPORTUNITY'}>Marketplace Opportunity</MenuItem>
                            <MenuItem value={'CANNOT_LISTED'}>Cannot be listed</MenuItem>
                            <MenuItem value="CAN_LISTED">Can be listed</MenuItem>
                            <MenuItem value="PROCESSING">In process to be listed</MenuItem>
                            <MenuItem value="DELISTING">Delisting candidate</MenuItem>
                            <MenuItem value="OTHERS">Others</MenuItem>
                        </Select>
                    </Grid>
                )}

                {fieldSearch == 'Module' && (
                    <Grid item xs={12} md>
                        <Module id={id} mmsModuleValue={mmsModuleValue} isMultiple />
                    </Grid>
                )}

                {fieldSearch == 'Product Group' && (
                    <Grid item xs={12} md>
                        <ProductGroup id={id} productGroupValue={productGroupValue} isMultiple />
                    </Grid>
                )}

                {fieldSearch == '3rd Party Category' && (
                    <Grid item xs={12} md>
                        <ThirdParty id={id} thirdPartyValue={thirdPartyValue} />
                    </Grid>
                )}

                {fieldSearch == 'Keywords' && (
                    <Grid item xs={12} md={8}>
                        <SearchTag
                            tagsData={searchData || []}
                            tagPlaceholder="Keywords"
                            onChangeTag={(tags: string[]) => handleChangeSearchTags(id, tags)}
                        />
                    </Grid>
                )}

                {fieldSearch == 'Assortment type' && (
                    <Grid item xs={12} md>
                        <Stack direction={'row'} spacing={1}>
                            <FormControl fullWidth size="small">
                                <Select
                                    value={assortmentTypeValue}
                                    displayEmpty
                                    onChange={(event: SelectChangeEvent) => handleAssortmentTypeChange(event, id)}
                                >
                                    <MenuItem value={'All'}>All</MenuItem>
                                    <MenuItem value={'Inventory'}>Inventory</MenuItem>
                                    <MenuItem value={'Marketplace'}>Marketplace</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl fullWidth size="small">
                                <Select
                                    value={assortmentAvailabilityValue}
                                    displayEmpty
                                    onChange={(event: SelectChangeEvent) => handleAssortmentAvailabilityChange(event, id)}
                                >
                                    <MenuItem value={'All Products'}>All Products</MenuItem>
                                    <MenuItem value={'In Stock'}>In Stock</MenuItem>
                                    <MenuItem value={'Out Of Stock'}>Out Of Stock</MenuItem>
                                    <MenuItem value={'Store Pick Up Only'}>Store Pick Up Only</MenuItem>
                                    <MenuItem value={'Online Only'}>Online Only</MenuItem>
                                </Select>
                            </FormControl>
                        </Stack>
                    </Grid>
                )}

                {fieldSearch == 'BetterWay' && (
                    <Grid item xs={12} md={8}>
                        <FormControl size="small" sx={{ width: '100%' }}>
                            <Select labelId="better-way-select-label" sx={{ width: '100%' }} id="better-way-select" defaultValue="">
                                <MenuItem value="yes" onClick={() => changeBetterWayValue(id, 'yes')}>
                                    Yes
                                </MenuItem>
                                <MenuItem value="no" onClick={() => changeBetterWayValue(id, 'no')}>
                                    No
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                )}

                {fieldSearch == 'Google Category' && (
                    <Grid item xs={12} md>
                        <GoogleCategory id={id} googleCategoryValue={googleCategoryValue} isMultiple />
                    </Grid>
                )}

                {fieldSearch == 'Website Category' && (
                    <Grid item xs={12} md>
                        <WebsiteCategory id={id} exactWebsiteCategory={exactWebsiteCategory} exactWebsiteOption={exactWebsiteOption} />
                    </Grid>
                )}

                {fieldSearch == 'Brand Name' && (
                    <Grid item xs={12} md={8}>
                        <SearchTag
                            tagsData={searchData || []}
                            tagPlaceholder="Brands"
                            onChangeTag={(tags: string[]) => handleChangeSearchTags(id, tags)}
                        />
                    </Grid>
                )}

                {fieldSearch == 'Rating' && (
                    <Grid item xs={12} md>
                        <Rating
                            value={ratingValue}
                            option={ratingOption}
                            handleChangeOption={(event: SelectChangeEvent) => handleChangeRatingOption(event, id)}
                            handleChangeValue={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeRatingRange(event, id)}
                        />
                    </Grid>
                )}
                {fieldSearch == 'Reviews' && (
                    <Grid item xs={12} md>
                        <Rating
                            value={reviewValue}
                            option={reviewOption}
                            handleChangeOption={(event: SelectChangeEvent) => handleChangeReviewOption(event, id)}
                            handleChangeValue={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeReviewValue(event, id)}
                        />
                    </Grid>
                )}

                {fieldSearch == 'Delivery Duration' && (
                    <Grid item xs={12} md>
                        <Stack direction={'row'} spacing={1}>
                            <FormControl fullWidth size="small">
                                <OutlinedInput
                                    fullWidth
                                    id="min-delivery"
                                    type={'number'}
                                    name="min-delivery"
                                    placeholder="Min Delivery"
                                    value={minDelivery}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeMinDelivery(event, id)}
                                />
                            </FormControl>
                            <FormControl fullWidth size="small">
                                <OutlinedInput
                                    fullWidth
                                    id="max-delivery"
                                    type={'number'}
                                    name="max-delivery"
                                    placeholder="Max Delivery"
                                    value={maxDelivery}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeMaxDelivery(event, id)}
                                />
                            </FormControl>
                        </Stack>
                    </Grid>
                )}

                {fieldSearch == 'Price Range' && (
                    <Grid item xs={12} md>
                        <Stack direction={'row'} spacing={1}>
                            <FormControl fullWidth size="small">
                                <OutlinedInput
                                    fullWidth
                                    id="min-price"
                                    type={'number'}
                                    name="min-price"
                                    placeholder="Min Price"
                                    value={minPrice}
                                    inputProps={{ min: 0 }}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeMinPrice(event, id)}
                                />
                            </FormControl>
                            <FormControl fullWidth size="small">
                                <OutlinedInput
                                    fullWidth
                                    id="max-price"
                                    type={'number'}
                                    name="max-price"
                                    placeholder="Max Price"
                                    value={maxPrice}
                                    inputProps={{ min: 0 }}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeMaxPrice(event, id)}
                                />
                            </FormControl>
                        </Stack>
                    </Grid>
                )}

                {fieldSearch == 'Flags' && (
                    <Grid item xs={12} md>
                        <MMFlagsInput id={id} value={flags} handleChangeFlags={handleChangeFlags} />
                        {/* <Autocomplete
                            multiple
                            clearOnEscape
                            size="small"
                            id="tags-standard"
                            onChange={(event: React.SyntheticEvent, value: ModuleOption[]) => handleChangeFlags(event, value, id)}
                            options={flags}
                            isOptionEqualToValue={(option, value) => option === value}
                            getOptionLabel={(option: any) => (option?.label ? option?.label : '')}
                            renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Search" size="small" />}
                        /> */}
                    </Grid>
                )}

                {fieldSearch == 'Relevance' && (
                    <Grid item xs={12} md>
                        <Stack direction={'row'} spacing={1}>
                            <FormControl fullWidth size="small">
                                <Select
                                    value={relevanceOption}
                                    onChange={(event: SelectChangeEvent) => handleChangeRelevanceOption(event, id)}
                                >
                                    <MenuItem value={'eq'}>Equal to (eq)</MenuItem>
                                    <MenuItem value={'gt'}>Greater than (gt)</MenuItem>
                                    <MenuItem value={'gte'}>Greater than or equal to (gte)</MenuItem>
                                    <MenuItem value={'lt'}>Less than (lt)</MenuItem>
                                    <MenuItem value={'lte'}>Less than or equal to (lte)</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl fullWidth size="small">
                                <OutlinedInput
                                    fullWidth
                                    id="relevance-range"
                                    type={'number'}
                                    name="rating-range"
                                    value={relevance}
                                    placeholder="Relevance"
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeRelevance(event, id)}
                                />
                            </FormControl>
                        </Stack>
                    </Grid>
                )}

                {fieldSearch == 'Custom Category' && (
                    <Grid item xs={12} md>
                        <CustomCollection id={id} value={customCollectionValue} />
                    </Grid>
                )}
            </Grid>
            {!hideAddingInput && (
                <Box sx={{ pt: 1 }}>
                    <Link
                        color={isDark ? '#fff' : 'rgb(66, 65, 62)'}
                        underline="always"
                        sx={{ py: 0, px: 0.5, mr: 1, cursor: 'pointer' }}
                        onClick={handleAdd}
                    >
                        Add
                    </Link>

                    {isRemoveButton && (
                        <Link color="rgb(242, 15, 32)" underline="always" sx={{ py: 0, px: 0.5, cursor: 'pointer' }} onClick={handleRemove}>
                            Remove
                        </Link>
                    )}
                </Box>
            )}
        </Stack>
    );
}

const checkOptionKeywords = ['Containing', 'Not Containing', 'Begins With', 'Exactly matching'];
const checkOptionIncludeExclude = ['Is', 'Is Not'];

export default InputFilterItem;
