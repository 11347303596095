import { Snackbar, Alert, SnackbarCloseReason, Box } from '@mui/material';
import Slide, { SlideProps } from '@mui/material/Slide';
import AlertTitle from '@mui/material/AlertTitle';

import ReactHtmlParser from 'react-html-parser';
import { useSelector } from 'react-redux';

import { RootState } from 'store/index';
import { useNotification } from 'hooks/useNotification';

function SlideTransition(props: SlideProps) {
    return <Slide {...props} direction="left" />;
}

export const Notification = (): JSX.Element => {
    const notification = useSelector((state: RootState) => state.notification);
    const { removeNotification } = useNotification();

    const handleClose = (_: unknown, reason?: SnackbarCloseReason) => reason !== 'clickaway' && removeNotification();

    return (
        <Snackbar
            open={notification.open}
            autoHideDuration={notification.timeout}
            onClose={handleClose}
            anchorOrigin={{ vertical: notification.vertical || 'bottom', horizontal: notification.horizontal || 'center' }}
            TransitionComponent={SlideTransition}
        >
            <Alert variant="filled" onClose={handleClose} severity={notification.type} sx={{ color: '#fff' }}>
                <AlertTitle>{notification.type}</AlertTitle>
                <Box>{ReactHtmlParser(notification.message || '')}</Box>
            </Alert>
        </Snackbar>
    );
};
