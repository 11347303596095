import { checkCurruncy } from 'utils/functions';
import { Region, regionCurrency } from 'constants/region-currency/regionCurrency';
import { useAppSelector } from 'store/hooks';
import { RootState } from 'store';

interface CurrencyFormatterProps {
    amount?: number;
    websiteId?: string;
    isNotignoreZero?: boolean;
}

function CurrencyFormatter({ amount, websiteId, isNotignoreZero }: CurrencyFormatterProps) {
    const { selectedRegion } = useAppSelector((state: RootState) => state.auth);

    let currentRegionConfig = regionCurrency[selectedRegion?.toLowerCase() as Region];

    if (selectedRegion.toLowerCase() == 'me') {
        if (websiteId && checkCurruncy(selectedRegion, websiteId)) {
            currentRegionConfig = regionCurrency['sa'];
        } else currentRegionConfig = regionCurrency['ae'];
    }

    const formatter = new Intl.NumberFormat(currentRegionConfig.ISO, {
        style: 'currency',
        currency: currentRegionConfig.currency,
        minimumFractionDigits: currentRegionConfig.minimumFractionDigits
    });

    return (isNotignoreZero && amount === 0) || amount ? <span>{formatter.format(amount)}</span> : <span>no price</span>;
}

export default CurrencyFormatter;
