import { MouseEvent, useState } from 'react';
import { Stack, Typography, Tooltip, Link, useTheme, Popover, Divider, Box, IconButton } from '@mui/material';
import { BsShop } from 'react-icons/bs';

import CurrencyFormatter from 'components/common/CurrencyFormatter';
import { checkLostProduct, getColorBasedOnAvailability, getProductAvailability } from 'utils/availability';
import { hexToRGB } from 'utils/functions';
import WebsiteImage from './WebsiteImage';
import Scrollbar from 'components/common/UI/ScrollBar';

interface WebSiteItemProps {
    name: string;
    price: number;
    websiteId: string;
    websiteAlias: string;
    availability: string;
    url: string;
    websiteLastDataDate?: string;
    productLastUpdate?: string;
    marketplace?: boolean;
    product: any;
}

function WebSiteItem({
    name,
    price,
    websiteId,
    websiteAlias,
    availability,
    url,
    websiteLastDataDate,
    productLastUpdate,
    marketplace,
    product
}: WebSiteItemProps) {
    const theme = useTheme();

    const websiteColor = getColorBasedOnAvailability(availability, websiteLastDataDate, productLastUpdate, theme);
    const websiteUrl = new URL(url).origin;

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <Stack
            spacing={1}
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ p: '2px', borderRadius: 1, border: `1px solid ${websiteColor}`, backgroundColor: hexToRGB(websiteColor, 0.1) }}
        >
            <Tooltip title={websiteAlias} placement="top">
                <Box sx={{ cursor: 'pointer' }}>
                    <WebsiteImage name={name} websiteId={websiteId} websiteUrl={url} />
                </Box>
            </Tooltip>
            <Link href={url} underline="none" target="_blank" rel="noopener noreferrer">
                <Tooltip
                    title={`${name} - ${getProductAvailability(availability, websiteLastDataDate, productLastUpdate)}`}
                    placement="top"
                    sx={{ cursor: 'pointer' }}
                >
                    <Typography
                        fontSize={12}
                        fontWeight={400}
                        variant="body2"
                        color={getColorBasedOnAvailability(availability, websiteLastDataDate, productLastUpdate, theme)}
                    >
                        <CurrencyFormatter amount={price} websiteId={websiteId} />
                    </Typography>
                </Tooltip>
            </Link>
            {/* marketplace */}
            {marketplace && (
                <>
                    <Tooltip title={`Markeplace`} placement="top" sx={{ cursor: 'pointer' }}>
                        <IconButton onClick={handleClick} size="small">
                            <BsShop style={{ color: theme.palette.grey[600], cursor: 'pointer' }} fontSize={14} />
                        </IconButton>
                    </Tooltip>
                    {product.offers.length > 1 && (
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left'
                            }}
                        >
                            <Scrollbar style={{ maxHeight: '250px', maxWidth: '230px' }}>
                                <Stack direction={'column'} divider={<Divider flexItem />} spacing={2} sx={{ p: 1 }}>
                                    {product.offers.map((offer: Offer, index: number) => {
                                        return (
                                            <Box
                                                key={index}
                                                onClick={() => {
                                                    if (offer.url) window.open(`${websiteUrl}${offer.url}`);
                                                }}
                                                sx={{
                                                    cursor: offer.url ? 'pointer' : 'text'
                                                }}
                                            >
                                                <Stack key={index} direction={'row'} spacing={1} divider={<Divider flexItem />}>
                                                    {/* marketplace */}
                                                    {offer?.marketplace && (
                                                        <Tooltip title={`Markeplace`} placement="top" sx={{ cursor: 'pointer' }}>
                                                            <Typography fontSize={13} fontWeight={400} variant="body2">
                                                                <BsShop />
                                                            </Typography>
                                                        </Tooltip>
                                                    )}
                                                    <Typography fontSize={13}>
                                                        <CurrencyFormatter amount={offer.price} websiteId={product?.websiteId} />
                                                    </Typography>
                                                    <Typography
                                                        fontSize={13}
                                                        color={getColorBasedOnAvailability(
                                                            offer.availability,
                                                            product?.websiteLastDataDate,
                                                            product?.productLastUpdate,
                                                            theme
                                                        )}
                                                    >
                                                        {checkLostProduct(
                                                            offer.availability,
                                                            product?.websiteLastDataDate,
                                                            product?.productLastUpdate
                                                        )}
                                                    </Typography>
                                                </Stack>
                                            </Box>
                                        );
                                    })}
                                </Stack>
                            </Scrollbar>
                        </Popover>
                    )}
                </>
            )}
        </Stack>
    );
}

export default WebSiteItem;
