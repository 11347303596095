export default (filter: any, defaultname: any) => {
    let name = defaultname;
    if (filter && filter.inputsList) {
        const customer = filter.inputsList.filter((el: any) => el.customcategory && el.customcategory.name);
        if (customer && customer.length) {
            name = `${customer[0].customcategory.name} ${defaultname ? ' - ' + defaultname : ''}`;
        } else {
            const mms = filter.inputsList.filter((el: any) => el.mmscategory && el.mmscategory.name);
            if (mms && mms.length) {
                name = `${mms[0].mmscategory.name} ${defaultname ? ' - ' + defaultname : ''}`;
            } else {
                const mmmms = filter.inputsList.filter((el: any) => el.mmscategorymm && el.mmscategorymm.name);
                if (mmmms && mmmms.length) {
                    name = `${mmmms[0].mmscategorymm.name} ${defaultname ? ' - ' + defaultname : ''}`;
                }
            }
            const mainPG = filter.inputsList.filter((el: any) => el.mainPG && el.mainPG.name);
            if (mainPG && mainPG.length) {
                name = `${mainPG[0].mainPG.name} ${defaultname ? ' - ' + defaultname : ''}`;
            }
            const mmsmodule = filter.inputsList.filter((el: any) => el.mmsmodule && el.mmsmodule.name);
            if (mmsmodule && mmsmodule.length) {
                name = `${mmsmodule[0].mmsmodule.name} ${defaultname ? ' - ' + defaultname : ''}`;
            }
            const google = filter.inputsList.filter((el: any) => el.google && el.google.name);
            if (google && google.length) {
                name = `${google[0].google.name} ${defaultname ? ' - ' + defaultname : ''}`;
            }
        }
        // mutltiselect for google and modules/pgs
        const googlefilter = filter.inputsList.find((el: any) => el.google && el.google.length);
        if (googlefilter && googlefilter.google && googlefilter.google.length) {
            const googleNames = googlefilter.google
                .slice(0, 2)
                .map((el: any) => el.name)
                .join('_');
            if (googleNames) {
                name = `${googleNames} ${defaultname ? ' - ' + defaultname : ''}`;
            }
        }
        const modules = filter.inputsList.find((el: any) => el.mmsmodule && el.mmsmodule.length);
        if (modules && modules.mmsmodule && modules.mmsmodule.length) {
            const moduleNames = modules.mmsmodule
                .slice(0, 2)
                .map((el: any) => el.name)
                .join(' _ ');
            if (moduleNames) {
                name = `${moduleNames} ${defaultname ? ' - ' + defaultname : ''}`;
            }
        }
        const pgs = filter.inputsList.find((el: any) => el.mmscategory && el.mmscategory.length);
        if (pgs && pgs.mmscategory && pgs.mmscategory.length) {
            const pgNames = pgs.mmscategory
                .slice(0, 2)
                .map((el: any) => el.name)
                .join(' _ ');
            if (pgNames) {
                name = `${pgNames} ${defaultname ? ' - ' + defaultname : ''}`;
            }
        }
    }
    return name;
};
