import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, NextLink, Operation, split } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { onError } from '@apollo/client/link/error';
import { GraphQLError } from 'graphql';
import { getMainDefinition } from '@apollo/client/utilities';

import config from 'config';

const cookie: string = localStorage.getItem('sessionId') || '';

// HTTP link for queries and mutations
const httpLink = new HttpLink({
    uri: config.graphqlAPIUrl,
    fetchOptions: {
        mode: 'cors'
    }
});

// WebSocket link for subscriptions
const wsLink = new WebSocketLink({
    uri: config.graphqlAPIUrl.replace('https', 'wss'), // `wss://4242.integ.crawlo.com/graphql`
    options: {
        reconnect: true,
        connectionParams: {
            Cookie: `__UserId__token=${cookie}` // Ensure cookie is set correctly
        }
    }
});

// Split link to route operations to either WebSocket or HTTP
const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    httpLink
);

// Authentication link
const authLink = new ApolloLink((operation: Operation, forward: NextLink) => {
    operation.setContext({
        headers: {
            Cookie: `__UserId__token=${cookie}`
        },
        credentials: 'include'
    });
    return forward(operation);
});

// setContext((_, { headers }) => {
//     return {
//         headers: {
//             ...headers,
//             Cookie: `__UserId__token=${cookie}`
//         },
//         credentials: 'include'
//     };
// });

// Error handling link
const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
        const Unauthorized = graphQLErrors.filter((error: GraphQLError) => error.message === 'Unauthorized');
        if (Unauthorized.length) {
            console.log('Unauthorized :', Unauthorized);
            const appVersion = localStorage.getItem('appVersion');
            localStorage.clear();
            if (appVersion) localStorage.setItem('appVersion', appVersion);
            console.log('Unauthorized! Redirecting...');
            window.location.href = '/login';
        }
    }
});

// Apollo Client setup
const apolloClient = new ApolloClient({
    link: ApolloLink.from([errorLink, authLink, splitLink]),
    cache: new InMemoryCache({ addTypename: false }),
    defaultOptions: {
        query: {
            fetchPolicy: 'no-cache'
        },
        mutate: {
            fetchPolicy: 'no-cache'
        }
    }
});

export default apolloClient;
