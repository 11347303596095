import { useEffect, useState } from 'react';
import {
    Grid,
    TableBody,
    TableContainer,
    Table,
    TableHead,
    Typography,
    useTheme,
    Stack,
    Link,
    Box,
    SxProps,
    Tab,
    Tooltip,
    Theme,
    FormControl,
    Chip,
    InputLabel,
    Select,
    MenuItem
} from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import { useMutation, useQuery } from '@apollo/client';
import { Link as RouterLink } from 'react-router-dom';
import { FiExternalLink } from 'react-icons/fi';
import { BsShop } from 'react-icons/bs';

import CurrencyFormatter from 'components/common/CurrencyFormatter';
import EChart from 'components/common/UI/EChart';
import AnalysisLoading from 'components/common/skeletons/AnalysisLoading';
import { checkLostProduct, getColorBasedOnAvailability } from 'utils/availability';
import { priceHistoryOption } from 'constants/chart-config/analysis';
import { useAppSelector } from 'store/hooks';
import ProductImagesCarousel from 'components/product/ProductImagesCarousel';
import OffersList from './OffersList';
import PositionAnalysis from 'components/analysis/PositionAnalysis';
import VisibilityAnalysis from 'components/analysis/VisibilityAnalysis';
import { StyledTableCell, StyledTableRow } from 'components/common/UI/tables';
import WebsiteImage from 'components/product/Websites/WebsiteImage';
import { RootState } from 'store';
import { PRICE_HISTORY_ANALYSIS } from 'services/analysis/queries';
import moment from 'moment';
import { FaCheckCircle } from 'react-icons/fa';
import { ReviewedStatusType, defaultReviewerdStatusOptions, listedReviewerdStatusAlreadyOptions } from 'components/product/ProductCard';
import { useNotification } from 'hooks/useNotification';
import { CHANGE_REVIEWED_STATUS_MUTATION } from 'services/settings/queries';

interface PriceHistoryAnalysisProps {
    productRef?: string;
    reload?: boolean;
    trackingSince?: string;
    sx?: SxProps;
    reviewed?: any;
    reviewedStatus?: any;
    showListingStatus?: boolean;
}

function PriceHistoryAnalysis({
    productRef,
    reload,
    trackingSince,
    sx,
    reviewed,
    showListingStatus,
    reviewedStatus
}: PriceHistoryAnalysisProps) {
    const theme: Theme = useTheme();
    const { displayNotification } = useNotification();

    const { selectedRegion, userWebsites, selectedWebsites, accessAccountUser, userInfo } = useAppSelector(
        (state: RootState) => state.auth
    );
    const { dateRangePicker } = useAppSelector((state: RootState) => state.filter);

    const [products, setProducts] = useState<Product[]>([]);
    const [defaultProduct, setDefaultProduct] = useState<Product>();
    const [bestPrice, setBestPrice] = useState<number>();
    const [maxPrice, setMaxPrice] = useState<number>();
    const [options, setOptions] = useState(priceHistoryOption);
    const [productNotFound, setProductNotFound] = useState<boolean>(false);
    const [isProductsVisibility, setIsProductsVisibility] = useState<boolean>(false);
    const [isProductsPositions, setIsProductsPositions] = useState<boolean>(false);
    const [value, setValue] = useState<string>('1');
    const [currentReviewed, setCurrentReviewed] = useState<any>({ ...reviewed, reviewedStatus });

    const [changeMatchStatus] = useMutation(CHANGE_REVIEWED_STATUS_MUTATION);
    const currentAccess: any[] = accessAccountUser.filter((item: any) => item.account.config.region == selectedRegion);

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const websites = userWebsites.filter((item: any) => item.region === selectedRegion)[0]?.websites;
    const configureChartOption = (options: any, products: any, dataObj: string, axis: string) => {
        options.title.text = '';
        if (!products[dataObj][axis].data || !products[dataObj][axis].data.length) {
            options.title.text = "Nothing to Show 🥺. There's no data to load, please check your filter.";
            return options;
        }
        if (products && products.error) {
            options.title.text = `${products.error}`;
            return;
        }
        options[axis].data = products[dataObj][axis].data;
        options.series = products[dataObj].series;

        const names: any = [];
        options.series.forEach((item: any) => {
            if (item.name && !item.name.includes('Availability')) {
                names.push(item.name as never);
            }
        });
        options.legend.data = names;

        return options;
    };

    const { loading, error, data, refetch } = useQuery(PRICE_HISTORY_ANALYSIS, {
        variables: {
            region: selectedRegion,
            ref: productRef,
            websites: selectedWebsites.length == 0 ? websites : selectedWebsites,
            fromDate: moment(dateRangePicker.endDate).subtract(3, 'months').format('YYYY-MM-DD'),
            toDate: moment(dateRangePicker.endDate).format('YYYY-MM-DD')
        },
        notifyOnNetworkStatusChange: true
    });

    const showToastMessage = (msg: string, success = true) => {
        displayNotification({
            message: `${msg}`,
            type: success ? 'success' : 'error',
            horizontal: 'right'
        });
    };

    const changeProductReviewedStatus = async (id?: string, match?: any, status?: string, cReviewed?: ReviewedStatusType) => {
        if (match) {
            await changeMatchStatus({
                variables: {
                    region: selectedRegion,
                    cacheID: '',
                    id,
                    match,
                    status,
                    user: userInfo?.id,
                    account: currentAccess.length ? currentAccess[0].account.id : ''
                }
            })
                .then((res) => {
                    if (res.data.changeReviewedStatus.id) {
                        showToastMessage(`Cool ! your changes just been saved to our database :) `, true);
                        setCurrentReviewed({ ...cReviewed, reviewedStatus: cReviewed });
                    } else {
                        showToastMessage(`Oops ! something go wrong retry later !`, false);
                    }
                })
                .catch((err) => {
                    console.log('error :', err);
                });
        }
    };

    const reviewedStatusOptions = reviewedStatus?.status == 'LISTED' ? listedReviewerdStatusAlreadyOptions : defaultReviewerdStatusOptions;

    useEffect(() => {
        const accountComponents: any[] = accessAccountUser.filter((item: any) => item.account.config.region == selectedRegion);

        if (accountComponents.length) {
            const components = accountComponents[0].account.components;

            const productsVisibility = components.filter((item: any) => item.name == 'productsvisibility' && item.allowed);
            setIsProductsVisibility(productsVisibility.length ? true : false);

            const productsPositions = components.filter((item: any) => item.name == 'productspositions' && item.allowed);
            setIsProductsPositions(productsPositions.length ? true : false);
        }
    }, []);

    useEffect(() => {
        if (!loading) refetch();
    }, [reload]);

    useEffect(() => {
        if (data) {
            if (data?.priceHistory?.products?.length == 0) {
                setProductNotFound(true);
            } else {
                setProductNotFound(false);
                setProducts(data.priceHistory.products);
                setDefaultProduct(data.priceHistory.defaultProduct);

                const chartOptions = configureChartOption(priceHistoryOption, data, 'priceHistory', 'xAxis');
                setOptions(chartOptions);

                if (data?.priceHistory?.products?.length) {
                    const productsData: Product[] =
                        data?.priceHistory?.products?.filter(
                            (product: Product) => product?.offers?.list && product?.offers?.list?.length && product?.offers?.list[0]?.price
                        ) || [];

                    const offersData: Offer[] = [];

                    productsData.forEach((product: Product) => {
                        if (product.offers.list && product.offers.list.length) {
                            product.offers.list.forEach((offer: Offer) => {
                                if (offer.price) offersData.push(offer);
                            });
                        }
                    });

                    if (productsData.length) {
                        const minPriceOffer: Offer = offersData?.reduce((prev: Offer, current: Offer) =>
                            prev.price < current.price ? prev : current
                        );
                        if (minPriceOffer?.price) {
                            setBestPrice(minPriceOffer.price);
                        } else {
                            setBestPrice(0);
                        }

                        const maxPriceOffer: Offer = offersData?.reduce((prev: Offer, current: Offer) =>
                            prev.price > current.price ? prev : current
                        );

                        if (maxPriceOffer?.price) {
                            setMaxPrice(maxPriceOffer.price);
                        } else {
                            setMaxPrice(0);
                        }
                    }
                }
            }
        }
    }, [loading]);

    if (loading) return <AnalysisLoading />;

    if (error) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 300 }}>
                <Typography>An error occurred. Please try again later.</Typography>
            </Box>
        );
    }

    if (productNotFound) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 300 }}>
                <Typography>Product Not Found.</Typography>
            </Box>
        );
    }

    return (
        <Grid container spacing={1} sx={sx} justifyContent={'center'} alignItems={'center'}>
            <Grid container spacing={2} justifyContent={'center'} alignItems={'flex-start'}>
                <Grid item xs={12} md={3}>
                    <Box sx={{ mt: '10px' }}>
                        <Grid item xs={12}>
                            <Tooltip title={defaultProduct?.name} placement="top">
                                <Typography
                                    component={'p'}
                                    variant="h4"
                                    color={theme.palette.secondary.main}
                                    sx={{
                                        lineHeight: '1.5em',
                                        height: '3em',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        width: '100%'
                                    }}
                                >
                                    <Link
                                        component={RouterLink}
                                        variant="h4"
                                        to={`/product?id=${productRef}`}
                                        color={theme.palette.secondary.main}
                                        underline="hover"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {defaultProduct?.name}
                                    </Link>
                                </Typography>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12} sx={{ mt: '10px' }}>
                            <ProductImagesCarousel
                                images={
                                    defaultProduct?.images?.length
                                        ? defaultProduct?.images
                                        : defaultProduct?.image
                                        ? [defaultProduct?.image]
                                        : []
                                }
                            />
                        </Grid>
                        {showListingStatus && (
                            <Grid justifyContent="center" alignItems="center" container spacing={1} sx={{ p: 2 }}>
                                <Grid item xs={4}>
                                    <Chip
                                        label={currentReviewed?.reviewedStatus?.name}
                                        avatar={<FaCheckCircle color={currentReviewed?.reviewedStatus?.textColor} />}
                                        disabled={currentReviewed?.reviewedStatus?.disabled}
                                        size="small"
                                        sx={{
                                            backgroundColor: currentReviewed?.reviewedStatus?.color,
                                            color: currentReviewed?.reviewedStatus?.textColor
                                        }}
                                    />
                                </Grid>
                                {/* reviewed status */}

                                <Grid item xs={8}>
                                    {currentReviewed && currentReviewed.status && (
                                        <FormControl fullWidth size="small">
                                            <InputLabel id="listing-select-label">Listing</InputLabel>
                                            <Select
                                                defaultValue=""
                                                value={currentReviewed.status}
                                                labelId="listing-select-label"
                                                id="listing-select"
                                                label="Listing"
                                            >
                                                {reviewedStatusOptions.map((rev: ReviewedStatusType, index: number) => {
                                                    return (
                                                        <MenuItem
                                                            value={rev.status}
                                                            onClick={() =>
                                                                changeProductReviewedStatus(
                                                                    currentReviewed.id,
                                                                    defaultProduct?.ean || productRef,
                                                                    rev.status,
                                                                    rev
                                                                )
                                                            }
                                                            key={index}
                                                        >
                                                            {rev.name}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    )}
                                </Grid>
                            </Grid>
                        )}
                        <Grid item xs={12} sx={{ mt: '10px' }}>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <StyledTableRow>
                                            <StyledTableCell colSpan={2}>Product Detail</StyledTableCell>
                                        </StyledTableRow>
                                    </TableHead>
                                    <TableBody>
                                        <StyledTableRow>
                                            <StyledTableCell component="th" scope="row" width={150}>
                                                <Typography fontSize={13}>Brand</Typography>
                                            </StyledTableCell>

                                            <StyledTableCell width={150}>
                                                <Typography fontSize={13}>{defaultProduct?.brand?.name}</Typography>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <StyledTableCell component="th" scope="row" width={150}>
                                                <Typography fontSize={13}>EAN</Typography>
                                            </StyledTableCell>

                                            <StyledTableCell width={150}>
                                                <Typography fontSize={13}>{defaultProduct?.ean ? defaultProduct?.ean : '-'}</Typography>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <StyledTableCell component="th" scope="row" width={150}>
                                                <Typography fontSize={13}>Tracking since</Typography>
                                            </StyledTableCell>

                                            <StyledTableCell width={150}>
                                                <Typography fontSize={13}>
                                                    {trackingSince ? `${moment(trackingSince).format('YYYY-MM-DD')}` : '-'}
                                                </Typography>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <StyledTableCell component="th" scope="row" width={150}>
                                                <Typography fontSize={13}>Best Price</Typography>
                                            </StyledTableCell>

                                            <StyledTableCell width={150}>
                                                <Typography fontSize={13} color={theme.palette.success.main}>
                                                    <CurrencyFormatter amount={bestPrice} websiteId={defaultProduct?.website?.id} />
                                                </Typography>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Box>
                </Grid>

                <Grid item xs={12} md={9}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="products details">
                                <Tab label="Price History" value="1" />
                                {isProductsPositions && <Tab label="Positions Tracking" value="2" />}
                                {isProductsVisibility && <Tab label="Visibility Tracking" value="3" />}
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <Box>
                                <Grid item xs={12}>
                                    <EChart height="300px" id="price-history-chart" option={options} />
                                </Grid>
                                <Grid item xs={12} sx={{ mt: '2px' }}>
                                    <Grid container spacing={2} justifyContent={'center'} alignItems={'flex-start'}>
                                        <Grid item xs={12}>
                                            <TableContainer>
                                                <Table>
                                                    <TableHead>
                                                        <StyledTableRow>
                                                            <StyledTableCell>Website</StyledTableCell>
                                                            <StyledTableCell>Price</StyledTableCell>
                                                            <StyledTableCell>Stock</StyledTableCell>
                                                            <StyledTableCell>Updated</StyledTableCell>
                                                        </StyledTableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {products.map((item: Product, index: number) => (
                                                            <StyledTableRow key={index.toString()}>
                                                                <StyledTableCell component="th" scope="row" width={150}>
                                                                    <Stack direction="row" alignItems={'center'} spacing={0.7}>
                                                                        <WebsiteImage
                                                                            name={item?.website?.name || ''}
                                                                            websiteId={item?.website?.id || ''}
                                                                            websiteUrl={item?.website?.url}
                                                                            height={20}
                                                                            width={20}
                                                                        />
                                                                        <Link
                                                                            underline="hover"
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                            href={item?.website?.url + item.url}
                                                                            variant="h4"
                                                                            color={theme.palette.secondary.main}
                                                                            fontSize={13}
                                                                        >
                                                                            {item?.website?.name}
                                                                        </Link>
                                                                        <FiExternalLink color={theme.palette.secondary.main} />
                                                                    </Stack>
                                                                </StyledTableCell>
                                                                <StyledTableCell component="th" scope="row" width={150}>
                                                                    <Stack direction={'row'} spacing={2} alignItems={'center'}>
                                                                        {item.offers.list.length ? (
                                                                            <Stack
                                                                                direction={'row'}
                                                                                justifyContent={'space-between'}
                                                                                rowGap={1}
                                                                                key={index}
                                                                                sx={{ py: 1, px: 2 }}
                                                                            >
                                                                                {/* marketplace */}
                                                                                {item.offers.list[0]?.marketplace && (
                                                                                    <Tooltip
                                                                                        title={`Markeplace`}
                                                                                        placement="top"
                                                                                        sx={{ cursor: 'pointer', mr: '5px' }}
                                                                                    >
                                                                                        <Typography
                                                                                            fontSize={13}
                                                                                            fontWeight={600}
                                                                                            variant="body2"
                                                                                        >
                                                                                            <BsShop />
                                                                                        </Typography>
                                                                                    </Tooltip>
                                                                                )}
                                                                                <Typography
                                                                                    fontSize={13}
                                                                                    color={
                                                                                        item.offers?.list[0]?.price == bestPrice
                                                                                            ? theme.palette.success.main
                                                                                            : item?.offers?.list[0]?.price == maxPrice
                                                                                            ? theme.palette.error.main
                                                                                            : 'text.primary'
                                                                                    }
                                                                                >
                                                                                    <CurrencyFormatter
                                                                                        amount={item?.offers?.list[0]?.price}
                                                                                        websiteId={item?.website?.id}
                                                                                    />
                                                                                </Typography>
                                                                            </Stack>
                                                                        ) : (
                                                                            'No Price'
                                                                        )}
                                                                        {item.offers.list.length > 1 && (
                                                                            <>
                                                                                <OffersList product={item} />
                                                                            </>
                                                                        )}
                                                                    </Stack>
                                                                </StyledTableCell>

                                                                <StyledTableCell component="th" scope="row" width={150}>
                                                                    {item.offers?.list?.length ? (
                                                                        <Typography
                                                                            fontSize={13}
                                                                            color={getColorBasedOnAvailability(
                                                                                item?.offers.list[0].availability,
                                                                                item?.website?.data?.lastDataDate,
                                                                                item?.lastUpdate,
                                                                                theme
                                                                            )}
                                                                        >
                                                                            {checkLostProduct(
                                                                                item.offers.list[0].availability,
                                                                                item?.website?.data?.lastDataDate,
                                                                                item?.lastUpdate
                                                                            )}
                                                                        </Typography>
                                                                    ) : (
                                                                        'No Availability'
                                                                    )}
                                                                </StyledTableCell>

                                                                <StyledTableCell component="th" scope="row" width={150}>
                                                                    <Typography fontSize={13}>{item.lastUpdate}</Typography>
                                                                </StyledTableCell>
                                                            </StyledTableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </TabPanel>
                        {isProductsPositions && (
                            <TabPanel value="2">
                                <PositionAnalysis productRef={productRef} sx={{ p: 3, pt: '5px' }} />
                            </TabPanel>
                        )}
                        {isProductsVisibility && (
                            <TabPanel value="3">
                                <VisibilityAnalysis productRef={productRef} sx={{ p: 3, pt: '5px' }} />
                            </TabPanel>
                        )}
                    </TabContext>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default PriceHistoryAnalysis;
