import GfkVariantsAnalysis from 'components/analysis/GfkVariantsAnalysis';
import { useTheme } from '@mui/material/styles';
import { Dialog, DialogContent, Typography, IconButton, DialogTitle, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface GfkVariantsModalProps {
    open: boolean;
    handleClose: () => void;
    gfkId?: string;
    gfkSku?: string;
    gfkMonthAbrv?: string;
}

function GfkVariantsModal({ open, handleClose, gfkId, gfkSku, gfkMonthAbrv }: GfkVariantsModalProps) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullScreen={fullScreen}
            fullWidth
            maxWidth={'xl'}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ m: 0, p: 0 }}
            scroll="paper"
            PaperProps={{ sx: { borderRadius: '20px' } }}
        >
            <DialogTitle id="scroll-dialog-title">
                <Typography variant="h5" component="p">
                    Product Variants - {gfkSku}
                </Typography>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers={true}>
                <GfkVariantsAnalysis gfkId={gfkId} monthAbrv={gfkMonthAbrv} />
            </DialogContent>
        </Dialog>
    );
}

export default GfkVariantsModal;
