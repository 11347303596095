import { useEffect, useState } from 'react';
// import axios from 'axios';
// import config from 'config';
import { useAppSelector } from 'store/hooks';
import { RootState } from 'store';
import { useLocation, Location, useNavigate, useParams } from 'react-router-dom';
import { useLazyQuery, useSubscription } from '@apollo/client';
import { CHAT, GET_MESSAGES_CHAT, MESSAGE_CHUNK_SUBSCRIPTION } from 'views/dashboard/AssistantPage/queries/queries';
// import { closeWebSocketConnection, wsClient } from './wsClient';
// import { WebSocketLink } from '@apollo/client/link/ws';

interface ChatMessage {
    text: string;
    sender: string;
    createdAt?: string;
}

const useChat = () => {
    const [messages, setMessages] = useState<ChatMessage[]>([]);
    const [chatStarted, setChatStarted] = useState(false);
    const [chatloading, setLoading] = useState(false);
    const [step, setStep] = useState(false);
    const [suggestions, setSuggestions] = useState<string[]>([]);
    const [showMoreOptions, setShowMoreOptions] = useState(false);
    const [errorOpenAI, setErrorOpenAI] = useState<any>(false);
    const { id } = useParams(); // Access the optional ID parameter
    const { selectedRegion, accessAccountUser } = useAppSelector((state: RootState) => state.auth);
    const currentAccess: any = accessAccountUser.find((item: any) => item.account.config.region == selectedRegion);
    const navigate = useNavigate();
    const location: Location = useLocation();
    const [currentMessage, setCurrentMessage] = useState<string>('');
    const [threadId, setThreadId] = useState<string | null>(null);
    // Use `skip` to only subscribe when `id` exists
    useEffect(() => {
        if (id) {
            setThreadId(id);
        } else if (currentAccess?.user?.id) {
            setThreadId(currentAccess.user.id);
        }
    }, [id, currentAccess?.user?.id]);

    // Subscription that only activates when `threadId` is set
    const { data: chunkData, error: chunkError } = useSubscription(MESSAGE_CHUNK_SUBSCRIPTION, {
        variables: { threadId },
        skip: !threadId // Skip subscription if `threadId` is not ready
    });

    const [getChatMessages, { data: dataChatMessages, error: dataChatError }] = useLazyQuery(GET_MESSAGES_CHAT, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache'
    });

    // Append each received chunk to messages
    useEffect(() => {
        if (chunkData?.messageChunk) {
            const { id, step, messageChunk } = chunkData.messageChunk;
            if (id) {
                navigate(`/assistant/chat/${id}`);
            } else if (step) {
                setStep(step);
            } else if (messageChunk) {
                if (chatloading || step) {
                    setLoading(false);
                    setStep(false);
                } // Set loading to false
                setCurrentMessage((prev) => prev + messageChunk); // Accumulate chunks in the current message
            }
        }
    }, [chunkData]);
    // console.log('id use chat', id);

    const handleMoreClick = () => {
        setShowMoreOptions(!showMoreOptions); // Toggle more options visibility
    };
    const handleClickOnSuggestion = (suggestion: string) => {
        console.log('suggestion', suggestion);
        // setInputValue(suggestion); // Set the input value to the selected suggestion
        setSuggestions([]); // Clear the suggestions
    };

    const handleClickOnOption = async (label: string) => {
        console.log('label', label);
        // setInputValue(label); // Set the input value to the selected label
        await handleSuggestions(); // Send the message
    };

    const [Chat, { data: dataChat, loading: loadingChat, error: errorChat }] = useLazyQuery(CHAT, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache'
    });

    const handleSuggestions = async () => {
        try {
            // setLoading(true);
            // const response = await axios.post("http://localhost:5000/api/suggest", {
            //   // Adjust this endpoint to your backend
            //   prompt: inputValue, // Send the input prompt
            // });

            // // console.log("response", response);
            // const aiMessage = JSON.parse(response.data);
            const aiMessage = [
                'Create image',
                'Code',
                'Make a plan',
                'Analyze images',
                'Schedule meeting',
                'Get directions',
                'Translate text',
                'Send email'
            ];
            setSuggestions(aiMessage);
            // setMessages((prev) => [...prev, aiMessage]);
        } catch (error: any) {
            console.error('Error sending message:', error.response?.data || error.message);
        } finally {
            // setLoading(false);
        }
    };

    const handleSend = async (inputValue: string) => {
        if (inputValue.trim()) {
            setLoading(true);
            const newMessage = { text: inputValue, sender: 'user' };
            setMessages((prevMessages) => [...prevMessages, newMessage]);

            try {
                // Trigger the lazy query with the required variables
                await Chat({
                    variables: {
                        threadID: id ? id : null,
                        prompt: inputValue,
                        currentAccess,
                        region: selectedRegion
                    }
                });
            } catch (error) {
                console.error('Error sending message:', error);
            }
        }
    };

    useEffect(() => {
        // setChatStarted(true);
        if (dataChatError?.message === 'THREAD_NOT_FOUND') {
            // Redirect to /assistant if thread does not exist
            navigate(`/assistant/chat`);
            return;
        }
        if (dataChatMessages) {
            if (dataChatMessages.getChatMessages) {
                // check if last msg comming from user or ai
                const lastMessage = dataChatMessages.getChatMessages.data[dataChatMessages.getChatMessages.data.length - 1];
                if (lastMessage.sender === 'user') {
                    // setMessages(dataChatMessages.getChatMessages.data);
                    setErrorOpenAI(
                        `Oops! We encountered an issue with OpenAI's server while processing your request.` +
                            ` [Refresh !!](${location.pathname})` +
                            ` or` +
                            ` [New chat !!](/assistant/chat)`
                    );
                } else {
                    setMessages(dataChatMessages.getChatMessages.data);
                }
                setLoading(false);
                setCurrentMessage('');
            } else {
                console.error('Unexpected structure of dataChatMessages', dataChatMessages);
            }
        }
    }, [dataChatMessages, dataChatError]);

    // Check if the data is available, then set AI response
    useEffect(() => {
        if (dataChat) {
            // const aiMessage = { text: dataChat.Chat.message.replace(/\\n/g, '\n'), sender: 'ai' };
            // setMessages((prevMessages) => [...prevMessages, aiMessage]);

            const threadInfo = dataChat.Chat.threadInfo;
            if (dataChat.Chat.error) {
                // setMessages((prevMessages) => {
                //     // Remove the last message and add the new one
                //     const updatedMessages = [...prevMessages];
                //     updatedMessages[updatedMessages.length] = {
                //         text: `We encountered an issue processing your request. can you please ` + `[Refresh !!](${location.pathname})`,
                //         sender: 'ai'
                //     };
                //     return updatedMessages;
                // });
                const userID = currentAccess.user.id; // Example user ID, if applicable
                if (userID && id) {
                    getChatMessages({
                        variables: {
                            userID: userID,
                            ThreadId: id
                        }
                    });
                }
            } else if (dataChat.Chat.message) {
                setMessages((prevMessages) => {
                    // Remove the last message and add the new one
                    const updatedMessages = [...prevMessages];
                    updatedMessages[updatedMessages.length] = {
                        text: dataChat.Chat.message,
                        sender: 'ai'
                    };
                    return updatedMessages;
                });
                setLoading(false);
                setCurrentMessage('');
            }
            if (threadInfo && threadInfo.id && threadInfo.id !== id) {
                setLoading(false);
                navigate(`/assistant/chat/${threadInfo.id}`);
            }
        }
    }, [dataChat]); // Only run this effect when dataChat is updated

    useEffect(() => {
        if (errorChat) {
            console.error('Error loading chat:', errorChat);
        }
    }, [errorChat]);

    return {
        showMoreOptions,
        handleMoreClick,
        loadingChat,
        chunkError,
        messages,
        setMessages,
        chatStarted,
        setChatStarted,
        chatloading: chatloading,
        handleSend,
        handleClickOnOption,
        suggestions,
        setSuggestions,
        handleClickOnSuggestion,
        currentAccess,
        id,
        currentMessage,
        errorOpenAI,
        step
    };
};

export default useChat;
