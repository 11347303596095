import { Stack, FormControlLabel, Typography } from '@mui/material';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { submitFilter, updateBundle, updateMarketPlace, updateRefurbished, updateStock } from 'store/slices/filter/filterSlice';
import CustomCheckbox from 'components/common/custom-elements/CustomCheckbox';
import { RootState } from 'store';

interface AvailabilityFilterProps {
    showOutOfStock?: boolean;
}

function AvailabilityFilter({ showOutOfStock }: AvailabilityFilterProps) {
    const dispatch = useAppDispatch();
    const { selectedRegion, accessAccountUser } = useAppSelector((state: RootState) => state.auth);
    const { checkFilter } = useAppSelector((state: RootState) => state.filter);

    const currentAccess: any[] = accessAccountUser.filter((item: any) => item.account.config.region == selectedRegion);
    const accountData =
        currentAccess.length &&
        currentAccess[0].account.components.find((item: { name: string; allowed: boolean }) => item.name == 'brandslisting');
    const isBrandAcount = accountData ? accountData.allowed : false;

    const handleChangeBundle = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(updateBundle(!event.target.checked));
        dispatch(submitFilter());
    };

    const handleChangeStock = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(updateStock(!event.target.checked));
        dispatch(submitFilter());
    };

    const handleChangeMarketplace = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(updateMarketPlace(!event.target.checked));
        dispatch(submitFilter());
    };

    const handleChangeRefurbished = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(updateRefurbished(!event.target.checked));
        dispatch(submitFilter());
    };

    return (
        <Stack direction="row" alignItems="center">
            <FormControlLabel
                control={<CustomCheckbox checked={!checkFilter.bundles} size="medium" onChange={handleChangeBundle} />}
                label={<Typography variant="subtitle2">Bundles</Typography>}
                sx={{ p: 0, m: 0 }}
            />
            <FormControlLabel
                control={<CustomCheckbox checked={!checkFilter.hideMarketplace} size="small" onChange={handleChangeMarketplace} />}
                label={<Typography variant="subtitle2">Marketplace</Typography>}
                sx={{ p: 0, m: 0 }}
            />
            {showOutOfStock && (
                <FormControlLabel
                    control={<CustomCheckbox checked={!checkFilter.instock} size="small" onChange={handleChangeStock} />}
                    label={<Typography variant="subtitle2">Out of stock</Typography>}
                    sx={{ p: 0, m: 0 }}
                />
            )}
            {isBrandAcount ? null : (
                <FormControlLabel
                    control={<CustomCheckbox checked={!checkFilter.refurbished} size="small" onChange={handleChangeRefurbished} />}
                    label={<Typography variant="subtitle2">Refurbished</Typography>}
                    sx={{ p: 0, m: 0 }}
                />
            )}
        </Stack>
    );
}

export default AvailabilityFilter;
