import { useQuery } from '@apollo/client';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { MMS_MODULE_RESULT } from 'services/filterService/queries';
import { submitFilter, updateInputList, updateModuleQuery } from 'store/slices/filter/filterSlice';

interface ModuleProps {
    id?: string;
    mmsModuleValue: any;
    isCustomModule?: boolean;
    customQuery?: string;
    customHandleInputChange?: (query: string) => void;
    customHandleChange?: (option: any) => void;
}

function SingleModule({ id, mmsModuleValue, isCustomModule, customQuery, customHandleInputChange, customHandleChange }: ModuleProps) {
    const dispatch = useAppDispatch();

    const { selectedRegion } = useAppSelector((state) => state.auth);
    const { queryModule } = useAppSelector((state) => state.filter);

    const [moduleData, setModuleData] = useState<any[]>([]);

    const { loading, data } = useQuery(MMS_MODULE_RESULT, {
        variables: {
            region: selectedRegion,
            q: customQuery ? customQuery : queryModule,
            size: 100,
            show: true
        },
        notifyOnNetworkStatusChange: true
    });

    const handleChange = (event: React.SyntheticEvent, option: any, id?: string) => {
        if (isCustomModule && customHandleChange) {
            customHandleChange(option);
        } else {
            dispatch(updateInputList({ id, data: { checkOption: 'Is', mmsmodule: option } }));
            dispatch(submitFilter());
        }
    };

    useEffect(() => {
        if (data) {
            setModuleData(data.mmsmoduleresult.categories);
        }
    }, [loading]);

    return (
        <Autocomplete
            size="small"
            id="filter-module"
            fullWidth
            onChange={(event: React.SyntheticEvent<Element, Event>, option: any) => handleChange(event, option, id)}
            clearOnEscape
            value={mmsModuleValue || null}
            getOptionLabel={(item: any) => (item?.name ? item.name : '')}
            loading={loading}
            options={moduleData}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder="Module"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        )
                    }}
                    onChange={(event) => {
                        if (customHandleInputChange) {
                            customHandleInputChange(event.target.value);
                        } else {
                            dispatch(updateModuleQuery(event.target.value));
                        }
                    }}
                />
            )}
        />
    );
}

export default SingleModule;
