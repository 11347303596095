import { useQuery } from '@apollo/client';
import { Grid, TableBody, TableContainer, Table, TableHead, Typography, useTheme, Stack, Link, Box, SxProps } from '@mui/material';

import EChart from 'components/common/UI/EChart';
import AnalysisLoading from 'components/common/skeletons/AnalysisLoading';
import { configureChartOption } from 'utils/functions';
import { StyledTableCell, StyledTableRow } from 'components/common/UI/tables';
import { visibilityHistoryOption } from 'constants/chart-config/analysis';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { FiExternalLink } from 'react-icons/fi';
import { useAppSelector } from 'store/hooks';
import WebsiteImage from 'components/product/Websites/WebsiteImage';
import { scaleLogarithmically } from 'utils/scale';
import { PRICE_HISTORY_ANALYSIS } from 'services/analysis/queries';

interface VisibilityAnalysisProps {
    productRef?: string;
    reload?: boolean;
    sx?: SxProps;
}

function VisibilityAnalysis({ productRef, reload, sx }: VisibilityAnalysisProps) {
    const theme = useTheme();

    const { selectedRegion, userWebsites, selectedWebsites } = useAppSelector((state) => state.auth);
    const { dateRangePicker } = useAppSelector((state) => state.filter);

    const [products, setProducts] = useState<Product[]>([]);
    const [visibilityOptions, setVisibilityOptions] = useState(visibilityHistoryOption);
    const [productNotFound, setProductNotFound] = useState<boolean>(false);

    const websites = userWebsites.filter((item: any) => item.region === selectedRegion)[0]?.websites;
    const months = ['es', 'pt'].includes(selectedRegion.toLowerCase()) ? { count: 1, type: 'months' } : { count: 8, type: 'weeks' };

    const fromDateRange = moment(dateRangePicker.endDate)
        .subtract(months.count as any, months.type as any)
        .format('YYYY-MM-DD');

    const { loading, error, data, refetch } = useQuery(PRICE_HISTORY_ANALYSIS, {
        variables: {
            region: selectedRegion,
            ref: productRef,
            includeCategories: true,
            websites: selectedWebsites.length == 0 ? websites : selectedWebsites,
            fromDate: fromDateRange,
            toDate: dateRangePicker.endDate
        },
        notifyOnNetworkStatusChange: true
    });

    const calculateVisibility = (position: number) => {
        const reductionRate = 0.05;
        const initialValue = 100; // Starting value for position 1
        const value = initialValue * Math.pow(1 - reductionRate, position - 1);
        return parseFloat(value.toFixed(2));
    };

    useEffect(() => {
        if (!loading) refetch();
    }, [reload]);

    useEffect(() => {
        if (data) {
            if (data?.priceHistory?.products?.length == 0) {
                setProductNotFound(true);
            } else {
                setProductNotFound(false);
                const allprods: Product[] = [];
                data.priceHistory.products.map((el: any) => {
                    const categoriesVisibility = el.categories.map((pc: any) => {
                        return calculateVisibility(pc.position);
                    });
                    let visibility = 0;

                    if (categoriesVisibility.length) {
                        visibility = categoriesVisibility.reduce((a: number, b: number) => {
                            return a + b;
                        });
                    }
                    allprods.push({ ...el, visibility });
                });
                allprods.sort((a: any, b: any) => b.visibility - a.visibility);
                // current website selection
                setProducts(allprods);

                const chartOptions = configureChartOption(visibilityHistoryOption, data, 'priceHistory', 'xAxis');
                const currentChartOptions = { ...chartOptions };
                currentChartOptions.legend.data = [];
                currentChartOptions.series = [];
                // categories to chart
                if (data.priceHistory.categoriesData.data.length) {
                    data.priceHistory.categoriesData.data.map((datum: any) => {
                        const catObject = {
                            connectNulls: true,
                            data: [],
                            name: `${datum.website.alias} Visibility`,
                            symbolSize: 5,
                            yAxisIndex: 1,
                            type: 'line'
                        };
                        currentChartOptions.xAxis.data.map((el: any) => {
                            const checkDate = datum.data.filter((dt: any) => dt.date == el);
                            if (checkDate.length) {
                                const vv = checkDate[0].categories.map((pc: any) => {
                                    return calculateVisibility(pc.position);
                                });
                                const visibility = vv.reduce((a: number, b: number) => {
                                    return a + b;
                                });
                                catObject.data.push(scaleLogarithmically(visibility, false) as never);
                            } else {
                                catObject.data.push(null as never);
                            }
                        });
                        currentChartOptions.legend.data.push(`${datum.website.alias} Visibility`);
                        currentChartOptions.series.push(catObject as never);
                    });
                }

                setVisibilityOptions(currentChartOptions);
            }
        }
    }, [loading]);

    if (loading) return <AnalysisLoading />;

    if (error) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 300 }}>
                <Typography>An error occurred. Please try again later.</Typography>
            </Box>
        );
    }

    if (productNotFound) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 300 }}>
                <Typography>Product Not Found.</Typography>
            </Box>
        );
    }

    return (
        <Grid container spacing={1} sx={sx} justifyContent={'center'} alignItems={'center'}>
            <Grid item xs={12} md={12}>
                <EChart height="300px" id="visibility-price-history-chart" option={visibilityOptions} />
            </Grid>

            <Grid item xs={12} sx={{ mt: '2px' }}>
                <Grid container spacing={2} justifyContent={'center'} alignItems={'flex-start'}>
                    <Grid item xs={12} md={12}>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <StyledTableRow>
                                        <StyledTableCell>Website</StyledTableCell>
                                        <StyledTableCell>Categories</StyledTableCell>
                                        <StyledTableCell>Visibility</StyledTableCell>
                                        <StyledTableCell>Date</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {products.map((item: Product, index: number) => (
                                        <StyledTableRow key={index.toString()}>
                                            <StyledTableCell component="th" scope="row" width={150}>
                                                <Stack direction="row" alignItems={'center'} spacing={0.7}>
                                                    <WebsiteImage
                                                        name={item?.website?.name || ''}
                                                        websiteId={item?.website?.id || ''}
                                                        websiteUrl={item?.website?.url}
                                                        height={20}
                                                        width={20}
                                                    />

                                                    <Link
                                                        underline="hover"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        href={item?.website?.url + item.url}
                                                        variant="h4"
                                                        color={theme.palette.secondary.main}
                                                        fontSize={13}
                                                    >
                                                        {item?.website?.name}
                                                    </Link>
                                                    <FiExternalLink color={theme.palette.secondary.main} />
                                                </Stack>
                                            </StyledTableCell>
                                            <StyledTableCell component="th" scope="row" width={250}>
                                                <Box>
                                                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                                        {item?.categories &&
                                                            item.categories.map((websiteCategory: WebsiteCategory, index: number) => (
                                                                <Grid item xs={2} sm={4} md={4} key={index}>
                                                                    <Link
                                                                        underline="hover"
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        href={(item?.website?.url || '') + websiteCategory?.category?.url}
                                                                        variant="h4"
                                                                        color={theme.palette.primary.main}
                                                                        fontSize={13}
                                                                    >
                                                                        {websiteCategory?.category?.name}
                                                                    </Link>
                                                                </Grid>
                                                            ))}
                                                    </Grid>
                                                </Box>
                                            </StyledTableCell>

                                            <StyledTableCell component="th" scope="row" width={150}>
                                                <Typography fontSize={13}>{scaleLogarithmically(item.visibility)}</Typography>
                                            </StyledTableCell>

                                            <StyledTableCell component="th" scope="row" width={150}>
                                                <Typography fontSize={13}>{item.lastUpdate}</Typography>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default VisibilityAnalysis;
