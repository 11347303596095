import { Fragment, SyntheticEvent, useEffect, useState } from 'react';

import { Box, Tab, Tabs, Typography } from '@mui/material';
import { useLazyQuery } from '@apollo/client';
import { saveAs } from 'file-saver';

import ExcelJS from 'exceljs';

import EChart from 'components/common/UI/EChart';
import { betterWayChart } from 'constants/chart-config/betterWay';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import ChartLoading from 'components/common/skeletons/ChartLoading';
import { unSubmitFilter } from 'store/slices/filter/filterSlice';
import { useNotification } from 'hooks/useNotification';
import getFileNameFromSearch from 'utils/getFileNameFromSearch';
import MainCard from 'components/cards/MainCard';
import MyTabContainer from 'components/common/UI/TabContainer';
import { BETTER_WAY_DIST_QUERY } from 'services/betterWay/queries';
import exportBetterWayDataChart from 'utils/exportation/exportBetterWayDataChart';

function BetterWayOverview() {
    const dispatch = useAppDispatch();
    const { displayNotification } = useNotification();

    const { selectedWebsites, selectedRegion, defaultWebsite, accessAccountUser } = useAppSelector((state) => state.auth);
    const { checkFilter, inputList, isSubmitting, datePicker } = useAppSelector((state) => state.filter);

    const currentAccess: any[] = accessAccountUser.filter((item: any) => item.account.config.region == selectedRegion);

    const [betterOptions, setBetterOptions] = useState(betterWayChart);
    const [categoriesOptions, setCategoriesOptions] = useState({} as any);
    const [tabValue, settabValue] = useState<number>(0);

    const [getBetterWayDistOverview, { loading, error, data, refetch }] = useLazyQuery(BETTER_WAY_DIST_QUERY, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache'
    });

    const handleApply = () => {
        refetch({
            advancedSearch: inputList,
            region: selectedRegion,
            websites: selectedWebsites,
            hideBundles: checkFilter.bundles,
            hideRefurbished: checkFilter.refurbished,
            hideMarketplace: checkFilter.hideMarketplace,
            access: currentAccess.length ? currentAccess[0].id : '',
            config: currentAccess.length ? currentAccess[0].account.config : {},
            reviewedAccount: currentAccess.length ? currentAccess[0].account.id : '',
            loadReviewed: true,
            website: defaultWebsite,
            date: datePicker
        });
        dispatch(unSubmitFilter());
    };

    const handleExportFile = async () => {
        if (tabValue == 0) {
            const fileName = getFileNameFromSearch({ inputsList: inputList }, 'Better Way data');
            const result = exportBetterWayDataChart(betterOptions, fileName);
            if (!result) {
                displayNotification({
                    message: `Sorry Something bad happend during download ! Please contact us to help !`,
                    type: 'error',
                    horizontal: 'right'
                });
            }
        }
        if (tabValue == 1) {
            console.log('Exporting tab 1');
            console.log(categoriesOptions);
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Chart Data');

            // Add header row
            worksheet.addRow(['Category', ...categoriesOptions.legend.data]);

            // Add data rows using a for loop
            const categories = categoriesOptions.xAxis.data;
            const flaggedData = categoriesOptions.series[0].data;
            const nonFlaggedData = categoriesOptions.series[1].data;

            for (let i = 0; i < categories.length; i++) {
                worksheet.addRow([categories[i], flaggedData[i], nonFlaggedData[i]]);
            }

            // Write to buffer and trigger download
            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], { type: 'application/octet-stream' });
            saveAs(blob, 'chart_data.xlsx');
            // Add data rows
        }
    };

    const handleChangeTabValue = (event: SyntheticEvent, newValue: number) => {
        settabValue(newValue);
    };

    useEffect(() => {
        getBetterWayDistOverview({
            variables: {
                region: selectedRegion,
                websites: selectedWebsites,
                hideBundles: checkFilter.bundles,
                hideRefurbished: checkFilter.refurbished,
                hideMarketplace: checkFilter.hideMarketplace,
                instock: checkFilter.instock,
                advancedSearch: inputList,
                website: defaultWebsite,
                date: datePicker,
                access: currentAccess.length ? currentAccess[0].id : '',
                config: currentAccess.length ? currentAccess[0].account.config : {},
                reviewedAccount: currentAccess.length ? currentAccess[0].account.id : '',
                loadReviewed: true
            }
        });
    }, []);

    useEffect(() => {
        if (isSubmitting) {
            handleApply();
        }
    }, [isSubmitting]);

    useEffect(() => {
        if (data) {
            const result = data.betterWayDist;
            const BWOptions = {
                series: result.series,
                xAxis: result.xAxis
            };

            setBetterOptions({ ...betterWayChart, ...BWOptions });

            const categories = result.categories.map((item: any) => `${item.category_ids.buckets[0].key}-${item.key}`);
            const flaggedPercentages = result.categories.map((item: any) => item.category_ids.buckets[0].flagged.doc_count);
            const nonFlaggedPercentages = result.categories.map(
                (item: any) => item.category_ids.buckets[0].betterWay.doc_count - item.category_ids.buckets[0].flagged.doc_count
            );

            const optionsData = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {
                    data: ['MM Flagging Progress', 'MM Missing Flags'],
                    bottom: 0
                },
                xAxis: {
                    type: 'category',
                    data: categories
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        formatter: '{value}'
                    }
                },
                series: [
                    {
                        name: 'MM Flagging Progress',
                        type: 'bar',
                        stack: 'total',
                        data: flaggedPercentages,
                        itemStyle: {
                            color: '#DB4D3B'
                        }
                    },
                    {
                        name: 'MM Missing Flags',
                        type: 'bar',
                        stack: 'total',
                        data: nonFlaggedPercentages,
                        itemStyle: {
                            color: '#464C5B'
                        }
                    }
                ]
            };

            setCategoriesOptions(optionsData);
        }
    }, [loading]);

    if (error)
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 300 }}>
                <Typography>Somthing Wrong</Typography>
            </Box>
        );

    return (
        <MainCard
            headerTitle="Better Way Overview"
            showDownload
            showReload
            showReportTime
            handleReload={handleApply}
            handleExport={handleExportFile}
        >
            {loading ? (
                <ChartLoading />
            ) : (
                <Fragment>
                    <Box>
                        <Tabs value={tabValue} onChange={handleChangeTabValue} variant="fullWidth">
                            <Tab label="Better Way Distribution" />
                            <Tab label="Distribution by Category" />
                        </Tabs>
                    </Box>
                    <Box sx={{ pt: 4 }}>
                        {tabValue === 0 && (
                            <MyTabContainer>
                                {betterOptions.series.length > 0 ? (
                                    <EChart id="better-Options" option={betterOptions} opts={{ renderer: 'svg' }} />
                                ) : (
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 300 }}>
                                        <Typography>Nothing to show ! There is no data to load, please check your filter.</Typography>
                                    </Box>
                                )}
                            </MyTabContainer>
                        )}
                        {tabValue === 1 && (
                            <MyTabContainer>
                                {betterOptions.series.length > 0 ? (
                                    <EChart id="categories-Options" option={categoriesOptions} opts={{ renderer: 'svg' }} />
                                ) : (
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 300 }}>
                                        <Typography>Nothing to show ! There is no data to load, please check your filter.</Typography>
                                    </Box>
                                )}
                            </MyTabContainer>
                        )}
                    </Box>
                </Fragment>
            )}
        </MainCard>
    );
}

export default BetterWayOverview;
