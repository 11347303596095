import { FiBarChart2 /* , FiCompass*/, FiBox } from 'react-icons/fi';
import { FaShippingFast } from 'react-icons/fa';
import {
    // MdOutlinePriceChange,
    MdAnalytics,
    MdOutlineSubtitles,
    MdOutlineScoreboard,
    MdGeneratingTokens,
    MdOutlineContentPasteSearch,
    MdOutlineFiberNew,
    // MdOutlineWifiTethering,
    MdOutlineCampaign,
    MdAssuredWorkload,
    MdEventAvailable,
    MdOutlineWebAsset
} from 'react-icons/md';
import {
    AiOutlineAmazon,
    AiOutlineUnorderedList,
    AiOutlineGoogle,
    // AiOutlineStar,
    // AiOutlineHistory,
    AiOutlineDollar,
    AiOutlineClockCircle,
    AiOutlineBook,
    AiOutlinePropertySafety
} from 'react-icons/ai';
import { /* HiOutlineCalendarDays, HiViewColumns,*/ HiOutlineStar } from 'react-icons/hi2';

import { BsCheckAll, BsBezier /* , BsFillTicketPerforatedFill*/, BsBroadcastPin } from 'react-icons/bs';
import { IoPieChartOutline } from 'react-icons/io5';
import { BiCategory } from 'react-icons/bi';
import { TbLayout2, TbPerfume } from 'react-icons/tb';

export const sideBarItems = [
    {
        id: 'marketshare',
        navlabel: true,
        subheader: 'Market Analysis',
        icon: <IoPieChartOutline size={20} />
    },
    {
        id: 'marketshare',
        title: 'Market Share',
        path: '/assortment/marketshare',
        icon: <BiCategory size={20} />
    },
    {
        id: 'marketsharebybrands',
        title: 'Market Share By Brands',
        path: '/assortment/marketsharebybrands',
        icon: <BsBroadcastPin size={20} />
    },
    {
        id: 'assortment',
        navlabel: true,
        subheader: 'Catalog Optimization',
        icon: <FiBarChart2 size={20} />
    },
    {
        id: 'brandsoverview',
        title: 'Brands Overview',
        path: '/brands/overview',
        icon: <MdAssuredWorkload size={20} />
    },
    {
        id: 'brandsvisibility',
        title: 'Brands Visibility',
        path: '/brands/visibility',
        icon: <AiOutlineBook size={20} />
    },
    {
        id: 'brandpricing',
        title: 'Brands Pricing',
        path: '/brands/pricing',
        icon: <AiOutlinePropertySafety size={20} />
    },
    {
        id: 'brandswebsite',
        title: 'Brands Website',
        path: '/brands/website',
        icon: <MdOutlineWebAsset size={20} />
    },
    {
        id: 'assortment',
        title: 'Relevant Catalog',
        path: '/assortment/relevant',
        icon: <FiBox size={20} />
    },

    {
        id: 'listing',
        title: 'Listing Wizard',
        path: '/assortment/listing',
        icon: <AiOutlineUnorderedList size={20} />
    },

    {
        id: 'googlelisting',
        title: 'Google Listing Wizard',
        path: '/assortment/googlelisting',
        icon: <AiOutlineGoogle size={20} />
    },
    {
        id: 'googlelistingcustom',
        title: 'Google Listing Wizard',
        path: '/assortment/googlelistingcustom',
        icon: <AiOutlineGoogle size={20} />
    },
    {
        id: 'exclusive',
        title: 'Stock Exclusive',
        path: '/assortment/exclusive',
        icon: <TbLayout2 size={20} />
    },
    {
        id: 'campaignmanager',
        title: 'Custom Collections',
        path: '/custom/collections',
        icon: <MdOutlineCampaign size={20} />
    },
    {
        id: 'assortment',
        title: 'Catalog Tracking',
        path: '/catalog',
        icon: <AiOutlineClockCircle size={20} />
    },
    {
        id: 'assortment',
        title: 'Stock Status',
        path: '/catalog/availability',
        icon: <MdEventAvailable size={20} />
    },
    {
        id: 'assortment',
        title: 'Shipping Time',
        path: '/catalog/shipping',
        icon: <FaShippingFast size={20} />
    },
    {
        id: 'amazonanalytics',
        title: 'Amazon Best Sellers',
        path: '/amazonanalytics/amazonbestseller',
        icon: <AiOutlineAmazon size={20} />
    },
    {
        id: 'pricingcompetitors',
        title: 'Market Prices',
        path: '/pricing/competitors',
        icon: <AiOutlineDollar size={20} />
    },

    {
        id: 'opportunities',
        navlabel: true,
        subheader: 'Opportunity Discovery',
        icon: <FiBarChart2 size={20} />
    },
    {
        id: 'opportunities',
        title: 'Opportunities',
        path: '/assortment/opportunities',
        icon: <BsCheckAll size={20} />
    },
    {
        id: 'gfkproducts',
        title: 'GfK Insights',
        path: '/assortment/gfkproducts',
        icon: <TbPerfume size={20} />
    },
    // {
    //     id: 'gfkOpportunities',
    //     title: 'GFK Opportunities',
    //     path: '/assortment/gfkopportunities',
    //     icon: <BsFillTicketPerforatedFill size={20} />
    // },
    {
        id: 'newproducts',
        title: 'New Arrivals',
        path: '/assortment/newlydiscovered',
        icon: <MdOutlineFiberNew size={20} />
    },
    {
        id: 'betterway',
        title: 'Better Way',
        path: '/assortment/betterway',
        icon: <BsBezier size={20} />
    },
    // {
    //     id: 'assortment',
    //     title: 'Historical Crawl',
    //     path: '/assortment',
    //     icon: <FiCompass size={20} />
    // },
    // {
    //     id: 'newproducts',
    //     title: 'Newly Listed Products',
    //     path: '/assortment/newdescoveries',
    //     icon: <MdOutlineWifiTethering size={20} />
    // },
    // {
    //     id: 'assortment',
    //     title: 'Best Rated',
    //     path: '/assortment/bestrated',
    //     icon: <AiOutlineStar size={20} />
    // },

    // {
    //     id: 'pricing',
    //     navlabel: true,
    //     subheader: 'Pricing Intelligence',
    //     icon: <MdOutlinePriceChange size={20} />
    // },

    // {
    //     id: 'pricingdaily',
    //     title: 'Daily Changes',
    //     path: '/pricing/changes',
    //     icon: <HiOutlineCalendarDays size={20} />
    // },
    {
        id: 'content',
        navlabel: true,
        subheader: 'Content Analytics',
        icon: <MdOutlineContentPasteSearch size={20} />
    },
    {
        id: 'contentOverview',
        title: 'Content Analytics Overview',
        path: '/content/overview',
        icon: <MdAnalytics size={20} />
    },
    {
        id: 'contentAdvancedAnalytics',
        title: 'Advanced Content Analytics',
        path: '/content/analytics',
        icon: <MdGeneratingTokens size={20} />
    },
    {
        id: 'contentRating',
        title: 'Rating and Reviews',
        path: '/content/rating',
        icon: <HiOutlineStar size={20} />
    },
    {
        id: 'contentTitle',
        title: 'Title Analytics',
        path: '/content/titles',
        icon: <MdOutlineSubtitles size={20} />
    },
    {
        id: 'contentQaulityScore',
        title: 'Content Quality Score',
        path: '/content/qualityscore',
        icon: <MdOutlineScoreboard size={20} />
    }
];
