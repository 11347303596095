import { gql } from '@apollo/client';

export const GET_MESSAGES_CHAT = gql`
    query getChatMessages($userID: String, $ThreadId: String) {
        getChatMessages(userID: $userID, ThreadId: $ThreadId) {
            data {
                text
                sender
                createdAt
            }
        }
    }
`;

export const GET_SHARED_CHAT = gql`
    query getSharedChat($userID: String, $ThreadId: String) {
        getSharedChat(userID: $userID, ThreadId: $ThreadId) {
            data {
                text
                sender
                createdAt
            }
        }
    }
`;

export const JOIN_THREAD = gql`
    query JoinThread($userID: String, $threadId: String) {
        JoinThread(userID: $userID, threadId: $threadId) {
            id
        }
    }
`;

export const GET_CHATS = gql`
    query getThereads($userID: String) {
        getThereads(userID: $userID) {
            data {
                name
                id
            }
        }
    }
`;

export const GET_ALL_CHATS = gql`
    query getAllChats($userID: String) {
        getAllChats(userID: $userID) {
            username
            threadIdsInfos
        }
    }
`;

export const CREATE_ID = gql`
    query CreateThread($userID: String) {
        CreateThread(userID: $userID) {
            id
        }
    }
`;

export const CHAT = gql`
    query Chat($threadID: String, $prompt: String!, $currentAccess: JSON!, $region: String!) {
        Chat(threadID: $threadID, prompt: $prompt, currentAccess: $currentAccess, region: $region) {
            message
            threadInfo {
                id
            }
            error
        }
    }
`;

export const MESSAGE_CHUNK_SUBSCRIPTION = gql`
    subscription ($threadId: String!) {
        messageChunk(threadId: $threadId) {
            id
            messageChunk
            step
            done
        }
    }
`;

export const DELETE_CHAT = gql`
    query deleteThread($userID: String, $threadId: String) {
        deleteThread(userID: $userID, threadId: $threadId) {
            data {
                name
                id
            }
        }
    }
`;

export const RENAME_CHAT = gql`
    query renameThread($userID: String, $threadId: String, $newName: String) {
        renameThread(userID: $userID, threadId: $threadId, newName: $newName) {
            data {
                name
                id
            }
        }
    }
`;
