import { gql } from '@apollo/client';

export const GET_MESSAGES_CHAT = gql`
    query getChatMessages($userID: String, $ThreadId: String) {
        getChatMessages(userID: $userID, ThreadId: $ThreadId) {
            data {
                text
                sender
            }
        }
    }
`;

export const GET_CHATS = gql`
    query getThereads($userID: String) {
        getThereads(userID: $userID) {
            data {
                name
                id
            }
        }
    }
`;

export const CREATE_ID = gql`
    query CreateThread($userID: String) {
        CreateThread(userID: $userID) {
            id
        }
    }
`;

export const CHAT = gql`
    query Chat($threadID: String, $prompt: String!, $currentAccess: JSON!, $region: String!) {
        Chat(threadID: $threadID, prompt: $prompt, currentAccess: $currentAccess, region: $region) {
            message
            threadInfo {
                id
            }
        }
    }
`;

export const MESSAGE_CHUNK_SUBSCRIPTION = gql`
    subscription ($threadId: String!) {
        messageChunk(threadId: $threadId)
    }
`;
