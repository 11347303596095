import { useEffect, useState } from 'react';
import { CssBaseline, StyledEngineProvider, ThemeProvider, Box, Typography } from '@mui/material';
import WifiOff from '@mui/icons-material/WifiOff';

import createCache, { EmotionCache } from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { useSelector } from 'react-redux';
import 'react-perfect-scrollbar/dist/css/styles.css';

import Routes from 'routes';
import NewVersionModalUpdate from 'components/common/UI/NewVersionModalUpdate';
import { BuildTheme } from 'theme/theme-variable';
import { Notification } from 'components/common/UI/Notification';
import { RootState } from 'store';
// import ErrorBoundary from 'ErrorBoundary';

const cache: EmotionCache = createCache({
    key: 'css',
    prepend: true
});

// Global error handling for unexpected synchronous errors
window.onerror = function (message, source, lineno, colno, error) {
    console.error('Global error caught:', { message, source, lineno, colno, error });
    return true; // Prevent the default browser error handling
};

// Global handler for unhandled promise rejections
window.addEventListener('unhandledrejection', function (event) {
    console.error('Unhandled promise rejection:', event.reason);
    return true; // Prevent the default browser error handling
});

function App() {
    const { isDark } = useSelector((state: RootState) => state.auth);
    const [waitingWorker] = useState<any>({});

    const [isOnline, setIsOnline] = useState(navigator.onLine);

    const theme = BuildTheme({
        activeMode: isDark ? 'dark' : 'light'
    });

    useEffect(() => {
        const handleStatusChange = () => {
            setIsOnline(navigator.onLine);
        };

        window.addEventListener('online', handleStatusChange);

        window.addEventListener('offline', handleStatusChange);

        return () => {
            window.removeEventListener('online', handleStatusChange);
            window.removeEventListener('offline', handleStatusChange);
        };
    }, [isOnline]);

    useEffect(() => {
        const onUpdate = () => {
            console.log('Service worker updated!');
            waitingWorker && waitingWorker.postMessage({ type: 'SKIP_WAITING' });
            window.location.reload();
        };

        if (navigator.serviceWorker) {
            navigator.serviceWorker.addEventListener('controllerchange', onUpdate);
        }

        return () => {
            if (navigator.serviceWorker) {
                navigator.serviceWorker.removeEventListener('controllerchange', onUpdate);
            }
        };
    }, []);

    useEffect(() => {
        const originalWebSocket = window.WebSocket;

        // Custom WebSocket constructor
        window.WebSocket = class extends originalWebSocket {
            constructor(url: string | URL, protocols?: string | string[]) {
                // Call the super constructor
                super(url, protocols);

                // Handle blocking only for specific URLs
                // if (url === 'ws://3000.integ.crawlo.com:3000/ws' || url === 'wss://3000.integ.crawlo.com:3000/ws') {
                //     console.warn('Blocking unwanted WebSocket connection to:', url);

                //     // Remove existing event listeners (if any)
                //     this.removeEventListener('open', this.handleOpen);
                //     this.removeEventListener('error', this.handleError);
                //     this.removeEventListener('close', this.handleClose);

                //     // Listen for the WebSocket connection state change
                //     this.addEventListener('open', () => {
                //         // Close the WebSocket connection once it's open
                //         this.close();
                //     });

                //     // Optionally handle other states like errors or closing
                //     this.addEventListener('error', () => {
                //         console.error('WebSocket error occurred while blocking connection.');
                //         this.close();
                //     });

                //     // Log when the connection is closed
                //     this.addEventListener('close', () => {
                //         console.log('WebSocket connection closed.');
                //     });
                // }
                // Check if this is the WebSocket URL we want to log
                if (url === 'wss://4242.integ.crawlo.com/graphql') {
                    // Listen for the WebSocket connection state change
                    this.addEventListener('open', () => {
                        // Close the WebSocket connection once it's open
                        console.warn('WebSocket opened');
                    });

                    // Optionally handle other states like errors or closing
                    this.addEventListener('error', () => {
                        console.error('WebSocket error occurred while blocking connection.');
                    });

                    // Log when the connection is closed
                    this.addEventListener('close', () => {
                        console.warn('WebSocket connection closed.');
                    });
                }
            }

            // Optional event handler methods to clean up
            handleOpen() {
                console.log('WebSocket opened');
            }

            handleError(event: Event) {
                console.error('WebSocket error:', event);
            }

            handleClose() {
                console.log('WebSocket closed');
            }
        };

        return () => {
            // Restore the original WebSocket when the component unmounts
            window.WebSocket = originalWebSocket;
        };
    }, []); // Run only once when the component mounts

    if (!isOnline) {
        return (
            <Box sx={{ paddingTop: '23px', padding: '132px', textAlign: 'center' }}>
                <WifiOff />
                <Typography sx={{ fontSize: '34px' }}>Your Connection was Offline.</Typography>
                <Typography>Please check your Wifi Connection.</Typography>
            </Box>
        );
    }

    return (
        // <ErrorBoundary>
        <CacheProvider value={cache}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <Routes />
                    <Notification />
                    <NewVersionModalUpdate />
                </ThemeProvider>
            </StyledEngineProvider>
        </CacheProvider>
        // </ErrorBoundary>
    );
}

export default App;
