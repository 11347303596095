import appoloClient from 'common/appolo-client';
import { ACCESS_ACCOUNT_MUTATION, SIGNUP_MUTATION, USER_SESSION_MUTATION } from './queries';

class AuthService {
    async userLogin(email: string, password: string, isOAuth?: boolean) {
        const response = await appoloClient.mutate({
            mutation: SIGNUP_MUTATION,
            variables: {
                key: email,
                password: password,
                isOAuth: isOAuth
            }
        });

        if (!response) throw new Error('Somthing went wrong');

        return response.data;
    }
    async userAccess(user: string) {
        const response = await appoloClient.mutate({
            mutation: ACCESS_ACCOUNT_MUTATION,
            variables: {
                user
            }
        });

        if (!response) throw new Error('Somthing went wrong');

        return response.data;
    }

    async userSession() {
        const response = await appoloClient.query({
            query: USER_SESSION_MUTATION
        });

        if (!response) throw new Error('Somthing went wrong');

        return response;
    }
}

export default AuthService;
