import { saveAs } from 'file-saver';

import ExcelJS from 'exceljs';

/**
 * export BetterWay Data to scv
 *
 * @param {*} data
 * @param {*} fileName
 */
export default function (data: any, fileName: string) {
    try {
        const fields = [
            {
                header: 'Title',
                key: 'title'
            },
            {
                header: 'MM Flagging Progress (InStock)',
                key: 'mmFlaggingProgress'
            },
            {
                header: 'MM Missing Flags Percentage',
                key: 'mmMissingFlagsPercentage'
            }
        ];
        const dataToExport = [];

        //
        for (let i = 0; i < data.series[0].data.length; i++) {
            const mmFlaggingProgress = data.series[0].data[i];
            const mmMissingFlagsPercentage = data.series[1].data[i];
            dataToExport.push({
                title: data.xAxis.data[i],
                mmMissingFlagsPercentage: `${mmFlaggingProgress} %`,
                mmFlaggingProgress: `${mmMissingFlagsPercentage} %`
            });
        }

        const workbook = new ExcelJS.Workbook();

        // sheet
        const worksheetByBrand = workbook.addWorksheet('Better Way data');
        // add column headers
        worksheetByBrand.columns = fields;
        worksheetByBrand.addRows(dataToExport);

        const fileNameDate = new Date().toISOString().split('T')[0];
        // download
        workbook.xlsx.writeBuffer().then(function (data: any) {
            const blob = new Blob([data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            saveAs(blob, `Crawlo_${fileName}_${fileNameDate}_${new Date().getTime()}.xlsx`);
        });
        return true;
    } catch (err) {
        console.log('error :', err);
        return false;
    }
}
