import { useEffect, useRef, useState } from 'react';
import {
    Box,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Paper,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import { FiX } from 'react-icons/fi';

import WebsiteCategory from './WebsiteCategory';
import AdvancedSearch from './advanced-search/AdvancedSearch';
import { useAppSelector } from 'store/hooks';
import { RootState } from 'store';
import { useLazyQuery, useMutation } from '@apollo/client';
import { COMPETITORS_CATEGORIES, SAVE_MODULE_CATEGORY_FILTER, SAVE_PG_CATEGORY_FILTER } from 'services/analysis/queries';
import { LoadingButton } from '@mui/lab';
import { CUSTOM_COLLECTION_QUERY, NEW_CUSTOM_COLLECTION_MUTATION } from 'services/catalog/queries';
import { useNotification } from 'hooks/useNotification';
import MigrationFilter from './advanced-search/MigrationFilter';

interface InstantCategoryProps {
    open: boolean;
    handleClose: () => void;
    handleReload: () => void;
}

function InstantCategory({ open, handleClose, handleReload }: InstantCategoryProps) {
    const { displayNotification } = useNotification();

    const { selectedRegion, selectedWebsites, defaultWebsite, userInfo } = useAppSelector((state: RootState) => state.auth);
    const { moduleValue, productGroupValue } = useAppSelector((state: RootState) => state.customFilter);

    const [advancedFilterData, setAdvancedFilterData] = useState<any>(null);
    const [websitesCategoriesData, setWebsitesCategoriesData] = useState<any>(null);

    const advancedSearchRef = useRef<any>();
    const websiteCategoryRef = useRef<any>();
    const categoryNameRef = useRef<any>();

    const advancedSearch = [
        {
            fieldSearch: 'Module',
            checkOption: 'Is',
            mmsmodule: { ...moduleValue, useRules: true }
        },
        {
            fieldSearch: 'Product Group',
            checkOption: 'Is',
            mmscategory: { ...productGroupValue, useRules: true }
        }
    ];

    const [competitorsDataLoading, { loading, data }] = useLazyQuery(COMPETITORS_CATEGORIES, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache'
    });

    const [getCustomCategory, { loading: customCategoryLoading }] = useLazyQuery(CUSTOM_COLLECTION_QUERY, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache'
    });

    const [savePGCategoryFilter, { loading: PGFilterLoading, error: PGFilterError, data: PGFilterData }] = useMutation(
        SAVE_PG_CATEGORY_FILTER,
        {
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'no-cache'
        }
    );

    const [MMSModuleSaving, { loading: ModuleFilterLoading, error: ModuleFilterError, data: ModuleFilterData }] = useMutation(
        SAVE_MODULE_CATEGORY_FILTER,
        {
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'no-cache'
        }
    );

    const [
        savePGCategoryFilterMigration,
        { loading: PGFilterLoadingMigration, error: PGFilterErrorMigration, data: PGFilterDataMigration }
    ] = useMutation(SAVE_PG_CATEGORY_FILTER, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache'
    });

    const [
        MMSModuleSavingMigration,
        { loading: ModuleFilterLoadingMigration, error: ModuleFilterErrorMigration, data: ModuleFilterDataMigration }
    ] = useMutation(SAVE_MODULE_CATEGORY_FILTER, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache'
    });

    const [saveNewCustomCollection, { loading: customCollectionLoading, data: customCollectionData, error: customCollectionError }] =
        useMutation(NEW_CUSTOM_COLLECTION_MUTATION, {
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'no-cache'
        });

    const handleSubmit = () => {
        const advancedSearch = advancedSearchRef?.current?.getData();
        const competitorsCategories = websiteCategoryRef?.current?.getData();
        const selectedCategories: any[] = [];

        competitorsCategories.websitesCategories.map((competitor: any) => {
            const checkedCategories = competitor.list.filter((item: any) => item.check && item.category);

            checkedCategories.map((webcategory: any) => {
                selectedCategories.push({
                    ...webcategory.category,
                    keywords: competitor.keywords,
                    website: competitor.website
                });
            });
        });

        const advancedSearchData = advancedSearch.values?.advancedFilter.map((item: any, index: number) => {
            return {
                ...item,
                id: `${index + 1}`
            };
        });

        advancedSearchData.push({
            id: `${advancedSearchData.length + 1}`,
            includeExclude: 'Include',
            fieldSearch: 'Website Category',
            checkOption: 'Is',
            websitecategory: {
                website: competitorsCategories.websitesCategories[0].website,
                category: selectedCategories
            }
        });

        if (productGroupValue && productGroupValue.id) {
            const requestVariable = {
                region: selectedRegion,
                name: productGroupValue.name,
                user: userInfo.id,
                id: productGroupValue.id,
                filter: advancedSearchData
            };

            savePGCategoryFilter({
                variables: requestVariable
            });
        }

        if (moduleValue && moduleValue.id) {
            const requestVariable = {
                region: selectedRegion,
                name: moduleValue.name,
                user: userInfo.id,
                id: moduleValue.id,
                filter: advancedSearchData
            };

            MMSModuleSaving({
                variables: requestVariable
            });
        }

        /**
         * save to cache
         */
        localStorage.setItem(
            'cache-mms-filter',
            JSON.stringify({
                id: productGroupValue?.id || moduleValue?.id,
                value: advancedFilterData
            })
        );

        localStorage.setItem(
            'cache-mms-website-category',
            JSON.stringify({
                id: productGroupValue?.id || moduleValue?.id,
                value: websitesCategoriesData
            })
        );
    };

    const handleSubmitMigration = (moduleData: any, productGroupData: any) => {
        const advancedSearch = advancedSearchRef?.current?.getData();
        const competitorsCategories = websiteCategoryRef?.current?.getData();
        const selectedCategories: any[] = [];

        competitorsCategories.websitesCategories.map((competitor: any) => {
            const checkedCategories = competitor.list.filter((item: any) => item.check && item.category);

            checkedCategories.map((webcategory: any) => {
                selectedCategories.push({
                    ...webcategory.category,
                    keywords: competitor.keywords,
                    website: competitor.website
                });
            });
        });

        const advancedSearchData = advancedSearch.values?.advancedFilter.map((item: any, index: number) => {
            return {
                ...item,
                id: `${index + 1}`
            };
        });

        advancedSearchData.push({
            id: `${advancedSearchData.length + 1}`,
            includeExclude: 'Include',
            fieldSearch: 'Website Category',
            checkOption: 'Is',
            websitecategory: {
                website: competitorsCategories.websitesCategories[0].website,
                category: selectedCategories
            }
        });

        if (productGroupData && productGroupData.id) {
            const requestVariable = {
                region: selectedRegion,
                name: productGroupData.name,
                user: userInfo.id,
                id: productGroupData.id,
                filter: advancedSearchData
            };

            savePGCategoryFilterMigration({
                variables: requestVariable
            });
        }

        if (moduleData && moduleData.id) {
            const requestVariable = {
                region: selectedRegion,
                name: moduleData.name,
                user: userInfo.id,
                id: moduleData.id,
                filter: advancedSearchData
            };

            MMSModuleSavingMigration({
                variables: requestVariable
            });
        }
    };

    const checkIfCategoryNameExistAlready = async (categoryName: string) => {
        try {
            const requestData = await getCustomCategory({
                variables: {
                    user: userInfo?.id,
                    q: categoryName,
                    region: selectedRegion
                }
            });

            const categoryItem = requestData.data?.customCategoriesSearch.categories.find(
                (item: { name: string }) => item.name == categoryName
            );

            if (categoryItem) return true;
            else return false;
        } catch (error) {
            console.log('error', error);
            return true;
        }
    };

    const handleSaveAsCustom = async () => {
        const customCategoryName = categoryNameRef?.current?.value;

        if (customCategoryName) {
            const isExist = await checkIfCategoryNameExistAlready(customCategoryName);

            if (isExist === false) {
                const advancedSearch = advancedSearchRef?.current?.getData();
                const competitorsCategories = websiteCategoryRef?.current?.getData();
                const selectedCategories: any[] = [];

                competitorsCategories.websitesCategories.map((competitor: any) => {
                    const checkedCategories = competitor.list.filter((item: any) => item.check && item.category);

                    checkedCategories.map((webcategory: any) => {
                        selectedCategories.push({
                            ...webcategory.category,
                            keywords: competitor.keywords,
                            website: competitor.website
                        });
                    });
                });

                const advancedSearchData = advancedSearch.advancedFilter.map((item: any, index: number) => {
                    return {
                        ...item,
                        id: `${index + 1}`
                    };
                });

                advancedSearchData.push({
                    id: `${advancedSearchData.length + 1}`,
                    includeExclude: 'Include',
                    fieldSearch: 'Website Category',
                    checkOption: 'Is',
                    websitecategory: {
                        website: competitorsCategories.websitesCategories[0].website,
                        category: selectedCategories
                    }
                });

                saveNewCustomCollection({
                    variables: {
                        region: selectedRegion,
                        name: customCategoryName,
                        filter: JSON.stringify(advancedSearchData),
                        websites: selectedWebsites,
                        website: defaultWebsite,
                        user: userInfo?.id
                    }
                });
            }
        } else {
            displayNotification({
                message: `Hey, Custom Collection name is required to save !`,
                type: 'error',
                horizontal: 'right'
            });
        }
    };

    useEffect(() => {
        competitorsDataLoading({
            variables: {
                region: selectedRegion,
                loadModule: moduleValue?.category?.id
                    ? `${moduleValue?.category?.id}`
                    : productGroupValue?.category?.id
                    ? `${productGroupValue?.category?.id}`
                    : '',
                thirdparties: '',
                advancedSearch,
                websites: selectedWebsites,
                website: defaultWebsite
            }
        });
    }, []);

    useEffect(() => {
        if (data) {
            const instantinputs = data.competitorscategories.instantinputsList;
            const competitorsCategories = data.competitorscategories.competitorsCategoriesList;
            const selectedKeywords: any[] = [];

            competitorsCategories.map((competitor: any) => {
                if (competitor.keywords.length) {
                    competitor.keywords.map((item: string) => {
                        selectedKeywords.push({ competitor: competitor.website, tg: item });
                    });
                }
            });

            setWebsitesCategoriesData(competitorsCategories);
            setAdvancedFilterData(instantinputs);
        }
    }, [loading]);

    /**
     * REMOVE DATA FROM CACHE
     */
    useEffect(() => {
        const id = productGroupValue?.id || moduleValue?.id;
        const cacheData = localStorage.getItem('cache-mms-filter');
        const checheWebsiteCategory = localStorage.getItem('cache-mms-website-category');

        if (id && cacheData && JSON.parse(cacheData)?.id && JSON.parse(cacheData)?.id != id) {
            localStorage.removeItem('cache-mms-filter');
        }

        if (id && checheWebsiteCategory && JSON.parse(checheWebsiteCategory)?.id && JSON.parse(checheWebsiteCategory)?.id != id) {
            localStorage.removeItem('cache-mms-website-category');
        }
    }, []);

    useEffect(() => {
        if (PGFilterData) {
            displayNotification({
                message: `MMS PG saved successfully.`,
                type: 'success',
                horizontal: 'right'
            });

            handleReload();
            handleClose();
        }

        if (PGFilterError) {
            displayNotification({
                message: `Error saving MMS PG.`,
                type: 'error',
                horizontal: 'right'
            });
        }
    }, [PGFilterLoading]);

    useEffect(() => {
        if (ModuleFilterData) {
            displayNotification({
                message: `MMS Module saved successfully.`,
                type: 'success',
                horizontal: 'right'
            });

            handleReload();
            handleClose();
        }

        if (ModuleFilterError) {
            displayNotification({
                message: `Error saving MMS Module.`,
                type: 'error',
                horizontal: 'right'
            });
        }
    }, [ModuleFilterLoading]);

    useEffect(() => {
        if (PGFilterDataMigration) {
            displayNotification({
                message: `MMS PG saved successfully.`,
                type: 'success',
                horizontal: 'right'
            });
        }

        if (PGFilterErrorMigration) {
            displayNotification({
                message: `Error saving MMS PG.`,
                type: 'error',
                horizontal: 'right'
            });
        }
    }, [PGFilterLoadingMigration]);

    useEffect(() => {
        if (ModuleFilterDataMigration) {
            displayNotification({
                message: `MMS Module saved successfully.`,
                type: 'success',
                horizontal: 'right'
            });
        }

        if (ModuleFilterErrorMigration) {
            displayNotification({
                message: `Error saving MMS Module.`,
                type: 'error',
                horizontal: 'right'
            });
        }
    }, [ModuleFilterLoadingMigration]);

    useEffect(() => {
        if (customCollectionData) {
            displayNotification({
                message: `Custom Collection saved successfully.`,
                type: 'success',
                horizontal: 'right'
            });

            handleClose();
        }
        if (customCollectionError) {
            displayNotification({
                message: `Error saving Custom Collection.`,
                type: 'error',
                horizontal: 'right'
            });
        }
    }, [customCollectionLoading]);

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth={'lg'}
            scroll="paper"
            aria-labelledby="instant-category-dialog-title"
            aria-describedby="instant-category-dialog-description"
        >
            <DialogTitle id="dialog-title-instant">
                <Stack spacing={{ xs: 2, sm: 2 }} direction="row" useFlexGap flexWrap="wrap">
                    <Typography variant="h5" component="p">
                        Instant Category
                    </Typography>
                </Stack>

                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <FiX />
                </IconButton>
            </DialogTitle>

            <DialogContent dividers={true} sx={{ minHeight: '100%' }}>
                <Box sx={{ minHeight: 500 }}>
                    {loading || !websitesCategoriesData || !advancedFilterData ? (
                        <Box sx={{ minHeight: 400, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <CircularProgress size={30} />
                        </Box>
                    ) : (
                        <Stack direction={'column'} spacing={3} sx={{ mt: 2 }}>
                            <Paper sx={{ p: 2 }} elevation={6}>
                                <Typography variant="h4" sx={{ mb: 4 }}>
                                    Websites Categories
                                </Typography>
                                {websitesCategoriesData && (
                                    <WebsiteCategory ref={websiteCategoryRef} websitesCategoriesData={websitesCategoriesData} />
                                )}
                            </Paper>
                            <Paper sx={{ p: 2 }} elevation={6}>
                                <Typography variant="h4" sx={{ mb: 4 }}>
                                    Advanced Filter
                                </Typography>
                                {advancedFilterData && <AdvancedSearch ref={advancedSearchRef} advancedFilterData={advancedFilterData} />}
                            </Paper>
                            <Paper sx={{ p: 2 }} elevation={6}>
                                <Typography variant="h4" sx={{ mb: 4 }}>
                                    Custom Collection Name
                                </Typography>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    name="categoryName"
                                    placeholder="Name"
                                    sx={{ width: 500 }}
                                    inputRef={categoryNameRef}
                                />
                            </Paper>
                            <MigrationFilter
                                handleSubmitMigration={handleSubmitMigration}
                                ModuleFilterLoading={ModuleFilterLoadingMigration}
                                PGFilterLoading={PGFilterLoadingMigration}
                            />
                        </Stack>
                    )}
                </Box>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    variant="outlined"
                    onClick={handleSaveAsCustom}
                    disabled={loading}
                    loading={customCategoryLoading || customCollectionLoading}
                >
                    Save as Custom Collection
                </LoadingButton>
                <LoadingButton
                    variant="contained"
                    onClick={handleSubmit}
                    size="small"
                    disabled={loading}
                    loading={PGFilterLoading || ModuleFilterLoading}
                >
                    {productGroupValue && productGroupValue.id ? 'Save MMS Category' : 'Save MMS Module'}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default InstantCategory;
