import { Box, Link, Stack, Typography } from '@mui/material';
import { FaCrown } from 'react-icons/fa';

import { nFormatter } from 'utils/nFormatter';
import { useAppDispatch } from 'store/hooks';
import { resetInputListItem, submitFilter, updateGoogleCategoryQuery } from 'store/slices/filter/filterSlice';

interface GFKProps {
    gfkId?: string;
    gfkPosition?: number;
    gfkPositionInCategory?: number;
    googleCategory?: any;
    isWinning?: boolean;
    isModal?: boolean;
    gfkSalesValue?: string;
    gfkUnit?: string;
    gfkVariants?: string;
    gfkMethod?: string;
    gfkMonth?: string;
    gfkVariantsLoad?: boolean;
    showDetails?: boolean;
    showMethod?: boolean;
    handleClickGfkVariants?: () => void;
}

function GFK({
    gfkPositionInCategory,
    googleCategory,
    isWinning,
    isModal,
    gfkMethod,
    gfkSalesValue,
    gfkUnit,
    gfkVariants,
    handleClickGfkVariants,
    gfkVariantsLoad = true,
    showDetails = true,
    showMethod = true,
    gfkMonth
}: GFKProps) {
    const dispatch = useAppDispatch();

    const formatGFKDate = (str?: string) => {
        if (str) {
            const num = str.replace(/[^0-9]/g, '');
            const month = str.replace(num, '');
            return `${month} 20${num}`;
        }
        return '?';
    };

    const handleClickGoogleCategory = () => {
        if (isModal) return;

        const breadcrumbs = `${googleCategory?.ref} - ${googleCategory.name} > ${googleCategory.parents
            .map((item: { name: string }) => `${item.name}`)
            .join(' > ')}`;

        dispatch(updateGoogleCategoryQuery(googleCategory.ref));
        dispatch(
            resetInputListItem({
                data: {
                    id: '1',
                    fieldSearch: 'Google Category',
                    checkOption: 'Is',
                    includeExclude: 'Include',
                    // google: googleCategory && googleCategory.ref ? { ...googleCategory, breadcrumbs } : undefined
                    google: googleCategory && googleCategory.ref ? [{ ...googleCategory, breadcrumbs }] : undefined
                }
            })
        );
        dispatch(submitFilter());
    };

    return (
        <>
            {gfkVariants && parseInt(gfkVariants) != 1 && (
                <Stack direction={'row'} spacing={0.6} sx={{ p: '5px', paddingLeft: '0px' }}>
                    {isWinning && <FaCrown size={15} />}
                    {/* <Typography variant="body2" fontSize={12}>
                        {isWinning && <>Winning Variant</>}
                        {!isWinning && <>Variant</>}
                    </Typography> */}
                    <Typography variant="body2" fontSize={12} fontWeight={600}>
                        {gfkVariantsLoad && (
                            <Link onClick={handleClickGfkVariants} color="inherit" sx={{ cursor: 'pointer' }}>
                                {`${gfkVariants} Variant`}
                            </Link>
                        )}
                        {!gfkVariantsLoad && <Link color="inherit">{`${gfkVariants} Variant`}</Link>}
                        {showMethod && (gfkMethod == 'GFK_SHARED_DATA' ? <> (GFK)</> : <> (Crawlo)</>)}
                    </Typography>
                </Stack>
            )}
            {showDetails ? (
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                    sx={{ border: '1px solid rgba(238, 240, 241, 1)', backgroundColor: 'rgba(238, 240, 241, 1)', borderRadius: 1, p: 0.5 }}
                >
                    <Box
                        sx={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            border: '1px solid #f96654',
                            borderRadius: 1,
                            background: '#f96654',
                            height: '100%',
                            p: '10px',
                            minWidth: '70px'
                        }}
                    >
                        <Typography
                            sx={{ justifyContent: 'center', alignItems: 'center' }}
                            variant="body2"
                            fontSize={12}
                            fontWeight={600}
                            color="white"
                            align="center"
                        >
                            Market
                        </Typography>
                        <Typography
                            sx={{ justifyContent: 'center', alignItems: 'center' }}
                            variant="body2"
                            fontSize={12}
                            fontWeight={600}
                            color="white"
                            align="center"
                        >
                            Sales
                        </Typography>
                    </Box>

                    <Box sx={{ p: 0.5 }}>
                        <Typography variant="body2" fontSize={12} color={'black'}>
                            Ranked <strong style={{ color: '#f96654', fontWeight: 600 }}>{gfkPositionInCategory}</strong> in{' '}
                            <strong onClick={handleClickGoogleCategory} style={{ color: '#f96654', fontWeight: 600, cursor: 'pointer' }}>
                                {googleCategory?.name}
                            </strong>
                        </Typography>
                        {gfkSalesValue && (
                            <Typography variant="body2" fontSize={12} color={'black'}>
                                With <strong className="marketvalue">{nFormatter(parseFloat(gfkSalesValue))} €</strong> for (
                                <span className="marketvalue">{parseInt(gfkUnit || '')}</span> Units) -{' '}
                                <span className="marketvalue">{formatGFKDate(gfkMonth)}</span>
                            </Typography>
                        )}
                    </Box>
                </Stack>
            ) : null}
        </>
    );
}

export default GFK;
