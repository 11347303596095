import React, { useEffect, useState } from 'react';
import { Box, Drawer, useMediaQuery, List, ListItemButton, ListItemIcon, ListItemText, Theme, Collapse } from '@mui/material';
import { FiChevronRight, FiChevronDown } from 'react-icons/fi';
import { MdPerson, MdChatBubbleOutline } from 'react-icons/md';
// import ChatIcon from '@mui/icons-material/Chat';
import Scrollbar from 'components/common/UI/ScrollBar';
import { SidebarWidth } from '../../../theme/theme-variable';
import { useLazyQuery } from '@apollo/client';
import useChat from 'hooks/useChat';
import { NavLink } from 'react-router-dom';
import { GET_ALL_CHATS } from 'views/dashboard/AssistantPage/queries/queries';
import { useAppSelector } from 'store/hooks';
import { chatDashboardAssitantUrl } from '..';

interface SideBarProps {
    isMobileSidebarOpen: boolean;
    isSidebarOpen: boolean;
    handleCloseMobileDrawer: () => void;
}

function SideBarchatsDashboard({ isMobileSidebarOpen, isSidebarOpen, handleCloseMobileDrawer }: SideBarProps) {
    const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
    const { currentAccess, id } = useChat();
    const { isDark } = useAppSelector((state: any) => state.auth);

    // State to hold chat items
    const [chats, setChats] = useState<any[]>([]);

    const [openUser, setOpenUser] = useState<string | null>(null);

    const handleToggleUser = (username: string) => {
        setOpenUser(openUser === username ? null : username);
    };

    const [getAllChats, { data: dataChats, error: errorChats }] = useLazyQuery(GET_ALL_CHATS, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache'
    });

    useEffect(() => {
        const userID = currentAccess.user.id; // Example user ID, if applicable
        if (userID) {
            getAllChats({
                variables: {
                    userID: userID
                }
            });
        }
    }, [currentAccess.user.id, id]); // Ensure it updates on user ID change

    // Log dataChats when it changes
    useEffect(() => {
        // setChatStarted(true);
        if (dataChats) {
            if (dataChats.getAllChats) {
                if (dataChats.getAllChats) {
                    const reversedThreads = dataChats.getAllChats.map((user: any) => {
                        return {
                            ...user,
                            threadIdsInfos: user.threadIdsInfos.reverse()
                        };
                    });
                    setChats(reversedThreads);
                }
            } else {
                console.error('Unexpected structure of dataChats', dataChats);
            }
        }
    }, [dataChats]);

    useEffect(() => {
        if (id && chats.length > 0) {
            const selectedUser = chats.find((user: any) => user.threadIdsInfos.find((thread: any) => thread.id === id));
            if (selectedUser) setOpenUser(selectedUser?.username);
        }
    }, [id, chats]);

    if (errorChats) {
        console.error('Error loading chats:', errorChats);
        <div>Error loading chats</div>;
    }

    const SidebarContent = (
        <List>
            {chats.map((user: any) => (
                <React.Fragment key={user.username}>
                    {/* Parent List Item */}
                    <ListItemButton
                        sx={{
                            bgcolor: openUser === user.username ? (isDark ? '#ffede9' : '#ffe5e1') : 'inherit',
                            color: openUser === user.username ? (isDark ? '#3c4043' : '#3c4043') : isDark ? '#ffffff' : '#3c4043',
                            borderRadius: '8px',
                            mx: 1, // Add horizontal margin for spacing
                            '&:hover': {
                                bgcolor: openUser === user.username ? (isDark ? '#ffcec3' : '#ffd7d1') : isDark ? '#444444' : '#f5f5f5'
                            }
                        }}
                        onClick={() => handleToggleUser(user.username)}
                    >
                        <ListItemIcon>
                            <MdPerson />
                        </ListItemIcon>
                        <ListItemText
                            primary={user.username.substring(0, 12) + (user.username.length > 12 ? '...' : '')}
                            title={user.username} // Shows the full name on hover
                        />
                        {openUser === user.username ? <FiChevronDown size="16" /> : <FiChevronRight size="16" />}
                    </ListItemButton>

                    {/* Collapsible Sublist */}
                    <Collapse in={openUser === user.username} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding sx={{ pl: 1 }}>
                            {user.threadIdsInfos.map((thread: any) => (
                                <ListItemButton
                                    sx={{
                                        bgcolor: id === thread.id ? (isDark ? '#ffede9' : '#ffe5e1') : 'inherit',
                                        color: id === thread.id ? (isDark ? '#3c4043' : '#3c4043') : isDark ? '#ffffff' : '#3c4043',
                                        borderRadius: '8px',
                                        mx: 1,
                                        my: 0.5,
                                        '&:hover': {
                                            bgcolor: id === thread.id ? (isDark ? '#ffcec3' : '#ffd7d1') : isDark ? '#444444' : '#f5f5f5'
                                        }
                                    }}
                                    key={thread.id}
                                    component={NavLink}
                                    to={`${chatDashboardAssitantUrl}/${thread.id}`}
                                >
                                    <ListItemIcon>
                                        <MdChatBubbleOutline />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={thread.name.substring(0, 10) + (thread.name.length > 10 ? '...' : '')}
                                        title={thread.name} // Shows the full name on hover
                                    />
                                </ListItemButton>
                            ))}
                        </List>
                    </Collapse>
                </React.Fragment>
            ))}
        </List>
    );

    if (lgUp) {
        return (
            <Drawer
                anchor="left"
                open={isSidebarOpen}
                variant="persistent"
                PaperProps={{
                    sx: {
                        width: SidebarWidth,
                        top: lgUp ? '100px' : '0px',
                        left: lgUp ? '30px' : '',
                        borderRadius: lgUp ? '9px' : '0',
                        border: '0',
                        height: 'calc(100vh - 130px)',
                        boxShadow: '0px 7px 30px 0px rgb(90 114 123 / 11%)'
                    }
                }}
            >
                <Scrollbar style={{ height: 'calc(100vh - 5px)' }}>
                    <Box sx={{ p: 2 }}>{SidebarContent}</Box>
                </Scrollbar>
            </Drawer>
        );
    } else {
        return (
            <Drawer
                anchor="left"
                open={isMobileSidebarOpen}
                onClose={handleCloseMobileDrawer}
                PaperProps={{
                    sx: {
                        width: SidebarWidth,
                        border: '0 !important'
                    }
                }}
                variant="temporary"
            >
                <Scrollbar style={{ height: 'calc(100vh - 5px)' }}>
                    <Box sx={{ p: 2 }}>{SidebarContent}</Box>
                </Scrollbar>
            </Drawer>
        );
    }
}

export default SideBarchatsDashboard;
