import { useState, useEffect } from 'react';
import { Avatar, Tooltip, Badge } from '@mui/material';

import { styled } from '@mui/material/';

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""'
        }
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0
        }
    }
}));

function stringToColor(str: string) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xff;
        color += ('00' + value.toString(16)).substr(-2);
    }
    return color;
}

interface BrandIconProps {
    brandName: string;
    brandDomain: string;
}

const BrandIcon = ({ brandName, brandDomain }: BrandIconProps) => {
    const [logoUrl, setLogoUrl] = useState(`https://logo.clearbit.com/${brandDomain}`);
    const [logoNotFound, setLogoNotFound] = useState(false);

    useEffect(() => {
        fetch(logoUrl)
            .then((response) => {
                if (!response.ok) {
                    setLogoNotFound(true);
                } else {
                    setLogoUrl(`https://logo.clearbit.com/${brandDomain}`);
                }
            })
            .catch(() => {
                setLogoNotFound(true);
            });
    }, [logoUrl]);

    const getInitials = (name: string) =>
        name
            .split(' ')
            .map((word) => word[0])
            .join('')
            .toUpperCase();

    const style = {
        backgroundColor: stringToColor(brandName), // Using the hash color function
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '30px',
        height: '30px',
        color: 'white',
        fontSize: '1em'
    };

    return (
        <>
            <Tooltip title={`Default brand is : ${brandName}`} placement="top">
                <span>
                    {logoNotFound ? (
                        <div style={style}>{getInitials(brandName)}</div>
                    ) : (
                        <StyledBadge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} variant="dot">
                            <Avatar alt={brandName} src={logoUrl} sx={{ width: 30, height: 30 }} />
                        </StyledBadge>
                    )}
                </span>
            </Tooltip>
        </>
    );
};

export default BrandIcon;
