import { LoadingButton } from '@mui/lab';
import { FormControl, Grid, MenuItem, Paper, Select, Stack, Typography } from '@mui/material';
import SingleModule from 'components/filter/field/SingleModule';
import SingleProductGroup from 'components/filter/field/SingleProductGroup';
import { useState } from 'react';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';

interface MigrationFilterProps {
    handleSubmitMigration: (moduleData: any, productGroupData: any) => void;
    PGFilterLoading: boolean;
    ModuleFilterLoading: boolean;
}
function MigrationFilter({ PGFilterLoading, ModuleFilterLoading, handleSubmitMigration }: MigrationFilterProps) {
    const { accuracyFilterField } = useAppSelector((state: RootState) => state.customFilter);

    const [moduleValue, setModuleValue] = useState<any>(null);
    const [queryModule, setQueryModule] = useState<string>('');
    const [productGroupValue, setProductGroupValue] = useState<any>(null);
    const [queryPG, setQueryPG] = useState<string>('');

    const handleChangeModule = (option: any) => {
        setModuleValue(option);
    };

    const handleChangeProductGroup = (option: any) => {
        setProductGroupValue(option);
    };

    const handleModuleInputChange = (queryData: string) => {
        setQueryModule(queryData || '');
    };

    const handleProductGroupInputChange = (queryData: string) => {
        setQueryPG(queryData || '');
    };

    return (
        <Paper sx={{ p: 2 }} elevation={6}>
            <Typography variant="h4" sx={{ mb: 4 }}>
                Migration
            </Typography>
            <Grid container spacing={2} justifyContent={'center'} alignItems={'center'}>
                <Grid item xs={2}>
                    <FormControl fullWidth size="small">
                        <Select displayEmpty value={accuracyFilterField} disabled>
                            <MenuItem value={'Module'}>Module</MenuItem>
                            <MenuItem value={'Product Group'}>Product Group</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={7}>
                    <Stack direction="row" spacing={2}>
                        {accuracyFilterField == 'Module' && (
                            <SingleModule
                                mmsModuleValue={moduleValue}
                                isCustomModule
                                customHandleChange={handleChangeModule}
                                customQuery={queryModule}
                                customHandleInputChange={handleModuleInputChange}
                            />
                        )}

                        {accuracyFilterField == 'Product Group' && (
                            <SingleProductGroup
                                productGroupValue={productGroupValue}
                                isCustomGroup
                                customHandleChange={handleChangeProductGroup}
                                customHandleInputChange={handleProductGroupInputChange}
                                customQuery={queryPG}
                            />
                        )}
                    </Stack>
                </Grid>
                <Grid item xs={3}>
                    <LoadingButton
                        variant="contained"
                        onClick={() => {
                            handleSubmitMigration(moduleValue, productGroupValue);
                        }}
                        disabled={PGFilterLoading || ModuleFilterLoading || (!moduleValue && !productGroupValue)}
                        loading={PGFilterLoading || ModuleFilterLoading}
                    >
                        Migrate
                    </LoadingButton>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default MigrationFilter;
