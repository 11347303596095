import { Typography } from '@mui/material';

interface TabContainerProps {
    children: any;
}

function MyTabContainer({ children }: TabContainerProps) {
    return <Typography component="div">{children}</Typography>;
}
export default MyTabContainer;
