import { useState } from 'react';

import { Box, Button, MobileStepper, Typography } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import ImageComponent from 'components/common/UI/ImageComponent';

interface ProductImagesCarouselProps {
    images?: string[];
}

function ProductImagesCarousel({ images = [] }: ProductImagesCarouselProps) {
    const [activeStep, setActiveStep] = useState<number>(0);
    const maxSteps: number = images.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep: number) => prevActiveStep - 1);
    };

    const handleStepChange = (step: number) => {
        setActiveStep(step);
    };

    if (!images.length)
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>
                <Typography>No images to show</Typography>
            </Box>
        );

    return (
        <Box sx={{ flexGrow: 1 }}>
            <SwipeableViews axis={'x'} index={activeStep} onChangeIndex={handleStepChange} enableMouseEvents>
                {images.map((image, index) => (
                    <Box key={index.toString()}>
                        {Math.abs(activeStep - index) <= 2 ? (
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <ImageComponent
                                    imageSource={image}
                                    height={200}
                                    sx={{
                                        overflow: 'hidden',
                                        maxWidth: '100%',
                                        width: 'auto'
                                    }}
                                />
                            </Box>
                        ) : null}
                    </Box>
                ))}
            </SwipeableViews>
            {images.length > 1 && (
                <MobileStepper
                    steps={maxSteps}
                    variant="progress"
                    position="static"
                    sx={{ bgcolor: 'transparent' }}
                    activeStep={activeStep}
                    nextButton={
                        <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                            Next
                            <FiArrowRight width="18" />
                        </Button>
                    }
                    backButton={
                        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                            <FiArrowLeft width="18" />
                            Back
                        </Button>
                    }
                />
            )}
        </Box>
    );
}

export default ProductImagesCarousel;
