import { createSlice } from '@reduxjs/toolkit';
import { accessAccountUser, loginUser } from './authActions';

const sessionId = localStorage.getItem('sessionId') || null;
const storedSelectedRegion = localStorage.getItem('selectedRegion') || null;
let storedSelectedWebsites: any = null;
try {
    storedSelectedWebsites = localStorage.getItem('selectedWebsites') ? JSON.parse(localStorage.getItem('selectedWebsites') || '') : null;
} catch (err) {
    console.log('cannot parse the selected websites ...', err);
}
let storedSelectedWebsite: any = null;
try {
    storedSelectedWebsite = localStorage.getItem('selectedWebsite') ? JSON.parse(localStorage.getItem('selectedWebsite') || '') : null;
} catch (err) {
    console.log('cannot parse the selected websites ...', err);
}
let storedSelectedBrands: any[] = [];
try {
    storedSelectedBrands = localStorage.getItem('selectedBrands') ? JSON.parse(localStorage.getItem('selectedBrands') || '') : null;
} catch (err) {
    console.log('cannot parse the selected websites ...', err);
}
const storedUserInfo = localStorage.getItem('userInfo') || null;
const userRegions = localStorage.getItem('userRegions') || null;
const userWebsites = localStorage.getItem('userWebsites') || null;
const userBrands = localStorage.getItem('userBrands') || null;
const isDarkTheme = localStorage.getItem('theme') || 'light';

const selectedRegion = localStorage.getItem('selectedRegion') || 'es';

const websitesRegions = userWebsites ? JSON.parse(userWebsites).filter((el: any) => el.region == selectedRegion)[0].websites : [];
const currentBrandsRegion =
    userBrands && JSON.parse(userBrands) ? JSON.parse(userBrands).filter((el: any) => el.region == selectedRegion) : [];
const brandsRegions = currentBrandsRegion.length ? currentBrandsRegion[0].brands : [];

const storedDefaultWebsite = websitesRegions?.filter((elm: any) => elm.default);
const storedIsMMS = localStorage.getItem('isMMS');
const storedIsBrand = localStorage.getItem('isBrand');
const storedIsVisibility = localStorage.getItem('isVisibility');
const appVersion = localStorage.getItem('appVersion');

const initialState = {
    isLoggedIn: sessionId ? true : false,
    newVersion: appVersion || '',
    newAppVersion: false,
    isDark: isDarkTheme == 'dark' ? true : false,
    selectedRegion: storedSelectedRegion ? storedSelectedRegion : '',
    selectedWebsites: storedSelectedWebsites ? storedSelectedWebsites : websitesRegions,
    selectedWebsite: storedSelectedWebsite
        ? storedSelectedWebsite
        : storedDefaultWebsite && storedDefaultWebsite.length
        ? storedDefaultWebsite[0]
        : null,
    selectedBrands: storedSelectedBrands ? storedSelectedBrands : brandsRegions,
    userRegions: userRegions ? userRegions.split(',') : [],
    userWebsites: userWebsites ? JSON.parse(userWebsites) : [],
    userBrands: userBrands ? JSON.parse(userBrands) : [],
    userInfo: storedUserInfo ? JSON.parse(storedUserInfo) : {},
    isMMS: storedIsMMS ? storedIsMMS : false,
    isBrand: storedIsBrand && String(storedIsBrand) == 'true' ? true : false,
    isVisibility: storedIsVisibility && String(storedIsVisibility) == 'true' ? true : false,
    isPrivacy: false,
    defaultWebsite: storedDefaultWebsite && storedDefaultWebsite.length ? storedDefaultWebsite[0] : {},
    accessAccountUser: [],
    menuItem: [],
    loadingMenuItems: true,
    loading: false,
    error: false,
    success: false,
    loadingAccess: false
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        userLogging: (state) => {
            state.isLoggedIn = true;
        },
        setNewAppVersion: (state, { payload }) => {
            state.newVersion = payload;
        },
        updateNewAppVersion: (state, { payload }) => {
            state.newAppVersion = payload;
        },
        userLoggingOut: () => {
            const appVersion = localStorage.getItem('appVersion');
            localStorage.clear();
            if (appVersion) localStorage.setItem('appVersion', appVersion);
            return {
                isLoggedIn: false,
                isDark: false,
                newVersion: appVersion || '',
                newAppVersion: false,
                selectedRegion: '',
                selectedWebsite: null,
                selectedWebsites: storedSelectedWebsites ? storedSelectedWebsites : websitesRegions,
                selectedBrands: storedSelectedBrands ? storedSelectedBrands : brandsRegions,
                userBrands: [],
                userRegions: [],
                userWebsites: [],
                userInfo: {},
                isMMS: false,
                isBrand: false,
                isVisibility: false,
                isPrivacy: false,
                defaultWebsite: {},
                accessAccountUser: [],
                menuItem: [],
                loadingMenuItems: false,
                loading: false,
                error: false,
                success: false,
                loadingAccess: false
            };
        },
        selectRegion: (state, { payload }) => {
            localStorage.setItem('selectedRegion', payload);
            state.selectedRegion = payload;
            state.isLoggedIn = true;
        },
        updateRegion: (state, { payload }) => {
            localStorage.setItem('selectedRegion', payload);
            state.selectedRegion = payload;
        },
        addUserConfig: (state, { payload }) => {
            state.accessAccountUser = payload;
        },
        addMenuItem: (state, { payload }) => {
            state.menuItem = payload;
        },
        loadMenuItem: (state, { payload }) => {
            state.loadingMenuItems = payload;
        },
        updateIsMMS: (state, { payload }) => {
            localStorage.setItem('isMMS', payload);
            state.isMMS = payload;
        },
        updateIsBrand: (state, { payload }) => {
            localStorage.setItem('isBrand', payload);
            state.isBrand = payload;
        },
        updateIsVisibility: (state, { payload }) => {
            localStorage.setItem('isVisibility', payload);
            state.isVisibility = payload;
        },
        updatedefaultWebsite: (state, { payload }) => {
            localStorage.setItem('defaultWebsite', JSON.stringify(payload));
            state.defaultWebsite = payload;
        },
        updateUserRegions: (state, { payload }) => {
            localStorage.setItem('userRegions', payload.join(','));
            state.userRegions = payload;
        },
        updateUserWebsites: (state, { payload }) => {
            localStorage.setItem('userWebsites', JSON.stringify(payload));
            state.userWebsites = payload;
        },
        updateUserBrands: (state, { payload }) => {
            localStorage.setItem('userBrands', JSON.stringify(payload));
            state.userBrands = payload;
        },
        updateUserInfo: (state, { payload }) => {
            localStorage.setItem('userInfo', JSON.stringify(payload));
            state.userInfo = payload;
            state.isPrivacy = payload.acceptPrivacy;
        },
        updatePrivacy: (state, { payload }) => {
            state.isPrivacy = payload;
        },
        updateSelectedWebsites: (state, { payload }) => {
            localStorage.setItem('selectedWebsites', JSON.stringify(payload));
            state.selectedWebsites = payload;
        },
        updateSelectedBrands: (state, { payload }) => {
            localStorage.setItem('selectedBrands', JSON.stringify(payload));
            state.selectedBrands = payload;
        },
        changeTheme: (state) => {
            state.isDark = !state.isDark;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginUser.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(loginUser.fulfilled, (state, { payload }) => {
                state.error = false;

                const userInfo = payload?.login?.user;

                if (userInfo) {
                    const userSessionId = userInfo.id;

                    localStorage.setItem('sessionId', userSessionId);
                    localStorage.setItem('userInfo', JSON.stringify(userInfo));
                    state.userInfo = userInfo;
                    state.isLoggedIn = true;
                    state.isPrivacy = userInfo.acceptPrivacy;
                } else {
                    state.error = payload?.login?.message;
                    state.loading = false;
                }
            })
            .addCase(loginUser.rejected, (state) => {
                state.loading = false;
                state.error = false;
            })
            .addCase(accessAccountUser.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.error = false;

                state.accessAccountUser = payload.accesses.list;
                const regions: string[] = payload.accesses.list.map((item: any) => item.account.config.region);
                const websites: any[] = payload.accesses.list.map((item: any) => {
                    const defaultWebsite = item.account.website;
                    return {
                        region: item.account.config.region,
                        websites: item.account.websites.map((website: any) => ({
                            alias: website.alias,
                            name: website.name,
                            id: website.id,
                            data: website.data,
                            default: website.id == defaultWebsite.id,
                            url: website.url
                        }))
                    };
                });

                const currentAccount = payload.accesses.list.find((item: any) => item.account.config.region === selectedRegion);

                if (currentAccount?.account?.config?.mms == true) state.isMMS = true;
                else state.isMMS = false;

                if (currentAccount?.account?.config?.isBrandAcount == true) state.isBrand = true;
                else state.isBrand = false;

                if (currentAccount?.account?.config?.isVisibility == true) state.isVisibility = true;
                else state.isVisibility = false;

                if (regions.length) {
                    state.userRegions = regions;
                    localStorage.setItem('userRegions', regions.join(','));
                }
                if (websites.length) {
                    state.userWebsites = websites;
                    localStorage.setItem('userWebsites', JSON.stringify(websites));
                }

                const brands: any[] = payload.accesses.list.map((item: any) => {
                    const defaultBrand = item.account.defaultBrand;
                    return {
                        region: item.account.config.region,
                        listOfCategories: item.account.listOfCategories.map((category: any, index: number) => {
                            return {
                                ref: category,
                                id: index
                            };
                        }),
                        brands: item.account.listOfBrands.map((brand: any, index: number) => ({
                            name: brand,
                            id: index,
                            default: brand == defaultBrand
                        }))
                    };
                });
                if (brands.length) {
                    state.userBrands = brands;
                    localStorage.setItem('userBrands', JSON.stringify(brands));
                }
            });
    }
});

export const {
    userLoggingOut,
    userLogging,
    selectRegion,
    addUserConfig,
    addMenuItem,
    loadMenuItem,
    updateRegion,
    updatedefaultWebsite,
    updateUserRegions,
    updateUserWebsites,
    updateUserInfo,
    updateIsMMS,
    updateIsBrand,
    updateIsVisibility,
    updatePrivacy,
    changeTheme,
    updateSelectedWebsites,
    updateSelectedBrands,
    updateUserBrands,
    setNewAppVersion,
    updateNewAppVersion
} = authSlice.actions;

export default authSlice.reducer;
