import { useEffect, useState } from 'react';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { useQuery } from '@apollo/client';

import { AMAZON_BEST_SELLER_CATEGORY } from 'services/filterService/queries';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { submitFilter, updateAmazonBestSeller, updateAmazonBestSellerQuery } from 'store/slices/filter/filterSlice';

function AmazonCategory() {
    const dispatch = useAppDispatch();

    const { selectedRegion, userWebsites, selectedWebsites } = useAppSelector((state) => state.auth);
    const { datePicker, amazonBestSeller, queryAmazonBestSeller } = useAppSelector((state) => state.filter);

    const [amazonCategory, setAmazonCategory] = useState([]);

    const websites: [] = userWebsites.filter((item: any) => item.region === selectedRegion)[0]?.websites || [];
    const amazonWebsite: any = websites.find((item: any) => /amazon/.test(item?.alias));

    const { loading, data } = useQuery(AMAZON_BEST_SELLER_CATEGORY, {
        variables: {
            region: selectedRegion,
            date: datePicker,
            websites: selectedWebsites,
            // eslint-disable-next-line camelcase
            website_amazon: amazonWebsite,
            q: queryAmazonBestSeller
        },
        notifyOnNetworkStatusChange: true
    });

    const handleChange = (event: React.SyntheticEvent, option: any) => {
        dispatch(updateAmazonBestSeller(option));
        dispatch(submitFilter());
    };

    useEffect(() => {
        if (data) {
            setAmazonCategory(data.BestSellersCategorySearch.categoryresult);
        }
    }, [loading]);

    return (
        <Autocomplete
            fullWidth
            disablePortal
            size="small"
            id="amazon-category"
            value={amazonBestSeller}
            options={amazonCategory}
            isOptionEqualToValue={(option, value) => option.ref === value.ref}
            getOptionLabel={(item: any) => (item?.breadcrumbs ? item.breadcrumbs : item?.name)}
            onChange={handleChange}
            renderInput={(params) => (
                <TextField
                    {...params}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => dispatch(updateAmazonBestSellerQuery(event.target.value))}
                    placeholder="Category"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        )
                    }}
                />
            )}
        />
    );
}

export default AmazonCategory;
